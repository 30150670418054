import {
  BaseTextField,
  Icon_default,
  Xmark_default,
  __async,
  __objRest,
  __restKey,
  __spreadProps,
  __spreadValues,
  config,
  css,
  keyframes,
  styled,
  theme
} from "./chunk-DNESVPGM.mjs";

// src/Accordion/Accordion.tsx
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var StyledAccordion = styled(AccordionPrimitive.Root, {});
var Accordion = React.forwardRef((_a, forwardedRef) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx(
    StyledAccordion,
    __spreadProps(__spreadValues(__spreadValues({
      ref: forwardedRef
    }, props), props.type === "single" ? { collapsible: true } : {}), {
      children
    })
  );
});
var StyledItem = styled(AccordionPrimitive.Item, {});
var StyledHeader = styled(AccordionPrimitive.Header, {
  all: "unset"
});
var StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: "unset",
  boxSizing: "border-box",
  userSelect: "none",
  "&::before": {
    boxSizing: "border-box"
  },
  "&::after": {
    boxSizing: "border-box"
  },
  display: "flex",
  alignItems: "center",
  width: "100%",
  m: "$1 0",
  fontFamily: "$primary",
  fontSize: "$2",
  fontWeight: "$medium",
  lineHeight: "$2",
  variants: {
    iconPosition: {
      left: {
        justifyContent: "flex-start"
      },
      right: {
        justifyContent: "space-between"
      }
    }
  },
  "&:focus": {
    outline: "none",
    boxShadow: "inset 0 0 0 1px $neutral70, 0 0 0 1px $neutral70"
  },
  ".stateIcon": {
    padding: "0 $2",
    transition: "transform 250ms cubic-bezier(0.65, 0, 0.35, 1)"
  },
  '&[data-state="closed"] .stateIcon': {
    transform: "rotate(180deg)"
  }
});
var AccordionTrigger = React.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, { children, customIcon, iconPosition = "right" } = _b, props = __objRest(_b, ["children", "customIcon", "iconPosition"]);
    return /* @__PURE__ */ jsx(StyledHeader, { children: /* @__PURE__ */ jsxs(StyledTrigger, __spreadProps(__spreadValues({}, props), { ref: forwardedRef, children: [
      iconPosition === "left" && /* @__PURE__ */ jsx("div", { className: "stateIcon", children: customIcon || /* @__PURE__ */ jsx(Icon_default, { name: "fa-caret-down-solid-small" }) }),
      children,
      iconPosition === "right" && /* @__PURE__ */ jsx("div", { className: "stateIcon", children: customIcon || /* @__PURE__ */ jsx(Icon_default, { name: "fa-caret-down-solid-small" }) })
    ] })) });
  }
);
var StyledContent = styled(AccordionPrimitive.Content, {
  marginTop: "$1",
  fontFamily: "$primary",
  fontWeight: "$regular",
  fontSize: "$2",
  lineHeight: "$2"
});
var AccordionItem = StyledItem;
var AccordionContent = StyledContent;

// src/Badge/Badge.tsx
import React2 from "react";

// src/Badge/BaseBadge.tsx
var BaseBadge = styled("span", {
  display: "inline-flex",
  padding: "2px 8px",
  alignItems: "center",
  gap: "4px",
  borderRadius: "$rounded-full",
  alignSelf: "flex-start",
  fontWeight: "$medium",
  lineHeight: "$2",
  fontSize: "$2",
  fontFamily: "$primary",
  variants: {
    compact: {
      true: {
        padding: "2px 6px",
        fontSize: "$1",
        lineHeight: "$1"
      }
    },
    color: {
      neutral: {
        backgroundColor: "$badge_surface_neutral",
        color: "$badge_text_neutral"
      },
      blue: {
        backgroundColor: "$badge_surface_blue",
        color: "$badge_text_blue"
      },
      green: {
        backgroundColor: "$badge_surface_green",
        color: "$badge_text_green"
      },
      yellow: {
        backgroundColor: "$badge_surface_yellow",
        color: "$badge_text_yellow"
      },
      red: {
        backgroundColor: "$badge_surface_red",
        color: "$badge_text_red"
      },
      purple: {
        backgroundColor: "$badge_surface_purple",
        color: "$badge_text_purple"
      }
    }
  },
  defaultVariants: {
    color: "neutral",
    compact: false
  }
});

// src/Badge/Badge.tsx
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var Badge = React2.forwardRef(
  (props, ref) => {
    const _a = props, { children, icon = null } = _a, rest = __objRest(_a, ["children", "icon"]);
    return /* @__PURE__ */ jsxs2(BaseBadge, __spreadProps(__spreadValues({ ref }, rest), { children: [
      icon && /* @__PURE__ */ jsx2(Icon_default, { name: icon, size: "sm" }),
      children
    ] }));
  }
);
Badge.displayName = "Badge";

// src/Banner/Banner.tsx
import React3 from "react";

// src/@shared/css/animations.ts
var rotate = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" }
});
var fadeIn = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 }
});
var fadeOut = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 }
});
var hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 }
});
var slideInFromLeft = keyframes({
  "0%": {
    transform: "translateX(var(---slideInStartX, -100%))"
  },
  "100%": {
    transform: "translateX(var(---slideInEndX, 0))"
  }
});
var slideInFromRight = keyframes({
  "0%": {
    transform: "translateX(var(---slideInStartX, 100%))"
  },
  "100%": {
    transform: "translateX(var(---slideInEndX, 0))"
  }
});
var swipeOut = keyframes({
  "0%": {
    transform: "translateX(var(--radix-toast-swipe-end-x))"
  },
  "100%": {
    transform: "translateX(100%)"
  }
});
var timings = config.theme.transitions;
var animations = {
  rotate: {
    default: `${rotate} ${timings.default}`,
    infinite: `${rotate} ${timings.infinite}`,
    raw: rotate
  },
  fadeIn: {
    default: `${fadeIn} ${timings.default}`,
    raw: fadeIn
  },
  fadeOut: {
    default: `${fadeOut} ${timings.default}`,
    raw: fadeOut
  },
  hide: {
    default: `${hide} ${timings.default}`,
    raw: hide
  },
  slideInFromLeft: {
    default: `${slideInFromLeft} ${timings.default}`,
    cubicCurve: `${slideInFromLeft} ${timings.cubicCurve}`,
    sineCurve: `${slideInFromLeft} ${timings.sineCurve}`,
    raw: slideInFromLeft
  },
  slideInFromRight: {
    default: `${slideInFromRight} ${timings.default}`,
    cubicCurve: `${slideInFromRight} ${timings.cubicCurve}`,
    sineCurve: `${slideInFromRight} ${timings.sineCurve}`,
    raw: slideInFromRight
  },
  swipeOut: {
    default: `${swipeOut} ${timings.default}`,
    cubicCurve: `${swipeOut} ${timings.cubicCurve}`,
    sineCurve: `${swipeOut} ${timings.sineCurve}`,
    raw: swipeOut
  }
};

// src/@shared/css/utils.ts
var marginUtilConfig = [
  {
    utilKey: "m",
    cssPropKey: "margin"
  },
  {
    utilKey: "mt",
    cssPropKey: "marginTop"
  },
  {
    utilKey: "mr",
    cssPropKey: "marginRight"
  },
  {
    utilKey: "mb",
    cssPropKey: "marginBottom"
  },
  {
    utilKey: "ml",
    cssPropKey: "marginLeft"
  },
  {
    utilKey: "mx",
    cssPropKey: ["marginLeft", "marginRight"]
  },
  {
    utilKey: "my",
    cssPropKey: ["marginTop", "marginBottom"]
  }
];
var paddingUtilConfig = [
  {
    utilKey: "p",
    cssPropKey: "padding"
  },
  {
    utilKey: "pt",
    cssPropKey: "paddingTop"
  },
  {
    utilKey: "pr",
    cssPropKey: "paddingRight"
  },
  {
    utilKey: "pb",
    cssPropKey: "paddingBottom"
  },
  {
    utilKey: "pl",
    cssPropKey: "paddingLeft"
  },
  {
    utilKey: "px",
    cssPropKey: ["paddingLeft", "paddingRight"]
  },
  {
    utilKey: "py",
    cssPropKey: ["paddingTop", "paddingBottom"]
  }
];
var marginUtilVariants = marginUtilConfig.map(
  ({ utilKey, cssPropKey }) => Object.fromEntries(
    Object.entries(theme[config.themeMap.margin]).map(
      ([tokenKey, tokenData]) => {
        const cssUtilKey = `${utilKey}${tokenKey}`;
        const cssPropValue = Array.isArray(cssPropKey) ? {
          true: Object.fromEntries(
            cssPropKey.map((cssProp) => [cssProp, `$${tokenData.token}`])
          )
        } : {
          true: {
            [cssPropKey]: `$${tokenData.token}`
          }
        };
        return [cssUtilKey, cssPropValue];
      }
    )
  )
).reduce(
  (allUtils, currUtil) => __spreadValues(__spreadValues({}, allUtils), currUtil),
  {}
);
var paddingUtilVariants = paddingUtilConfig.map(
  ({ utilKey, cssPropKey }) => Object.fromEntries(
    Object.entries(theme[config.themeMap.padding]).map(
      ([tokenKey, tokenData]) => {
        const cssUtilKey = `${utilKey}${tokenKey}`;
        const cssPropValue = Array.isArray(cssPropKey) ? {
          true: Object.fromEntries(
            cssPropKey.map((cssProp) => [cssProp, `$${tokenData.token}`])
          )
        } : {
          true: {
            [cssPropKey]: `$${tokenData.token}`
          }
        };
        return [cssUtilKey, cssPropValue];
      }
    )
  )
).reduce(
  (allUtils, currUtil) => __spreadValues(__spreadValues({}, allUtils), currUtil),
  {}
);
var sizeUtilVariants = {
  wFull: { true: { width: "100%" } },
  hFull: {
    true: { height: "100%" }
  },
  wScreen: {
    true: { width: "100vw" }
  },
  hScreen: {
    true: { height: "100vh" }
  }
};
var stackingUtilVariants = {
  z1: { true: { zIndex: 1 } },
  z2: { true: { zIndex: 2 } },
  z3: { true: { zIndex: 3 } },
  z4: { true: { zIndex: 4 } },
  z5: { true: { zIndex: 5 } }
  // zIndices: {
  //   1: '100',
  //   2: '200',
  //   3: '300',
  //   4: '400',
  //   max: '999',
  // },
};
var cssUtils = css({
  variants: __spreadValues(__spreadValues(__spreadValues(__spreadValues({}, marginUtilVariants), paddingUtilVariants), sizeUtilVariants), stackingUtilVariants)
});
var marginUtilValues = marginUtilConfig.map(({ cssPropKey }) => cssPropKey);
var paddingUtilValues = paddingUtilConfig.map(({ cssPropKey }) => cssPropKey);
var sizeUtilValues = ["width", "height"];
var cssUtilValues = [
  ...marginUtilValues,
  ...paddingUtilValues,
  ...sizeUtilValues
];
var cssUtilKeys = Object.keys(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, marginUtilVariants), paddingUtilVariants), sizeUtilVariants), stackingUtilVariants));
var isCssUtilKeyGuard = (key) => cssUtilKeys.includes(key);
var extractCssUtilsFromProps = (props) => {
  const groupedProps = Object.entries(props).reduce(
    (propsByType, currProp) => {
      const [propKey, propValue] = currProp;
      if (isCssUtilKeyGuard(propKey)) {
        return __spreadProps(__spreadValues({}, propsByType), {
          cssUtilProps: __spreadProps(__spreadValues({}, propsByType.cssUtilProps), {
            [propKey]: propValue
          })
        });
      }
      return __spreadProps(__spreadValues({}, propsByType), {
        restProps: __spreadProps(__spreadValues({}, propsByType.restProps), {
          [propKey]: propValue
        })
      });
    },
    {
      cssUtilProps: {},
      restProps: {}
    }
  );
  return [groupedProps.cssUtilProps, groupedProps.restProps];
};
function cn(classNameConfig, selectorPrefix, selectorPostfix) {
  const DEFAULT_SELECTOR_PREFIX = "&";
  let classNameArg = "";
  let selectorPrefixArg = "";
  let selectorPostfixArg = "";
  if (typeof classNameConfig === "string" && ["&", ">", "+", "~"].includes(classNameConfig)) {
    classNameArg = selectorPrefix;
    selectorPrefixArg = classNameConfig;
    selectorPostfixArg = selectorPostfix;
  } else {
    classNameArg = classNameConfig;
    selectorPrefixArg = DEFAULT_SELECTOR_PREFIX;
    selectorPostfixArg = selectorPrefix;
  }
  let className;
  if (typeof classNameArg === "string") {
    className = classNameArg.split(" ").map((c) => `.${c}`).join("");
  } else if (classNameArg && "className" in classNameArg) {
    className = classNameArg.className.split(" ").map((c) => `.${c}`).join("");
  }
  const prefix = selectorPrefixArg ? `${selectorPrefixArg} ` : "";
  const postfix = selectorPostfixArg != null ? selectorPostfixArg : "";
  return `${prefix}${className}${postfix}`;
}

// src/Box/Box.tsx
var Box = styled(
  "div",
  {
    // Reset
    boxSizing: "border-box"
  },
  cssUtils
);
var Box_default = Box;

// src/Flex/Flex.tsx
var flexGapVariants = {
  none: {
    gap: "$0"
  },
  xs: {
    gap: "$1"
  },
  sm: {
    gap: "$2"
  },
  md: {
    gap: "$3"
  },
  lg: {
    gap: "$4"
  },
  xl: {
    gap: "$5"
  }
};
var Flex = styled(Box_default, {
  display: "flex",
  fontFamily: "$primary",
  variants: {
    wrap: {
      wrap: {
        flexWrap: "wrap"
      },
      "no-wrap": {
        flexWrap: "nowrap"
      },
      "wrap-reverse": {
        flexWrap: "wrap-reverse"
      }
    },
    flow: {
      row: {
        flexDirection: "row"
      },
      "row-reverse": {
        flexDirection: "row-reverse"
      },
      column: {
        flexDirection: "column"
      },
      "column-reverse": {
        flexDirection: "column-reverse"
      }
    },
    justifyContent: {
      start: {
        justifyContent: "flex-start"
      },
      center: {
        justifyContent: "center"
      },
      end: {
        justifyContent: "flex-end"
      },
      stretch: {
        justifyContent: "stretch"
      },
      "space-between": {
        justifyContent: "space-between"
      }
    },
    alignItems: {
      start: {
        alignItems: "flex-start"
      },
      center: {
        alignItems: "center"
      },
      end: {
        alignItems: "flex-end"
      },
      stretch: {
        alignItems: "stretch"
      }
    },
    gap: flexGapVariants,
    flex: {
      1: {
        flex: 1
      },
      none: {
        flex: "none"
      }
    }
  }
});

// src/Banner/BaseBanner.tsx
var BannerIcon = styled(Icon_default, {
  maxWidth: "20px",
  maxHeight: "20px",
  height: "100%",
  width: "100%"
});
var BannerDismiss = styled(BannerIcon, {
  cursor: "pointer"
});
var BannerText = styled("span", {
  all: "unset",
  fontWeight: "400",
  fontSize: "$2",
  lineHeight: "$2",
  flex: 1
});
var BaseBanner = styled(Flex, {
  padding: "$2",
  borderRadius: "$rounded",
  gap: "6px",
  alignItems: "flex-start",
  height: "min-content",
  variants: {
    theme: {
      default: {
        color: "$neutral70",
        backgroundColor: "$neutral20",
        [`& > ${BannerIcon}`]: {
          fill: "$neutral70"
        }
      },
      success: {
        color: "$green60",
        backgroundColor: "$green10",
        [`& > ${BannerIcon}`]: {
          fill: "$green60"
        }
      },
      danger: {
        color: "$red60",
        backgroundColor: "$red10",
        [`& > ${BannerIcon}`]: {
          fill: "$red60"
        }
      },
      warning: {
        color: "$yellow60",
        backgroundColor: "$yellow10",
        [`& > ${BannerIcon}`]: {
          fill: "$yellow60"
        }
      }
    }
  },
  defaultVariants: {
    theme: "default"
  }
});

// src/Banner/Banner.tsx
import { jsx as jsx3, jsxs as jsxs3 } from "react/jsx-runtime";
var themeIcons = {
  default: "circle-info",
  success: "circle-check",
  warning: "diamond-exclamation",
  danger: "octagon-exclamation"
};
var Banner = (props) => {
  const {
    className,
    icon,
    theme: theme2 = "default",
    text,
    css: css3,
    iconWrapper = React3.Fragment,
    isDismissable,
    onDismiss
  } = props;
  const IconWrapper2 = iconWrapper;
  return /* @__PURE__ */ jsxs3(BaseBanner, { className, theme: theme2, css: css3, children: [
    /* @__PURE__ */ jsx3(IconWrapper2, { children: /* @__PURE__ */ jsx3(BannerIcon, { name: icon || themeIcons[theme2] }) }),
    /* @__PURE__ */ jsx3(BannerText, { children: text }),
    isDismissable && /* @__PURE__ */ jsx3(IconWrapper2, { children: /* @__PURE__ */ jsx3(BannerDismiss, { name: "fa-xmark-small", onClick: onDismiss }) })
  ] });
};

// src/Body/Body.tsx
import React4 from "react";
import { jsx as jsx4 } from "react/jsx-runtime";
var commonBodyStyles = {
  color: "$neutral90",
  fontFeatureSettings: "'liga' off",
  fontFamily: "Untitled Sans",
  fontStyle: "normal"
};
var bodySizeVariants = {
  "1": __spreadProps(__spreadValues({}, commonBodyStyles), {
    fontSize: "$2",
    lineHeight: "20px /* 142.857% */"
  }),
  "2": __spreadProps(__spreadValues({}, commonBodyStyles), {
    fontSize: "$1",
    lineHeight: "16px /* 133.333% */"
  })
};
var bodyWeightVariants = {
  regular: {
    fontWeight: "$regular"
  },
  medium: {
    fontWeight: "$medium"
  }
};
var BaseBody = styled("p", {
  "& b, & strong": {
    fontWeight: "$medium"
  },
  variants: {
    size: bodySizeVariants,
    weight: bodyWeightVariants,
    tabular: {
      true: {
        fontVariantNumeric: "lining-nums tabular-nums"
      }
    }
  }
});
var Body = React4.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      as = "p",
      size = "1",
      weight = "regular",
      tabular = false,
      css: css3,
      children
    } = _b, props = __objRest(_b, [
      "as",
      "size",
      "weight",
      "tabular",
      "css",
      "children"
    ]);
    return /* @__PURE__ */ jsx4(
      BaseBody,
      __spreadProps(__spreadValues({
        as,
        ref,
        size,
        weight,
        tabular,
        css: css3
      }, props), {
        children
      })
    );
  }
);
Body.displayName = "Body";

// src/Breadcrumbs/BaseBreadcrumbs.tsx
import { styled as styled2 } from "@stitches/react";
var ContainerStyles = {
  color: "$neutral70",
  fontSize: "$1",
  fontWeight: "$regular",
  fontFamily: "$primary",
  lineHeight: "$1"
};
var NavContainer = styled2("nav", ContainerStyles);
var DivContainer = styled2("div", ContainerStyles);
var ListContainer = styled2("ol", {
  display: "flex",
  margin: "$0",
  padding: "$0",
  flexWrap: "wrap"
});
var ListItemContainer = styled2("li", {
  display: "flex",
  flexDirection: "row",
  boxSizing: "border-box",
  margin: "$0",
  padding: "$0",
  "&:not(:last-child)::after": {
    padding: "$0 $1",
    flexShrink: 0,
    content: '"/"',
    textAlign: "center"
  },
  a: {
    color: "$neutral70",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  }
});

// src/Breadcrumbs/Breadcrumbs.tsx
import { jsx as jsx5 } from "react/jsx-runtime";
var Breadcrumbs = (props) => {
  const {
    label = "Breadcrumbs",
    isNavigation = true,
    children,
    testId,
    css: css3
  } = props;
  const Component = isNavigation ? NavContainer : DivContainer;
  const breadcrumbsItems = children;
  return /* @__PURE__ */ jsx5(Component, { css: css3, tabIndex: -1, "aria-label": label, "data-testid": testId, children: /* @__PURE__ */ jsx5(ListContainer, { children: breadcrumbsItems }) });
};

// src/Breadcrumbs/BreadcrumbsItem.tsx
import { jsx as jsx6 } from "react/jsx-runtime";
var BreadcrumbsItem = (props) => {
  const { item, itemRender } = props;
  let renderItem;
  if (itemRender) {
    renderItem = itemRender(item);
  } else if (item.url) {
    renderItem = /* @__PURE__ */ jsx6("a", { href: item.url, children: item.title });
  } else {
    renderItem = item.title;
  }
  return /* @__PURE__ */ jsx6(ListItemContainer, { children: renderItem });
};

// src/Button/Button.tsx
import * as React5 from "react";

// src/Button/BaseButton.tsx
var BaseButton = styled("button", {
  // Reset
  all: "unset",
  alignItems: "center",
  boxSizing: "border-box",
  userSelect: "none",
  "&::before": {
    boxSizing: "border-box"
  },
  "&::after": {
    boxSizing: "border-box"
  },
  // Custom reset?
  display: "inline-flex",
  gap: "$1",
  flexShrink: 0,
  justifyContent: "center",
  lineHeight: "$2",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  // Custom
  fontFamily: "$primary",
  fontSize: "$2",
  fontWeight: "$medium",
  fontVariantNumeric: "tabular-nums",
  borderRadius: "$rounded",
  padding: "8px 12px",
  variants: {
    compact: {
      true: {
        padding: "4px 8px"
      }
    },
    iconLeft: {
      true: {
        svg: {
          width: "$sizes$5",
          height: "$sizes$5"
        },
        paddingLeft: "8px"
      }
    },
    iconRight: {
      true: {
        svg: {
          width: "$sizes$5",
          height: "$sizes$5"
        },
        paddingRight: "8px"
      }
    },
    iconOnly: {
      true: {
        padding: "8px"
      }
    },
    modifiedRightBorder: {
      true: {
        borderRightWidth: "1px",
        borderRightStyle: "solid"
      }
    },
    variant: {
      primary: {
        backgroundColor: "$blue50",
        color: "$neutral0",
        "&:disabled": {
          backgroundColor: "$neutral20",
          color: "$neutral40",
          pointerEvents: "none"
        },
        "&:hover": {
          background: "linear-gradient(0deg, rgba(31, 37, 44, 0.16), rgba(31, 37, 44, 0.16)), $blue50"
        },
        "&:active": {
          background: "linear-gradient(0deg, rgba(239, 240, 245, 0.16), rgba(239, 240, 245, 0.16)), $blue50"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$blue30",
          background: "$blue50"
        }
      },
      default: {
        backgroundColor: "$neutral20",
        color: "$neutral80",
        "&:disabled": {
          backgroundColor: "$button_disabled_background",
          color: "$button_disabled_text",
          pointerEvents: "none"
        },
        "&:hover": {
          backgroundColor: "$button_default_background_hover"
        },
        "&:active": {
          backgroundColor: "$button_default_background_active"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$button_primary_border_focus",
          backgroundColor: "$neutral20"
        }
      },
      warning: {
        backgroundColor: "$yellow50",
        color: "$neutral80",
        "&:disabled": {
          backgroundColor: "$neutral20",
          color: "$neutral40",
          pointerEvents: "none"
        },
        "&:hover": {
          background: "linear-gradient(0deg, rgba(31, 37, 44, 0.16), rgba(31, 37, 44, 0.16)), $yellow50"
        },
        "&:active": {
          background: "linear-gradient(0deg, rgba(239, 240, 245, 0.16), rgba(239, 240, 245, 0.16)), $yellow50"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$yellow30",
          background: "$yellow50"
        }
      },
      danger: {
        backgroundColor: "$red50",
        color: "$neutral0",
        "&:disabled": {
          backgroundColor: "$neutral20",
          color: "$neutral40",
          pointerEvents: "none"
        },
        "&:hover": {
          background: "linear-gradient(0deg, rgba(31, 37, 44, 0.16), rgba(31, 37, 44, 0.16)), $red50"
        },
        "&:active": {
          background: "linear-gradient(0deg, rgba(239, 240, 245, 0.16), rgba(239, 240, 245, 0.16)), $red50"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$red30",
          background: "$red50"
        }
      },
      subtle: {
        backgroundColor: "$button_subtle_background_enabled",
        color: "$neutral70",
        "&:disabled": {
          backgroundColor: "rgba(255, 255, 255, 1e-05)",
          color: "$neutral40",
          pointerEvents: "none"
        },
        "&:hover": {
          background: "$button_subtle_background_hover"
        },
        "&:active": {
          background: "$button_subtle_background_active"
        },
        '&:where([data-state="open"])': {
          background: "$button_subtle_background_active"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$button_primary_border_focus",
          background: "$button_subtle_background_enabled"
        }
      },
      subtleDanger: {
        backgroundColor: "$button_subtle_background_enabled",
        color: "$neutral70",
        "&:disabled": {
          backgroundColor: "rgba(255, 255, 255, 1e-05)",
          color: "$neutral40",
          pointerEvents: "none"
        },
        "&:hover": {
          background: "$button_danger_background_hover",
          color: "$neutral0"
        },
        "&:active": {
          background: "$button_subtle_background_active",
          color: "$neutral0"
        },
        '&:where([data-state="open"])': {
          background: "$button_subtle_background_active"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$button_primary_border_focus",
          background: "$button_subtle_background_enabled"
        }
      },
      inverse: {
        backgroundColor: "$neutral0",
        color: "$neutral80",
        fontSize: "$2",
        fontStyle: "normal",
        lineHeight: "$2",
        border: "1px solid rgba(53, 65, 78, 0.24)",
        "&:hover": {
          background: "$blue10",
          color: "$blue50",
          border: "1px solid $blue50"
        }
        // TODO: We're using inverse in ButtonGroup, which is a bit of a hack.
        //       We should probably just use a different component for that.
        //       Commenting the psuedo states out for now so they don't override.
        // '&:active': {
        //   background: '$blue10',
        //   color: '$blue50',
        //   border: '1px solid $blue50',
        // },
        // '&:focus': {
        //   background: '$blue10',
        //   color: '$blue50',
        //   border: '1px solid $blue50',
        // },
      },
      inverted: {
        backgroundColor: "$neutral0",
        color: "$neutral80",
        fontSize: "$2",
        fontStyle: "normal",
        lineHeight: "$2",
        border: "1px solid rgba(53, 65, 78, 0.24)",
        "&:hover": {
          background: "$blue10",
          color: "$blue50",
          border: "1px solid $blue50"
        },
        "&:active": {
          background: "$blue10",
          color: "$blue50",
          border: "1px solid $blue50"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px rgba(16, 106, 242, 0.72)",
          border: "none"
        }
      },
      active: {
        backgroundColor: "$neutral0",
        fontSize: "$2",
        fontStyle: "normal",
        lineHeight: "$2",
        background: "$blue10",
        color: "$blue50",
        border: "1px solid $blue50"
      },
      filter: {
        boxSizing: "border-box",
        fontWeight: "$regular",
        border: "1px solid rgba(53, 65, 78, 0.24)",
        background: "$neutral0",
        color: "$neutral80",
        "&:hover": {
          background: "rgba(31, 37, 44, 0.08)"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$blue40",
          background: "$neutral0"
        },
        "&:focus:not(:active)": {
          borderColor: "$blue40",
          boxShadow: "0px 0px 0px 2px $colors$blue40",
          background: "$neutral0"
        },
        "&:active": {
          boxShadow: "none",
          background: "rgba(31, 37, 44, 0.12)"
        },
        "&:focus:active": {
          background: "rgba(31, 37, 44, 0.12)"
        },
        "&[data-state=open]": {
          background: "rgba(31, 37, 44, 0.12)"
        },
        "&[aria-expanded=true]": {
          background: "rgba(31, 37, 44, 0.12)"
        }
      },
      filterApplied: {
        fontWeight: "$medium",
        background: "rgba(170, 202, 255, 0.36)",
        border: "1px solid rgba(0, 61, 166, 0.24)",
        color: "$filter_button_filters_selected_color",
        "&:hover": {
          background: "rgba(170, 202, 255, 0.64)"
        },
        "&:focus": {
          background: "rgba(170, 202, 255, 0.36)"
        },
        "&:focus:not(:active)": {
          borderColor: "$blue40",
          background: "rgba(170, 202, 255, 0.36)"
        },
        "&:active": {
          background: "rgba(170, 202, 255, 0.72)"
        },
        "&:focus:active": {
          background: "rgba(170, 202, 255, 0.72)"
        },
        "&[data-state=open]": {
          background: "rgba(170, 202, 255, 0.72)"
        },
        "&[aria-expanded=true]": {
          background: "rgba(170, 202, 255, 0.72)"
        }
      },
      input: {
        fontWeight: "$regular",
        outline: "none",
        backgroundColor: "$input_background_enabled",
        color: "$input_default_text_enabled",
        fontVariantNumeric: "tabular-nums",
        border: "1px solid $colors$input_default_border_enabled",
        ".selectIcon": {
          color: "$input_default_text_enabled"
        },
        "&:focus": {
          boxShadow: "$primary_focus",
          borderColor: "$input_default_border_active"
        },
        "&[data-state=open]": {
          boxShadow: "$primary_focus",
          borderColor: "$input_default_border_active"
        },
        "&[aria-expanded=true]": {
          boxShadow: "$primary_focus",
          borderColor: "$input_default_border_active"
        },
        "&:disabled": {
          pointerEvents: "none",
          color: "$input_default_text_disabled",
          borderColor: "$text_input_default_border_disabled",
          cursor: "not-allowed",
          "&[readonly]": {
            color: "$input_default_text_enabled !important",
            svg: {
              color: "$input_default_text_disabled"
            }
          }
        }
      },
      link: {
        gap: "$1",
        padding: "0px",
        cursor: "pointer",
        backgroundColor: "$neutral0",
        color: "$blue50",
        "&:disabled": {
          backgroundColor: "$neutral0",
          color: "$neutral40",
          pointerEvents: "none"
        },
        "&:hover": {
          textDecoration: "underline"
        },
        "&:active": {
          color: "$blue70",
          textDecoration: "underline"
        },
        "&:focus": {
          textDecoration: "underline"
        }
      },
      primaryWhite: {
        backgroundColor: "$neutral0",
        color: "$blue60",
        "&:disabled": {
          backgroundColor: "$neutral20",
          color: "$neutral40",
          pointerEvents: "none"
        },
        "&:hover": {
          backgroundColor: "$button_primary_surface_hover",
          color: "$neutral0"
        },
        "&:active": {
          background: "$button_primary_surface_active",
          color: "$neutral0"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$blue30",
          background: "$blue50",
          color: "$neutral0"
        }
      }
    }
  },
  compoundVariants: [
    {
      variant: "link",
      compact: true,
      css: {
        padding: "0px",
        fontSize: "$1"
      }
    },
    {
      compact: true,
      iconLeft: true,
      css: {
        svg: {
          width: "$sizes$4",
          height: "$sizes$4"
        },
        paddingLeft: "4px"
      }
    },
    {
      compact: true,
      iconRight: true,
      css: {
        svg: {
          width: "$sizes$4",
          height: "$sizes$4"
        },
        paddingRight: "4px"
      }
    },
    {
      compact: true,
      iconOnly: true,
      css: {
        padding: "6px"
      }
    },
    {
      compact: true,
      variant: "filter",
      css: {
        padding: "$1 $1 $1 $2",
        height: 28
      }
    },
    {
      compact: true,
      variant: "filterApplied",
      css: {
        padding: "$1 $1 $1 $2",
        height: 28
      }
    },
    {
      modifiedRightBorder: true,
      variant: "primary",
      css: {
        borderRightColor: "$colors$blue40",
        "&:disabled": {
          borderRightColor: "$colors$neutral30"
        }
      }
    },
    {
      modifiedRightBorder: true,
      variant: "default",
      css: {
        borderRightColor: "$colors$neutral40",
        "&:disabled": {
          borderRightColor: "$colors$neutral30"
        }
      }
    }
  ],
  defaultVariants: {
    variant: "default"
  }
});

// src/Button/Button.tsx
import { jsx as jsx7, jsxs as jsxs4 } from "react/jsx-runtime";
var Button = React5.forwardRef(
  (props, ref) => {
    const _a = props, {
      children,
      variant = "default",
      compact = false,
      disabled = false,
      onClick,
      icon = null,
      modifiedRightBorder = false,
      loading = false
    } = _a, rest = __objRest(_a, [
      "children",
      "variant",
      "compact",
      "disabled",
      "onClick",
      "icon",
      "modifiedRightBorder",
      "loading"
    ]);
    return /* @__PURE__ */ jsxs4(
      BaseButton,
      __spreadProps(__spreadValues({
        ref,
        variant,
        compact,
        onClick,
        disabled: disabled || loading,
        iconLeft: !!icon && icon.position !== "right",
        iconRight: !!icon && icon.position === "right",
        iconOnly: !!icon && !children,
        modifiedRightBorder
      }, rest), {
        children: [
          icon && icon.position !== "right" && /* @__PURE__ */ jsx7(Icon_default, { name: loading ? "arrows-rotate" : icon.name, size: "sm" }),
          children,
          icon && icon.position === "right" && /* @__PURE__ */ jsx7(Icon_default, { name: loading ? "arrows-rotate" : icon.name, size: "sm" })
        ]
      })
    );
  }
);

// src/ButtonGroup/ButtonGroup.tsx
import * as React6 from "react";

// src/ButtonGroup/BaseButtonGroup.tsx
var BaseButtonGroup = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  "button:first-child": {
    borderRadius: "$rounded 0 0 $rounded"
  },
  "button:not(:first-child):not(:last-child)": {
    borderRadius: 0
  },
  "button:last-child": {
    borderRadius: "0 $rounded $rounded 0"
  }
});
var BaseButtonGroupItem = styled(Button, {
  variants: {
    selected: {
      true: {
        borderWidth: "1px",
        background: "$blue10",
        color: "$blue50",
        border: "1px solid $blue50"
      }
    }
  }
});
var BaseButtonGroup_default = BaseButtonGroup;

// src/ButtonGroup/ButtonGroup.tsx
import { jsx as jsx8 } from "react/jsx-runtime";
var ButtonGroup = React6.forwardRef(
  (props, ref) => {
    const _a = props, {
      buttons = [],
      variant = "primary",
      compact = false
    } = _a, rest = __objRest(_a, [
      "buttons",
      "variant",
      "compact"
    ]);
    return /* @__PURE__ */ jsx8(BaseButtonGroup_default, __spreadProps(__spreadValues({ ref }, rest), { children: buttons.map(
      (_b, index) => {
        var _c = _b, {
          label,
          disabled = false,
          icon = null,
          buttonRef = null,
          selected = false
        } = _c, buttonProps = __objRest(_c, [
          "label",
          "disabled",
          "icon",
          "buttonRef",
          "selected"
        ]);
        return /* @__PURE__ */ jsx8(
          BaseButtonGroupItem,
          __spreadProps(__spreadValues({
            selected,
            ref: buttonRef,
            variant,
            modifiedRightBorder: index !== buttons.length - 1,
            disabled,
            compact,
            icon
          }, buttonProps), {
            children: label
          }),
          label
        );
      }
    ) }));
  }
);

// src/ButtonSelect/ButtonSelect.tsx
import * as ToggleGroup from "@radix-ui/react-toggle-group";
var ButtonSelectRoot = styled(ToggleGroup.Root, {
  display: "inline-flex",
  backgroundColor: theme.colors.neutral0,
  borderRadius: theme.radii.rounded,
  fontFamily: theme.fonts.primary,
  fontWeight: theme.fontWeights.medium,
  fontSize: theme.fontSizes[2],
  variants: {
    compact: {
      true: {
        "& > *": {
          padding: "4px 8px"
        }
      }
    }
  }
});
var ButtonSelectItem = styled(ToggleGroup.Item, {
  all: "unset",
  backgroundColor: "white",
  color: theme.colors.button_default_text,
  padding: "8px 12px",
  display: "flex",
  lineHeight: "18px",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid rgba(53, 65, 78, 0.24)",
  cursor: "pointer",
  marginLeft: "-1px",
  "&:first-child": {
    marginLeft: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  "&:last-child": { borderTopRightRadius: 4, borderBottomRightRadius: 4 },
  "&:hover": { color: theme.colors.blue50 },
  "&[data-state=on]": {
    backgroundColor: theme.colors.blue10,
    color: theme.colors.blue50,
    borderColor: theme.colors.blue50,
    position: "relative"
  },
  "&:active": {
    border: "1px solid rgba(53, 65, 78, 0.24)",
    backgroundColor: theme.colors.blue10,
    color: theme.colors.blue50
  },
  "&:focus:not(:active)": {
    boxShadow: "$primary_focus",
    zIndex: 1
  },
  "&:disabled": {
    backgroundColor: theme.colors.neutral10,
    color: theme.colors.neutral50,
    borderColor: theme.colors.neutral30,
    "&:hover": { color: theme.colors.neutral50 },
    cursor: "not-allowed"
  }
});
var ButtonSelect = {
  Root: ButtonSelectRoot,
  Item: ButtonSelectItem
};

// src/Callout/Callout.tsx
import { useState } from "react";

// src/Link/Link.tsx
var Link = styled("a", {
  alignItems: "center",
  gap: "$1",
  flexShrink: 0,
  outline: "none",
  textDecorationLine: "none",
  textUnderlineOffset: "3px",
  textDecorationColor: "$neutral90",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  lineHeight: "inherit",
  fontFamily: "$primary",
  cursor: "pointer",
  "@hover": {
    "&:hover": {
      textDecorationLine: "underline"
    }
  },
  "&:hover": {
    textDecorationLine: "underline"
  },
  variants: {
    variant: {
      primary: {
        color: "$blue50",
        textDecorationColor: "$blue50",
        "&:hover": {
          color: "$blue50",
          textDecorationColor: "$blue50"
        }
      },
      subtle: {
        color: "$neutral90",
        textDecorationColor: "$neutral90",
        "&:hover": {
          color: "$neutral90",
          textDecorationColor: "$neutral90"
        }
      }
    },
    compact: {
      true: {
        fontSize: "$1"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    compact: "false"
  }
});

// src/Paragraph/Paragraph.tsx
import merge from "lodash.merge";
import React7 from "react";

// src/Text/Text.tsx
var Text = styled("span", {
  // Reset
  lineHeight: "$1",
  fontWeight: "$regular",
  margin: "0",
  display: "block",
  fontFamily: "$primary",
  "& b, & strong": {
    fontWeight: "$medium"
  },
  variants: {
    size: {
      1: { fontSize: "$1" },
      2: { fontSize: "$2" },
      3: { fontSize: "$3" },
      4: { fontSize: "$4" },
      5: { fontSize: "$5" },
      6: { fontSize: "$6" }
    },
    variant: {
      default: { color: "$neutral90" },
      description: { color: "$neutral60" },
      disabled: { color: "$neutral50" },
      subtle: { color: "$neutral40" },
      primary: { color: "$blue50" },
      secondary: { color: "$neutral70" },
      success: { color: "$green50" },
      warning: { color: "$yellow50" },
      danger: { color: "$red50" }
    }
  },
  defaultVariants: {
    size: "$3",
    variant: "$default"
  }
});

// src/Paragraph/Paragraph.tsx
import { jsx as jsx9 } from "react/jsx-runtime";
var DEFAULT_TAG = "p";
var Paragraph = React7.forwardRef((props, forwardedRef) => {
  const _a = props, { size = "1" } = _a, textProps = __objRest(_a, ["size"]);
  const textSize = {
    1: { "@initial": "2" },
    2: { "@initial": "1" }
  };
  const textCss = {
    1: {
      fontWeight: "$regular",
      lineHeight: "$2"
    },
    2: {
      fontWeight: "$regular",
      lineHeight: "$1"
    }
  };
  return /* @__PURE__ */ jsx9(
    Text,
    __spreadProps(__spreadValues({
      as: DEFAULT_TAG
    }, textProps), {
      ref: forwardedRef,
      size: textSize[size],
      css: __spreadValues({}, merge(textCss[size], props.css))
    })
  );
});

// src/Callout/BaseCallout.tsx
var BaseCalloutCard = styled(Box_default, {
  borderRadius: "$rounded",
  border: "none !important",
  padding: "$3",
  variants: {
    theme: {
      default: {
        color: "$neutral70",
        backgroundColor: "$neutral10"
      },
      success: {
        color: "$green60",
        backgroundColor: "$green10"
      },
      danger: {
        color: "$red60",
        backgroundColor: "$red10"
      },
      warning: {
        color: "$yellow60",
        backgroundColor: "$yellow10"
      }
    },
    compact: {
      true: {
        padding: "$2 $3"
      },
      false: {}
    }
  },
  defaultVariants: {
    theme: "default",
    compact: false
  }
});
var BaseCalloutLayout = styled(Flex, {
  flexFlow: "row",
  variants: {
    theme: {
      default: {
        color: "$neutral70"
      },
      success: {
        color: "$green60"
      },
      danger: {
        color: "$red60"
      },
      warning: {
        color: "$yellow60"
      }
    }
  },
  defaultVariants: {
    theme: "default"
  }
});
var BaseCalloutContent = styled(Flex, {
  flexFlow: "column",
  paddingLeft: "8px",
  gap: "4px",
  variants: {
    theme: {
      default: {
        color: "$neutral80"
      },
      success: {
        color: "$green70"
      },
      danger: {
        color: "$red70"
      },
      warning: {
        color: "$yellow70"
      }
    }
  },
  defaultVariants: {
    theme: "default"
  }
});

// src/Callout/Callout.tsx
import { Fragment, jsx as jsx10, jsxs as jsxs5 } from "react/jsx-runtime";
var themeIcons2 = {
  default: "circle-info",
  success: "circle-check",
  warning: "diamond-exclamation",
  danger: "octagon-exclamation"
};
var DismissIcon = styled(Icon_default, {
  "&:hover": {
    color: "$neutral60"
  },
  "&:active": {
    color: "$neutral80"
  },
  cursor: "pointer"
});
var Callout = (props) => {
  const {
    theme: theme2 = "default",
    title,
    text,
    linkLabel,
    link,
    onClick,
    showCloseButton = true,
    className,
    css: css3,
    compact = false
  } = props;
  const [isOpen, setIsOpen] = useState(true);
  const iconSize = compact ? "16px" : "20px";
  function closeCallout() {
    setIsOpen(false);
  }
  function getCallout() {
    if (isOpen) {
      return /* @__PURE__ */ jsx10(
        BaseCalloutCard,
        {
          theme: theme2,
          compact,
          className,
          css: css3,
          children: /* @__PURE__ */ jsxs5(BaseCalloutLayout, { theme: theme2, children: [
            /* @__PURE__ */ jsx10(
              Flex,
              {
                css: { width: iconSize, height: iconSize, alignItems: "center" },
                children: /* @__PURE__ */ jsx10(Icon_default, { name: themeIcons2[theme2] })
              }
            ),
            /* @__PURE__ */ jsxs5(BaseCalloutContent, { theme: theme2, children: [
              title && /* @__PURE__ */ jsx10(Paragraph, { css: { fontWeight: 500 }, size: compact ? "2" : "1", children: title }),
              /* @__PURE__ */ jsx10(Paragraph, { size: compact ? "2" : "1", children: text }),
              (link || linkLabel) && /* @__PURE__ */ jsx10(
                Link,
                {
                  css: {
                    textDecoration: "none",
                    color: "$blue50",
                    lineHeight: "20px",
                    fontSize: compact ? "$1" : "$2",
                    cursor: "pointer"
                  },
                  href: link,
                  onClick,
                  rel: "noreferrer",
                  target: "_blank",
                  children: /* @__PURE__ */ jsx10(Text, { css: { color: "$blue50", fontWeight: 500 }, children: linkLabel })
                }
              )
            ] }),
            showCloseButton && /* @__PURE__ */ jsx10(Flex, { css: { marginLeft: "auto", color: "$neutral70" }, children: /* @__PURE__ */ jsx10(
              DismissIcon,
              {
                name: "fa-xmark-small",
                color: "$neutral70",
                onClick: closeCallout
              }
            ) })
          ] })
        }
      );
    }
    return /* @__PURE__ */ jsx10(Fragment, {});
  }
  return getCallout();
};

// src/Card/BaseCard.tsx
import { styled as styled3 } from "@stitches/react";
var CardContainer = styled3("div", {
  all: "unset",
  appearance: "none",
  boxSizing: "border-box",
  font: "inherit",
  lineHeight: "1",
  outline: "none",
  border: "none",
  backgroundColor: "$neutral0",
  display: "block",
  textDecoration: "none",
  color: "$neutral90",
  flexShrink: 0,
  borderRadius: "$rounded-lg",
  position: "relative",
  padding: "$4",
  transition: "all 0.15s ease-in-out",
  // TODO: Temporary
  defaultVariants: {
    variant: "flat"
  },
  variants: {
    variant: {
      flat: {
        border: "1px solid $neutral20"
      },
      "elevate-1": {
        boxShadow: "0px 1px 4px rgba(15, 19, 23, 0.24)"
      },
      "elevate-2": {
        border: "1px solid $neutral20",
        boxShadow: "0px 2px 8px rgba(15, 19, 23, 0.2)"
      },
      "elevate-3": {
        border: "1px solid $neutral20",
        boxShadow: "0px 4px 16px rgba(15, 19, 23, 0.16)"
      },
      "elevate-4": {
        border: "1px solid $neutral20",
        boxShadow: "0px 6px 24px rgba(15, 19, 23, 0.16)"
      }
    },
    hoverVariant: {
      "elevate-1": {
        "&:hover": {
          boxShadow: "0px 1px 4px rgba(15, 19, 23, 0.24)"
        }
      },
      "elevate-2": {
        "&:hover": {
          boxShadow: "0px 2px 8px rgba(15, 19, 23, 0.2)"
        }
      },
      "elevate-3": {
        "&:hover": {
          boxShadow: "0px 4px 16px rgba(15, 19, 23, 0.16)"
        }
      },
      "elevate-4": {
        "&:hover": {
          boxShadow: "0px 6px 24px rgba(15, 19, 23, 0.16)"
        }
      }
    },
    interactive: {
      true: {
        border: "1px solid $neutral20",
        background: "$neutral0",
        boxShadow: "0px 0px 0px 0px rgba(15, 19, 23, 0.00)",
        "&:hover": {
          border: "1px solid $neutral20",
          background: "$neutral5",
          boxShadow: "$elevation2"
        },
        "&:active": {
          border: "1px solid $neutral20",
          background: "$neutral10",
          boxShadow: "$elevation2"
        }
      }
    },
    selected: {
      true: {
        border: "1px solid $blue40",
        background: "#ECF3FE",
        boxShadow: "none",
        "&:hover": {
          border: "1px solid $blue40",
          background: "$blue10",
          boxShadow: "$elevation2"
        },
        "&:active": {
          border: "1px solid $blue40",
          background: "$neutral10",
          boxShadow: "$elevation2"
        }
      }
    }
  }
});

// src/Card/Card.tsx
import { jsx as jsx11 } from "react/jsx-runtime";
var Card = (props) => {
  const {
    variant,
    hoverVariant,
    interactive,
    selected,
    onClick,
    children,
    css: css3,
    className,
    id,
    testId
  } = props;
  return /* @__PURE__ */ jsx11(
    CardContainer,
    {
      css: css3,
      variant,
      hoverVariant,
      interactive,
      selected,
      onClick,
      className,
      id,
      "data-testid": testId,
      children
    }
  );
};

// src/Checkbox/Checkbox.tsx
import * as React10 from "react";

// src/Label/Label.tsx
import { t } from "@kandji-inc/nectar-i18n";
import * as React9 from "react";

// src/Label/BaseLabel.tsx
import * as LabelPrimitive from "@radix-ui/react-label";
var colorNeutral50 = {
  color: "$neutral50"
};
var BaseLabel = styled(LabelPrimitive.Root, Text, {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  verticalAlign: "middle",
  cursor: "default",
  fontSize: "$2",
  color: "$neutral90",
  variants: {
    disabled: {
      true: colorNeutral50
    },
    viewOnly: {
      true: {
        color: "$input_default_text_enabled !important",
        svg: {
          color: "$input_default_text_disabled"
        }
      }
    },
    clickable: {
      true: {
        cursor: "pointer"
      }
    },
    above: {
      true: {
        color: "$neutral70",
        fontWeight: "$medium"
      }
    }
  },
  // This compund variant prioritizes the disabled color when both apply
  compoundVariants: [
    {
      above: true,
      disabled: true,
      css: colorNeutral50
    }
  ]
});
var OptionalText = styled(Text, {});

// src/Label/Label.tsx
import { jsx as jsx12, jsxs as jsxs6 } from "react/jsx-runtime";
var Label = React9.forwardRef(
  (props, forwardedRef) => {
    const _a = props, { children, optional, optionalText } = _a, labelProps = __objRest(_a, ["children", "optional", "optionalText"]);
    return /* @__PURE__ */ jsxs6(BaseLabel, __spreadProps(__spreadValues({}, labelProps), { ref: forwardedRef, children: [
      /* @__PURE__ */ jsx12("span", { children }),
      optional && /* @__PURE__ */ jsx12(OptionalText, { variant: "disabled", children: optionalText || t("(optional)") })
    ] }));
  }
);

// src/Checkbox/BaseCheckbox.tsx
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
var CheckboxContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  variants: {
    gap: __spreadProps(__spreadValues({}, flexGapVariants), {
      default: {
        gap: 7
      },
      control: {
        gap: 11
      }
    })
  },
  defaultVariants: {
    gap: "default"
  }
});
var StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: "unset",
  boxSizing: "border-box",
  userSelect: "none",
  "&::before": {
    boxSizing: "border-box"
  },
  "&::after": {
    boxSizing: "border-box"
  },
  alignItems: "center",
  appearance: "none",
  display: "inline-flex",
  justifyContent: "center",
  lineHeight: "1",
  margin: "0",
  outline: "none",
  padding: "0",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  border: "1px solid $neutral50",
  backgroundColor: "$neutral0",
  overflow: "hidden",
  "&:hover": {
    background: "linear-gradient(0deg, rgba(31, 37, 44, 0.08), rgba(31, 37, 44, 0.08)), #FFFFFF"
  },
  "&:focus": {
    outline: "none",
    borderColor: "$blue50",
    boxShadow: "0px 0px 0px 2px #8FB4F3"
  },
  "&:disabled": {
    backgroundColor: "$neutral10",
    borderColor: "$neutral40",
    color: "$neutral50",
    "&:hover": {
      background: "$neutral10"
    }
  },
  '&[data-state="checked"]': {
    backgroundColor: "$blue50",
    borderColor: "$blue50",
    color: "$neutral0",
    "&:disabled": {
      backgroundColor: "$neutral30",
      color: "$neutral50",
      borderColor: "$neutral30"
    }
  },
  '&[data-state="indeterminate"]': {
    backgroundColor: "$blue50",
    borderColor: "$blue50",
    color: "$neutral0",
    "&:disabled": {
      backgroundColor: "$neutral30",
      color: "$neutral50",
      borderColor: "$neutral30"
    }
  },
  variants: {
    size: {
      "1": {
        width: "$3",
        height: "$3",
        borderRadius: "$rounded-sm"
      },
      "2": {
        width: "$5",
        height: "$5",
        borderRadius: "$rounded"
      }
    },
    viewOnly: {
      true: {
        pointerEvents: "none",
        backgroundColor: "$neutral10",
        borderColor: "$neutral40",
        color: "$neutral50",
        "&:hover": {
          background: "$neutral10"
        },
        '&[data-state="checked"]': {
          backgroundColor: "$neutral30",
          color: "$icon_primary",
          borderColor: "$neutral30"
        },
        '&[data-state="checked"]:disabled': {
          color: "$icon_primary"
        },
        '&[data-state="indeterminate"]': {
          backgroundColor: "$neutral30",
          color: "$neutral50",
          borderColor: "$neutral30"
        }
      }
    }
  },
  defaultVariants: {
    size: "1",
    viewOnly: false
  }
});
var StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  width: "100%",
  color: "currentColor"
});

// src/Checkbox/Checkbox.tsx
import { jsx as jsx13, jsxs as jsxs7 } from "react/jsx-runtime";
var Checkbox = React10.forwardRef((_a, forwardedRef) => {
  var _b = _a, { gap, viewOnly = false } = _b, checkboxProps = __objRest(_b, ["gap", "viewOnly"]);
  var _a2;
  const uid = React10.useId();
  const elementId = (_a2 = checkboxProps.id) != null ? _a2 : uid;
  return /* @__PURE__ */ jsxs7(CheckboxContainer, { gap, children: [
    /* @__PURE__ */ jsx13(
      StyledCheckbox,
      __spreadProps(__spreadValues({}, checkboxProps), {
        id: elementId,
        ref: forwardedRef,
        viewOnly,
        disabled: checkboxProps.disabled || !!viewOnly,
        children: /* @__PURE__ */ jsxs7(StyledIndicator, { children: [
          checkboxProps.checked === "indeterminate" && /* @__PURE__ */ jsx13(Icon_default, { name: "dash" }),
          checkboxProps.checked === true && /* @__PURE__ */ jsx13(Icon_default, { name: "fa-check-14px" })
        ] })
      })
    ),
    checkboxProps.label && /* @__PURE__ */ jsx13(
      Label,
      {
        clickable: true,
        htmlFor: elementId,
        optional: checkboxProps.optional,
        disabled: checkboxProps.disabled,
        viewOnly,
        children: checkboxProps.label
      }
    )
  ] });
});

// src/Chip/Chip.tsx
import * as React11 from "react";

// src/Chip/BaseChip.tsx
var ChipIcon = styled(Icon_default, {
  variants: {
    isClickable: {
      true: {
        color: "var(--icon-color)",
        "&:hover": {
          color: "var(--icon-hover)"
        },
        "&:active": {
          color: "var(--icon-active)"
        }
      }
    }
  }
});
var BaseChip = styled("span", {
  all: "unset",
  display: "inline-flex",
  gap: "$1",
  alignItems: "center",
  width: "max-content",
  padding: "2px 4px",
  borderRadius: "$rounded-sm",
  fontFamily: "$primary",
  fontWeight: "$medium",
  userSelect: "none",
  variants: {
    size: {
      compact: {
        lineHeight: "$1",
        fontSize: "$1"
      },
      normal: {
        lineHeight: "$2",
        fontSize: "$2"
      }
    },
    color: {
      neutral: {
        backgroundColor: "$chip_surface",
        color: "$chip_text",
        "--icon-color": "var(--colors-chip_remove_icon_enabled)",
        "--icon-hover": "var(--colors-chip_remove_icon_hover)",
        "--icon-active": "var(--colors-chip_remove_icon_active)"
      },
      unfilled: {
        color: "$neutral60",
        backgroundColor: "transparent",
        border: "1px solid $neutral30"
      },
      default: {
        backgroundColor: "$chip_surface",
        color: "$chip_text",
        border: "1px solid $neutral20",
        fontSize: "$1",
        fontWeight: "$normal",
        lineHeight: "$1",
        padding: "4px 6px",
        borderRadius: "$rounded"
      }
    }
  },
  defaultVariants: {
    color: "neutral",
    size: "normal"
  }
});

// src/Chip/Chip.tsx
import { jsx as jsx14, jsxs as jsxs8 } from "react/jsx-runtime";
var DEFAULT_DISMISS_ICON = "xmark";
var DEFAULT_ICON_SIZE = "xs";
var DEFAULT_ICON_FALLBACK = "octagon-exclamation";
var Chip = React11.forwardRef((props, forwardedRef) => {
  const _a = props, { size, color, label, iconLeft, iconRight, dismissible } = _a, rest = __objRest(_a, ["size", "color", "label", "iconLeft", "iconRight", "dismissible"]);
  const _b = typeof iconLeft === "string" ? {
    icon: iconLeft || DEFAULT_ICON_FALLBACK,
    onIconClick: void 0,
    size: DEFAULT_ICON_SIZE,
    css: void 0
  } : iconLeft != null ? iconLeft : { icon: DEFAULT_ICON_FALLBACK }, {
    icon: leftIconName,
    onIconClick: leftIconClick,
    size: leftIconSize = DEFAULT_ICON_SIZE,
    css: leftIconCSS
  } = _b, restLeftIcon = __objRest(_b, [
    "icon",
    "onIconClick",
    "size",
    "css"
  ]);
  const _c = typeof iconRight === "string" ? {
    icon: iconRight || DEFAULT_ICON_FALLBACK,
    onIconClick: void 0,
    size: DEFAULT_ICON_SIZE,
    css: void 0
  } : iconRight != null ? iconRight : { icon: DEFAULT_ICON_FALLBACK }, {
    icon: rightIconName,
    onIconClick: rightIconClick,
    size: rightIconSize = DEFAULT_ICON_SIZE,
    css: rightIconCSS
  } = _c, restRightIcon = __objRest(_c, [
    "icon",
    "onIconClick",
    "size",
    "css"
  ]);
  return /* @__PURE__ */ jsxs8(BaseChip, __spreadProps(__spreadValues({ ref: forwardedRef, size, color }, rest), { children: [
    iconLeft && /* @__PURE__ */ jsx14(
      ChipIcon,
      __spreadValues({
        name: leftIconName,
        onClick: leftIconClick,
        size: leftIconSize,
        isClickable: !!leftIconClick,
        css: leftIconCSS
      }, restLeftIcon)
    ),
    label,
    iconRight && /* @__PURE__ */ jsx14(
      ChipIcon,
      __spreadValues({
        name: rightIconName,
        onClick: rightIconClick,
        size: rightIconSize,
        isClickable: !!rightIconClick,
        css: rightIconCSS
      }, restRightIcon)
    ),
    dismissible && /* @__PURE__ */ jsx14(
      ChipIcon,
      {
        name: dismissible.icon || DEFAULT_DISMISS_ICON,
        onClick: dismissible.onIconClick,
        size: dismissible.size || DEFAULT_ICON_SIZE,
        isClickable: true,
        css: dismissible.css
      }
    )
  ] }));
});

// src/Code/Code.tsx
var Code = styled("code", {
  fontFamily: "$code",
  fontSize: "$2",
  lineHeight: "$2",
  whiteSpace: "nowrap",
  padding: "2px",
  background: "rgba(80, 94, 113, 0.06)",
  border: "1px solid rgba(80, 94, 113, 0.12)",
  borderRadius: "$rounded",
  color: "$neutral80"
});

// src/Counter/Counter.tsx
var Counter = styled("span", {
  all: "unset",
  display: "inline-flex",
  alignItems: "center",
  padding: "2px 6px",
  borderRadius: "20px",
  fontFamily: "$primary",
  fontWeight: "$medium",
  lineHeight: "$1",
  fontSize: "$1",
  variants: {
    variant: {
      default: {
        backgroundColor: "$counter_default_background_enabled",
        color: "$counter_default_text_enabled"
      },
      disabled: {
        backgroundColor: "$counter_default_background_disabled",
        color: "$counter_default_text_disabled"
      },
      strong: {
        backgroundColor: "$counter_strong_background_enabled",
        color: "$counter_strong_text_enabled"
      }
    }
  },
  defaultVariants: {
    variant: "default"
  }
});

// src/DatePicker/DatePicker.tsx
import { getJSLocale, t as t5 } from "@kandji-inc/nectar-i18n";
import {
  useDatePicker
} from "@rehookify/datepicker";
import * as React26 from "react";

// src/IconButton/IconButton.tsx
import * as React12 from "react";
import { jsx as jsx15 } from "react/jsx-runtime";
var BaseIconButton = styled("button", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  appearance: "none",
  borderWidth: "0",
  boxSizing: "border-box",
  padding: "$2",
  gap: "$1",
  background: "$button_subtle_background_enabled",
  borderRadius: "$rounded",
  variants: {
    compact: {
      true: {
        padding: "6px",
        gap: "$space_4"
      }
    },
    variant: {
      primary: {
        color: "$button_primary_icon_enabled",
        padding: "$space_8",
        gap: "$space_4",
        background: "$button_primary_surface_enabled",
        "& svg": {
          width: "20px",
          height: "20px"
        },
        "&:hover": {
          color: "$button_primary_icon_hover",
          padding: "$space_8",
          gap: "$space_4",
          background: "$button_primary_surface_hover"
        },
        "&:active": {
          color: "$button_primary_icon_active",
          padding: "$space_8",
          gap: "$space_4",
          background: "$button_primary_surface_active"
        },
        "&:focus": {
          color: "$button_primary_icon_focus",
          padding: "$space_8",
          gap: "$space_4",
          background: "$button_primary_surface_focus",
          boxShadow: "0px 0px 0px 2px rgba(16, 106, 242, 0.36)"
        },
        "&:disabled": {
          color: "$button_icon_disabled",
          padding: "$space_8",
          gap: "$space_4",
          background: "$button_surface_disabled"
        }
      },
      subtle: {
        background: "$button_subtle_surface_enabled",
        "&:hover": {
          background: "$button_subtle_surface_hover"
        },
        "&:active": {
          background: "$button_subtle_surface_active"
        },
        "&:focus": {
          background: "$button_subtle_surface_focus",
          boxShadow: "0px 0px 0px 2px rgba(16, 106, 242, 0.72)"
        },
        "&:disabled": {
          background: "$button_surface_disabled"
        }
      }
    }
  }
});
var IconButton = React12.forwardRef(
  (props, ref) => {
    const _a = props, { icon } = _a, rest = __objRest(_a, ["icon"]);
    return /* @__PURE__ */ jsx15(BaseIconButton, __spreadProps(__spreadValues({}, rest), { ref, children: typeof icon === "string" ? /* @__PURE__ */ jsx15(Icon_default, { name: icon }) : icon }));
  }
);
IconButton.displayName = "IconButton";

// src/Select/hooks/useSelect/useSelect.tsx
import { assertElseThrow as assertElseThrow2, hasOwn, usePrevious } from "@kandji-inc/nectar-utils";
import { useComposedRefs as useComposedRefs2 } from "@radix-ui/react-compose-refs";
import { useId as useId3 } from "@radix-ui/react-id";
import * as React19 from "react";
import useSelectLib from "use-select";

// src/Select/BaseSelect/StyledSelect.tsx
import * as SelectPrimitive from "@radix-ui/react-select";

// src/DropdownMenu/BaseDropdown.tsx
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
var dropdownSizeTokens = {
  $$xxs: "80px",
  $$xs: "160px",
  $$sm: "240px",
  $$md: "320px",
  $$lg: "480px"
};
var DropdownMenuContent = styled(
  DropdownMenuPrimitive.Content,
  {
    height: "min-content",
    padding: "$1 0px",
    background: "$neutral0",
    filter: "drop-shadow($elevation2)",
    borderRadius: "$rounded"
  },
  cssUtils
);
var DropdownMenuArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: "$neutral0",
  width: "$3",
  height: "$2"
});
var DropdownMenuDivider = styled(DropdownMenuPrimitive.Separator, {
  height: "1px",
  backgroundColor: "$dropdown_default_divider_enabled",
  margin: "$1 0px"
});
var DropdownMenuItemControlStyles = {
  width: 14,
  height: 14,
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0
};
var DropdownMenuItemControl = styled(
  Flex,
  DropdownMenuItemControlStyles
);
var DropdownMenuItemIcon = styled(
  Icon_default,
  {
    fill: "$neutral90"
  },
  DropdownMenuItemControl
);
var DropdownMenuItem = styled(DropdownMenuPrimitive.Item, {
  all: "unset",
  padding: "6px $3",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "$2",
  gap: "$1",
  cursor: "pointer",
  // These are common across all theme variants
  backgroundColor: "$neutral0",
  "&[data-highlighted]": {
    backgroundColor: "#505E7129"
  },
  "&:active": {
    backgroundColor: "#505E713D"
  },
  "&[data-disabled]": {
    backgroundColor: "$neutral0",
    cursor: "not-allowed"
  },
  variants: {
    theme: {
      default: {
        color: "$neutral90",
        "&[data-disabled]": {
          color: "#35414E7A"
        }
      },
      danger: {
        color: "$red50",
        "&[data-disabled]": {
          color: "#DB30257A"
        }
      },
      action: {
        color: "$blue50",
        "&[data-disabled]": {
          color: "#106AF27A"
        }
      }
    },
    selected: {
      true: {
        background: "$dropdown_surface_selected_enabled",
        color: "$dropdown_content_selected",
        "&:hover": {
          background: "$dropdown_surface_selected_hover"
        },
        "&:active": {
          background: "$dropdown_surface_selected_active"
        }
      }
    },
    noHighlight: {
      true: {
        "&[data-highlighted]": {
          backgroundColor: "transparent"
        }
      }
    }
  },
  defaultVariants: {
    theme: "default"
  }
});
var DropdownMenuSubMenu = styled(DropdownMenuPrimitive.Sub, {});
var DropdownMenuSubMenuTrigger = styled(
  DropdownMenuPrimitive.SubTrigger,
  {
    all: "unset",
    padding: "6px $3",
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "$2",
    gap: "$3",
    cursor: "pointer",
    variants: {
      theme: {
        default: {
          backgroundColor: "$neutral0",
          '&[data-state="open"]': {
            backgroundColor: "#505E7129"
          }
        },
        danger: {},
        action: {
          background: "$dropdown_surface_selected_enabled",
          color: "$blue50",
          '&[data-state="open"]': {
            background: "$dropdown_surface_selected_hover"
          }
        }
      }
    },
    defaultVariants: {
      theme: "default"
    }
  }
);
var DropdownMenuSubContent = styled(DropdownMenuPrimitive.SubContent, {
  height: "min-content",
  background: "$neutral0",
  filter: "drop-shadow($elevation3)",
  borderRadius: "$rounded",
  borderTopLeftRadius: "$none"
});
var DropdownMenuSectionLabel = styled(DropdownMenuPrimitive.Label, {
  fontFamily: "$primary",
  fontSize: "$1",
  fontWeight: 500,
  lineHeight: "$1",
  letterSpacing: "$3",
  textTransform: "uppercase",
  color: "$dropdown_default_sectiontext_enabled"
});
var DropdownMenuFooterContainer = styled(Flex, {
  justifyContent: "space-between",
  py: "$2",
  px: "$3",
  "& [data-action]": {
    fontWeight: "$medium"
  }
});
var DropdownMenuItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: "absolute",
  right: 12,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center"
});
var DropdownMenuCheckboxItem = styled(
  DropdownMenuPrimitive.CheckboxItem,
  {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    gap: "4px",
    alignSelf: "stretch",
    fontSize: "$2",
    border: "2px solid transparent",
    "&[data-highlighted]": {
      border: "2px solid $colors$dropdown_ring_focus"
    },
    '&[data-state="checked"]': {
      background: "$dropdown_surface_selected_enabled",
      color: "$dropdown_content_selected",
      "&:hover": {
        background: "$dropdown_surface_selected_hover"
      },
      "&:active": {
        background: "$dropdown_surface_selected_active"
      }
    }
  }
);
var DropdownMenuPrimitives = {
  Content: DropdownMenuContent,
  Divider: DropdownMenuDivider,
  Item: DropdownMenuItem,
  SubMenu: DropdownMenuSubMenu,
  SubMenuTrigger: DropdownMenuSubMenuTrigger,
  SubMenuContent: DropdownMenuSubContent,
  Label: DropdownMenuSectionLabel,
  Arrow: DropdownMenuArrow,
  Root: DropdownMenuPrimitive.Root,
  Trigger: DropdownMenuPrimitive.Trigger,
  Portal: DropdownMenuPrimitive.Portal,
  CheckboxItem: DropdownMenuCheckboxItem,
  // RadioItemUnstyled: DropdownMenuPrimitive.RadioItem, // TODO: will add back in when we need it
  ItemIndicator: DropdownMenuItemIndicator
};

// src/Select/BaseSelect/StyledSelect.tsx
var selectTriggerCss = css(__spreadProps(__spreadValues({
  // @note: edit carefully if changing as these variables are used for
  // dimension calculations in trigger children nodes
  $$triggerWidth: "auto",
  $$triggerMinHeight: "36px",
  $$triggerMaxHeight: "83px",
  $$triggerPadY: "$sizes$1",
  $$triggerPadX: "$sizes$2",
  $$triggerMarginX: "0px",
  $$triggerMarginY: "0px",
  $$triggerBorderWidth: "1px",
  $$triggerBoxSizing: "border-box",
  $$openIconWidth: "20px",
  $$valueContainerWidth: "calc($$triggerWidth - $$openIconWidth - (2 * $$triggerPadX) - (2 * $$triggerMarginX) - (2 * $$triggerBorderWidth))",
  $$multiValueGap: "$space$1"
}, dropdownSizeTokens), {
  boxSizing: "$$triggerBoxSizing",
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "1fr $$openIconWidth",
  fontFamily: "$primary",
  fontSize: "$2",
  lineHeight: "1.2",
  width: "$$triggerWidth",
  minHeight: "$$triggerMinHeight",
  maxHeight: "$$triggerMaxHeight",
  py: "$$triggerPadY",
  px: "$$triggerPadX",
  overflowY: "auto",
  borderWidth: "$$triggerBorderWidth",
  // override default TextField readonly styles to enabled styles since the
  // styled trigger button is implicitly readonly
  "&:read-only:enabled": {
    backgroundColor: "$input_background_enabled"
  },
  "&[data-readonly]": {
    backgroundColor: "$input_background_readonly"
  },
  variants: {
    state: {
      default: {
        "&[data-state=open]": {
          boxShadow: "$primary_focus",
          borderColor: "$input_default_border_active"
        }
      },
      error: {
        "&[data-state=open]": {
          boxShadow: "$danger_focus",
          borderColor: "$input_danger_border_enabled"
        }
      }
    },
    size: {
      xxs: {
        width: "$$xxs"
      },
      xs: {
        $$triggerWidth: "$$xs"
      },
      sm: {
        $$triggerWidth: "$$sm"
      },
      md: {
        $$triggerWidth: "$$md"
      },
      lg: {
        $$triggerWidth: "$$lg"
      },
      full: {
        width: "100%"
      }
    }
  },
  defaultVariants: {
    state: "default",
    size: "md"
  }
}));
var selectOpenIconCss = css({
  py: "$1",
  alignSelf: "start"
});
var selectMenuCss = css(__spreadProps(__spreadValues({
  $$defaultMaxHeight: "320px"
}, dropdownSizeTokens), {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  background: "$neutral0",
  borderRadius: "$rounded",
  boxShadow: "$3",
  overflowY: "auto",
  fontFamily: "$primary",
  fontSize: "$2",
  mt: "$1",
  py: "$1",
  maxHeight: "$$defaultMaxHeight",
  zIndex: 2,
  variants: {
    open: {
      true: {
        display: "block"
      }
    },
    size: {
      xxs: {
        width: "$$xxs"
      },
      xs: {
        width: "$$xs"
      },
      sm: {
        width: "$$sm"
      },
      md: {
        width: "$$md"
      },
      lg: {
        width: "$$lg"
      },
      full: {
        width: "100%"
      }
    }
  },
  defaultVariants: {
    size: "md"
  }
}));
var selectOptionItemContainerCss = css({
  lineHeight: "$2"
});
var selectMultiItemOptionContainerCss = css(
  selectOptionItemContainerCss,
  {
    "& [role=label]": {
      lineHeight: "$2"
    },
    variants: {
      disabled: {
        true: {
          cursor: "not-allowed",
          "*": {
            cursor: "not-allowed"
          }
        }
      }
    }
  }
);
var selectNoOptionsCss = css({
  color: "$dropdown_default_text_disabled_alpha",
  py: 6,
  px: "$3"
});
var selectHintCss = css({
  width: "100%",
  justifyContent: "flex-end"
});
var StyledSelectProvider = styled(Box_default, {
  position: "relative",
  width: "fit-content",
  variants: {
    size: {
      full: {
        width: "100%"
      }
    }
  }
});
var StyledSelectTrigger = styled(
  "button",
  BaseTextField,
  selectTriggerCss
);
var StyledSelectLabel = styled(Label, {});
var StyledSelectSearchInput = styled("textarea", {
  // @note: edit carefully if changing as these variables are used for
  // dimension calculations in trigger children nodes
  $$searchTextWidth: "100%",
  $$searchEditableHeight: "min-content",
  border: "none",
  background: "transparent",
  outline: "none",
  fontFamily: "$primary",
  fontSize: "$2",
  color: "$input_default_text_enabled",
  width: "clamp(1px, 100%, min($$searchTextWidth, $$valueContainerWidth))",
  height: "$$searchEditableHeight",
  px: 0,
  resize: "none",
  overflowY: "auto",
  overflowX: "hidden",
  "&::placeholder": {
    color: "$input_secondary_text_enabled"
  },
  "&:disabled": {
    color: "$input_default_text_disabled"
  },
  '&[data-search-value=""]': {
    width: "auto"
  },
  '&[data-search-value=""][placeholder=""]': {
    width: 1
  }
});
var StyledSelectOpenIcon = styled(
  SelectPrimitive.Icon,
  selectOpenIconCss
);
var StyledMultiValueContainer = styled(Box_default, {
  display: "flex",
  flexWrap: "wrap",
  gap: "$$multiValueGap",
  maxWidth: "var(---valueContainerWidth, 100%)"
});
var StyledMultiValueChip = styled(Chip, {
  textAlign: "left",
  wordBreak: "break-all",
  svg: {
    flexShrink: 0
  },
  variants: {
    noDisplay: {
      true: {
        display: "none"
      }
    },
    animateIn: {
      true: {
        animation: `${animations.fadeIn.default}`
      }
    }
  }
});
var StyledSelectMenu = styled(Box_default, DropdownMenuContent, selectMenuCss);
var StyledSelectMenuSection = styled(SelectPrimitive.Group, {
  borderBottom: "1px solid $dropdown_default_divider_enabled",
  variants: {
    noBottomDivider: {
      true: {
        borderBottom: "none"
      }
    }
  }
});
var StyledSelectMenuSectionLabel = styled(
  SelectPrimitive.Label,
  DropdownMenuSectionLabel,
  {
    px: "$3",
    py: "$2"
  }
);
var StyledSelectItem = styled(
  Flex,
  DropdownMenuItem,
  selectOptionItemContainerCss,
  {
    "&[data-state=checked]": {
      background: "$blue10"
    },
    "&[data-disabled]": {
      color: "$input_default_text_disabled",
      cursor: "not-allowed"
    }
  }
);
var StyledMultiItem = styled(
  Flex,
  DropdownMenuItem,
  selectMultiItemOptionContainerCss
);
var StyledMultiItemCheckbox = styled(
  Checkbox,
  {
    "&[role=checkbox]": DropdownMenuItemControlStyles
  }
);
var StyledSelectNoOptions = styled(Flex, selectNoOptionsCss);
var StyledMultiSelectFooterWrapper = styled(Box_default, {
  flexShrink: 0,
  variants: {
    isContentOverflowed: {
      true: {
        borderTop: "1px solid $neutral20"
      }
    }
  }
});
var publicSelectStyledComponents = {
  Item: StyledSelectItem,
  Label: StyledSelectLabel,
  Menu: StyledSelectMenu,
  MenuSection: StyledSelectMenuSection,
  MenuSectionLabel: StyledSelectMenuSectionLabel,
  MultiItem: StyledMultiItem,
  MultiItemCheckbox: StyledMultiItemCheckbox,
  MultiValueChip: StyledMultiValueChip,
  MultiValueContainer: StyledMultiValueContainer,
  NoOptions: StyledSelectNoOptions,
  OpenIcon: StyledSelectOpenIcon,
  SearchInput: StyledSelectSearchInput,
  Trigger: StyledSelectTrigger
};

// src/Select/BaseSelect/components/SelectProvider.tsx
import * as React14 from "react";

// src/Select/utils/selectUtils.ts
import { t as t2 } from "@kandji-inc/nectar-i18n";
import { assertElseThrow } from "@kandji-inc/nectar-utils";
var [HAS_DATA_ATTR_KEY, NO_DATA_ATTR_KEY] = ["", void 0];
var SELECT_ALL_OPTION = {
  label: "Select all",
  value: "__select-all-option-value__"
};
var SELECT_KEYBOARD_EVENT_KEY = {
  TAB: "Tab",
  ENTER: "Enter",
  UP: "ArrowUp",
  DOWN: "ArrowDown",
  SPACE: " ",
  BACKSPACE: "Backspace"
};
var DEFAULT_VALUE_KEY = "value";
var DEFAULT_LABEL_KEY = "label";
var getDefaultPlaceholderText = () => t2("Select an option");
var DEFAULT_NO_OPTIONS_FOUND_TEXT = "No results";
var DEFAULT_SEARCH_INPUT_MAX_LENGTH = 500;
var DEFAULT_OPEN_ICON_NAME = "fa-angle-down-small";
var DEFAULT_OPEN_ICON_SIZE = 20;
var LAST_ROW_RESERVED_WIDTH = 0.1;
var LAST_ROW_RESERVED_WIDTH_WITH_SEARCH_INPUT = 0.33;
var noop = (val = void 0) => val;
var givesTrue = (..._args) => true;
var dataAttrHelper = {
  selectType: {
    key: "data-select-type",
    value: {
      select: "select",
      multi: "multi"
    },
    selector: {
      select: "[data-select-type=select]",
      multi: "[data-select-type=multi]"
    }
  },
  selectOptionType: {
    key: "data-select-option-type",
    value: {
      select: "select",
      multi: "multi"
    },
    selector: {
      select: "[data-select-option-type=select]",
      multi: "[data-select-option-type=multi]"
    },
    get: {
      select: (target) => {
        var _a;
        return (_a = target == null ? void 0 : target.querySelector(
          dataAttrHelper.selectOptionType.selector.select
        )) != null ? _a : null;
      },
      multi: (target) => {
        var _a;
        return (_a = target == null ? void 0 : target.querySelector(
          dataAttrHelper.selectOptionType.selector.multi
        )) != null ? _a : null;
      }
    },
    getAll: {
      multi: (target) => {
        var _a;
        return Array.from(
          (_a = target == null ? void 0 : target.querySelectorAll(
            dataAttrHelper.selectOptionType.selector.multi
          )) != null ? _a : []
        );
      },
      select: (target) => {
        var _a;
        return Array.from(
          (_a = target == null ? void 0 : target.querySelectorAll(
            dataAttrHelper.selectOptionType.selector.select
          )) != null ? _a : []
        );
      }
    }
  },
  hiddenMultiValueCount: {
    key: "data-multi-value-hidden-count",
    value: {
      true: HAS_DATA_ATTR_KEY,
      false: NO_DATA_ATTR_KEY
    },
    selector: { true: "[data-multi-value-hidden-count]" },
    get: {
      true: (target) => {
        var _a;
        return (_a = target == null ? void 0 : target.querySelector(
          dataAttrHelper.hiddenMultiValueCount.selector.true
        )) != null ? _a : null;
      }
    }
  },
  dismissibleMultiValue: {
    key: "data-multi-value-dismissible",
    value: {
      true: HAS_DATA_ATTR_KEY,
      false: NO_DATA_ATTR_KEY
    },
    selector: { true: "[data-multi-value-dismissible]" }
  },
  disabledOptionItem: {
    key: "data-disabled",
    value: {
      true: HAS_DATA_ATTR_KEY,
      false: NO_DATA_ATTR_KEY
    },
    selector: { true: "[data-disabled]" }
  }
};
var defaultSelectOptionsFilter = (optionsToFilter, search) => optionsToFilter.filter(
  (option) => option.label.toLowerCase().trim().includes(search.toLowerCase().trim())
);
var getEnabledOptionsFallback = (options) => {
  var _a, _b;
  return (_b = (_a = options == null ? void 0 : options.filter) == null ? void 0 : _a.call(options, (opt) => !opt.disabled)) != null ? _b : [];
};
var getDisabledOptionsFallback = (options) => {
  var _a, _b;
  return (_b = (_a = options == null ? void 0 : options.filter) == null ? void 0 : _a.call(options, (opt) => opt.disabled)) != null ? _b : [];
};
var getOptionFallback = (value, options) => {
  var _a, _b;
  return (_b = (_a = options == null ? void 0 : options.find) == null ? void 0 : _a.call(options, (opt) => opt.value === value)) != null ? _b : null;
};
var getSelectAllCheckedType = (selectedItems, totalCount) => {
  assertElseThrow(
    Array.isArray(selectedItems),
    `Expected array value for \`selectedItems\`, got: ${typeof selectedItems}.`,
    selectedItems,
    {
      prefix: "Nectar useSelect"
    }
  );
  if (selectedItems.length === 0 || !totalCount) {
    return false;
  }
  return selectedItems.length === totalCount ? true : "indeterminate";
};
var getFocusable = (target, query) => {
  const menuOptions = Array.from(target.querySelectorAll(query));
  const foundFocusIndex = menuOptions.findIndex(
    (option) => option === document.activeElement
  );
  return [foundFocusIndex, menuOptions];
};
var generateTestId = (id, componentName, suffix, {
  hideInProd = true
} = {}) => {
  const isProduction = process.env.NODE_ENV === "production";
  const isInvalidId = !id || typeof id !== "string";
  const canHideInProd = isProduction && hideInProd;
  if (isInvalidId || canHideInProd) {
    return void 0;
  }
  return id ? `${componentName}--${id}--${suffix}` : `${componentName}--${suffix}`;
};
function computeValueContainerWidth(triggerStyles) {
  const triggerWidth = parseInt(
    triggerStyles.getPropertyValue("---triggerWidth"),
    10
  );
  const openIconWidth = parseInt(
    triggerStyles.getPropertyValue("---openIconWidth"),
    10
  );
  const triggerPadX = parseInt(
    triggerStyles.getPropertyValue("---triggerPadX"),
    10
  );
  const triggerMarginX = parseInt(
    triggerStyles.getPropertyValue("---triggerMarginX"),
    10
  );
  const triggerBorderWidth = parseInt(
    triggerStyles.getPropertyValue("---triggerBorderWidth"),
    10
  );
  const valueContainerWidth = triggerWidth - openIconWidth - 2 * triggerPadX - 2 * triggerMarginX - 2 * triggerBorderWidth;
  return valueContainerWidth;
}
function computeValueContainerRight(triggerRect, triggerStyles) {
  const openIconWidth = parseInt(
    triggerStyles.getPropertyValue("---openIconWidth"),
    10
  );
  const triggerPadX = parseInt(
    triggerStyles.getPropertyValue("---triggerPadX"),
    10
  );
  const triggerMarginX = parseInt(
    triggerStyles.getPropertyValue("---triggerMarginX"),
    10
  );
  const triggerBorderWidth = parseInt(
    triggerStyles.getPropertyValue("---triggerBorderWidth"),
    10
  );
  const valueContainerRight = triggerRect.right - openIconWidth - triggerPadX - triggerMarginX - triggerBorderWidth;
  return valueContainerRight;
}
var onMultiValueOverflowRef = (ref, {
  enabled,
  optionIndex,
  totalOptions,
  multiValueOverflowIndexRef,
  triggerRect,
  textInputRect,
  searchable,
  prevSelectAllCheckedType,
  rerenderValues
}) => {
  if (!enabled || !ref || !triggerRect || searchable && !textInputRect) {
    return;
  }
  const mutableOverflowIndexRef = multiValueOverflowIndexRef;
  const wasSelectAll = prevSelectAllCheckedType === true;
  const currFrameSelectTrigger = ref.closest(
    dataAttrHelper.selectType.selector.multi
  );
  if (currFrameSelectTrigger) {
    const triggerStyles = getComputedStyle(currFrameSelectTrigger);
    const triggerMaxHeight = parseInt(
      triggerStyles.getPropertyValue("---triggerMaxHeight"),
      10
    );
    const multiValueContainerRowGap = parseInt(triggerStyles.getPropertyValue("---multiValueGap"), 10) || 0;
    const [currTriggerRect, refNodeRect] = [currFrameSelectTrigger, ref].map(
      (node) => node.getBoundingClientRect()
    );
    const isTriggerAtMaxHeight = triggerMaxHeight ? Math.ceil(currTriggerRect.height) === triggerMaxHeight : false;
    const isLastValue = optionIndex === totalOptions - 1;
    const canCheckForHidden = wasSelectAll ? true : isLastValue;
    if (isTriggerAtMaxHeight && canCheckForHidden) {
      const reservedWidthRatio = searchable ? LAST_ROW_RESERVED_WIDTH_WITH_SEARCH_INPUT : LAST_ROW_RESERVED_WIDTH;
      const multiValueRectWidth = computeValueContainerWidth(triggerStyles);
      const multiValueRectRight = computeValueContainerRight(
        triggerRect,
        triggerStyles
      );
      const willExtendPastValueContainer = refNodeRect.right + multiValueRectWidth * reservedWidthRatio + multiValueContainerRowGap > multiValueRectRight;
      const triggerBorderBoxBottomSpacing = parseInt(triggerStyles.getPropertyValue("---triggerPadY"), 10) + parseInt(triggerStyles.getPropertyValue("---triggerBorderWidth"), 10);
      const isAtMaxHeightRow = Math.ceil(refNodeRect.bottom) + triggerBorderBoxBottomSpacing >= Math.ceil(currTriggerRect.bottom);
      const isOverflowX = isAtMaxHeightRow && willExtendPastValueContainer;
      const isOverflowY = refNodeRect.bottom > currTriggerRect.bottom;
      const isHidden = isOverflowX || isOverflowY;
      if (isHidden && !mutableOverflowIndexRef.current) {
        mutableOverflowIndexRef.current = optionIndex;
        rerenderValues();
      }
    }
  }
};
var NullRenderComponent = () => null;
NullRenderComponent.displayName = "NullRenderComponent";
var getNullRenderComponent = (component) => component === null ? NullRenderComponent : component;
var checkSearchPattern = (pattern, searchValue) => {
  if (searchValue === "" || pattern === void 0) {
    return true;
  }
  return pattern.test(searchValue);
};
var publicSelectUtils = {
  checkSearchPattern,
  computeValueContainerRight,
  computeValueContainerWidth,
  defaultSelectOptionsFilter,
  generateTestId,
  getFocusable,
  getSelectAllCheckedType,
  onMultiValueOverflowRef,
  constants: {
    DEFAULT_NO_OPTIONS_FOUND_TEXT,
    DEFAULT_OPEN_ICON_NAME,
    DEFAULT_OPEN_ICON_SIZE,
    getDefaultPlaceholderText,
    DEFAULT_SEARCH_INPUT_MAX_LENGTH,
    HAS_DATA_ATTR_KEY,
    NO_DATA_ATTR_KEY,
    SELECT_ALL_OPTION,
    SELECT_KEYBOARD_EVENT_KEY
  }
};

// src/Select/utils/selectScope.ts
import * as SelectPrimitive2 from "@radix-ui/react-select";
import * as React13 from "react";
var getDefaultSelectScope = SelectPrimitive2.createSelectScope();
function createUseSelectScope(selectScope) {
  return () => {
    const selectRootProps = React13.useContext(
      /* eslint-disable no-underscore-dangle -- using radix hidden prop */
      selectScope.__scopeSelect.Select[0]
      /* eslint-enable no-underscore-dangle -- using radix hidden prop */
    );
    return { selectRootProps };
  };
}

// src/Select/utils/creatable.ts
var { TAB, ENTER } = SELECT_KEYBOARD_EVENT_KEY;
var getDefaultCreatableConfig = (creatableConfig, {
  valueKey,
  labelKey,
  triggerKey,
  searchable
}) => {
  if (!creatableConfig || !searchable) {
    return {
      active: false
    };
  }
  const VALID_CREATABLE_TRIGGER_KEYS = {
    [ENTER]: true,
    [TAB]: true
  };
  if (!VALID_CREATABLE_TRIGGER_KEYS[triggerKey]) {
    return {
      active: false
    };
  }
  const defaults = {
    active: false,
    disabled: false,
    showMenuMessage: true,
    customMenuMessage: noop,
    maxLength: null,
    ignoredKeys: [],
    triggerCreateKeys: [ENTER],
    persistOtherTriggerKeyDownEvents: [],
    onCreate: givesTrue,
    formatValue: (creatableOptionValue) => creatableOptionValue,
    formatLabel: noop,
    formatMenuMessage: noop,
    // TODO: when creatable menu option needs to be supported
    valueKey,
    labelKey
  };
  return {
    active: typeof creatableConfig.active === "boolean" ? creatableConfig.active : defaults.active,
    disabled: typeof creatableConfig.disabled === "boolean" ? creatableConfig.disabled : defaults.disabled,
    showMenuMessage: typeof creatableConfig.showMenuMessage === "boolean" ? creatableConfig.showMenuMessage : defaults.showMenuMessage,
    customMenuMessage: typeof creatableConfig.customMenuMessage === "function" ? creatableConfig.customMenuMessage : defaults.customMenuMessage,
    maxLength: typeof creatableConfig.maxLength === "number" ? creatableConfig.maxLength : defaults.maxLength,
    ignoredKeys: Array.isArray(creatableConfig.ignoredKeys) ? creatableConfig.ignoredKeys : defaults.ignoredKeys,
    triggerCreateKeys: Array.isArray(creatableConfig.triggerCreateKeys) && creatableConfig.triggerCreateKeys.every(
      (key) => VALID_CREATABLE_TRIGGER_KEYS[key]
    ) ? creatableConfig.triggerCreateKeys : defaults.triggerCreateKeys,
    persistOtherTriggerKeyDownEvents: Array.isArray(creatableConfig.persistOtherTriggerKeyDownEvents) && creatableConfig.persistOtherTriggerKeyDownEvents.every(
      (key) => VALID_CREATABLE_TRIGGER_KEYS[key]
    ) ? creatableConfig.persistOtherTriggerKeyDownEvents : defaults.persistOtherTriggerKeyDownEvents,
    onCreate: typeof creatableConfig.onCreate === "function" ? creatableConfig.onCreate : defaults.onCreate,
    formatValue: typeof creatableConfig.formatValue === "function" ? creatableConfig.formatValue : defaults.formatValue,
    formatLabel: typeof creatableConfig.formatLabel === "function" ? creatableConfig.formatLabel : defaults.formatLabel,
    formatMenuMessage: typeof creatableConfig.formatMenuMessage === "function" ? creatableConfig.formatMenuMessage : defaults.formatMenuMessage,
    valueKey: creatableConfig.valueKey && typeof creatableConfig.valueKey === "string" ? creatableConfig.valueKey : defaults.valueKey,
    labelKey: creatableConfig.labelKey && typeof creatableConfig.labelKey === "string" ? creatableConfig.labelKey : defaults.labelKey
  };
};

// src/Select/BaseSelect/components/SelectProvider.tsx
import { jsx as jsx16 } from "react/jsx-runtime";
var SELECT_PROVIDER_NAME = "SelectProvider";
var SelectProvider = ({
  children,
  value,
  size,
  css: rootCss,
  testId,
  trackId
}) => {
  var _a;
  const { Provider: Provider3 } = SelectContext;
  const { selectProps, scope } = value;
  const { useSelectScope, selectScopeRef } = scope || {};
  const scopeValues = (useSelectScope == null ? void 0 : useSelectScope()) || {
    selectRootProps: null
  };
  const { selectRootProps } = scopeValues;
  const valueWithRootProps = React14.useMemo(
    () => __spreadProps(__spreadValues({}, value), {
      selectRootProps,
      scope
    }),
    [value, selectRootProps, scope]
  );
  React14.useMemo(() => {
    if (selectRootProps) {
      Object.entries(selectRootProps).forEach(([k, v]) => {
        if (selectScopeRef.current) {
          selectScopeRef.current[k] = v;
        }
      });
    }
  }, [selectRootProps, selectScopeRef]);
  const open = (_a = selectProps == null ? void 0 : selectProps.isSelectMenuOpen) != null ? _a : false;
  const wrapperSize = size === "full" ? size : void 0;
  return /* @__PURE__ */ jsx16(
    StyledSelectProvider,
    {
      size: wrapperSize,
      css: rootCss,
      "data-state": open ? "open" : "closed",
      "data-testid": generateTestId(testId, SELECT_PROVIDER_NAME, "select-root"),
      "data-trackid": trackId,
      children: /* @__PURE__ */ jsx16(
        Provider3,
        {
          value: valueWithRootProps,
          children
        }
      )
    }
  );
};
SelectProvider.displayName = SELECT_PROVIDER_NAME;

// src/Select/BaseSelect/components/SelectRoot.tsx
import * as SelectPrimitive3 from "@radix-ui/react-select";
import * as React15 from "react";
import { jsx as jsx17 } from "react/jsx-runtime";
var SELECT_ROOT_NAME = "SelectRoot";
var SelectRoot = ({
  children,
  scope,
  value,
  open,
  defaultOpen = false,
  size,
  selectProps,
  searchProps,
  refs,
  handlers,
  restContext,
  css: rootCss,
  testId,
  trackId
}) => {
  const { onOpenChange, onValueChange } = selectProps;
  const contextValue = React15.useMemo(
    () => __spreadValues({
      selectProps,
      searchProps,
      refs,
      handlers,
      scope
    }, restContext || {}),
    [selectProps, searchProps, refs, handlers, scope, restContext]
  );
  const rootValue = Array.isArray(value) ? value.join(",") : value;
  return /* @__PURE__ */ jsx17(
    SelectPrimitive3.Root,
    {
      __scopeSelect: scope.selectScope.__scopeSelect,
      value: rootValue,
      onValueChange,
      open,
      defaultOpen,
      onOpenChange,
      children: /* @__PURE__ */ jsx17(
        SelectProvider,
        {
          value: contextValue,
          size,
          css: rootCss,
          testId,
          trackId,
          children
        }
      )
    }
  );
};
SelectRoot.displayName = SELECT_ROOT_NAME;

// src/Select/BaseSelect/components/SelectTriggerSlot.tsx
import * as SlotPrimitive from "@radix-ui/react-slot";
import { jsx as jsx18 } from "react/jsx-runtime";
var SELECT_TRIGGER_NAME = "SelectTrigger";
var SelectTriggerSlot = (_a) => {
  var _b = _a, {
    asChild
  } = _b, props = __objRest(_b, [
    "asChild"
  ]);
  const Comp = asChild ? SlotPrimitive.Slot : StyledSelectTrigger;
  return /* @__PURE__ */ jsx18(Comp, __spreadValues({}, props));
};
SelectTriggerSlot.displayName = SELECT_TRIGGER_NAME;

// src/Select/BaseSelect/components/SelectOptionItem.tsx
import * as React17 from "react";

// src/Tooltip/Tooltip.tsx
import { useOnOutsideClick } from "@kandji-inc/nectar-utils";
import * as TooltipPrimitive2 from "@radix-ui/react-tooltip";
import { useEffect, useRef, useState as useState2 } from "react";

// src/Tooltip/BaseTooltip.tsx
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
var TooltipArrow = styled(TooltipPrimitive.Arrow, {
  width: "$3",
  height: "$2"
});
var TooltipContent = styled(TooltipPrimitive.Content, {
  fontFamily: "$primary",
  fontSize: "$1",
  lineHeight: "$1",
  p: "$1 $2",
  borderRadius: `$rounded-sm`,
  overflow: "auto",
  variants: {
    theme: {
      // Alternate themes here should be used sparingly, if your tooltip has
      // rich content please consider using the Popover component instead
      light: {
        backgroundColor: "$neutral0",
        color: "inherit",
        [`& ${TooltipArrow}`]: {
          fill: "$neutral0"
        }
      },
      dark: {
        backgroundColor: "$neutral90",
        color: "$neutral0",
        [`& ${TooltipArrow}`]: {
          fill: "$neutral90"
        }
      }
    },
    shadow: {
      shadow: {
        "box-shadow": "$elevation2"
      },
      "no-shadow": { boxShadow: "none" }
    }
  }
});

// src/Tooltip/Tooltip.tsx
import { Fragment as Fragment2, jsx as jsx19, jsxs as jsxs9 } from "react/jsx-runtime";
var { TooltipProvider } = TooltipPrimitive2;
var MAX_TOOLTIP_WIDTH = "217px";
var Tooltip = (props) => {
  const _a = props, {
    css: css3,
    children,
    content,
    trigger = "hover",
    defaultOpen,
    onOpen,
    onOpenChange,
    side = "top",
    align = "center",
    openDelay = 0,
    closeOnOutsideClick = true,
    interactive,
    theme: theme2 = "dark",
    shadow = "shadow",
    hideArrow,
    maxWidth = MAX_TOOLTIP_WIDTH,
    portalContainer,
    sideOffset = 0,
    disabled
  } = _a, rest = __objRest(_a, [
    "css",
    "children",
    "content",
    "trigger",
    "defaultOpen",
    "onOpen",
    "onOpenChange",
    "side",
    "align",
    "openDelay",
    "closeOnOutsideClick",
    "interactive",
    "theme",
    "shadow",
    "hideArrow",
    "maxWidth",
    "portalContainer",
    "sideOffset",
    "disabled"
  ]);
  const contentRef = useRef(null);
  const triggerRef = useRef(null);
  const [isOpen, setIsOpen] = useState2(defaultOpen || false);
  const [delayTimeout, setDelayTimeout] = useState2();
  useOnOutsideClick(
    contentRef,
    () => {
      if (closeOnOutsideClick) {
        setIsOpen(false);
      }
    },
    [triggerRef]
  );
  useEffect(() => {
    onOpenChange == null ? void 0 : onOpenChange(isOpen);
  }, [isOpen]);
  return /* @__PURE__ */ jsxs9(
    TooltipPrimitive2.Root,
    {
      delayDuration: openDelay,
      open: disabled ? false : isOpen,
      onOpenChange: (o) => {
        if (trigger === "hover") {
          if (onOpen && o) {
            Promise.resolve(onOpen(o)).then(
              (shouldOpen) => setIsOpen(shouldOpen !== void 0 ? Boolean(shouldOpen) : o)
            ).catch(() => {
            });
          } else {
            setIsOpen(o);
          }
        }
      },
      disableHoverableContent: !interactive,
      children: [
        /* @__PURE__ */ jsx19(
          TooltipPrimitive2.Trigger,
          {
            ref: triggerRef,
            onClick: () => {
              if (trigger === "click") {
                if (onOpen && !isOpen) {
                  clearTimeout(delayTimeout);
                  Promise.resolve(onOpen(!isOpen)).then(
                    () => setDelayTimeout(
                      setTimeout(() => {
                        setIsOpen((prev) => !prev);
                      }, openDelay)
                    )
                  ).catch(() => {
                  });
                } else {
                  setIsOpen((prev) => !prev);
                }
              }
            },
            asChild: true,
            children
          }
        ),
        /* @__PURE__ */ jsx19(TooltipPrimitive2.Portal, { container: portalContainer, children: /* @__PURE__ */ jsx19(
          TooltipContent,
          __spreadProps(__spreadValues({
            css: __spreadValues({ maxWidth }, css3),
            ref: contentRef,
            side,
            sideOffset: hideArrow ? 8 : sideOffset,
            align,
            theme: theme2,
            shadow
          }, rest), {
            children: /* @__PURE__ */ jsxs9(Fragment2, { children: [
              typeof content === "function" ? content(() => setIsOpen(false)) : content,
              !hideArrow && /* @__PURE__ */ jsx19(TooltipArrow, {})
            ] })
          })
        ) })
      ]
    }
  );
};

// src/Tooltip/ConditionalTooltip.tsx
import { Fragment as Fragment3, jsx as jsx20 } from "react/jsx-runtime";
var ConditionalTooltip = (_a) => {
  var _b = _a, {
    children
  } = _b, tooltipProps = __objRest(_b, [
    "children"
  ]);
  const _a2 = tooltipProps, { content } = _a2, restTooltipProps = __objRest(_a2, ["content"]);
  const canShowTooltip = validateTooltipContent(content);
  return canShowTooltip ? /* @__PURE__ */ jsx20(Tooltip, __spreadProps(__spreadValues(__spreadValues({ content }, defaultTooltipProps), restTooltipProps), { children })) : /* @__PURE__ */ jsx20(NoTooltipWrapper, { children });
};
var defaultTooltipProps = {
  side: "right",
  sideOffset: 4
};
var NoTooltipWrapper = ({ children }) => /* @__PURE__ */ jsx20(Fragment3, { children });
function mergeTooltipProps(tooltipValue, globalTooltipProps = {}) {
  if (!tooltipValue) {
    return globalTooltipProps;
  }
  if (typeof tooltipValue === "string" || typeof tooltipValue === "function") {
    return __spreadProps(__spreadValues({}, globalTooltipProps), {
      content: tooltipValue
    });
  }
  const isObject = (value) => typeof value === "object" && value !== null;
  if (!isObject(tooltipValue)) {
    return globalTooltipProps;
  }
  return __spreadValues(__spreadValues({}, globalTooltipProps), tooltipValue);
}
function validateTooltipContent(content) {
  if (!content) {
    return false;
  }
  if (typeof content === "string") {
    return content.length > 0;
  }
  if (typeof content === "function") {
    return true;
  }
  return false;
}

// src/Select/BaseSelect/components/SelectOptionItem.tsx
import { jsx as jsx21, jsxs as jsxs10 } from "react/jsx-runtime";
var SELECT_OPTION_ITEM_NAME = "SelectOptionItem";
var SelectOptionItem = React17.forwardRef(
  ({
    children: labelChild,
    value,
    disabled = false,
    icon,
    iconSize = "sm",
    tooltip,
    richLabel,
    css: optionCss,
    testId
  }, forwardedRef) => {
    const scopeProps = useSelectContext();
    const { selectRootProps } = scopeProps;
    const [isHovered, setIsHovered] = React17.useState(false);
    const checked = value === selectRootProps.value;
    const hasIcon = icon && typeof icon === "string";
    const onSelectedChange = React17.useCallback(
      (e) => {
        const dispatchValueChangeAction = selectRootProps == null ? void 0 : selectRootProps.onValueChange;
        if (disabled || !dispatchValueChangeAction) {
          return;
        }
        const event = new CustomEvent("select-option", {
          bubbles: true,
          cancelable: true,
          detail: {
            originalEvent: e,
            currentTarget: e.currentTarget
          }
        });
        dispatchValueChangeAction({
          action: "select-option",
          event,
          data: value,
          option: { value, label: labelChild },
          selectType: "select"
        });
      },
      [disabled, labelChild, selectRootProps == null ? void 0 : selectRootProps.onValueChange, value]
    );
    const handleHoverOver = React17.useCallback(() => setIsHovered(true), []);
    const handleHoverOut = React17.useCallback(() => setIsHovered(false), []);
    return /* @__PURE__ */ jsx21(ConditionalTooltip, __spreadProps(__spreadValues({}, tooltip), { children: /* @__PURE__ */ jsxs10(
      StyledSelectItem,
      {
        ref: forwardedRef,
        role: "option",
        gap: "sm",
        onMouseOver: handleHoverOver,
        onMouseOut: handleHoverOut,
        onPointerUp: onSelectedChange,
        css: optionCss,
        "aria-disabled": disabled || NO_DATA_ATTR_KEY,
        "aria-selected": checked && isHovered,
        "data-state": checked ? "checked" : "unchecked",
        "data-highlighted": isHovered ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
        "data-disabled": disabled ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
        "data-select-option-type": dataAttrHelper.selectOptionType.value.select,
        "data-testid": generateTestId(
          testId,
          SELECT_OPTION_ITEM_NAME,
          `option-item--${value}`
        ),
        children: [
          hasIcon && /* @__PURE__ */ jsx21(Icon_default, { name: icon, size: iconSize, style: { flexShrink: 0 } }),
          /* @__PURE__ */ jsx21(
            "span",
            {
              "data-disabled": disabled ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
              "data-testid": generateTestId(
                testId,
                SELECT_OPTION_ITEM_NAME,
                `option-item--${value}--label`
              ),
              style: {
                flexGrow: 1
              },
              children: richLabel || labelChild
            }
          )
        ]
      }
    ) }));
  }
);
SelectOptionItem.displayName = SELECT_OPTION_ITEM_NAME;

// src/Select/BaseSelect/components/MultiSelectOptionItem.tsx
import { isNonNullish } from "@kandji-inc/nectar-utils";
import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { useId as useId2 } from "@radix-ui/react-id";
import * as React18 from "react";
import { jsx as jsx22, jsxs as jsxs11 } from "react/jsx-runtime";
var { ENTER: ENTER2 } = SELECT_KEYBOARD_EVENT_KEY;
var MULTISELECT_OPTION_ITEM_NAME = "MultiSelectOptionItem";
var MultiSelectOptionItem = React18.forwardRef(
  ({
    children: labelChild,
    value,
    badgeColor,
    richLabel,
    disabled = false,
    icon,
    iconSize = "sm",
    onSelectAll,
    onDeselectAll,
    selectAllCheckedType,
    selectedOption,
    css: optionCss,
    testId
  }, forwardedRef) => {
    const scopeProps = useSelectContext();
    const { selectRootProps } = scopeProps;
    const checkboxRef = React18.useRef(null);
    const optionRef = React18.useRef(null);
    const composedCheckboxRefs = useComposedRefs(forwardedRef, checkboxRef);
    const optionId = useId2();
    const [isHovered, setIsHovered] = React18.useState(false);
    const isSelectAllOptionItem = value === SELECT_ALL_OPTION.value;
    const isAllSelected = selectAllCheckedType === true;
    const hasIcon = icon && typeof icon === "string";
    const checked = React18.useMemo(
      () => isSelectAllOptionItem ? isAllSelected : isNonNullish(value) && Boolean(
        selectedOption.find(
          (selectedItem) => selectedItem.value === value
        )
      ),
      [isAllSelected, isSelectAllOptionItem, selectedOption, value]
    );
    const labelContent = React18.useMemo(
      () => hasIcon ? /* @__PURE__ */ jsxs11(Flex, { alignItems: "center", gap: "sm", children: [
        /* @__PURE__ */ jsx22(Icon_default, { name: icon, size: iconSize }),
        labelChild
      ] }) : labelChild,
      [hasIcon, icon, iconSize, labelChild]
    );
    const label = React18.useMemo(
      () => !!badgeColor ? /* @__PURE__ */ jsx22(Badge, { color: badgeColor, children: labelContent }) : labelContent,
      [badgeColor, labelContent]
    );
    const onCheckedChange = React18.useCallback(
      (isChecked) => {
        const dispatchValueChangeAction = selectRootProps == null ? void 0 : selectRootProps.onValueChange;
        if (disabled || !dispatchValueChangeAction) {
          return false;
        }
        const event = new CustomEvent("select-option", {
          bubbles: true,
          cancelable: true,
          detail: {
            originalEvent: null,
            currentTarget: optionRef.current
          }
        });
        if (isSelectAllOptionItem && isAllSelected) {
          const defaultDeselectAll = () => {
            dispatchValueChangeAction({
              action: "deselect-all",
              event,
              data: SELECT_ALL_OPTION.value,
              option: SELECT_ALL_OPTION,
              selectType: "multi"
            });
          };
          const handleDeselectAll = onDeselectAll != null ? onDeselectAll : defaultDeselectAll;
          return handleDeselectAll();
        }
        if (isSelectAllOptionItem && !isAllSelected) {
          const defaultSelectAll = () => {
            dispatchValueChangeAction({
              action: "select-all",
              event,
              data: SELECT_ALL_OPTION.value,
              option: SELECT_ALL_OPTION,
              selectType: "multi"
            });
          };
          const handleSelectAll = onSelectAll != null ? onSelectAll : defaultSelectAll;
          return handleSelectAll();
        }
        return isChecked ? dispatchValueChangeAction({
          action: "select-option",
          event,
          data: value,
          option: { value, label: labelChild },
          selectType: "multi"
        }) : dispatchValueChangeAction({
          action: "deselect-option",
          event,
          data: value,
          option: { value, label: labelChild },
          selectType: "multi"
        });
      },
      [
        disabled,
        isAllSelected,
        isSelectAllOptionItem,
        labelChild,
        onDeselectAll,
        onSelectAll,
        selectRootProps == null ? void 0 : selectRootProps.onValueChange,
        value
      ]
    );
    const onMultiItemClick = React18.useCallback(() => {
      onCheckedChange(!checked);
    }, [checked, onCheckedChange]);
    const onCheckboxKeyDown = React18.useCallback(
      (event) => {
        if (event.key === ENTER2) {
          onCheckedChange(!checked);
        }
      },
      [checked, onCheckedChange]
    );
    const handleHoverOver = React18.useCallback(() => setIsHovered(true), []);
    const handleHoverOut = React18.useCallback(() => setIsHovered(false), []);
    return /* @__PURE__ */ jsx22(
      StyledMultiItem,
      {
        ref: optionRef,
        role: "option",
        gap: "sm",
        disabled,
        onClick: onMultiItemClick,
        onMouseOver: handleHoverOver,
        onMouseOut: handleHoverOut,
        css: optionCss,
        "aria-disabled": disabled || NO_DATA_ATTR_KEY,
        "aria-labelledby": optionId,
        "aria-selected": checked && isHovered,
        "data-state": checked ? "checked" : "unchecked",
        "data-highlighted": isHovered ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
        "data-disabled": disabled ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
        "data-select-all-option": isSelectAllOptionItem ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
        "data-testid": generateTestId(
          testId,
          MULTISELECT_OPTION_ITEM_NAME,
          `multi-option-item--${value}`
        ),
        children: /* @__PURE__ */ jsx22(
          StyledMultiItemCheckbox,
          {
            ref: composedCheckboxRefs,
            gap: "control",
            id: optionId,
            label: richLabel || label,
            checked,
            disabled,
            onCheckedChange,
            onKeyDown: onCheckboxKeyDown,
            "data-select-option-type": dataAttrHelper.selectOptionType.value.multi,
            "data-disabled": disabled ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
            "data-testid": generateTestId(
              testId,
              MULTISELECT_OPTION_ITEM_NAME,
              `multi-option-item-checkbox--${value}`
            ),
            onClick: (e) => e.preventDefault()
          }
        )
      }
    );
  }
);
MultiSelectOptionItem.displayName = MULTISELECT_OPTION_ITEM_NAME;

// src/Select/BaseSelect/index.ts
import * as SelectPrimitive4 from "@radix-ui/react-select";
var publicSelectBaseComponents = {
  Styled: publicSelectStyledComponents,
  Provider: SelectProvider,
  Root: SelectRoot,
  TriggerSlot: SelectTriggerSlot,
  OptionItem: SelectOptionItem,
  MultiOptionItem: MultiSelectOptionItem
};

// src/Select/hooks/useSelect/useSelect.tsx
import { jsx as jsx23 } from "react/jsx-runtime";
var MULTI_VALUE_OVERFLOW_INDEX_BUFFER = 1;
var { TAB: TAB2, ENTER: ENTER3, UP, DOWN, BACKSPACE } = SELECT_KEYBOARD_EVENT_KEY;
function useSelect({
  multi,
  value,
  onChange,
  onValueChange: consumerOnValueChange = void 0,
  onEventAction: consumerOnEventAction = void 0,
  onOpenChange: consumerOnOpenChange = void 0,
  options,
  optionsFilter = defaultSelectOptionsFilter,
  hideNoOptionsFoundMessage = false,
  open: controlledOpen,
  defaultOpen = false,
  closeMenuOnSelect = true,
  closeMenuOnEscape = true,
  closeMenuOnOutsideClick = true,
  searchable = false,
  keepSearchOnSelect = false,
  searchPattern = void 0,
  searchFn = void 0,
  onCancelSearch = void 0,
  creatable = void 0,
  disabled = false,
  readOnly = false,
  selectAll,
  valueKey = DEFAULT_VALUE_KEY,
  labelKey = DEFAULT_LABEL_KEY
}, forwardedRef) {
  var _a, _b, _c;
  const [isSelectMenuOpen, setIsSelectMenuOpen] = React19.useState(
    controlledOpen || defaultOpen
  );
  const [multiValueKey, setMultiValueKey] = React19.useState(() => 1);
  const selectTriggerRef = React19.useRef(null);
  const optionsMenuRef = React19.useRef(null);
  const searchInputRef = React19.useRef(null);
  const optionsMetaRef = React19.useRef(
    (/* @__PURE__ */ new Map()).set("optionsCollection", {
      options: null,
      values: null
    }).set("enabledOptionsCollection", {
      options: null,
      values: null
    }).set("disabledOptionsCollection", {
      options: null,
      values: null
    })
  );
  const multiValueOverflowIndexRef = React19.useRef(
    void 0
  );
  const isSearchingRef = React19.useRef(false);
  const canvasRef = React19.useRef(
    document.createElement("canvas")
  );
  const composedTriggerRefs = useComposedRefs2(forwardedRef, selectTriggerRef);
  const searchInputId = useId3();
  const selectScopeRef = React19.useRef({});
  const selectScope = getDefaultSelectScope({
    useSelect: SelectContext
  });
  const useSelectScope = React19.useMemo(
    () => createUseSelectScope(selectScope),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- scope is stable
    []
  );
  const scope = React19.useMemo(
    () => ({
      selectScopeRef,
      selectScope,
      useSelectScope
    }),
    [selectScope, useSelectScope]
  );
  const internalSelectOptions = React19.useMemo(() => {
    const optValueKey = valueKey;
    const optLabelKey = labelKey;
    const opts = options == null ? void 0 : options.flatMap((opt) => {
      if (hasOwn(opt, "section")) {
        return opt.options.map((nestedOpt) => {
          const _a3 = nestedOpt, {
            [optValueKey]: normalizedValue2,
            [optLabelKey]: normalizedLabel2
          } = _a3, restOpt2 = __objRest(_a3, [
            __restKey(optValueKey),
            __restKey(optLabelKey)
          ]);
          const normalizedOption2 = __spreadValues({
            value: normalizedValue2,
            label: normalizedLabel2
          }, restOpt2);
          return __spreadProps(__spreadValues({}, normalizedOption2), {
            kind: "section-option",
            section: opt.section,
            // @ts-expect-error -- TODO: fix
            showSectionLabel: opt.showSectionLabel || false,
            originalOption: nestedOpt,
            originalSection: opt,
            normalizedOption: normalizedOption2
          });
        });
      }
      const _a2 = opt, {
        [optValueKey]: normalizedValue,
        [optLabelKey]: normalizedLabel
      } = _a2, restOpt = __objRest(_a2, [
        __restKey(optValueKey),
        __restKey(optLabelKey)
      ]);
      const normalizedOption = __spreadValues({
        value: normalizedValue,
        label: normalizedLabel
      }, restOpt);
      return __spreadProps(__spreadValues({}, normalizedOption), {
        kind: "option",
        section: null,
        showSectionLabel: false,
        originalOption: opt,
        originalSection: null,
        normalizedOption
      });
    });
    return opts || [];
  }, [labelKey, options, valueKey]);
  React19.useEffect(() => {
    const optionsMap = /* @__PURE__ */ new Map();
    const optionValues = [];
    const enabledOpts = [];
    const enabledValues = [];
    const disabledOpts = [];
    const disabledValues = [];
    const optionsMeta = optionsMetaRef.current;
    internalSelectOptions.forEach((opt) => {
      optionsMap.set(opt.value, opt);
      optionValues.push(opt.value);
      if (opt.disabled === true) {
        disabledOpts.push(opt);
        disabledValues.push(opt.value);
      } else {
        enabledOpts.push(opt);
        enabledValues.push(opt.value);
      }
    });
    optionsMeta.set("optionsMap", optionsMap);
    optionsMeta.set("optionsCollection", {
      options: internalSelectOptions,
      values: optionValues
    });
    optionsMeta.set("enabledOptionsCollection", {
      options: enabledOpts,
      values: enabledValues
    });
    optionsMeta.set("disabledOptionsCollection", {
      options: disabledOpts,
      values: disabledValues
    });
    return () => {
      var _a2;
      (_a2 = optionsMeta.get("optionsMap")) == null ? void 0 : _a2.clear();
      optionsMeta.clear();
    };
  }, [internalSelectOptions]);
  const getOption = React19.useCallback(
    (optValue) => {
      var _a2, _b2;
      return (_b2 = (_a2 = optionsMetaRef.current.get("optionsMap")) == null ? void 0 : _a2.get(optValue)) != null ? _b2 : getOptionFallback(optValue, internalSelectOptions);
    },
    [internalSelectOptions]
  );
  const getEnabledOptions = React19.useCallback(
    () => {
      var _a2;
      return (_a2 = optionsMetaRef.current.get("enabledOptionsCollection")) != null ? _a2 : getEnabledOptionsFallback(internalSelectOptions);
    },
    [internalSelectOptions]
  );
  const getDisabledOptions = React19.useCallback(
    () => {
      var _a2;
      return (_a2 = optionsMetaRef.current.get("disabledOptionsCollection")) != null ? _a2 : getDisabledOptionsFallback(internalSelectOptions);
    },
    [internalSelectOptions]
  );
  const getSectionedOptions = React19.useCallback(
    ({
      visibleOptions: filteredInVisibleOpts = [],
      isReturnOriginalOptions = false
    } = {}) => {
      var _a2;
      const processOptions = isReturnOriginalOptions ? internalSelectOptions : filteredInVisibleOpts;
      const filteredInSectionedOpts = processOptions == null ? void 0 : processOptions.reduce(
        // @ts-expect-error -- TODO: fix
        (filterMap, currOpt) => {
          const isSection = currOpt.kind === "section-option";
          if (isSection) {
            const section = filterMap.get(currOpt.section);
            if (!section) {
              const newSection = {
                section: currOpt.section,
                showSectionLabel: currOpt.showSectionLabel,
                options: [currOpt.normalizedOption]
              };
              filterMap.set(currOpt.section, newSection);
              filterMap.get(filteredInVisibleOpts).push(newSection);
            } else {
              section.options.push(currOpt.normalizedOption);
            }
          } else {
            filterMap.get(filteredInVisibleOpts).push(currOpt.normalizedOption);
          }
          return filterMap;
        },
        (/* @__PURE__ */ new Map()).set(filteredInVisibleOpts, [])
      );
      return {
        values: filteredInVisibleOpts,
        options: (_a2 = filteredInSectionedOpts == null ? void 0 : filteredInSectionedOpts.get(filteredInVisibleOpts)) != null ? _a2 : []
      };
    },
    [internalSelectOptions]
  );
  const optionsUtils = React19.useMemo(
    () => ({
      getOption,
      getSectionedOptions,
      getEnabledOptions,
      getDisabledOptions,
      optionsMeta: optionsMetaRef.current
    }),
    [getDisabledOptions, getEnabledOptions, getOption, getSectionedOptions]
  );
  const {
    visibleOptions,
    selectedOption,
    getInputProps,
    getOptionProps,
    searchValue,
    setSearch
  } = useSelectLib({
    value,
    onChange,
    multi,
    /**
     * @privateRemarks
     *
     * The `options` array needs to be normalized with each option item having
     * `label` and `value` key values instead of dynamic `labelKey` and
     * `valueKey` computed key values for `use-select` lib to work properly.
     * The returned `visibleOptions` and `selectedOption` hook values will
     * always use the normalized `label` and `value` key values instead of the
     * dynamic `labelKey` and `valueKey` computed key values, if passed in.
     */
    options: internalSelectOptions,
    optionsRef: optionsMenuRef,
    filterFn: optionsFilter,
    /**
     * @privateRemarks
     *
     * Setting `duplicates` to true as a workaround to display all
     * option items in the select menu even though they are already selected.
     */
    duplicates: !!multi
  });
  React19.useEffect(() => {
    if (!multi) {
      assertElseThrow2(
        !Array.isArray(value),
        "Expected `value` to be a single option value. Pass `multi` as false for `useSelect` args.",
        value,
        {
          prefix: "Nectar useSelect"
        }
      );
      const optForValue = getOption(value);
      if (optForValue == null ? void 0 : optForValue.label) {
        setSearch(optForValue.label);
      }
    }
    return void 0;
  }, [getOption, multi, setSearch, value]);
  const hasControlledOpen = typeof controlledOpen === "boolean";
  const hasSearchValue = !!searchValue;
  const isUnselectable = disabled || readOnly;
  const isSelectAllEnabled = multi && selectAll !== void 0;
  const {
    label: selectAllValueLabel,
    selectAllLabel,
    onSelectAll,
    onDeselectAll
  } = React19.useMemo(
    () => ({
      label: (selectAll == null ? void 0 : selectAll.valueLabel) || "",
      selectAllLabel: selectAll == null ? void 0 : selectAll.selectAllLabel,
      onSelectAll: selectAll == null ? void 0 : selectAll.onSelectAll,
      onDeselectAll: selectAll == null ? void 0 : selectAll.onDeselectAll
    }),
    [
      selectAll == null ? void 0 : selectAll.valueLabel,
      selectAll == null ? void 0 : selectAll.selectAllLabel,
      selectAll == null ? void 0 : selectAll.onSelectAll,
      selectAll == null ? void 0 : selectAll.onDeselectAll
    ]
  );
  const selectAllCheckedType = multi ? getSelectAllCheckedType(
    value,
    (_c = (_b = (_a = getEnabledOptions()) == null ? void 0 : _a.values) == null ? void 0 : _b.length) != null ? _c : getEnabledOptionsFallback(internalSelectOptions)
  ) : void 0;
  const prevSelectAllCheckedType = usePrevious(selectAllCheckedType);
  const isAllSelected = selectAllCheckedType === true;
  const isShowNoOptionsMessage = !hideNoOptionsFoundMessage && !visibleOptions.length && !isAllSelected;
  const searchFilteredOptions = React19.useMemo(() => {
    const filteredSectionedOptions = getSectionedOptions({
      visibleOptions,
      isReturnOriginalOptions: !isSearchingRef.current || !hasSearchValue
    }).options;
    if (multi) {
      const selectAllOpt = isSelectAllEnabled && !isShowNoOptionsMessage ? [
        __spreadProps(__spreadValues({}, SELECT_ALL_OPTION), {
          label: selectAllLabel || SELECT_ALL_OPTION.label
        })
      ] : [];
      return hasSearchValue ? filteredSectionedOptions : selectAllOpt.concat(filteredSectionedOptions);
    }
    return filteredSectionedOptions;
  }, [
    getSectionedOptions,
    hasSearchValue,
    internalSelectOptions,
    isSelectAllEnabled,
    isShowNoOptionsMessage,
    multi,
    selectAllLabel,
    visibleOptions
  ]);
  const onEventAction = React19.useCallback(
    (eventMeta) => {
      const internalOnEventAction = (internalEventMeta) => __async(this, null, function* () {
        var _a2, _b2, _c2, _d2, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t;
        const { action } = internalEventMeta;
        switch (action) {
          case "select-option": {
            if (!multi) {
              const { option } = internalEventMeta;
              setSearch(option.label);
            } else if (!keepSearchOnSelect) {
              setSearch("");
            }
            isSearchingRef.current = false;
            if (closeMenuOnSelect && !hasControlledOpen) {
              (_b2 = (_a2 = selectScopeRef.current) == null ? void 0 : _a2.onOpenChange) == null ? void 0 : _b2.call(_a2, false);
            }
            return void 0;
          }
          case "search-input-change": {
            const targetValue = internalEventMeta.event.target.value;
            if (searchFn) {
              yield searchFn(targetValue);
            }
            if (multi) {
              const isActivateSearching = !isSearchingRef.current && targetValue;
              if (isActivateSearching) {
                isSearchingRef.current = true;
              }
            } else {
              assertElseThrow2(
                !Array.isArray(selectedOption),
                "Expected `selectedOption` to be a single option item. Pass `multi` as false for `useSelect` args.",
                selectedOption,
                {
                  prefix: "Nectar useSelect"
                }
              );
              setSearch(
                targetValue.replace((_c2 = selectedOption.label) == null ? void 0 : _c2.toString(), "")
              );
              if (targetValue === "") {
                yield setSearch((_d2 = selectedOption.label) == null ? void 0 : _d2.toString());
                (_g = searchInputRef.current) == null ? void 0 : _g.setSelectionRange(
                  (_e = selectedOption.label) == null ? void 0 : _e.toString().length,
                  (_f = selectedOption.label) == null ? void 0 : _f.toString().length
                );
              }
              const isActivateSearching = !isSearchingRef.current && targetValue && (!selectedOption.label || selectedOption.label && value !== selectedOption.label);
              if (isActivateSearching) {
                isSearchingRef.current = true;
              }
            }
            return void 0;
          }
          case "search-input-focus": {
            if (!multi) {
              assertElseThrow2(
                !Array.isArray(selectedOption),
                "Expected `selectedOption` to be a single option item. Pass `multi` as false for `useSelect` args.",
                selectedOption,
                {
                  prefix: "Nectar useSelect"
                }
              );
              if (selectedOption.label) {
                setSearch(selectedOption.label);
              }
              (_h = searchInputRef.current) == null ? void 0 : _h.setSelectionRange(
                selectedOption.label.length,
                selectedOption.label.length
              );
            } else if (keepSearchOnSelect) {
              setSearch(internalEventMeta.event.target.value);
            }
            return void 0;
          }
          case "select-content-pointer-down-outside": {
            if (!closeMenuOnOutsideClick) {
              return internalEventMeta.event.preventDefault();
            }
            isSearchingRef.current = false;
            if (multi) {
              const path2 = (_j = (_i = internalEventMeta.event.detail.originalEvent).composedPath) == null ? void 0 : _j.call(_i);
              const hasTriggerInPath2 = path2 == null ? void 0 : path2.some(
                (element) => element === selectTriggerRef.current
              );
              if (((_k = selectScopeRef.current) == null ? void 0 : _k.open) && !hasTriggerInPath2 && !hasControlledOpen) {
                (_m = (_l = selectScopeRef.current) == null ? void 0 : _l.onOpenChange) == null ? void 0 : _m.call(_l, false);
                queueMicrotask(() => {
                  setSearch("");
                });
              }
              onCancelSearch == null ? void 0 : onCancelSearch();
              return void 0;
            }
            assertElseThrow2(
              !Array.isArray(selectedOption),
              "Expected `selectedOption` to be a single option item. Pass `multi` as false for `useSelect` args.",
              selectedOption,
              {
                prefix: "Nectar useSelect"
              }
            );
            const path = (_o = (_n = internalEventMeta.event.detail.originalEvent).composedPath) == null ? void 0 : _o.call(_n);
            const hasTriggerInPath = path == null ? void 0 : path.some(
              (element) => element === selectTriggerRef.current
            );
            if (((_p = selectScopeRef.current) == null ? void 0 : _p.open) && !hasTriggerInPath && !hasControlledOpen) {
              onCancelSearch == null ? void 0 : onCancelSearch();
              (_r = (_q = selectScopeRef.current) == null ? void 0 : _q.onOpenChange) == null ? void 0 : _r.call(_q, false);
              setSearch(selectedOption.label || "");
            }
            return void 0;
          }
          case "select-content-escape-keydown": {
            if (closeMenuOnEscape && !hasControlledOpen) {
              onCancelSearch == null ? void 0 : onCancelSearch();
              (_t = (_s = selectScopeRef.current) == null ? void 0 : _s.onOpenChange) == null ? void 0 : _t.call(_s, false);
            }
            return void 0;
          }
          default:
            return false;
        }
      });
      if (consumerOnEventAction) {
        return consumerOnEventAction(eventMeta, {
          internalOnEventAction
        });
      }
      return internalOnEventAction(eventMeta);
    },
    [
      closeMenuOnEscape,
      closeMenuOnOutsideClick,
      closeMenuOnSelect,
      consumerOnEventAction,
      hasControlledOpen,
      multi,
      selectedOption,
      value,
      searchFn,
      setSearch,
      onCancelSearch
    ]
  );
  const onSelectTriggerPointerDown = React19.useCallback(
    (e) => {
      var _a2, _b2, _c2, _d2, _e, _f, _g, _h, _i;
      const { nativeEvent } = e;
      const path = (_a2 = nativeEvent.composedPath) == null ? void 0 : _a2.call(nativeEvent);
      if (!path) {
        return;
      }
      const isOpenIconNode = path.find((element) => {
        var _a3;
        if (element instanceof HTMLElement) {
          return (_a3 = element.classList) == null ? void 0 : _a3.contains(StyledSelectOpenIcon.className);
        }
        return false;
      });
      const isCurrOpen = (_b2 = selectScopeRef.current) == null ? void 0 : _b2.open;
      const willOpen = !isCurrOpen;
      if (isOpenIconNode && !hasControlledOpen) {
        e.preventDefault();
        (_d2 = (_c2 = selectScopeRef.current) == null ? void 0 : _c2.onOpenChange) == null ? void 0 : _d2.call(_c2, willOpen);
        if (willOpen) {
          (_e = searchInputRef.current) == null ? void 0 : _e.focus();
        } else if (document.activeElement === searchInputRef.current) {
          (_f = searchInputRef.current) == null ? void 0 : _f.blur();
        }
        return;
      }
      if (willOpen && !hasControlledOpen) {
        e.preventDefault();
        (_h = (_g = selectScopeRef.current) == null ? void 0 : _g.onOpenChange) == null ? void 0 : _h.call(_g, true);
      }
      (_i = searchInputRef.current) == null ? void 0 : _i.focus();
    },
    [hasControlledOpen]
  );
  const onSelectContentPointerDownOutside = React19.useCallback(
    (event) => {
      onEventAction({
        action: "select-content-pointer-down-outside",
        selectType: multi ? "multi" : "select",
        event,
        data: null,
        option: null
      });
    },
    [multi, onEventAction]
  );
  const onOpenChange = React19.useCallback(
    (open) => {
      const isOpen = hasControlledOpen ? controlledOpen : open;
      setIsSelectMenuOpen(isOpen);
      if (consumerOnOpenChange) {
        return consumerOnOpenChange(isOpen);
      }
    },
    [controlledOpen, hasControlledOpen]
  );
  const onValueChangeActionSelect = React19.useCallback(
    (valueChangeMeta) => {
      if (consumerOnValueChange) {
        return consumerOnValueChange(valueChangeMeta);
      }
      assertElseThrow2(
        !multi,
        "`onValueChangeSelect` can only be used for selects. Pass `multi` as false for `useSelect` args.",
        {
          prefix: "Nectar useSelect"
        }
      );
      assertElseThrow2(
        onChange,
        `Expected \`onChange\` to be a function, got ${typeof onChange} instead. Pass \`onChange\` as a function for \`useSelect\` args.`,
        onChange,
        {
          prefix: "Nectar useSelect"
        }
      );
      const { action } = valueChangeMeta;
      switch (action) {
        case "select-option": {
          onEventAction({
            action: "select-option",
            selectType: "select",
            event: valueChangeMeta.event,
            data: valueChangeMeta.data,
            option: valueChangeMeta.option
          });
          const valueChangeOption = getOption(valueChangeMeta.option.value) || valueChangeMeta.option;
          return onChange(valueChangeMeta.data, {
            action: "select-option",
            selectType: "select",
            event: valueChangeMeta.event,
            data: valueChangeMeta.data,
            option: valueChangeOption,
            // @ts-expect-error -- TODO: fix
            optionsUtils
          });
        }
        default:
          return false;
      }
    },
    [
      consumerOnValueChange,
      getOption,
      multi,
      onChange,
      onEventAction,
      optionsUtils
    ]
  );
  const onValueChangeActionMulti = React19.useCallback(
    (valueChangeMeta) => {
      var _a2, _b2, _c2;
      if (consumerOnValueChange) {
        return consumerOnValueChange(valueChangeMeta);
      }
      assertElseThrow2(
        multi,
        "`onValueChangeMulti` can only be used for multi-selects. Pass `multi` as true for `useSelect` args.",
        value,
        {
          prefix: "Nectar useSelect"
        }
      );
      assertElseThrow2(
        onChange,
        `Expected \`onChange\` to be a function, got ${typeof onChange} instead. Pass \`onChange\` as a function for \`useSelect\` args.`,
        onChange,
        {
          prefix: "Nectar useSelect"
        }
      );
      const { action } = valueChangeMeta;
      switch (action) {
        case "select-option": {
          onEventAction({
            action: "select-option",
            selectType: "multi",
            event: valueChangeMeta.event,
            data: valueChangeMeta.data,
            option: valueChangeMeta.option
          });
          const valueChangeOption = getOption(valueChangeMeta.option.value) || valueChangeMeta.option;
          return onChange([...value, valueChangeMeta.data], {
            action: "select-option",
            selectType: "multi",
            event: valueChangeMeta.event,
            data: valueChangeMeta.data,
            option: valueChangeOption,
            selectedMultiOptions: [...selectedOption, valueChangeOption],
            // @ts-expect-error -- TODO: fix
            optionsUtils
          });
        }
        case "deselect-option": {
          const valueChangeOption = getOption(valueChangeMeta.option.value) || valueChangeMeta.option;
          return onChange(
            value.filter((val) => val !== valueChangeMeta.data),
            {
              action: "deselect-option",
              selectType: "multi",
              event: valueChangeMeta.event,
              data: valueChangeMeta.data,
              option: valueChangeOption,
              selectedMultiOptions: selectedOption.filter(
                (opt) => opt.value !== valueChangeMeta.option.value
              ),
              // @ts-expect-error -- TODO: fix
              optionsUtils
            }
          );
        }
        case "select-all": {
          return onChange(
            (_b2 = (_a2 = getEnabledOptions()) == null ? void 0 : _a2.values) != null ? _b2 : getEnabledOptionsFallback(internalSelectOptions),
            {
              action: "select-all",
              selectType: "multi",
              event: valueChangeMeta.event,
              data: valueChangeMeta.data,
              option: valueChangeMeta.option,
              selectedMultiOptions: (_c2 = getEnabledOptions()) == null ? void 0 : _c2.options,
              // @ts-expect-error -- TODO: fix
              optionsUtils
            }
          );
        }
        case "deselect-all":
          return onChange([], {
            action: "deselect-all",
            selectType: "multi",
            event: valueChangeMeta.event,
            data: valueChangeMeta.data,
            option: valueChangeMeta.option,
            selectedMultiOptions: [],
            // @ts-expect-error -- TODO: fix
            optionsUtils
          });
        case "deselect-hidden": {
          if (typeof valueChangeMeta.data === "number") {
            const removedOptionValues = value.slice(
              0,
              valueChangeMeta.data - MULTI_VALUE_OVERFLOW_INDEX_BUFFER
            );
            return onChange(removedOptionValues, {
              action: "deselect-hidden",
              selectType: "multi",
              event: valueChangeMeta.event,
              data: valueChangeMeta.data,
              option: valueChangeMeta.option,
              selectedMultiOptions: removedOptionValues.map(
                (val) => getOption(val)
              ),
              // @ts-expect-error -- TODO: fix
              optionsUtils
            });
          }
          return void 0;
        }
        default:
          return false;
      }
    },
    [
      consumerOnValueChange,
      getEnabledOptions,
      getOption,
      internalSelectOptions,
      multi,
      onChange,
      onEventAction,
      optionsUtils,
      selectedOption,
      value
    ]
  );
  const rerenderValues = React19.useCallback(
    () => setMultiValueKey((prev) => {
      if (prev >= Number.MAX_SAFE_INTEGER) {
        return 0;
      }
      return prev + 1;
    }),
    []
  );
  const renderMultiSelectValues = React19.useCallback(
    ({ showHiddenCountValue } = {
      showHiddenCountValue: true
    }) => {
      var _a2, _b2;
      if (isSelectAllEnabled && isAllSelected && selectAllValueLabel) {
        return /* @__PURE__ */ jsx23(
          Chip,
          {
            label: selectAllValueLabel,
            dismissible: {
              onIconClick: (e) => {
                const event = new CustomEvent("select-option", {
                  bubbles: true,
                  cancelable: true,
                  detail: {
                    originalEvent: e,
                    currentTarget: e.currentTarget
                  }
                });
                onValueChangeActionMulti({
                  action: "deselect-all",
                  event,
                  data: SELECT_ALL_OPTION.value,
                  option: SELECT_ALL_OPTION,
                  selectType: "multi"
                });
              }
            },
            "data-multi-value-dismissible": dataAttrHelper.dismissibleMultiValue.value.true
          }
        );
      }
      const triggerRect = (_a2 = selectTriggerRef.current) == null ? void 0 : _a2.getBoundingClientRect();
      const textInputRect = (_b2 = searchInputRef.current) == null ? void 0 : _b2.getBoundingClientRect();
      const valuesJsx = selectedOption.flatMap(
        (opt, optionIndex, optionsArr) => {
          if (opt.value === SELECT_ALL_OPTION.value) {
            return null;
          }
          const totalOptions = optionsArr.length;
          const overflowIndexAt = multiValueOverflowIndexRef.current || Infinity;
          const overflowIndexBuffer = optionIndex + MULTI_VALUE_OVERFLOW_INDEX_BUFFER;
          if (overflowIndexAt === overflowIndexBuffer) {
            const moreValuesCount = totalOptions - optionIndex;
            return /* @__PURE__ */ jsx23(
              StyledMultiValueChip,
              {
                label: `+${moreValuesCount} more`,
                "data-multi-value-hidden-count": dataAttrHelper.hiddenMultiValueCount.value.true,
                animateIn: true
              },
              "more-multi-select-values"
            );
          }
          return /* @__PURE__ */ jsx23(
            StyledMultiValueChip,
            {
              ref: (valueRef) => onMultiValueOverflowRef(valueRef, {
                enabled: showHiddenCountValue === true,
                optionIndex,
                totalOptions,
                multiValueOverflowIndexRef,
                triggerRect,
                textInputRect,
                searchable,
                prevSelectAllCheckedType,
                rerenderValues
              }),
              label: opt.label,
              dismissible: {
                onIconClick: (e) => {
                  const event = new CustomEvent("select-option", {
                    bubbles: true,
                    cancelable: true,
                    detail: {
                      originalEvent: e,
                      currentTarget: e.currentTarget
                    }
                  });
                  onValueChangeActionMulti({
                    action: "deselect-option",
                    event,
                    data: opt.value,
                    option: opt,
                    selectType: "multi"
                  });
                }
              },
              noDisplay: overflowIndexAt < overflowIndexBuffer,
              "data-multi-value-dismissible": dataAttrHelper.dismissibleMultiValue.value.true
            },
            `${String(opt.value)}:${optionIndex}`
          );
        }
      );
      return /* @__PURE__ */ jsx23(React19.Fragment, { children: valuesJsx }, multiValueKey);
    },
    [
      isAllSelected,
      isSelectAllEnabled,
      multiValueKey,
      onValueChangeActionMulti,
      prevSelectAllCheckedType,
      rerenderValues,
      searchable,
      selectAllValueLabel,
      selectedOption
    ]
  );
  const selectCreatableOption = React19.useCallback(
    (e, creatableConfig) => {
      var _a2, _b2;
      const config2 = getDefaultCreatableConfig(
        creatableConfig,
        {
          searchable,
          valueKey,
          labelKey,
          triggerKey: e.key
        }
      );
      const creatableSearchValue = e.target.value;
      const isCreatableActive = typeof config2.active === "function" ? config2.active(creatableSearchValue, {
        selectedOption,
        searchFilteredOptions
      }) === true : config2.active === true;
      if (!isCreatableActive) {
        return {
          skipOtherKeyDownKey: false
        };
      }
      const isCreatableDisabled = typeof config2.disabled === "function" ? config2.disabled(creatableSearchValue, {
        selectedOption,
        searchFilteredOptions
        // triggerKey: e.key,
      }) === true : config2.disabled;
      const creatableOption = {
        [valueKey]: ((_a2 = config2 == null ? void 0 : config2.formatValue) == null ? void 0 : _a2.call(config2, creatableSearchValue, {
          selectedOption,
          searchFilteredOptions
          // triggerKey: e.key,
        })) || creatableSearchValue,
        [labelKey]: (config2 == null ? void 0 : config2.formatLabel(creatableSearchValue, {
          selectedOption,
          searchFilteredOptions,
          creatableSearchValue,
          triggerKey: e.key
        })) || creatableSearchValue,
        disabled: isCreatableDisabled
        // ...restOptionProps, // TODO: support option icon, icon size, etc. when needed
      };
      const isEmptyValue = creatableOption[valueKey] === "";
      if (
        /* isDupeValue || */
        isEmptyValue
      ) {
        return {
          skipOtherKeyDownKey: false
        };
      }
      const canContinueToCreate = config2 == null ? void 0 : config2.onCreate(creatableOption, {
        selectedOption,
        searchFilteredOptions,
        creatableSearchValue,
        triggerKey: e.key
      });
      if (canContinueToCreate === false) {
        return {
          skipOtherKeyDownKey: false
        };
      }
      onValueChangeActionMulti({
        action: "select-option",
        event: e,
        data: creatableOption[valueKey],
        option: {
          value: creatableOption[valueKey],
          label: creatableOption[labelKey],
          disabled: creatableOption.disabled
          // ...restOptionProps, // TODO: support option icon, icon size, etc. when needed
        },
        selectType: multi ? "multi" : "select"
      });
      const skipOtherKeyDownKey = (
        // @ts-expect-error -- TODO: fix type
        !((_b2 = config2 == null ? void 0 : config2.persistOtherTriggerKeyDownEvents) == null ? void 0 : _b2.includes(
          e.key
        ))
      );
      return { skipOtherKeyDownKey };
    },
    [
      labelKey,
      multi,
      onValueChangeActionMulti,
      searchFilteredOptions,
      searchable,
      selectedOption,
      valueKey
    ]
  );
  const _d = React19.useMemo(
    () => searchable ? getInputProps() : {
      ref: null,
      value: "",
      onBlur: noop,
      onChange: noop,
      onClick: noop,
      onFocus: noop,
      onKeyDown: noop
    },
    [getInputProps, searchable]
  ), {
    value: _omitInputValue,
    ref: selectLibInputRefCallback
  } = _d, selectLibInputHandlers = __objRest(_d, [
    "value",
    "ref"
  ]);
  const onSearchInputRef = React19.useCallback(
    (inputRef) => {
      if (inputRef) {
        inputRef.setAttribute("rows", "1");
        inputRef.setAttribute("data-search-value", inputRef.value);
        searchInputRef.current = inputRef;
        selectLibInputRefCallback == null ? void 0 : selectLibInputRefCallback(inputRef);
      }
    },
    [selectLibInputRefCallback]
  );
  const onSearchInputChange = React19.useCallback(
    (event) => {
      if (!searchable || !checkSearchPattern(searchPattern, event.currentTarget.value)) {
        return void 0;
      }
      onEventAction({
        action: "search-input-change",
        selectType: multi ? "multi" : "select",
        event,
        data: event.currentTarget.value,
        option: null
      });
      return selectLibInputHandlers.onChange(event);
    },
    [multi, onEventAction, searchPattern, searchable, selectLibInputHandlers]
  );
  const onSearchInputKeyDown = React19.useCallback(
    (e) => {
      var _a2, _b2, _c2, _d2;
      if (!searchable) {
        return void 0;
      }
      const defaultSelectLibOnKeyDown = selectLibInputHandlers.onKeyDown;
      const optionType = multi ? dataAttrHelper.selectOptionType.value.multi : dataAttrHelper.selectOptionType.value.select;
      const { skipOtherKeyDownKey } = selectCreatableOption(e, creatable);
      if (skipOtherKeyDownKey) {
        return void 0;
      }
      switch (e.key) {
        case TAB2:
        case ENTER3: {
          e.preventDefault();
          const firstFocusableOption = dataAttrHelper.selectOptionType.get[optionType](
            optionsMenuRef.current
          );
          if (firstFocusableOption) {
            const isChecked = (firstFocusableOption == null ? void 0 : firstFocusableOption.getAttribute("data-state")) === "checked";
            const firstOption = isSelectAllEnabled ? SELECT_ALL_OPTION : options[0];
            if (!isChecked && firstOption && visibleOptions.length === 1) {
              return onValueChangeActionMulti({
                action: isSelectAllEnabled ? "select-all" : "select-option",
                event: e,
                data: firstOption.value,
                option: firstOption,
                selectType: "multi"
              });
            }
            (_b2 = (_a2 = selectScopeRef.current) == null ? void 0 : _a2.onOpenChange) == null ? void 0 : _b2.call(_a2, false);
            onCancelSearch == null ? void 0 : onCancelSearch();
          } else if (creatable && searchValue) {
            creatable == null ? void 0 : creatable.onCreate(searchValue);
            setSearch("");
          }
          return;
        }
        case DOWN: {
          e.preventDefault();
          if (!isSelectMenuOpen && e.altKey) {
            return (_c2 = selectTriggerRef.current) == null ? void 0 : _c2.dispatchEvent(
              new PointerEvent("pointerdown", {
                pointerId: 1,
                bubbles: true,
                cancelable: true
              })
            );
          }
          const firstFocusableOption = dataAttrHelper.selectOptionType.get[optionType](
            optionsMenuRef.current
          );
          return firstFocusableOption == null ? void 0 : firstFocusableOption.focus();
        }
        case UP: {
          e.preventDefault();
          if (isSelectMenuOpen && e.altKey) {
            return (_d2 = selectTriggerRef.current) == null ? void 0 : _d2.dispatchEvent(
              new PointerEvent("pointerdown", {
                pointerId: 1,
                bubbles: true,
                cancelable: true
              })
            );
          }
          const firstFocusableOption = dataAttrHelper.selectOptionType.get[optionType](
            optionsMenuRef.current
          );
          return firstFocusableOption == null ? void 0 : firstFocusableOption.focus();
        }
        case BACKSPACE: {
          if (multi && Array.isArray(value) && value.length && !searchValue) {
            const hiddenValuesNode = dataAttrHelper.hiddenMultiValueCount.get.true(
              selectTriggerRef.current
            );
            return hiddenValuesNode && multiValueOverflowIndexRef.current ? onValueChangeActionMulti({
              action: "deselect-hidden",
              event: e,
              data: multiValueOverflowIndexRef.current,
              option: null,
              selectType: "multi"
            }) : onValueChangeActionMulti({
              action: "deselect-option",
              event: new CustomEvent("deselect-option", {
                bubbles: true,
                cancelable: true,
                detail: {
                  originalEvent: e,
                  currentTarget: e.currentTarget
                }
              }),
              data: value[value.length - 1],
              option: selectedOption[value.length - 1],
              selectType: "multi"
            });
          }
          return void 0;
        }
        default: {
          return defaultSelectLibOnKeyDown(e);
        }
      }
    },
    [
      creatable,
      isSelectMenuOpen,
      multi,
      onValueChangeActionMulti,
      searchValue,
      searchable,
      selectCreatableOption,
      selectLibInputHandlers.onKeyDown,
      selectedOption,
      value
    ]
  );
  const onSearchInputFocus = React19.useCallback(
    (event) => {
      if (!searchable) {
        return void 0;
      }
      selectLibInputHandlers.onFocus(event);
      return onEventAction({
        action: "search-input-focus",
        selectType: multi ? "multi" : "select",
        event,
        data: null,
        option: null
      });
    },
    [multi, onEventAction, searchable, selectLibInputHandlers]
  );
  const searchInputResizeSideEffect = React19.useCallback(() => {
    const canvasCtx = canvasRef.current.getContext("2d");
    if (searchInputRef.current && selectTriggerRef.current && canvasCtx) {
      canvasCtx.font = getComputedStyle(searchInputRef.current).font;
      const textWidth = canvasCtx.measureText(searchValue).width;
      const PREVENT_OVERFLOW_Y_TEXT_WIDTH_PADDING_PX = 20;
      searchInputRef.current.style.setProperty(
        "---searchTextWidth",
        textWidth ? `${Math.ceil(
          textWidth + PREVENT_OVERFLOW_Y_TEXT_WIDTH_PADDING_PX
        )}px` : "100%"
      );
      const multiValueContainerWidth = computeValueContainerWidth(
        getComputedStyle(selectTriggerRef.current)
      );
      if (textWidth >= multiValueContainerWidth) {
        searchInputRef.current.style.height = "auto";
        const dynamicHeight = `${searchInputRef.current.scrollHeight}px`;
        searchInputRef.current.style.setProperty(
          "---searchEditableHeight",
          dynamicHeight
        );
        searchInputRef.current.style.removeProperty("height");
        selectTriggerRef.current.scrollTop = searchInputRef.current.scrollHeight;
      } else {
        searchInputRef.current.style.setProperty(
          "---searchEditableHeight",
          "min-content"
        );
      }
    }
  }, [searchValue]);
  const getSearchInputProps = React19.useMemo(
    () => () => {
      if (multi && searchable) {
        searchInputResizeSideEffect();
      }
      return __spreadProps(__spreadValues({}, selectLibInputHandlers), {
        value: searchValue,
        ref: onSearchInputRef,
        onChange: onSearchInputChange,
        onKeyDown: onSearchInputKeyDown,
        onFocus: onSearchInputFocus,
        // noop to prevent `use-select` lib from clearing search value on input
        // click
        onClick: noop
      });
    },
    [
      multi,
      onSearchInputChange,
      onSearchInputFocus,
      onSearchInputKeyDown,
      onSearchInputRef,
      searchInputResizeSideEffect,
      searchValue,
      searchable,
      selectLibInputHandlers
    ]
  );
  const onFocusOptionUp = React19.useCallback(
    (event) => {
      var _a2, _b2;
      event.preventDefault();
      const optionType = multi ? dataAttrHelper.selectOptionType.value.multi : dataAttrHelper.selectOptionType.value.select;
      const [focusIndex, menuOptions] = getFocusable(
        event.currentTarget,
        `${dataAttrHelper.selectOptionType.selector[optionType]}:not(${dataAttrHelper.disabledOptionItem.selector.true})`
      );
      if (focusIndex === 0) {
        return (_a2 = menuOptions[menuOptions.length - 1]) == null ? void 0 : _a2.focus();
      }
      if (focusIndex > -1) {
        return (_b2 = menuOptions[focusIndex - 1]) == null ? void 0 : _b2.focus();
      }
      return void 0;
    },
    [multi]
  );
  const onFocusOptionDown = React19.useCallback(
    (event) => {
      var _a2, _b2;
      event.preventDefault();
      const optionType = multi ? dataAttrHelper.selectOptionType.value.multi : dataAttrHelper.selectOptionType.value.select;
      const [focusIndex, menuOptions] = getFocusable(
        event.currentTarget,
        `${dataAttrHelper.selectOptionType.selector[optionType]}:not(${dataAttrHelper.disabledOptionItem.selector.true})`
      );
      if (focusIndex === menuOptions.length - 1) {
        return (_a2 = menuOptions[0]) == null ? void 0 : _a2.focus();
      }
      if (focusIndex > -1) {
        return (_b2 = menuOptions[focusIndex + 1]) == null ? void 0 : _b2.focus();
      }
      return void 0;
    },
    [multi]
  );
  const onSelectMenuKeyDown = React19.useCallback(
    (e) => {
      switch (e.key) {
        case UP: {
          return onFocusOptionUp(e);
        }
        case DOWN: {
          return onFocusOptionDown(e);
        }
        case TAB2: {
          if (e.shiftKey) {
            return onFocusOptionUp(e);
          }
          return onFocusOptionDown(e);
        }
        default:
          return false;
      }
    },
    [onFocusOptionUp, onFocusOptionDown]
  );
  const onSelectContentEscapeKeyDown = React19.useCallback(
    (event) => {
      onEventAction({
        action: "select-content-escape-keydown",
        selectType: multi ? "multi" : "select",
        event,
        data: null,
        option: null
      });
    },
    [multi, onEventAction]
  );
  const selectProps = React19.useMemo(
    () => ({
      onValueChange: multi ? onValueChangeActionMulti : onValueChangeActionSelect,
      onEventAction,
      selectedOption,
      visibleOptions,
      searchFilteredOptions,
      getOptionProps,
      getEnabledOptions,
      getDisabledOptions,
      getOption,
      isSelectMenuOpen,
      onOpenChange,
      isUnselectable,
      isShowNoOptionsMessage,
      isAllSelected: multi ? isAllSelected : void 0,
      isSelectAllEnabled: multi ? isSelectAllEnabled : false,
      selectAllValueLabel: multi ? selectAllValueLabel : void 0,
      selectAllCheckedType: multi ? selectAllCheckedType : void 0,
      prevSelectAllCheckedType: multi ? prevSelectAllCheckedType : void 0,
      selectAllOptionItem: multi ? SELECT_ALL_OPTION : void 0,
      onSelectAll: multi ? onSelectAll : () => assertElseThrow2(
        false,
        "`onSelectAll` can only be used for multi-selects. Pass `multi` as true for `useSelect` args.",
        {
          prefix: "Nectar useSelect"
        }
      ),
      onDeselectAll: multi ? onDeselectAll : () => assertElseThrow2(
        false,
        "`onDeselectAll` can only be used for multi-selects. Pass `multi` as true for `useSelect` args.",
        {
          prefix: "Nectar useSelect"
        }
      ),
      renderMultiSelectValues: multi ? renderMultiSelectValues : () => assertElseThrow2(
        false,
        "`renderMultiSelectValues` can only be used for multi-selects. Pass `multi` as true for `useSelect` args.",
        {
          prefix: "Nectar useSelect"
        }
      )
    }),
    [
      multi,
      onValueChangeActionMulti,
      onValueChangeActionSelect,
      onEventAction,
      selectedOption,
      visibleOptions,
      searchFilteredOptions,
      getOptionProps,
      getEnabledOptions,
      getDisabledOptions,
      getOption,
      isSelectMenuOpen,
      onOpenChange,
      isUnselectable,
      isShowNoOptionsMessage,
      isAllSelected,
      isSelectAllEnabled,
      selectAllValueLabel,
      selectAllCheckedType,
      prevSelectAllCheckedType,
      onSelectAll,
      onDeselectAll,
      renderMultiSelectValues
    ]
  );
  const searchProps = React19.useMemo(
    () => ({
      searchInputId,
      getSearchInputProps,
      setSearchValue: setSearch
    }),
    [searchInputId, setSearch, getSearchInputProps]
  );
  const refs = React19.useMemo(
    () => ({
      selectTriggerRef,
      optionsMenuRef,
      searchInputRef,
      multiValueOverflowIndexRef: multi ? multiValueOverflowIndexRef : void 0,
      isSearchingRef,
      composedTriggerRefs
    }),
    [composedTriggerRefs, multi]
  );
  const handlers = React19.useMemo(
    () => ({
      setIsSelectMenuOpen,
      onSelectTriggerPointerDown,
      onSelectContentPointerDownOutside,
      onSearchInputFocus,
      onFocusOptionUp,
      onFocusOptionDown,
      onSelectMenuKeyDown,
      onSelectContentEscapeKeyDown
    }),
    [
      onSelectTriggerPointerDown,
      onSelectContentPointerDownOutside,
      onSearchInputFocus,
      onFocusOptionUp,
      onFocusOptionDown,
      onSelectMenuKeyDown,
      onSelectContentEscapeKeyDown
    ]
  );
  return {
    scope,
    selectProps,
    searchProps,
    refs,
    handlers
  };
}

// src/Select/hooks/useSelectContext.ts
import { assertElseThrow as assertElseThrow3 } from "@kandji-inc/nectar-utils";
import * as React20 from "react";
var SelectContext = React20.createContext({
  scope: {},
  selectRootProps: {},
  selectProps: {},
  searchProps: {},
  refs: {},
  handlers: {}
});
var useSelectContext = () => {
  const context = React20.useContext(SelectContext);
  assertElseThrow3(
    context,
    "`useSelectContext` must be used within a `SelectProvider`.",
    {
      prefix: "Nectar useSelectContext"
    }
  );
  return context;
};

// src/Select/Select.tsx
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useId as useId4 } from "@radix-ui/react-id";
import * as React22 from "react";

// src/Hint/Hint.tsx
import * as React21 from "react";

// src/Hint/BaseHint.tsx
var HintWrapper = styled("div", {
  display: "flex",
  alignItems: "start",
  justifyContent: "end",
  gap: "4px",
  variants: {
    variant: {
      default: {
        svg: {
          color: "$neutral60"
        }
      },
      error: {
        svg: {
          color: "$red50"
        }
      },
      success: {
        svg: {
          color: "$green50"
        }
      },
      warning: {
        span: {
          color: "$yellow60"
        },
        svg: {
          color: "$yellow60"
        }
      }
    }
  }
});
var CharacterCount = styled(Text, {});
var BaseHint = styled(Text, {
  width: "100%",
  wordWrap: "break-word"
});

// src/Hint/hintUtils.ts
function getIconName(variant) {
  switch (variant) {
    case "error":
      return "octagon-exclamation";
    case "success":
      return "circle-check";
    case "warning":
      return "diamond-exclamation";
    default:
      return "circle-info";
  }
}
function isCharCountReached(charCounter) {
  const { count: count2 = 0, max } = charCounter;
  return count2 >= max;
}

// src/Hint/Hint.tsx
import { Fragment as Fragment5, jsx as jsx24, jsxs as jsxs12 } from "react/jsx-runtime";
var Hint = React21.forwardRef(
  (props, forwardedRef) => {
    const _a = props, { label, variant = "default", icon, charCounter } = _a, rest = __objRest(_a, ["label", "variant", "icon", "charCounter"]);
    const textVariant = {
      default: { "@initial": "description" },
      error: { "@initial": "danger" },
      success: { "@initial": "success" },
      warning: { "@initial": "warning" }
    };
    const wrapperVariant = {
      default: { "@initial": "default" },
      error: { "@initial": "error" },
      success: { "@initial": "success" },
      warning: { "@initial": "warning" }
    };
    return /* @__PURE__ */ jsxs12(HintWrapper, __spreadProps(__spreadValues({ variant: wrapperVariant[variant] }, rest), { children: [
      label && /* @__PURE__ */ jsxs12(Fragment5, { children: [
        icon !== "omit" && /* @__PURE__ */ jsx24(Icon_default, { size: "sm", name: icon || getIconName(variant) }),
        /* @__PURE__ */ jsx24(
          BaseHint,
          {
            size: "1",
            variant: textVariant[variant],
            ref: forwardedRef,
            children: label
          }
        )
      ] }),
      charCounter && /* @__PURE__ */ jsxs12(
        CharacterCount,
        {
          size: "1",
          variant: isCharCountReached(charCounter) ? textVariant.error : textVariant.default,
          children: [
            charCounter.count,
            "/",
            charCounter.max
          ]
        }
      )
    ] }));
  }
);

// src/Select/Select.tsx
import { jsx as jsx25, jsxs as jsxs13 } from "react/jsx-runtime";
var SELECT_NAME = "Select";
var defaultComponents = {
  Label: StyledSelectLabel,
  Trigger: StyledSelectTrigger,
  SearchInput: StyledSelectSearchInput,
  OpenIcon: StyledSelectOpenIcon,
  Menu: StyledSelectMenu,
  OptionItem: SelectOptionItem
};
var defaultComponentCss = {
  root: void 0,
  selectContainer: void 0,
  label: void 0,
  triggerContainer: void 0,
  trigger: void 0,
  valueContainer: void 0,
  searchInput: void 0,
  openIcon: void 0,
  hint: void 0,
  menu: void 0,
  option: void 0,
  noOptions: void 0,
  section: void 0,
  sectionLabel: void 0
};
var defaultOpenIconProps = {
  name: DEFAULT_OPEN_ICON_NAME,
  size: DEFAULT_OPEN_ICON_SIZE,
  color: void 0,
  className: void 0,
  onClick: void 0,
  onMouseOver: void 0,
  onMouseLeave: void 0
};
var Select = React22.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      value = "",
      valueText = "",
      onChange = noop,
      onValueChange = void 0,
      onEventAction = void 0,
      options,
      optionsFilter = defaultSelectOptionsFilter,
      open = void 0,
      defaultOpen = false,
      closeMenuOnSelect = true,
      closeMenuOnEscape = true,
      closeMenuOnOutsideClick = true,
      searchable = false,
      searchPattern = void 0,
      searchFn = void 0,
      onCancelSearch = void 0,
      creatable = void 0,
      disabled = false,
      readOnly = false,
      label = "",
      optional = false,
      hint = void 0,
      size = "md",
      error = "",
      placeholder = getDefaultPlaceholderText(),
      noOptionsFoundMessage = DEFAULT_NO_OPTIONS_FOUND_TEXT,
      valueKey = DEFAULT_VALUE_KEY,
      labelKey = DEFAULT_LABEL_KEY,
      components = defaultComponents,
      componentCss = defaultComponentCss,
      openIconProps = defaultOpenIconProps,
      tooltipProps = {},
      children,
      testId,
      trackId,
      restContext
    } = _b, restProps = __objRest(_b, [
      "value",
      "valueText",
      "onChange",
      "onValueChange",
      "onEventAction",
      "options",
      "optionsFilter",
      "open",
      "defaultOpen",
      "closeMenuOnSelect",
      "closeMenuOnEscape",
      "closeMenuOnOutsideClick",
      "searchable",
      "searchPattern",
      "searchFn",
      "onCancelSearch",
      "creatable",
      "disabled",
      "readOnly",
      "label",
      "optional",
      "hint",
      "size",
      "error",
      "placeholder",
      "noOptionsFoundMessage",
      "valueKey",
      "labelKey",
      "components",
      "componentCss",
      "openIconProps",
      "tooltipProps",
      "children",
      "testId",
      "trackId",
      "restContext"
    ]);
    var _a2, _b2;
    const { scope, selectProps, searchProps, refs, handlers } = useSelect(
      {
        multi: false,
        value,
        onChange,
        onValueChange,
        onEventAction,
        options,
        optionsFilter,
        open,
        defaultOpen,
        disabled,
        readOnly,
        closeMenuOnSelect,
        closeMenuOnEscape,
        closeMenuOnOutsideClick,
        searchable,
        searchPattern,
        searchFn,
        onCancelSearch,
        creatable,
        selectAll: void 0,
        valueKey,
        labelKey
      },
      forwardedRef
    );
    const {
      isSelectMenuOpen,
      getOptionProps,
      isUnselectable,
      searchFilteredOptions,
      isShowNoOptionsMessage
    } = selectProps;
    const { searchInputId, getSearchInputProps } = searchProps;
    const selectSearchInputProps = getSearchInputProps();
    const { optionsMenuRef, composedTriggerRefs } = refs;
    const {
      onSelectTriggerPointerDown,
      onSelectMenuKeyDown,
      onSelectContentPointerDownOutside,
      onSelectContentEscapeKeyDown
    } = handlers;
    const selectComponents = React22.useMemo(
      () => ({
        Label: components.Label || getNullRenderComponent(components.Label) || defaultComponents.Label,
        Trigger: components.Trigger || getNullRenderComponent(components.Trigger) || defaultComponents.Trigger,
        SearchInput: components.SearchInput || getNullRenderComponent(components.SearchInput) || defaultComponents.SearchInput,
        OpenIcon: components.OpenIcon || getNullRenderComponent(components.OpenIcon) || defaultComponents.OpenIcon,
        Menu: components.Menu || getNullRenderComponent(components.Menu) || defaultComponents.Menu,
        OptionItem: components.OptionItem || getNullRenderComponent(components.OptionItem) || defaultComponents.OptionItem
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps -- custom components obj must be stable
      []
    );
    const selectCss = React22.useMemo(
      () => ({
        rootCss: componentCss.root || defaultComponentCss.root,
        selectContainerCss: componentCss.selectContainer || defaultComponentCss.selectContainer,
        labelCss: componentCss.label || defaultComponentCss.label,
        triggerContainerCss: componentCss.triggerContainer || defaultComponentCss.triggerContainer,
        triggerCss: componentCss.trigger || defaultComponentCss.trigger,
        valueContainerCss: defaultComponentCss.valueContainer,
        searchInputCss: componentCss.searchInput || defaultComponentCss.searchInput,
        openIconCss: componentCss.openIcon || defaultComponentCss.openIcon,
        hintCss: componentCss.hint || defaultComponentCss.hint,
        menuCss: componentCss.menu || defaultComponentCss.menu,
        optionCss: componentCss.option || defaultComponentCss.option,
        noOptionsCss: componentCss.noOptions || defaultComponentCss.noOptions,
        sectionCss: componentCss.section || defaultComponentCss.section,
        sectionLabelCss: componentCss.sectionLabel || defaultComponentCss.sectionLabel
      }),
      [
        componentCss.root,
        componentCss.selectContainer,
        componentCss.label,
        componentCss.triggerContainer,
        componentCss.trigger,
        componentCss.searchInput,
        componentCss.openIcon,
        componentCss.hint,
        componentCss.menu,
        componentCss.option,
        componentCss.noOptions,
        componentCss.section,
        componentCss.sectionLabel
      ]
    );
    const selectOpenIconProps = React22.useMemo(
      () => ({
        openIconName: openIconProps.name || defaultOpenIconProps.name,
        openIconSize: openIconProps.size || defaultOpenIconProps.size,
        openIconColor: openIconProps.color || defaultOpenIconProps.color,
        openIconClassName: openIconProps.className || defaultOpenIconProps.className,
        openIconOnClick: openIconProps.onClick || defaultOpenIconProps.onClick,
        openIconOnMouseOver: openIconProps.onMouseOver || defaultOpenIconProps.onMouseOver,
        openIconOnMouseLeave: openIconProps.onMouseLeave || defaultOpenIconProps.onMouseLeave
      }),
      [
        openIconProps.name,
        openIconProps.size,
        openIconProps.color,
        openIconProps.className,
        openIconProps.onClick,
        openIconProps.onMouseOver,
        openIconProps.onMouseLeave
      ]
    );
    const { Label: Label5, Trigger: Trigger10, SearchInput, OpenIcon, Menu, OptionItem } = selectComponents;
    const {
      rootCss,
      selectContainerCss,
      labelCss,
      triggerContainerCss,
      triggerCss,
      searchInputCss,
      openIconCss,
      hintCss,
      menuCss,
      optionCss,
      noOptionsCss,
      sectionCss,
      sectionLabelCss
    } = selectCss;
    const {
      openIconName,
      openIconSize,
      openIconColor,
      openIconClassName,
      openIconOnClick,
      openIconOnMouseOver,
      openIconOnMouseLeave
    } = selectOpenIconProps;
    const wrapperSize = size === "full" ? "full" : void 0;
    const triggerState = !!error && typeof error === "string" ? "error" : "default";
    const hintLabel = triggerState === "error" ? error : hint == null ? void 0 : hint.label;
    const hasHintCounter = typeof ((_a2 = hint == null ? void 0 : hint.charCounter) == null ? void 0 : _a2.count) === "number" && typeof ((_b2 = hint == null ? void 0 : hint.charCounter) == null ? void 0 : _b2.max) === "number";
    const hasHint = !!hintLabel || hasHintCounter;
    const defaultTestId = useId4();
    const baseTestId = testId || defaultTestId;
    const renderChildren = () => {
      const isRenderPropsChildren = typeof children === "function";
      if (isRenderPropsChildren) {
        return children({
          selectProps,
          searchProps,
          refs,
          handlers
        });
      }
      return children;
    };
    return /* @__PURE__ */ jsxs13(
      SelectRoot,
      {
        scope,
        value,
        selectProps,
        searchProps,
        refs,
        handlers,
        restContext,
        open,
        defaultOpen,
        size: wrapperSize,
        css: rootCss,
        testId: generateTestId(baseTestId, SELECT_NAME, "root"),
        trackId,
        children: [
          /* @__PURE__ */ jsxs13(
            Flex,
            {
              flow: "column",
              alignItems: "start",
              gap: "xs",
              css: selectContainerCss,
              "data-testid": generateTestId(
                baseTestId,
                SELECT_NAME,
                "select-container"
              ),
              children: [
                !!label && /* @__PURE__ */ jsx25(
                  Label5,
                  {
                    htmlFor: searchInputId,
                    disabled,
                    optional,
                    above: true,
                    css: labelCss,
                    "data-testid": generateTestId(baseTestId, SELECT_NAME, "label"),
                    children: label
                  }
                ),
                /* @__PURE__ */ jsx25(
                  Flex,
                  {
                    flow: "column",
                    gap: "xs",
                    wFull: true,
                    css: triggerContainerCss,
                    "data-testid": generateTestId(
                      baseTestId,
                      SELECT_NAME,
                      "trigger-container"
                    ),
                    children: /* @__PURE__ */ jsx25(
                      SelectPrimitive4.Trigger,
                      {
                        asChild: true,
                        __scopeSelect: scope.selectScope.__scopeSelect,
                        onKeyDownCapture: (e) => {
                          if (e.key === SELECT_KEYBOARD_EVENT_KEY.SPACE) {
                            e.stopPropagation();
                          }
                        },
                        children: renderChildren() || /* @__PURE__ */ jsxs13(
                          Trigger10,
                          __spreadProps(__spreadValues({
                            ref: composedTriggerRefs,
                            as: "button",
                            size,
                            disabled: isUnselectable || disabled,
                            state: triggerState,
                            onPointerDown: onSelectTriggerPointerDown,
                            css: triggerCss,
                            "aria-readonly": readOnly,
                            "aria-haspopup": "listbox",
                            "data-readonly": readOnly ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
                            "data-select-type": dataAttrHelper.selectType.value.select,
                            "data-testid": generateTestId(
                              baseTestId,
                              SELECT_NAME,
                              "trigger"
                            )
                          }, restProps), {
                            children: [
                              /* @__PURE__ */ jsx25(
                                SearchInput,
                                __spreadProps(__spreadValues({}, selectSearchInputProps), {
                                  value: valueText || selectSearchInputProps.value,
                                  id: searchInputId,
                                  placeholder,
                                  disabled,
                                  readOnly: readOnly || !searchable,
                                  maxLength: DEFAULT_SEARCH_INPUT_MAX_LENGTH,
                                  css: searchInputCss,
                                  "data-testid": generateTestId(
                                    baseTestId,
                                    SELECT_NAME,
                                    "search-input"
                                  )
                                })
                              ),
                              /* @__PURE__ */ jsx25(
                                OpenIcon,
                                {
                                  css: openIconCss,
                                  "data-testid": generateTestId(
                                    baseTestId,
                                    SELECT_NAME,
                                    "open-icon"
                                  ),
                                  children: /* @__PURE__ */ jsx25(
                                    Icon_default,
                                    {
                                      name: openIconName,
                                      size: openIconSize,
                                      color: openIconColor,
                                      className: openIconClassName,
                                      onClick: openIconOnClick,
                                      onMouseOver: openIconOnMouseOver,
                                      onMouseLeave: openIconOnMouseLeave
                                    }
                                  )
                                }
                              )
                            ]
                          })
                        )
                      }
                    )
                  }
                ),
                hasHint && /* @__PURE__ */ jsx25(
                  Hint,
                  {
                    className: selectHintCss,
                    label: hintLabel,
                    charCounter: hint == null ? void 0 : hint.charCounter,
                    variant: triggerState,
                    css: hintCss,
                    "data-testid": generateTestId(baseTestId, SELECT_NAME, "hint")
                  }
                )
              ]
            }
          ),
          /* @__PURE__ */ jsx25(DialogPrimitive.Root, { modal: false, open: open != null ? open : isSelectMenuOpen, children: /* @__PURE__ */ jsx25(
            DialogPrimitive.Content,
            {
              asChild: true,
              onPointerDownOutside: onSelectContentPointerDownOutside,
              onEscapeKeyDown: onSelectContentEscapeKeyDown,
              onOpenAutoFocus: (e) => e.preventDefault(),
              role: "listbox",
              children: /* @__PURE__ */ jsx25(
                Menu,
                {
                  ref: optionsMenuRef,
                  size,
                  onKeyDown: onSelectMenuKeyDown,
                  css: menuCss,
                  "data-testid": generateTestId(baseTestId, SELECT_NAME, "menu"),
                  children: /* @__PURE__ */ jsxs13(TooltipProvider, { children: [
                    isShowNoOptionsMessage && /* @__PURE__ */ jsx25(
                      StyledSelectNoOptions,
                      {
                        css: noOptionsCss,
                        "data-testid": generateTestId(
                          baseTestId,
                          SELECT_NAME,
                          "no-options"
                        ),
                        children: noOptionsFoundMessage
                      }
                    ),
                    searchFilteredOptions.map(
                      (option, index) => {
                        const { key, option: sectionOrOpt } = getOptionProps({
                          option,
                          index
                        });
                        const isSection = sectionOrOpt.section && typeof sectionOrOpt.section === "string" && Array.isArray(sectionOrOpt.options);
                        if (isSection) {
                          const isLastSection = index === searchFilteredOptions.length - 1;
                          const hasSectionLabel = sectionOrOpt.showSectionLabel === true;
                          const hasNoOptions = sectionOrOpt.options.length === 0;
                          return /* @__PURE__ */ jsxs13(
                            StyledSelectMenuSection,
                            {
                              noBottomDivider: hasSectionLabel || isLastSection || hasNoOptions || isShowNoOptionsMessage,
                              css: sectionCss,
                              "aria-label": !hasSectionLabel ? `${sectionOrOpt.section} section` : NO_DATA_ATTR_KEY,
                              "data-section": sectionOrOpt.section,
                              "data-testid": generateTestId(
                                baseTestId,
                                SELECT_NAME,
                                `menu-section--${sectionOrOpt.section}`
                              ),
                              children: [
                                hasSectionLabel && !isShowNoOptionsMessage && /* @__PURE__ */ jsx25(
                                  StyledSelectMenuSectionLabel,
                                  {
                                    css: sectionLabelCss,
                                    "data-section-label": sectionOrOpt.section,
                                    "data-testid": generateTestId(
                                      baseTestId,
                                      SELECT_NAME,
                                      `menu-section-label--${sectionOrOpt.section}}`
                                    ),
                                    children: sectionOrOpt.section
                                  }
                                ),
                                sectionOrOpt.options.map(
                                  (sectionOption, sectionIndex) => {
                                    const { key: sectionKey, option: sectionOpt } = getOptionProps({
                                      option: sectionOption,
                                      index: sectionIndex
                                    });
                                    return /* @__PURE__ */ jsx25(
                                      OptionItem,
                                      {
                                        value: sectionOpt.value,
                                        disabled: sectionOpt.disabled,
                                        icon: sectionOpt.icon,
                                        iconSize: sectionOpt.iconSize,
                                        richLabel: sectionOpt.richLabel,
                                        tooltip: mergeTooltipProps(
                                          sectionOpt.tooltip,
                                          tooltipProps
                                        ),
                                        meta: sectionOpt.meta,
                                        css: optionCss,
                                        testId: generateTestId(
                                          baseTestId,
                                          SELECT_NAME,
                                          `${sectionOrOpt.section}-section-option-item--${sectionOpt.value}`
                                        ),
                                        children: sectionOpt.label
                                      },
                                      sectionKey
                                    );
                                  }
                                )
                              ]
                            },
                            key
                          );
                        }
                        return /* @__PURE__ */ jsx25(
                          OptionItem,
                          {
                            value: sectionOrOpt.value,
                            disabled: sectionOrOpt.disabled,
                            icon: sectionOrOpt.icon,
                            iconSize: sectionOrOpt.iconSize,
                            richLabel: sectionOrOpt.richLabel,
                            tooltip: mergeTooltipProps(
                              sectionOrOpt.tooltip,
                              tooltipProps
                            ),
                            meta: sectionOrOpt.meta,
                            css: optionCss,
                            testId: generateTestId(
                              baseTestId,
                              SELECT_NAME,
                              `option-item--${sectionOrOpt.value}`
                            ),
                            children: sectionOrOpt.label
                          },
                          key
                        );
                      }
                    )
                  ] })
                }
              )
            }
          ) })
        ]
      }
    );
  }
);
Select.displayName = SELECT_NAME;

// src/Select/MultiSelect.tsx
import * as DialogPrimitive2 from "@radix-ui/react-dialog";
import { useId as useId5 } from "@radix-ui/react-id";
import * as React24 from "react";
import { t as t4 } from "@kandji-inc/nectar-i18n";

// src/DropdownMenu/DropdownMenu.tsx
import { t as t3 } from "@kandji-inc/nectar-i18n";
import * as DropdownMenuPrimitive2 from "@radix-ui/react-dropdown-menu";
import React23, { useState as useState6 } from "react";
import { Fragment as Fragment6, jsx as jsx26, jsxs as jsxs14 } from "react/jsx-runtime";
var typeaheadSearchDisabler = {
  toLowerCase: () => ({
    startsWith: () => false
  })
};
var DropdownMenu = (props) => {
  const _a = props, {
    children,
    withArrow = true,
    defaultOpen,
    handleOpenChange,
    options,
    footer,
    contentProps = {
      align: "center",
      preventOutsideInteraction: false,
      container: void 0,
      side: void 0
    },
    portalProps = {},
    itemProps = {
      preventMenuCloseOnSelect: false,
      preventWithinItemFocus: false,
      preventLeaveItemBlur: false,
      disableTypeaheadSearch: false
    },
    testId,
    css: css3
  } = _a, rest = __objRest(_a, [
    "children",
    "withArrow",
    "defaultOpen",
    "handleOpenChange",
    "options",
    "footer",
    "contentProps",
    "portalProps",
    "itemProps",
    "testId",
    "css"
  ]);
  const [cssUtils2] = extractCssUtilsFromProps(rest);
  const [isOpen, setIsOpen] = useState6(defaultOpen || false);
  const renderOption = (option) => {
    const _a2 = option, {
      render: RenderComp,
      label,
      icon,
      iconPosition = "left",
      onClick,
      theme: theme2,
      testId: optionTestId,
      subMenuProps
    } = _a2, renderProps = __objRest(_a2, [
      "render",
      "label",
      "icon",
      "iconPosition",
      "onClick",
      "theme",
      "testId",
      "subMenuProps"
    ]);
    if (!RenderComp) {
      return /* @__PURE__ */ jsxs14(Fragment6, { children: [
        icon && iconPosition === "left" && /* @__PURE__ */ jsx26(DropdownMenuItemIcon, { name: icon }),
        /* @__PURE__ */ jsx26(Text, { size: "2", css: { lineHeight: "$2", width: "100%" }, children: label }),
        subMenuProps && /* @__PURE__ */ jsxs14(Flex, { alignItems: "center", gap: "sm", children: [
          typeof (subMenuProps == null ? void 0 : subMenuProps.count) === "number" && /* @__PURE__ */ jsx26(Counter, { variant: "strong", children: subMenuProps == null ? void 0 : subMenuProps.count }),
          (subMenuProps == null ? void 0 : subMenuProps.subMenu) && /* @__PURE__ */ jsx26(Icon_default, { name: "fa-angle-right-small", size: "sm" })
        ] }),
        icon && iconPosition === "right" && !(subMenuProps == null ? void 0 : subMenuProps.subMenu) && /* @__PURE__ */ jsx26(DropdownMenuItemIcon, { name: icon })
      ] });
    }
    const optionProps = { label, icon, onClick, theme: theme2, testId: optionTestId };
    return /* @__PURE__ */ jsx26(RenderComp, __spreadValues(__spreadValues({}, optionProps), renderProps));
  };
  const renderFooter = () => {
    if (!(footer == null ? void 0 : footer.type) || typeof (footer == null ? void 0 : footer.type) !== "string") {
      return null;
    }
    switch (footer.type) {
      case "filter":
        return /* @__PURE__ */ jsx26(
          DropdownMenuFilterFooter,
          {
            setIsOpen,
            handleApply: footer.handleApply,
            handleClear: footer.handleClear,
            handleCancel: footer.handleCancel,
            showApply: footer.showApply,
            showClear: footer.showClear,
            showCancel: footer.showCancel,
            applyDisabled: footer.applyDisabled,
            clearDisabled: footer.clearDisabled,
            cancelDisabled: footer.cancelDisabled,
            closeOnApply: footer.closeOnApply,
            closeOnClear: footer.closeOnClear,
            closeOnCancel: footer.closeOnCancel,
            applyLabel: footer.applyLabel,
            clearLabel: footer.clearLabel,
            cancelLabel: footer.cancelLabel,
            testId: footer.testId
          }
        );
      default:
        return null;
    }
  };
  return /* @__PURE__ */ jsxs14(
    DropdownMenuPrimitive2.Root,
    {
      open: isOpen,
      onOpenChange: (o) => {
        const continueToSetOpen = handleOpenChange == null ? void 0 : handleOpenChange(o, {
          setIsOpen
        });
        if (continueToSetOpen !== false) {
          setIsOpen(o);
        }
      },
      modal: false,
      children: [
        /* @__PURE__ */ jsx26(DropdownMenuPrimitive2.Trigger, { asChild: true, children }),
        /* @__PURE__ */ jsx26(DropdownMenuPrimitive2.Portal, __spreadProps(__spreadValues({}, portalProps), { children: /* @__PURE__ */ jsxs14(
          DropdownMenuContent,
          __spreadProps(__spreadValues({
            "data-testid": testId,
            css: css3,
            sideOffset: 4,
            align: contentProps == null ? void 0 : contentProps.align,
            side: contentProps == null ? void 0 : contentProps.side,
            onInteractOutside: (contentProps == null ? void 0 : contentProps.preventOutsideInteraction) ? (e) => e.preventDefault() : () => {
            }
          }, cssUtils2), {
            children: [
              options.map((opt, idx) => {
                const {
                  label = "",
                  icon = "",
                  theme: theme2 = "default",
                  onClick,
                  subMenuProps
                } = opt;
                if (subMenuProps == null ? void 0 : subMenuProps.subMenu) {
                  const { subMenu: SubMenu, count: count2 } = subMenuProps;
                  const hasCount = typeof count2 === "number";
                  const countTheme = hasCount ? "action" : "default";
                  return /* @__PURE__ */ jsxs14(DropdownMenuSubMenu, { children: [
                    /* @__PURE__ */ jsx26(DropdownMenuSubMenuTrigger, { theme: countTheme || theme2, children: renderOption(opt) }),
                    /* @__PURE__ */ jsx26(DropdownMenuPrimitive2.Portal, { children: /* @__PURE__ */ jsx26(DropdownMenuSubContent, { children: SubMenu }) })
                  ] });
                }
                return /* @__PURE__ */ jsx26(
                  DropdownMenuItem,
                  {
                    theme: theme2,
                    textValue: itemProps.disableTypeaheadSearch ? typeaheadSearchDisabler : void 0,
                    onSelect: (e) => {
                      if (itemProps == null ? void 0 : itemProps.preventMenuCloseOnSelect) {
                        e.preventDefault();
                      }
                    },
                    onPointerMove: (e) => {
                      if (itemProps == null ? void 0 : itemProps.preventWithinItemFocus) {
                        e.preventDefault();
                      }
                    },
                    onPointerLeave: (e) => {
                      if (itemProps == null ? void 0 : itemProps.preventLeaveItemBlur) {
                        e.preventDefault();
                      }
                    },
                    onClick: (e) => {
                      e.stopPropagation();
                      if (opt == null ? void 0 : opt.disabled) {
                        return;
                      }
                      onClick == null ? void 0 : onClick(e);
                    },
                    "data-testid": opt == null ? void 0 : opt.testId,
                    disabled: opt == null ? void 0 : opt.disabled,
                    selected: opt == null ? void 0 : opt.selected,
                    children: renderOption(opt)
                  },
                  `${label || idx}_${icon}_${theme2}`
                );
              }),
              withArrow && /* @__PURE__ */ jsx26(DropdownMenuArrow, {}),
              renderFooter()
            ]
          })
        ) }))
      ]
    }
  );
};
var DropdownMenuFilterFooter = React23.forwardRef(
  ({
    setIsOpen = () => {
    },
    handleApply,
    handleClear,
    handleCancel,
    showApply = true,
    showClear = true,
    showCancel = true,
    applyDisabled = false,
    clearDisabled = false,
    cancelDisabled = false,
    closeOnApply = true,
    closeOnClear = false,
    closeOnCancel = true,
    applyLabel = t3("Apply"),
    clearLabel = t3("Reset"),
    cancelLabel = t3("Cancel"),
    testId,
    css: css3
  }, forwardedRef) => /* @__PURE__ */ jsxs14(
    DropdownMenuFooterContainer,
    {
      "data-testid": testId,
      ref: forwardedRef,
      css: css3,
      children: [
        showClear && /* @__PURE__ */ jsx26(
          Button,
          {
            compact: true,
            variant: "subtle",
            "data-action": "clear",
            disabled: clearDisabled,
            onClick: (e) => {
              handleClear == null ? void 0 : handleClear(e, {
                setIsOpen
              });
              if (closeOnClear) {
                setIsOpen(false);
              }
            },
            children: clearLabel
          }
        ),
        /* @__PURE__ */ jsxs14(Flex, { gap: "xs", children: [
          showCancel && /* @__PURE__ */ jsx26(
            Button,
            {
              compact: true,
              variant: "default",
              "data-action": "cancel",
              disabled: cancelDisabled,
              onClick: (e) => {
                handleCancel == null ? void 0 : handleCancel(e, {
                  setIsOpen
                });
                if (closeOnCancel) {
                  setIsOpen(false);
                }
              },
              children: cancelLabel
            }
          ),
          showApply && /* @__PURE__ */ jsx26(
            Button,
            {
              compact: true,
              variant: "primary",
              "data-action": "apply",
              disabled: applyDisabled,
              onClick: (e) => {
                const continueToClose = handleApply == null ? void 0 : handleApply(e, {
                  setIsOpen
                });
                if (continueToClose === false) {
                  return;
                }
                if (closeOnApply) {
                  setIsOpen(false);
                }
              },
              children: applyLabel
            }
          )
        ] })
      ]
    }
  )
);

// src/Select/MultiSelect.tsx
import { jsx as jsx27, jsxs as jsxs15 } from "react/jsx-runtime";
var MULTISELECT_NAME = "MultiSelect";
var defaultComponents2 = {
  Label: StyledSelectLabel,
  Trigger: StyledSelectTrigger,
  SearchInput: StyledSelectSearchInput,
  OpenIcon: StyledSelectOpenIcon,
  Menu: StyledSelectMenu,
  OptionItem: MultiSelectOptionItem
};
var defaultComponentCss2 = {
  root: void 0,
  selectContainer: void 0,
  label: void 0,
  triggerContainer: void 0,
  trigger: void 0,
  valueContainer: void 0,
  searchInput: void 0,
  openIcon: void 0,
  hint: void 0,
  menu: void 0,
  option: void 0,
  noOptions: void 0,
  section: void 0,
  sectionLabel: void 0
};
var defaultOpenIconProps2 = {
  name: DEFAULT_OPEN_ICON_NAME,
  size: DEFAULT_OPEN_ICON_SIZE,
  color: void 0,
  className: void 0,
  onClick: void 0,
  onMouseOver: void 0,
  onMouseLeave: void 0
};
var MultiSelect = React24.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      value,
      valueText = "",
      onChange = noop,
      onValueChange = void 0,
      onEventAction = void 0,
      onOpenChange = void 0,
      options,
      optionsFilter = defaultSelectOptionsFilter,
      open = void 0,
      defaultOpen = false,
      closeMenuOnSelect = false,
      closeMenuOnEscape = true,
      closeMenuOnOutsideClick = true,
      selectAll = void 0,
      searchable = false,
      keepSearchOnSelect = false,
      searchFn = void 0,
      searchPattern = void 0,
      creatable = void 0,
      disabled = false,
      readOnly = false,
      label = "",
      optional = false,
      hint = void 0,
      size = "md",
      error = "",
      placeholder = getDefaultPlaceholderText(),
      noOptionsFoundMessage = DEFAULT_NO_OPTIONS_FOUND_TEXT,
      hideNoOptionsFoundMessage = false,
      valueKey = DEFAULT_VALUE_KEY,
      labelKey = DEFAULT_LABEL_KEY,
      components = defaultComponents2,
      componentCss = defaultComponentCss2,
      openIconProps = defaultOpenIconProps2,
      showHiddenCountValue = true,
      customHeader = null,
      children,
      footer,
      testId
    } = _b, restProps = __objRest(_b, [
      "value",
      "valueText",
      "onChange",
      "onValueChange",
      "onEventAction",
      "onOpenChange",
      "options",
      "optionsFilter",
      "open",
      "defaultOpen",
      "closeMenuOnSelect",
      "closeMenuOnEscape",
      "closeMenuOnOutsideClick",
      "selectAll",
      "searchable",
      "keepSearchOnSelect",
      "searchFn",
      "searchPattern",
      "creatable",
      "disabled",
      "readOnly",
      "label",
      "optional",
      "hint",
      "size",
      "error",
      "placeholder",
      "noOptionsFoundMessage",
      "hideNoOptionsFoundMessage",
      "valueKey",
      "labelKey",
      "components",
      "componentCss",
      "openIconProps",
      "showHiddenCountValue",
      "customHeader",
      "children",
      "footer",
      "testId"
    ]);
    var _a2, _b2;
    const { scope, selectProps, searchProps, refs, handlers } = useSelect(
      {
        multi: true,
        keepSearchOnSelect,
        value,
        onChange,
        onValueChange,
        onEventAction,
        onOpenChange,
        options,
        optionsFilter,
        hideNoOptionsFoundMessage,
        open,
        defaultOpen,
        readOnly,
        disabled,
        closeMenuOnSelect,
        closeMenuOnEscape,
        closeMenuOnOutsideClick,
        selectAll,
        searchable,
        searchFn,
        searchPattern,
        creatable,
        valueKey,
        labelKey
      },
      forwardedRef
    );
    const {
      isSelectMenuOpen,
      getOptionProps,
      isUnselectable,
      searchFilteredOptions,
      isShowNoOptionsMessage,
      selectedOption,
      selectAllCheckedType,
      renderMultiSelectValues
    } = selectProps;
    const { searchInputId, getSearchInputProps, setSearchValue } = searchProps;
    const selectSearchInputProps = getSearchInputProps();
    const { optionsMenuRef, composedTriggerRefs } = refs;
    const {
      onSelectTriggerPointerDown,
      onSelectMenuKeyDown,
      onSelectContentPointerDownOutside,
      onSelectContentEscapeKeyDown
    } = handlers;
    const multiSelectComponents = React24.useMemo(
      () => ({
        Label: components.Label || getNullRenderComponent(components.Label) || defaultComponents2.Label,
        Trigger: components.Trigger || getNullRenderComponent(components.Trigger) || defaultComponents2.Trigger,
        SearchInput: components.SearchInput || getNullRenderComponent(components.SearchInput) || defaultComponents2.SearchInput,
        OpenIcon: components.OpenIcon || getNullRenderComponent(components.OpenIcon) || defaultComponents2.OpenIcon,
        Menu: components.Menu || getNullRenderComponent(components.Menu) || defaultComponents2.Menu,
        OptionItem: components.OptionItem || getNullRenderComponent(components.OptionItem) || defaultComponents2.OptionItem
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps -- custom components obj must be stable
      []
    );
    const multiSelectCss = React24.useMemo(
      () => ({
        rootCss: componentCss.root || defaultComponentCss2.root,
        selectContainerCss: componentCss.selectContainer || defaultComponentCss2.selectContainer,
        labelCss: componentCss.label || defaultComponentCss2.label,
        triggerContainerCss: componentCss.triggerContainer || defaultComponentCss2.triggerContainer,
        triggerCss: componentCss.trigger || defaultComponentCss2.trigger,
        valueContainerCss: componentCss.valueContainer || defaultComponentCss2.valueContainer,
        searchInputCss: componentCss.searchInput || defaultComponentCss2.searchInput,
        openIconCss: componentCss.openIcon || defaultComponentCss2.openIcon,
        hintCss: componentCss.hint || defaultComponentCss2.hint,
        menuCss: componentCss.menu || defaultComponentCss2.menu,
        optionCss: componentCss.option || defaultComponentCss2.option,
        noOptionsCss: componentCss.noOptions || defaultComponentCss2.noOptions,
        sectionCss: componentCss.section || defaultComponentCss2.section,
        sectionLabelCss: componentCss.sectionLabel || defaultComponentCss2.sectionLabel
      }),
      [
        componentCss.root,
        componentCss.selectContainer,
        componentCss.label,
        componentCss.triggerContainer,
        componentCss.trigger,
        componentCss.valueContainer,
        componentCss.searchInput,
        componentCss.openIcon,
        componentCss.hint,
        componentCss.menu,
        componentCss.option,
        componentCss.noOptions,
        componentCss.section,
        componentCss.sectionLabel
      ]
    );
    const multiSelectOpenIconProps = React24.useMemo(
      () => ({
        openIconName: openIconProps.name || defaultOpenIconProps2.name,
        openIconSize: openIconProps.size || defaultOpenIconProps2.size,
        openIconColor: openIconProps.color || defaultOpenIconProps2.color,
        openIconClassName: openIconProps.className || defaultOpenIconProps2.className,
        openIconOnClick: openIconProps.onClick || defaultOpenIconProps2.onClick,
        openIconOnMouseOver: openIconProps.onMouseOver || defaultOpenIconProps2.onMouseOver,
        openIconOnMouseLeave: openIconProps.onMouseLeave || defaultOpenIconProps2.onMouseLeave
      }),
      [
        openIconProps.name,
        openIconProps.size,
        openIconProps.color,
        openIconProps.className,
        openIconProps.onClick,
        openIconProps.onMouseOver,
        openIconProps.onMouseLeave
      ]
    );
    const { Label: Label5, Trigger: Trigger10, SearchInput, OpenIcon, Menu, OptionItem } = multiSelectComponents;
    const {
      rootCss,
      selectContainerCss,
      labelCss,
      triggerContainerCss,
      triggerCss,
      valueContainerCss,
      searchInputCss,
      openIconCss,
      hintCss,
      menuCss,
      optionCss,
      noOptionsCss,
      sectionCss,
      sectionLabelCss
    } = multiSelectCss;
    const {
      openIconName,
      openIconSize,
      openIconColor,
      openIconClassName,
      openIconOnClick,
      openIconOnMouseOver,
      openIconOnMouseLeave
    } = multiSelectOpenIconProps;
    const wrapperSize = size === "full" ? "full" : void 0;
    const triggerState = !!error && typeof error === "string" ? "error" : "default";
    const hintLabel = triggerState === "error" ? error : hint == null ? void 0 : hint.label;
    const hasHintCounter = typeof ((_a2 = hint == null ? void 0 : hint.charCounter) == null ? void 0 : _a2.count) === "number" && typeof ((_b2 = hint == null ? void 0 : hint.charCounter) == null ? void 0 : _b2.max) === "number";
    const hasHint = !!hintLabel || hasHintCounter;
    const defaultTestId = useId5();
    const baseTestId = testId || defaultTestId;
    const renderChildren = () => {
      const isRenderPropsChildren = typeof children === "function";
      if (isRenderPropsChildren) {
        return children({
          selectProps,
          searchProps,
          refs,
          handlers
        });
      }
      return children;
    };
    const renderFooter = () => {
      if (footer) {
        return /* @__PURE__ */ jsx27(
          DropdownMenuFilterFooter,
          {
            showApply: false,
            showCancel: false,
            handleClear: footer.handleClear,
            showClear: footer.showClear,
            clearDisabled: footer.clearDisabled,
            clearLabel: footer.clearLabel || t4("Clear"),
            testId: footer.testId
          }
        );
      }
      return null;
    };
    const [isScrolled, setIsScrolled] = React24.useState({
      top: true,
      bottom: false
    });
    const [bodyEl, setBodyEl] = React24.useState();
    React24.useEffect(() => {
      if (bodyEl) {
        const handleScroll = () => {
          if (!bodyEl)
            return;
          setIsScrolled({
            top: bodyEl.scrollTop === 0,
            bottom: bodyEl.scrollTop + bodyEl.clientHeight === bodyEl.scrollHeight
          });
        };
        handleScroll();
        bodyEl.addEventListener("scroll", handleScroll);
        return () => bodyEl.removeEventListener("scroll", handleScroll);
      }
    }, [bodyEl, searchFilteredOptions]);
    React24.useEffect(() => {
      if (!isSelectMenuOpen) {
        setIsScrolled({ top: true, bottom: false });
        setBodyEl(void 0);
      }
    }, [isSelectMenuOpen]);
    return /* @__PURE__ */ jsxs15(
      SelectRoot,
      {
        scope,
        value,
        selectProps,
        searchProps,
        refs,
        handlers,
        open,
        defaultOpen,
        size: wrapperSize,
        css: rootCss,
        testId: generateTestId(baseTestId, MULTISELECT_NAME, "root"),
        children: [
          /* @__PURE__ */ jsxs15(
            Flex,
            {
              flow: "column",
              alignItems: "start",
              gap: "xs",
              css: selectContainerCss,
              "data-testid": generateTestId(
                baseTestId,
                MULTISELECT_NAME,
                "select-container"
              ),
              children: [
                !!label && /* @__PURE__ */ jsx27(
                  Label5,
                  {
                    htmlFor: searchInputId,
                    disabled,
                    optional,
                    above: true,
                    css: labelCss,
                    "data-testid": generateTestId(
                      baseTestId,
                      MULTISELECT_NAME,
                      "label"
                    ),
                    children: label
                  }
                ),
                /* @__PURE__ */ jsx27(
                  Flex,
                  {
                    flow: "column",
                    gap: "xs",
                    wFull: true,
                    css: triggerContainerCss,
                    "data-testid": generateTestId(
                      baseTestId,
                      MULTISELECT_NAME,
                      "trigger-container"
                    ),
                    children: /* @__PURE__ */ jsx27(
                      SelectPrimitive4.Trigger,
                      {
                        asChild: true,
                        __scopeSelect: scope.selectScope.__scopeSelect,
                        onKeyDownCapture: (e) => {
                          if (e.key === SELECT_KEYBOARD_EVENT_KEY.SPACE) {
                            e.stopPropagation();
                          }
                        },
                        children: renderChildren() || /* @__PURE__ */ jsxs15(
                          Trigger10,
                          __spreadProps(__spreadValues({
                            ref: composedTriggerRefs,
                            as: "button",
                            size,
                            disabled: isUnselectable,
                            state: triggerState,
                            onPointerDown: onSelectTriggerPointerDown,
                            css: triggerCss,
                            "aria-readonly": readOnly,
                            "aria-haspopup": "listbox",
                            "data-readonly": readOnly ? HAS_DATA_ATTR_KEY : NO_DATA_ATTR_KEY,
                            "data-select-type": dataAttrHelper.selectType.value.multi,
                            "data-testid": generateTestId(
                              baseTestId,
                              MULTISELECT_NAME,
                              "trigger"
                            )
                          }, restProps), {
                            children: [
                              /* @__PURE__ */ jsxs15(
                                StyledMultiValueContainer,
                                {
                                  css: valueContainerCss,
                                  "data-testid": generateTestId(
                                    baseTestId,
                                    MULTISELECT_NAME,
                                    "value-container"
                                  ),
                                  children: [
                                    renderMultiSelectValues({
                                      showHiddenCountValue
                                    }),
                                    /* @__PURE__ */ jsx27(
                                      SearchInput,
                                      __spreadProps(__spreadValues({}, selectSearchInputProps), {
                                        value: valueText || selectSearchInputProps.value,
                                        id: searchInputId,
                                        placeholder: (value == null ? void 0 : value.length) ? "" : placeholder,
                                        disabled,
                                        readOnly: readOnly || !searchable,
                                        maxLength: (creatable == null ? void 0 : creatable.maxLength) || DEFAULT_SEARCH_INPUT_MAX_LENGTH,
                                        onKeyDown: (e) => {
                                          var _a3, _b3;
                                          if ((_a3 = creatable == null ? void 0 : creatable.ignoredKeys) == null ? void 0 : _a3.includes(e.key)) {
                                            e.preventDefault();
                                            return;
                                          }
                                          (_b3 = selectSearchInputProps.onKeyDown) == null ? void 0 : _b3.call(selectSearchInputProps, e);
                                        },
                                        css: searchInputCss,
                                        "data-testid": generateTestId(
                                          testId,
                                          MULTISELECT_NAME,
                                          "search-input"
                                        )
                                      })
                                    )
                                  ]
                                }
                              ),
                              /* @__PURE__ */ jsx27(
                                OpenIcon,
                                {
                                  css: openIconCss,
                                  "data-testid": generateTestId(
                                    baseTestId,
                                    MULTISELECT_NAME,
                                    "open-icon"
                                  ),
                                  children: /* @__PURE__ */ jsx27(
                                    Icon_default,
                                    {
                                      name: openIconName,
                                      size: openIconSize,
                                      color: openIconColor,
                                      className: openIconClassName,
                                      onClick: openIconOnClick,
                                      onMouseOver: openIconOnMouseOver,
                                      onMouseLeave: openIconOnMouseLeave
                                    }
                                  )
                                }
                              )
                            ]
                          })
                        )
                      }
                    )
                  }
                ),
                hasHint && /* @__PURE__ */ jsx27(
                  Hint,
                  {
                    className: selectHintCss,
                    label: hintLabel,
                    charCounter: hint == null ? void 0 : hint.charCounter,
                    variant: triggerState,
                    css: hintCss,
                    "data-testid": generateTestId(baseTestId, MULTISELECT_NAME, "hint")
                  }
                )
              ]
            }
          ),
          /* @__PURE__ */ jsx27(DialogPrimitive2.Root, { modal: false, open: isSelectMenuOpen, children: /* @__PURE__ */ jsx27(
            DialogPrimitive2.Content,
            {
              asChild: true,
              onPointerDownOutside: onSelectContentPointerDownOutside,
              onEscapeKeyDown: onSelectContentEscapeKeyDown,
              onOpenAutoFocus: (e) => e.preventDefault(),
              role: "listbox",
              children: /* @__PURE__ */ jsxs15(
                Menu,
                {
                  ref: optionsMenuRef,
                  size,
                  onKeyDown: onSelectMenuKeyDown,
                  css: menuCss,
                  "data-testid": generateTestId(baseTestId, MULTISELECT_NAME, "menu"),
                  children: [
                    customHeader,
                    isShowNoOptionsMessage && /* @__PURE__ */ jsx27(
                      StyledSelectNoOptions,
                      {
                        css: noOptionsCss,
                        "data-testid": generateTestId(
                          baseTestId,
                          MULTISELECT_NAME,
                          "no-options"
                        ),
                        children: noOptionsFoundMessage
                      }
                    ),
                    /* @__PURE__ */ jsx27(
                      Box_default,
                      {
                        ref: (r) => {
                          if (r && !bodyEl) {
                            setBodyEl(r);
                          }
                        },
                        css: { flexGrow: 1, overflow: "auto" },
                        children: searchFilteredOptions.map(
                          (option, index) => {
                            const { key, option: opt } = getOptionProps({
                              option,
                              index
                            });
                            if (opt.value === SELECT_ALL_OPTION.value) {
                              return /* @__PURE__ */ jsx27(
                                StyledSelectMenuSection,
                                {
                                  css: sectionCss,
                                  "aria-label": "Select all section",
                                  "data-section": "select-all",
                                  "data-testid": generateTestId(
                                    baseTestId,
                                    MULTISELECT_NAME,
                                    "select-all-section"
                                  ),
                                  children: /* @__PURE__ */ jsx27(
                                    OptionItem,
                                    {
                                      value: opt.value,
                                      disabled: opt.disabled,
                                      selectedOption,
                                      selectAllCheckedType,
                                      css: optionCss,
                                      "data-testid": generateTestId(
                                        baseTestId,
                                        MULTISELECT_NAME,
                                        "select-all-option-item"
                                      ),
                                      children: opt.label
                                    },
                                    key
                                  )
                                },
                                key
                              );
                            }
                            const isSection = opt.section && typeof opt.section === "string" && Array.isArray(opt.options);
                            if (isSection) {
                              const isLastSection = index === searchFilteredOptions.length - 1;
                              const hasSectionLabel = opt.showSectionLabel === true;
                              return /* @__PURE__ */ jsxs15(
                                StyledSelectMenuSection,
                                {
                                  noBottomDivider: hasSectionLabel || isLastSection || isShowNoOptionsMessage,
                                  css: sectionCss,
                                  "aria-label": !hasSectionLabel ? `${opt.section} section` : NO_DATA_ATTR_KEY,
                                  "data-section": opt.section,
                                  "data-testid": generateTestId(
                                    baseTestId,
                                    MULTISELECT_NAME,
                                    `menu-section--${opt.section}`
                                  ),
                                  children: [
                                    hasSectionLabel && !isShowNoOptionsMessage && /* @__PURE__ */ jsx27(
                                      StyledSelectMenuSectionLabel,
                                      {
                                        css: sectionLabelCss,
                                        "data-section-label": opt.section,
                                        "data-testid": generateTestId(
                                          baseTestId,
                                          MULTISELECT_NAME,
                                          `menu-section-label--${opt.section}}`
                                        ),
                                        children: opt.section
                                      }
                                    ),
                                    opt.options.map(
                                      (sectionOption, sectionIndex) => {
                                        const { key: sectionKey, option: sectionOpt } = getOptionProps({
                                          option: sectionOption,
                                          index: sectionIndex
                                        });
                                        return /* @__PURE__ */ jsx27(
                                          OptionItem,
                                          {
                                            value: sectionOpt.value,
                                            richLabel: sectionOpt.richLabel,
                                            disabled: sectionOpt.disabled,
                                            icon: sectionOpt.icon,
                                            iconSize: sectionOpt.iconSize,
                                            selectedOption,
                                            css: optionCss,
                                            testId: generateTestId(
                                              baseTestId,
                                              MULTISELECT_NAME,
                                              `${opt.section}-section-option-item--${sectionOpt.value}`
                                            ),
                                            children: sectionOpt.label
                                          },
                                          sectionKey
                                        );
                                      }
                                    )
                                  ]
                                },
                                key
                              );
                            }
                            return /* @__PURE__ */ jsx27(
                              OptionItem,
                              {
                                value: opt.value,
                                badgeColor: opt.badgeColor,
                                richLabel: opt.richLabel,
                                disabled: opt.disabled,
                                icon: opt.icon,
                                iconSize: opt.iconSize,
                                selectedOption,
                                css: optionCss,
                                testId: generateTestId(
                                  baseTestId,
                                  MULTISELECT_NAME,
                                  `option-item--${opt.value}`
                                ),
                                children: opt.label
                              },
                              key
                            );
                          }
                        )
                      }
                    ),
                    (creatable == null ? void 0 : creatable.showMenuMessage) && // Only show when there is a value in the search input and there is not a match in the options list
                    selectSearchInputProps.value && !searchFilteredOptions.some(
                      (option) => option.label === selectSearchInputProps.value
                    ) && /* @__PURE__ */ jsx27(
                      Box_default,
                      {
                        css: {
                          padding: "6px 12px",
                          cursor: "pointer",
                          background: `${searchFilteredOptions.length ? "unset" : "$button_subtle_background_hover"}`,
                          "&:hover": {
                            background: "$button_subtle_background_hover"
                          }
                        },
                        onClick: () => {
                          creatable == null ? void 0 : creatable.onCreate(selectSearchInputProps.value);
                          setSearchValue("");
                        },
                        children: (creatable == null ? void 0 : creatable.customMenuMessage) ? creatable.customMenuMessage(
                          selectSearchInputProps.value
                        ) : `Create "${selectSearchInputProps.value}"`
                      }
                    ),
                    footer && /* @__PURE__ */ jsx27(
                      StyledMultiSelectFooterWrapper,
                      {
                        isContentOverflowed: !isScrolled.bottom,
                        children: renderFooter()
                      }
                    )
                  ]
                }
              )
            }
          ) })
        ]
      }
    );
  }
);
MultiSelect.displayName = MULTISELECT_NAME;

// src/TextField/TextField.tsx
import * as React25 from "react";

// src/TextField/components/TextFieldLayout.tsx
var InnerTextFieldWrapper = styled(Flex, {
  position: "relative",
  width: "100%",
  height: "100%"
});
var rotate2 = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" }
});
var LoaderCircle = styled(Box_default, {
  top: "3px",
  right: "4px",
  transform: "translate(-50%, -50%)",
  width: "32px",
  height: "32px",
  borderRadius: "$rounded-full",
  border: "2.5px solid $input_default_icon_active",
  borderTopColor: "transparent",
  animation: `${rotate2} 1s linear infinite`
});
var IconWrapper = styled(Box_default, {
  display: "flex",
  alignItems: "center",
  svg: {
    color: "$input_default_icon_enabled",
    height: "13px",
    width: "13px"
  },
  variants: {
    position: {
      left: {
        position: "absolute",
        left: "8px"
      },
      right: {
        position: "absolute",
        right: "8px"
      }
    },
    clearButton: {
      true: {
        cursor: "pointer",
        svg: {
          height: "12px",
          width: "12px"
        }
      }
    }
  }
});
var SearchSeparator = styled(Box_default, {
  height: "calc(100% - 2px)",
  width: "0.5px",
  margin: "1px 0",
  backgroundColor: "$neutral20"
});
var SearchPaginationWrapper = styled(Flex, {
  alignItems: "center",
  fontSize: "11px",
  color: "$neutral90",
  "& svg": {
    width: "20px",
    height: "20px",
    color: "$neutral70",
    cursor: "pointer",
    borderRadius: "$rounded",
    "&:hover": {
      backgroundColor: "$button_default_background_hover"
    },
    "&:active": {
      backgroundColor: "$button_default_background_active"
    }
  }
});
var SearchExtrasWrapper = styled("div", {
  display: "flex",
  position: "absolute",
  width: "fit-content",
  height: "100%",
  right: 0,
  gap: "$1",
  "&:not(:empty)": {
    paddingRight: "6px",
    paddingLeft: "8px"
  }
});

// src/TextField/TextField.tsx
import { jsx as jsx28, jsxs as jsxs16 } from "react/jsx-runtime";
var defaultComponentCss3 = {
  input: void 0,
  icon: void 0
};
var TextField = React25.forwardRef(
  (props, forwardedRef) => {
    var _d;
    const _a = props, {
      label,
      isOptional,
      placeholder,
      state = "default",
      icon,
      iconPosition = "left",
      onIconClick,
      hint,
      loading = false,
      disabled,
      compact,
      showClearButton = false,
      onClear,
      pagination,
      css: css3 = {},
      componentCss = defaultComponentCss3
    } = _a, rest = __objRest(_a, [
      "label",
      "isOptional",
      "placeholder",
      "state",
      "icon",
      "iconPosition",
      "onIconClick",
      "hint",
      "loading",
      "disabled",
      "compact",
      "showClearButton",
      "onClear",
      "pagination",
      "css",
      "componentCss"
    ]);
    const [cssUtils2, _b] = extractCssUtilsFromProps(rest), _c = _b, { testId } = _c, restProps = __objRest(_c, ["testId"]);
    const extrasRef = React25.useRef(null);
    const uid = React25.useId();
    const elementId = (_d = rest.id) != null ? _d : uid;
    const [extrasWidth, setExtrasWidth] = React25.useState(0);
    React25.useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setExtrasWidth(entry.target.offsetWidth);
        }
      });
      if (extrasRef.current) {
        resizeObserver.observe(extrasRef.current);
      }
      return () => {
        if (extrasRef.current) {
          resizeObserver.unobserve(extrasRef.current);
        }
      };
    }, []);
    const childrenCss = React25.useMemo(
      () => ({
        input: componentCss.input || {},
        icon: componentCss.icon || {}
      }),
      [componentCss.input]
    );
    const hasExtras = (pagination == null ? void 0 : pagination.visible) || loading || showClearButton;
    return /* @__PURE__ */ jsxs16(Flex, __spreadProps(__spreadValues({ flow: "column", gap: "xs" }, cssUtils2), { css: css3, children: [
      label && /* @__PURE__ */ jsx28(
        Label,
        {
          htmlFor: elementId,
          disabled,
          optional: isOptional,
          above: true,
          children: label
        }
      ),
      /* @__PURE__ */ jsxs16(InnerTextFieldWrapper, { alignItems: "center", gap: "sm", children: [
        icon && iconPosition === "left" && /* @__PURE__ */ jsx28(IconWrapper, { position: "left", css: childrenCss.icon, children: /* @__PURE__ */ jsx28(Icon_default, { name: icon }) }),
        /* @__PURE__ */ jsx28(
          BaseTextField,
          __spreadProps(__spreadValues({}, restProps), {
            id: elementId,
            ref: forwardedRef,
            placeholder,
            iconLeft: !!icon && iconPosition === "left",
            showLoader: loading,
            disabled,
            compact,
            state,
            showClearButton,
            css: hasExtras ? __spreadValues({ paddingRight: `${extrasWidth}px` }, childrenCss.input) : __spreadValues({}, childrenCss.input),
            "data-testid": testId || restProps["data-testid"],
            onBlur: (e) => {
              var _a2, _b2;
              if ((pagination == null ? void 0 : pagination.visible) && ((_a2 = extrasRef.current) == null ? void 0 : _a2.contains(e.relatedTarget))) {
                e.target.focus();
                return;
              }
              (_b2 = rest.onBlur) == null ? void 0 : _b2.call(rest, e);
            }
          })
        ),
        icon && iconPosition === "right" && /* @__PURE__ */ jsx28(IconWrapper, { position: "right", css: childrenCss.icon, children: /* @__PURE__ */ jsx28(Icon_default, { name: icon, onClick: onIconClick }) }),
        /* @__PURE__ */ jsxs16(SearchExtrasWrapper, { ref: extrasRef, children: [
          (pagination == null ? void 0 : pagination.visible) && /* @__PURE__ */ jsx28(SearchPaginationWrapper, { tabIndex: 0, children: /* @__PURE__ */ jsxs16(Flex, { alignItems: "center", css: { gap: "6px" }, children: [
            /* @__PURE__ */ jsx28(Text, { children: `${pagination.current}/${pagination.max}` }),
            /* @__PURE__ */ jsxs16(Flex, { alignItems: "center", children: [
              /* @__PURE__ */ jsx28(
                Icon_default,
                {
                  name: "fa-angle-up-small",
                  onClick: pagination.onNext
                }
              ),
              /* @__PURE__ */ jsx28(
                Icon_default,
                {
                  name: "fa-angle-down-small",
                  onClick: pagination.onPrev
                }
              )
            ] })
          ] }) }),
          (pagination == null ? void 0 : pagination.visible) && (showClearButton || loading) && /* @__PURE__ */ jsx28(SearchSeparator, {}),
          loading && /* @__PURE__ */ jsx28(IconWrapper, { children: /* @__PURE__ */ jsx28(LoaderCircle, {}) }),
          showClearButton && // biome-ignore lint/a11y/noPositiveTabindex: fix
          /* @__PURE__ */ jsx28(IconWrapper, { clearButton: true, tabIndex: 1, children: /* @__PURE__ */ jsx28(Icon_default, { name: "xmark", onClick: onClear }) })
        ] })
      ] }),
      hint && /* @__PURE__ */ jsx28(Hint, __spreadProps(__spreadValues({}, hint), { variant: state }))
    ] }));
  }
);

// src/DatePicker/BaseDatePicker.ts
var StyledListItem = styled("li", {
  padding: "2px"
});
var StyledDateTextField = styled(TextField, {
  variants: {
    dateRangeFilter: {
      true: {
        width: "136px"
      }
    }
  }
});
var StyledCalendarRoot = styled(Box_default, {
  display: "flex",
  flexDirection: "column",
  background: "$neutral0",
  boxShadow: "$elevation3",
  padding: "$3",
  borderRadius: "$rounded",
  position: "absolute",
  zIndex: 1,
  top: "53px"
});
var StyledCalendar = styled("ol", {
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  listStyle: "none",
  margin: 0,
  padding: 0,
  textAlign: "center",
  gap: "10px"
});
var StyledCalendarNav = styled(Flex, {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  pb: "$2",
  "& svg": {
    color: "$neutral70"
  }
});
var StyledDaysHeader = styled(Flex, {
  flexDirection: "row",
  width: "100%"
});
var StyledDayName = styled(StyledListItem, {
  padding: "2px",
  fontSize: "$1",
  lineHeight: "$1",
  color: "$neutral50",
  fontWeight: "$medium"
});
var StyledCalendarContent = styled(Flex, {
  flexDirection: "column",
  width: "100%"
});
var StyledWeek = styled(Flex, {
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
  gap: "10px"
});
var StyledDate = styled("button", {
  // reset
  appearance: "none",
  border: "none",
  background: "transparent",
  fontSize: "$2",
  fontFamily: "$primary",
  lineHeight: "$2",
  textAlign: "center",
  fontWeight: "$regular",
  padding: "6px",
  "&:hover": {
    background: "$neutral10",
    borderRadius: "$rounded",
    cursor: "pointer"
  },
  "&:disabled": {
    color: "$neutral30",
    cursor: "not-allowed"
  },
  variants: {
    isDifferentMonth: {
      true: {
        color: "$neutral60"
      }
    },
    isToday: {
      true: {
        color: "$blue50",
        borderBottom: "1px solid $blue50",
        fontWeight: "$medium",
        "&:hover": {
          background: "transparent",
          borderRadius: "$rounded-none"
        },
        "&:disabled": {
          borderBotttom: "1px solid $neutral30",
          color: "$neutral30"
        }
      }
    },
    isSelected: {
      true: {
        background: "$blue50",
        color: "$neutral0",
        borderRadius: "$rounded",
        fontWeight: "$medium",
        "&:hover": {
          background: "$blue40",
          borderRadius: "$rounded",
          color: "$neutral0"
        }
      }
    }
  }
});

// src/DatePicker/DatePicker.tsx
import { jsx as jsx29, jsxs as jsxs17 } from "react/jsx-runtime";
var DatePicker = ({
  value,
  onChange,
  onClear,
  datePickerCss,
  minDate,
  maxDate,
  placeholder = t5("MM/DD/YYYY", { _context: "Date format" }),
  disabled = false,
  showCalendarIcon = true,
  showClearButton = true,
  isRange = false,
  hint
}) => {
  const dateInput = React26.useRef(null);
  const datePickerRef = React26.useRef(null);
  const [isCalendarOpen, setIsCalendarOpen] = React26.useState(false);
  const [dateValue, setDateValue] = React26.useState("");
  const {
    data: { weekDays, calendars, months, years },
    propGetters: {
      dayButton,
      previousMonthButton,
      nextMonthButton,
      monthButton,
      yearButton
    },
    actions
  } = useDatePicker({
    selectedDates: value,
    onDatesChange: onChange,
    locale: {
      locale: getJSLocale()
    }
  });
  const { month, year, days } = calendars[0];
  function handleInputFocus() {
    setIsCalendarOpen(true);
  }
  function handleInputChange(e) {
    setDateValue(e.target.value);
  }
  function handleClear() {
    setDateValue("");
    onClear();
  }
  React26.useEffect(() => {
    function handleClickOutside(event) {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target) && dateInput.current && !dateInput.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const inputValue = React26.useMemo(
    () => {
      var _a;
      return ((_a = value[0]) == null ? void 0 : _a.toLocaleDateString(getJSLocale())) || dateValue;
    },
    [value, dateValue]
  );
  return /* @__PURE__ */ jsxs17(Flex, { flow: "column", children: [
    /* @__PURE__ */ jsx29(Box_default, { children: /* @__PURE__ */ jsx29(
      StyledDateTextField,
      {
        ref: dateInput,
        onChange: handleInputChange,
        icon: showCalendarIcon ? "calendar" : void 0,
        iconLeft: showCalendarIcon,
        placeholder,
        value: inputValue,
        onFocus: handleInputFocus,
        showClearButton,
        onClear: handleClear,
        readOnly: true,
        dateField: true,
        dateRangeFilter: isRange,
        disabled,
        hint,
        state: (hint == null ? void 0 : hint.variant) === "error" ? "error" : "default"
      }
    ) }),
    isCalendarOpen && /* @__PURE__ */ jsxs17(StyledCalendarRoot, { ref: datePickerRef, css: datePickerCss, children: [
      /* @__PURE__ */ jsxs17(StyledCalendarNav, { children: [
        /* @__PURE__ */ jsx29(
          IconButton,
          __spreadProps(__spreadValues({}, previousMonthButton({ step: 1 })), {
            icon: "fa-angle-left-small"
          })
        ),
        /* @__PURE__ */ jsxs17(Flex, { children: [
          /* @__PURE__ */ jsx29(
            Select,
            {
              options: months.map((m) => ({
                value: m.month,
                label: m.month
              })),
              value: month,
              onChange: (val) => {
                const selectedMonth = months.find(
                  (m) => m.month === val
                );
                const monthBtn = monthButton(
                  selectedMonth
                );
                if (monthBtn.onClick) {
                  monthBtn.onClick();
                }
              },
              componentCss: {
                menu: {
                  maxWidth: "100px"
                }
              },
              children: /* @__PURE__ */ jsx29(
                Button,
                {
                  icon: { name: "fa-angle-down-small" },
                  compact: true,
                  variant: "subtle",
                  children: month
                }
              )
            }
          ),
          /* @__PURE__ */ jsx29(
            Select,
            {
              options: years.map((y) => ({
                value: y.year.toString(),
                label: y.year.toString()
              })),
              value: year,
              onChange: (val) => {
                const selectedYear = years.find(
                  (y) => y.year.toString() === val
                );
                const yearBtn = yearButton(selectedYear);
                if (yearBtn.onClick) {
                  yearBtn.onClick();
                }
              },
              componentCss: {
                menu: {
                  maxWidth: "100px"
                }
              },
              children: /* @__PURE__ */ jsx29(
                Button,
                {
                  icon: { name: "fa-angle-down-small" },
                  compact: true,
                  variant: "subtle",
                  children: year
                }
              )
            }
          )
        ] }),
        /* @__PURE__ */ jsx29(
          IconButton,
          __spreadProps(__spreadValues({}, nextMonthButton({ step: 1 })), {
            icon: "fa-angle-right-small"
          })
        )
      ] }),
      /* @__PURE__ */ jsxs17(StyledCalendar, { children: [
        weekDays.map((day) => /* @__PURE__ */ jsx29(StyledDayName, { children: day }, day)),
        days.map((day) => {
          const isDisabled = minDate && day.$date <= minDate || maxDate && day.$date >= maxDate;
          return /* @__PURE__ */ jsx29(
            StyledDate,
            __spreadProps(__spreadValues({
              isDifferentMonth: !day.inCurrentMonth,
              isToday: day.now,
              isSelected: day.selected
            }, dayButton(day)), {
              disabled: isDisabled,
              onClick: () => {
                const dayBtn = dayButton(day);
                if (dayBtn.onClick) {
                  dayBtn.onClick();
                  setIsCalendarOpen(false);
                }
              },
              children: day.day
            }),
            day.$date.toString()
          );
        })
      ] }),
      /* @__PURE__ */ jsx29(Box_default, { css: { pt: "12px" }, children: /* @__PURE__ */ jsx29(
        Button,
        {
          variant: "subtle",
          compact: true,
          onClick: () => actions.setMonth(/* @__PURE__ */ new Date()),
          children: t5("View today")
        }
      ) })
    ] })
  ] });
};

// src/DatePicker/DateRangePicker.tsx
import { jsx as jsx30, jsxs as jsxs18 } from "react/jsx-runtime";
var DateRangePicker = ({
  from = {
    value: [],
    onChange: () => {
    },
    onClear: () => {
    }
  },
  to = {
    value: [],
    onChange: () => {
    },
    onClear: () => {
    }
  },
  datePickerCss,
  containerCss = { width: "100%", gap: "$2" }
}) => /* @__PURE__ */ jsxs18(Flex, { css: containerCss, children: [
  /* @__PURE__ */ jsx30(
    DatePicker,
    {
      value: from.value,
      onChange: from.onChange,
      onClear: from.onClear,
      disabled: from.disabled,
      placeholder: from.placeholder,
      maxDate: to.value[0],
      datePickerCss,
      showCalendarIcon: false,
      showDropdownIcon: true,
      showClearButton: false,
      isRange: true,
      hint: from.hint
    }
  ),
  /* @__PURE__ */ jsx30(
    DatePicker,
    {
      value: to.value,
      onChange: to.onChange,
      onClear: to.onClear,
      disabled: to.disabled,
      placeholder: to.placeholder,
      minDate: from.value[0],
      datePickerCss,
      showCalendarIcon: false,
      showDropdownIcon: true,
      showClearButton: false,
      isRange: true,
      hint: to.hint
    }
  )
] });

// src/DatePicker/RelativeDatePicker.tsx
import { t as t6 } from "@kandji-inc/nectar-i18n";
import * as React27 from "react";
import { jsx as jsx31, jsxs as jsxs19 } from "react/jsx-runtime";
var RelativeDatePicker = ({
  value,
  hint,
  onChange
}) => {
  var _a;
  const parts = value ? value.split(" ") : [];
  const initialPeriod = parts[0] ? parseInt(parts[0]) : "";
  const [period, setPeriod] = React27.useState(initialPeriod);
  const initialUnit = (initialPeriod !== 1 ? (_a = parts[1]) == null ? void 0 : _a.slice(0, -1) : parts[1]) || "hour";
  const [unit, setUnit] = React27.useState(initialUnit);
  const isSingular = React27.useCallback(
    (period2) => period2 === 1,
    []
  );
  const onPeriodChange = React27.useCallback(
    (e) => {
      const { value: value2 } = e.target;
      const newPeriod = value2 ? parseInt(value2) : "";
      setPeriod(newPeriod);
      const newUnit = newPeriod && isSingular(newPeriod) ? unit : `${unit}s`;
      onChange(t6("{newPeriod} {newUnit}", { newPeriod, newUnit }));
    },
    [isSingular, onChange, unit]
  );
  const onUnitChange = React27.useCallback(
    (unit2) => {
      setUnit(unit2);
      const newUnit = period && isSingular(period) ? unit2 : `${unit2}s`;
      onChange(t6("{period} {newUnit}", { period, newUnit }));
    },
    [isSingular, onChange, period]
  );
  const options = React27.useMemo(
    () => [
      {
        value: "minute",
        label: isSingular(period) ? t6("minute ago") : t6("minutes ago")
      },
      {
        value: "hour",
        label: isSingular(period) ? t6("hour ago") : t6("hours ago")
      },
      {
        value: "day",
        label: isSingular(period) ? t6("day ago") : t6("days ago")
      },
      {
        value: "week",
        label: isSingular(period) ? t6("week ago") : t6("weeks ago")
      },
      {
        value: "month",
        label: isSingular(period) ? t6("month ago") : t6("months ago")
      },
      {
        value: "year",
        label: isSingular(period) ? t6("year ago") : t6("years ago")
      }
    ],
    [isSingular, period]
  );
  return /* @__PURE__ */ jsxs19(Flex, { gap: "sm", children: [
    /* @__PURE__ */ jsx31(
      TextField,
      {
        type: "number",
        css: { width: "152px" },
        state: hint ? "error" : "default",
        hint: { label: hint },
        placeholder: t6("Enter number of"),
        value: period,
        onChange: onPeriodChange
      }
    ),
    /* @__PURE__ */ jsx31(
      Select,
      {
        size: "xs",
        value: unit,
        onChange: onUnitChange,
        options
      }
    )
  ] });
};

// src/DatePicker/RelativeDateRangePicker.tsx
import { t as t7 } from "@kandji-inc/nectar-i18n";
import { jsx as jsx32, jsxs as jsxs20 } from "react/jsx-runtime";
var RelativeDateRangePicker = ({
  from = {
    value: [],
    hint: "",
    onChange: () => {
    },
    onClear: () => {
    }
  },
  to = {
    value: [],
    hint: "",
    onChange: () => {
    },
    onClear: () => {
    }
  }
}) => /* @__PURE__ */ jsxs20(Flex, { flow: "column", css: { gap: "$space_12" }, children: [
  /* @__PURE__ */ jsx32(
    RelativeDatePicker,
    {
      value: from.value ? from.value[0] : null,
      hint: from.hint,
      onChange: from.onChange
    }
  ),
  /* @__PURE__ */ jsx32(Label, { children: t7("and") }),
  /* @__PURE__ */ jsx32(
    RelativeDatePicker,
    {
      value: to.value ? to.value[0] : null,
      hint: to.hint,
      onChange: to.onChange
    }
  )
] });

// src/Dialog/Dialog.tsx
import * as DialogPrimitive4 from "@radix-ui/react-dialog";

// src/Dialog/BaseDialog.tsx
import * as DialogPrimitive3 from "@radix-ui/react-dialog";

// src/Heading/Heading.tsx
import merge2 from "lodash.merge";
import React28 from "react";
import { jsx as jsx33 } from "react/jsx-runtime";
var DEFAULT_TAG2 = "h1";
var Heading = React28.forwardRef((props, forwardedRef) => {
  const _a = props, { size = "1" } = _a, textProps = __objRest(_a, ["size"]);
  const textSize = {
    1: { "@initial": "6" },
    2: { "@initial": "5" },
    3: { "@initial": "4" },
    4: { "@initial": "3" },
    5: { "@initial": "2" },
    6: { "@initial": "1" }
  };
  const textCss = {
    1: {
      fontWeight: "$medium",
      lineHeight: "$5",
      letterSpacing: "-1.2px"
    },
    2: {
      fontWeight: "$medium",
      lineHeight: "36px",
      // stitches is missing this size
      letterSpacing: "-0.8px"
    },
    3: {
      fontWeight: "$medium",
      lineHeight: "$4",
      letterSpacing: "-0.8px"
    },
    4: {
      fontWeight: "$medium",
      lineHeight: "$3",
      letterSpacing: "-0.4px"
    },
    5: {
      fontWeight: "$medium",
      lineHeight: "$2"
    },
    6: {
      fontWeight: "$medium",
      lineHeight: "$1"
    }
  };
  return /* @__PURE__ */ jsx33(
    Text,
    __spreadProps(__spreadValues({
      as: DEFAULT_TAG2,
      variant: "default"
    }, textProps), {
      ref: forwardedRef,
      size: textSize[size],
      css: __spreadValues({
        fontVariantNumeric: "proportional-nums"
      }, merge2(textCss[size], props.css))
    })
  );
});

// src/Dialog/BaseDialog.tsx
var DialogOverlayShow = keyframes({
  "0%": {
    opacity: 0
  },
  "100%": {
    opacity: 1
  }
});
var DialogContentShow = keyframes({
  "0%": {
    opacity: 0,
    transform: "translate(-50%, -48%) scale(0.96)"
  },
  "100%": {
    opacity: 1,
    transform: "translate(-50%, -50%) scale(1)"
  }
});
var DialogContentShowDrawer = keyframes({
  "0%": {
    opacity: 0,
    transform: "translateX(100%)"
    // Start off-screen to the right
  },
  "100%": {
    opacity: 1,
    transform: "translateX(0)"
    // Move to the visible position
  }
});
var DialogOverlay = styled(DialogPrimitive3.Overlay, {
  background: "rgba(26, 29, 37, 0.45)",
  position: "fixed",
  zIndex: "$max",
  inset: "0",
  variants: {
    animateOpen: {
      true: {
        animation: `${DialogOverlayShow} 200ms ease-in-out`
      }
    },
    blurred: {
      true: {
        background: "rgba(31, 39, 47, 0.64)",
        backdropFilter: "blur(6.5px)"
      }
    }
  }
});
var DialogTitle = styled(Heading, {});
var DialogContent = styled(DialogPrimitive3.Content, {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "$neutral0",
  borderRadius: "$rounded",
  position: "fixed",
  zIndex: "$max",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  variants: {
    animateOpen: {
      true: {
        animation: `${DialogContentShow} 200ms ease-in-out`
      }
    },
    kind: {
      drawer: {},
      modal: {}
    }
  },
  compoundVariants: [
    {
      animateOpen: true,
      kind: "drawer",
      css: {
        animation: `${DialogContentShowDrawer} 200ms ease-in-out`
      }
    }
  ]
});
var DialogContentMain = styled(Flex, {
  padding: "$4 $5"
});
var DialogContentBody = styled(Box_default, {
  marginTop: "$4"
});
var DialogContentFooter = styled(Box_default, {
  padding: "$4 $3",
  borderTop: "1px solid $neutral20"
});

// src/Dialog/Dialog.tsx
import { jsx as jsx34, jsxs as jsxs21 } from "react/jsx-runtime";
var Dialog = (props) => {
  const {
    children,
    css: css3,
    headerCss,
    bodyCss,
    contentCss,
    title,
    content,
    footer,
    isOpen,
    onOpenChange,
    closeIcon = "xmark",
    closeOnOutsideClick,
    closeOnEscape,
    autoFocusOnOpen,
    animateOnOpen = true,
    kind = "modal"
  } = props;
  const controlledOpenProps = __spreadValues(__spreadValues({}, isOpen !== void 0 && { open: isOpen }), onOpenChange !== void 0 && {
    onOpenChange: (o) => onOpenChange == null ? void 0 : onOpenChange(o)
  });
  return /* @__PURE__ */ jsxs21(DialogPrimitive4.Root, __spreadProps(__spreadValues({}, controlledOpenProps), { children: [
    /* @__PURE__ */ jsx34(DialogPrimitive4.Trigger, { asChild: true, children }),
    /* @__PURE__ */ jsxs21(DialogPrimitive4.Portal, { children: [
      /* @__PURE__ */ jsx34(DialogOverlay, { animateOpen: animateOnOpen }),
      /* @__PURE__ */ jsxs21(
        DialogContent,
        {
          css: css3,
          onPointerDownOutside: (e) => !closeOnOutsideClick && e.preventDefault(),
          onEscapeKeyDown: (e) => !closeOnEscape && e.preventDefault(),
          onOpenAutoFocus: (e) => !autoFocusOnOpen && e.preventDefault(),
          animateOpen: animateOnOpen,
          kind,
          children: [
            /* @__PURE__ */ jsxs21(DialogContentMain, { flow: "column", flex: "1", css: contentCss, children: [
              /* @__PURE__ */ jsxs21(Flex, { alignItems: "center", justifyContent: "space-between", children: [
                title && /* @__PURE__ */ jsx34(DialogTitle, { size: "2", css: headerCss, children: title }),
                /* @__PURE__ */ jsx34(DialogPrimitive4.Close, { asChild: true, children: /* @__PURE__ */ jsx34(Button, { compact: true, variant: "subtle", icon: { name: closeIcon } }) })
              ] }),
              /* @__PURE__ */ jsx34(DialogContentBody, { css: bodyCss, children: content })
            ] }),
            footer && /* @__PURE__ */ jsx34(DialogContentFooter, { children: footer })
          ]
        }
      )
    ] })
  ] }));
};

// src/Dialog/DialogV2.tsx
import * as DialogPrimitive6 from "radix-ui-react-dialog-1.0.5";
import React30, { useState as useState11 } from "react";

// src/ScrollArea/ScrollArea.tsx
import * as ScrollAreaPrimitive2 from "@radix-ui/react-scroll-area";
import * as React29 from "react";

// src/ScrollArea/BaseScrollArea.tsx
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
var StyledScrollAreaRoot = styled(ScrollAreaPrimitive.Root, {
  overflow: "hidden",
  position: "relative",
  backgroundColor: "white"
});
var StyledScrollAreaViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: "100%",
  height: "100%"
});
var StyledScrollAreaScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: "flex",
  // ensures no selection
  userSelect: "none",
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: "none",
  padding: 4,
  background: "#F3F7FA",
  transition: "background 160ms ease-out",
  "&:hover": { background: "#E4E4E7" },
  '&[data-orientation="vertical"]': { width: "12px" },
  '&[data-orientation="horizontal"]': {
    flexDirection: "column",
    height: "12px"
  },
  variants: {
    compact: {
      true: {
        padding: 1,
        '&[data-orientation="vertical"]': { width: "4px" },
        '&[data-orientation="horizontal"]': { height: "4px" }
      }
    },
    scrollbarPosition: {
      left: {
        borderRight: "1px solid #D7E1ED"
      },
      right: {
        borderLeft: "1px solid #D7E1ED"
      },
      bottom: {
        borderTop: "1px solid #D7E1ED"
      },
      top: {
        borderTop: "1px solid #D7E1ED"
      }
    }
  },
  compoundVariants: [
    {
      scrollbarPosition: "left",
      compact: true,
      css: {
        border: "none"
      }
    },
    {
      scrollbarPosition: "right",
      compact: true,
      css: {
        border: "none"
      }
    },
    {
      scrollbarPosition: "bottom",
      compact: true,
      css: {
        border: "none"
      }
    },
    {
      scrollbarPosition: "top",
      compact: true,
      css: {
        border: "none"
      }
    }
  ],
  defaultVariants: {
    scrollbarPosition: "right"
  }
});
var StyledScrollAreaThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: "rgba(80, 94, 113, 0.24)",
  borderRadius: "$rounded",
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    minWidth: 32,
    minHeight: 32
  }
});

// src/ScrollArea/ScrollArea.tsx
import { jsx as jsx35, jsxs as jsxs22 } from "react/jsx-runtime";
var ScrollArea = React29.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      className,
      children,
      scrollbarPosition,
      compact,
      viewportCss,
      onScroll
    } = _b, props = __objRest(_b, [
      "className",
      "children",
      "scrollbarPosition",
      "compact",
      "viewportCss",
      "onScroll"
    ]);
    return /* @__PURE__ */ jsxs22(StyledScrollAreaRoot, __spreadProps(__spreadValues({ ref, className }, props), { children: [
      /* @__PURE__ */ jsx35(StyledScrollAreaViewport, { css: viewportCss, onScroll, children }),
      /* @__PURE__ */ jsx35(ScrollBar, { scrollbarPosition, compact }),
      /* @__PURE__ */ jsx35(ScrollAreaPrimitive2.Corner, {})
    ] }));
  }
);
ScrollArea.displayName = ScrollAreaPrimitive2.Root.displayName;
var ScrollBar = React29.forwardRef((_a, ref) => {
  var _b = _a, { className, orientation = "vertical" } = _b, props = __objRest(_b, ["className", "orientation"]);
  return /* @__PURE__ */ jsx35(
    StyledScrollAreaScrollbar,
    __spreadProps(__spreadValues({
      ref,
      orientation,
      className
    }, props), {
      children: /* @__PURE__ */ jsx35(StyledScrollAreaThumb, {})
    })
  );
});
ScrollBar.displayName = ScrollAreaPrimitive2.ScrollAreaScrollbar.displayName;

// src/ScrollArea/index.ts
import * as ScrollAreaPrimitive3 from "@radix-ui/react-scroll-area";

// src/Dialog/BaseDialogV2.tsx
import * as DialogPrimitive5 from "radix-ui-react-dialog-1.0.5";
var DialogOverlayShow2 = keyframes({
  "0%": {
    opacity: 0
  },
  "100%": {
    opacity: 1
  }
});
var DialogContentShow2 = keyframes({
  "0%": {
    opacity: 0,
    transform: "translate(-50%, -48%) scale(0.96)"
  },
  "100%": {
    opacity: 1,
    transform: "translate(-50%, -50%) scale(1)"
  }
});
var DialogOverlay2 = styled(DialogPrimitive5.Overlay, {
  background: "rgba(26, 29, 37, 0.45)",
  position: "fixed",
  zIndex: "$max",
  inset: "0",
  variants: {
    animateOpen: {
      true: {
        animation: `${DialogOverlayShow2} 200ms ease-in-out`
      }
    }
  }
});
var DialogTitle2 = styled(Heading, {});
var DialogContent2 = styled(DialogPrimitive5.Content, {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "$neutral0",
  borderRadius: "$rounded",
  position: "fixed",
  zIndex: "2000",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  variants: {
    animateOpen: {
      true: {
        animation: `${DialogContentShow2} 200ms ease-in-out`
      }
    }
  }
  // TODO: width, height and responsiveness
});
var DialogContentMain2 = styled(Flex, {});
var DialogContentHeader = styled(Flex, {
  padding: "$4 $5 $4 $5"
});
var DialogContentBody2 = styled(Box_default, {});
var DialogContentFooter2 = styled(Box_default, {
  padding: "$4 $3",
  borderTop: "1px solid $neutral20"
});

// src/Dialog/DialogV2.tsx
import { jsx as jsx36, jsxs as jsxs23 } from "react/jsx-runtime";
var sizeCSSMap = {
  sm: {
    height: "340px"
  },
  md: {
    height: "440px"
  },
  lg: {
    height: "560px"
  }
};
var DialogV2 = (props) => {
  var _a;
  const {
    children,
    css: css3,
    title,
    headerContent,
    content,
    footer,
    isOpen,
    onOpenChange,
    closeIcon = "xmark",
    closeOnOutsideClick,
    closeOnEscape,
    autoFocusOnOpen,
    animateOnOpen = true,
    size
  } = props;
  const [isScrolled, setIsScrolled] = useState11(false);
  const timeoutId = React30.useRef(null);
  const controlledOpenProps = __spreadValues(__spreadValues({}, isOpen !== void 0 && { open: isOpen }), onOpenChange !== void 0 && {
    onOpenChange: (o) => onOpenChange == null ? void 0 : onOpenChange(o)
  });
  const handleBodyScroll = React30.useCallback(
    (e) => {
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current);
      }
      const scrollY = e.currentTarget.scrollTop;
      const containerTop = e.currentTarget.offsetTop;
      timeoutId.current = setTimeout(() => {
        setIsScrolled(scrollY > containerTop);
      }, 20);
    },
    []
  );
  return /* @__PURE__ */ jsxs23(DialogPrimitive6.Root, __spreadProps(__spreadValues({}, controlledOpenProps), { children: [
    /* @__PURE__ */ jsx36(DialogPrimitive6.Trigger, { asChild: true, children }),
    /* @__PURE__ */ jsxs23(DialogPrimitive6.Portal, { children: [
      /* @__PURE__ */ jsx36(DialogOverlay2, { animateOpen: animateOnOpen }),
      /* @__PURE__ */ jsxs23(
        DialogContent2,
        {
          css: css3,
          onPointerDownOutside: (e) => !closeOnOutsideClick && e.preventDefault(),
          onEscapeKeyDown: (e) => !closeOnEscape && e.preventDefault(),
          onOpenAutoFocus: (e) => !autoFocusOnOpen && e.preventDefault(),
          animateOpen: animateOnOpen,
          children: [
            /* @__PURE__ */ jsxs23(DialogContentMain2, { flow: "column", children: [
              /* @__PURE__ */ jsxs23(
                DialogContentHeader,
                {
                  flex: "1",
                  flow: "column",
                  css: {
                    borderBottom: isScrolled ? "1px solid $neutral20" : "none"
                  },
                  children: [
                    /* @__PURE__ */ jsxs23(Flex, { alignItems: "start", justifyContent: "space-between", children: [
                      title && /* @__PURE__ */ jsx36(DialogTitle2, { size: "2", children: title }),
                      /* @__PURE__ */ jsx36(DialogPrimitive6.Close, { asChild: true, children: /* @__PURE__ */ jsx36(
                        Button,
                        {
                          compact: true,
                          variant: "subtle",
                          icon: { name: closeIcon },
                          css: {
                            marginTop: "$2"
                          }
                        }
                      ) })
                    ] }),
                    headerContent && headerContent
                  ]
                }
              ),
              /* @__PURE__ */ jsx36(DialogContentBody2, { children: /* @__PURE__ */ jsx36(
                ScrollArea,
                {
                  css: {
                    height: `${size ? (_a = sizeCSSMap[size]) == null ? void 0 : _a.height : "auto"}`
                  },
                  viewportCss: {
                    padding: "0 $5 $4 $5"
                    // <-- necessary for the overflow hidden not to cut off parts of the content
                  },
                  onScroll: handleBodyScroll,
                  children: content
                }
              ) })
            ] }),
            footer && /* @__PURE__ */ jsx36(DialogContentFooter2, { children: footer })
          ]
        }
      )
    ] })
  ] }));
};

// src/Dialog/useDialog.ts
import { useState as useState12 } from "react";
var useDialog = (defaultIsOpen) => {
  const [isOpen, setIsOpen] = useState12(defaultIsOpen || false);
  const toggle = (override) => setIsOpen((prev) => {
    if (override !== void 0) {
      return override;
    }
    return !prev;
  });
  return [isOpen, toggle];
};

// src/Dialog/DialogPrimitives.tsx
import * as DialogPrimitive7 from "@radix-ui/react-dialog";
import * as React31 from "react";
import { Fragment as Fragment7, jsx as jsx37, jsxs as jsxs24 } from "react/jsx-runtime";
var Dialog2 = DialogPrimitive7.Root;
var DialogTrigger = DialogPrimitive7.Trigger;
var StyledPortal = styled(DialogPrimitive7.Portal, {});
var DialogPortal = (_a) => {
  var props = __objRest(_a, []);
  return /* @__PURE__ */ jsx37(StyledPortal, __spreadValues({}, props));
};
DialogPortal.displayName = DialogPrimitive7.Portal.displayName;
var DialogOverlay3 = React31.forwardRef((_a, ref) => {
  var _b = _a, { animateOpen = true, blurred = false } = _b, props = __objRest(_b, ["animateOpen", "blurred"]);
  return /* @__PURE__ */ jsx37(
    DialogOverlay,
    __spreadValues({
      ref,
      animateOpen,
      blurred
    }, props)
  );
});
DialogOverlay3.displayName = DialogPrimitive7.Overlay.displayName;
var StyledClose = styled(DialogPrimitive7.Close, {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: "4px",
  right: "4px",
  backgroundColor: "transparent",
  border: "none",
  borderRadius: "$rounded",
  padding: "8px",
  color: "$neutral70",
  cursor: "pointer",
  "&:hover": {
    color: "$neutral80",
    backgroundColor: "$neutral20"
  },
  // For screen readers
  "& span": {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: 0,
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    borderWidth: 0
  }
});
var DialogContent3 = React31.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      animateOpen = true,
      blurred = false,
      hideClose = false,
      children
    } = _b, props = __objRest(_b, [
      "animateOpen",
      "blurred",
      "hideClose",
      "children"
    ]);
    return /* @__PURE__ */ jsxs24(DialogPortal, { children: [
      /* @__PURE__ */ jsx37(DialogOverlay3, { animateOpen, blurred }),
      /* @__PURE__ */ jsxs24(DialogContent, __spreadProps(__spreadValues({ ref, animateOpen }, props), { children: [
        children,
        !hideClose && /* @__PURE__ */ jsxs24(StyledClose, { children: [
          /* @__PURE__ */ jsx37(Xmark_default, {}),
          /* @__PURE__ */ jsx37("span", { children: "Close" })
        ] })
      ] }))
    ] });
  }
);
DialogContent3.displayName = DialogPrimitive7.Content.displayName;
var StyledHeader2 = styled(Flex, {
  direction: "column",
  p: "16px 24px 8px 24px"
});
var DialogHeader = (_a) => {
  var props = __objRest(_a, []);
  return /* @__PURE__ */ jsx37(StyledHeader2, __spreadValues({}, props));
};
DialogHeader.displayName = "DialogHeader";
var StyledFooter = styled(Flex, {
  justifyContent: "flex-end",
  p: "16px 12px",
  gap: "4px"
});
var DialogFooter = (_a) => {
  var props = __objRest(_a, []);
  return /* @__PURE__ */ jsxs24(Fragment7, { children: [
    /* @__PURE__ */ jsx37(DropdownMenuDivider, { css: { m: 0 } }),
    /* @__PURE__ */ jsx37(StyledFooter, __spreadValues({}, props))
  ] });
};
DialogFooter.displayName = "DialogFooter";
var StyledTitle = styled(DialogPrimitive7.Title, {
  color: "$neutral100",
  fontFamily: "$primary",
  fontSize: "$4",
  fontWeight: "$medium",
  fontStyle: "normal",
  lineHeight: "1.6",
  letterSpacing: "-0.8px"
});
var DialogTitle3 = React31.forwardRef((_a, ref) => {
  var props = __objRest(_a, []);
  return /* @__PURE__ */ jsx37(StyledTitle, __spreadValues({ ref }, props));
});
DialogTitle3.displayName = DialogPrimitive7.Title.displayName;
var StyledDescription = styled(DialogPrimitive7.Description, {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
  p: "0 24px 16px 24px",
  "& p": {
    color: "$neutral70",
    fontFamily: "$primary",
    fontSize: "$2",
    fontStyle: "normal",
    fontWeight: "$regular",
    lineHeight: "$2",
    m: 0
  }
});
var DialogDescription = React31.forwardRef((_a, ref) => {
  var props = __objRest(_a, []);
  return /* @__PURE__ */ jsx37(StyledDescription, __spreadValues({ ref }, props));
});
DialogDescription.displayName = DialogPrimitive7.Description.displayName;
var DialogPrimitives = {
  Root: Dialog2,
  Trigger: DialogTrigger,
  Content: DialogContent3,
  Header: DialogHeader,
  Footer: DialogFooter,
  Title: DialogTitle3,
  Description: DialogDescription
};

// src/FilterButton/FilterButton.tsx
import * as React32 from "react";

// src/FilterButton/BaseFilterButton.ts
var BaseFilterButton = styled(BaseButton, {
  variants: {
    variant: {
      // override `BaseButton` default variant specificity
      default: {
        background: "$neutral0",
        color: "$neutral80",
        "&:hover": {
          background: "rgba(31, 37, 44, 0.08)"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$blue40",
          background: "$neutral0"
        },
        "&:focus:not(:active)": {
          borderColor: "$blue40",
          boxShadow: "0px 0px 0px 2px $colors$blue40",
          background: "$neutral0"
        },
        "&:active": {
          boxShadow: "none",
          background: "rgba(31, 37, 44, 0.12)"
        },
        "&:focus:active": {
          background: "rgba(31, 37, 44, 0.12)"
        }
      },
      subtle: {
        backgroundColor: "$button_subtle_background_enabled",
        color: "$neutral70",
        "&:disabled": {
          backgroundColor: "rgba(255, 255, 255, 1e-05)",
          color: "$neutral40",
          pointerEvents: "none"
        },
        "&:hover": {
          background: "$button_subtle_background_hover"
        },
        "&:active": {
          background: "$button_subtle_background_active"
        },
        '&:where([data-state="open"])': {
          background: "$button_subtle_background_active"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 2px $colors$button_primary_border_focus",
          background: "$button_subtle_background_enabled"
        }
      }
    },
    filtersSelected: {
      true: {
        background: "rgba(170, 202, 255, 0.36)",
        border: "1px solid rgba(0, 61, 166, 0.24)",
        color: "$filter_button_filters_selected_color",
        "&:hover": {
          background: "rgba(170, 202, 255, 0.64)"
        },
        "&:focus": {
          background: "rgba(170, 202, 255, 0.36)"
        },
        "&:focus:not(:active)": {
          borderColor: "$blue40",
          background: "rgba(170, 202, 255, 0.36)"
        },
        "&:active": {
          background: "rgba(170, 202, 255, 0.72)"
        },
        "&:focus:active": {
          background: "rgba(170, 202, 255, 0.72)"
        }
      }
    }
  },
  compoundVariants: [
    // Hack: Force overriding the BaseButton using the compound variant.
    {
      css: {
        border: "1px solid rgba(53, 65, 78, 0.24)",
        borderRadius: "$rounded",
        padding: 0,
        display: "flex",
        flexBasis: "0",
        justifyContent: "flex-start",
        "&[data-state=open]": {
          "[data-purpose=state-indicator]": {
            transform: "rotate(180deg)"
          },
          borderColor: "$blue40",
          boxShadow: "0px 0px 0px 2px $colors$blue40"
        },
        "&:focus:not(:active)": {
          boxShadow: "0px 0px 0px 2px $colors$blue40"
        }
      }
    },
    // Ignore `filtersSelected` style when the variant is subtle
    {
      filtersSelected: true,
      variant: "subtle",
      css: {
        backgroundColor: "$button_subtle_background_enabled",
        border: "none",
        color: "$neutral70",
        "&:hover": {
          background: "$button_subtle_background_hover"
        },
        '&:where([data-state="open"])': {
          borderColor: "none !important",
          boxShadow: "none !important"
        },
        "&:focus": {
          boxShadow: "none"
        },
        "&:focus:not(:active)": {
          borderColor: "none",
          background: "none"
        }
      }
    }
  ]
});
var StyledRemoveFilter = styled("div", {
  display: "flex",
  flex: "0 1 auto",
  marginLeft: "auto",
  borderLeft: "1px solid rgba(53, 65, 78, 0.24)",
  background: "none",
  height: 20,
  padding: 0,
  paddingRight: 4,
  "& svg": {
    color: "rgba(80, 94, 113, 0.72)"
  }
});

// src/FilterButton/FilterButton.tsx
import { Fragment as Fragment8, jsx as jsx38, jsxs as jsxs25 } from "react/jsx-runtime";
var WithTooltip = ({
  children,
  tooltip
}) => tooltip ? /* @__PURE__ */ jsx38(Tooltip, { content: tooltip, children }) : children;
var FilterButton = React32.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      children,
      selectedFilters,
      iconLeft,
      icon = "fa-angle-down-small",
      filtersActive = true,
      showRemove: controlledShowRemove,
      handleRemove,
      itemMaxWidth = 100,
      maxWidth = 320,
      tooltip,
      variant = null
    } = _b, rest = __objRest(_b, [
      "children",
      "selectedFilters",
      "iconLeft",
      "icon",
      "filtersActive",
      "showRemove",
      "handleRemove",
      "itemMaxWidth",
      "maxWidth",
      "tooltip",
      "variant"
    ]);
    const filtersSelected = Boolean(
      selectedFilters && selectedFilters.length > 0 && filtersActive
    );
    const showRemove = typeof controlledShowRemove === "boolean" ? controlledShowRemove : filtersSelected;
    const labelRef = React32.useRef(null);
    const filtersRef = React32.useRef(null);
    const memoizedMaxWidth = React32.useMemo(
      () => showRemove ? maxWidth - 30 : maxWidth,
      [maxWidth, showRemove]
    );
    const [visibleFilters, setVisibleFilters] = React32.useState(selectedFilters || []);
    React32.useEffect(() => {
      setVisibleFilters(selectedFilters || []);
    }, [selectedFilters]);
    React32.useEffect(() => {
      var _a2;
      if (((_a2 = filtersRef.current) == null ? void 0 : _a2.children.length) && labelRef.current && visibleFilters.length === (selectedFilters || []).length) {
        const labelWidth = labelRef.current.getBoundingClientRect().width;
        const totalFilterWidth = filtersRef.current.getBoundingClientRect().width;
        const filters = Array.from(filtersRef.current.children);
        let freeSpace = memoizedMaxWidth - labelWidth - 32;
        if (freeSpace < totalFilterWidth) {
          freeSpace -= 30;
        }
        let visibleCount = 0;
        while (freeSpace > 0 && filters.length > 0) {
          freeSpace -= filters[0].getBoundingClientRect().width + 4;
          filters.shift();
          if (freeSpace > 0) {
            visibleCount++;
          }
        }
        if (visibleCount !== visibleFilters.length) {
          setVisibleFilters((selectedFilters || []).slice(0, visibleCount));
        }
      }
    }, [memoizedMaxWidth, selectedFilters, visibleFilters]);
    const renderButtonLabel = React32.useCallback(
      () => typeof children === "function" ? children({
        selectedFilters: selectedFilters || [],
        visibleFilters,
        filtersSelected,
        filtersActive,
        showRemove,
        ref
      }) : /* @__PURE__ */ jsxs25(Fragment8, { children: [
        children,
        Array.isArray(selectedFilters) && selectedFilters.length > 0 ? ":" : null
      ] }),
      [
        children,
        filtersSelected,
        ref,
        selectedFilters,
        showRemove,
        filtersActive,
        visibleFilters
      ]
    );
    const onRemove = React32.useCallback(
      (e) => {
        handleRemove == null ? void 0 : handleRemove(e, {
          selectedFilters: selectedFilters || [],
          visibleFilters,
          filtersSelected,
          filtersActive,
          ref
        });
      },
      [
        filtersSelected,
        handleRemove,
        ref,
        selectedFilters,
        filtersActive,
        visibleFilters
      ]
    );
    return /* @__PURE__ */ jsx38(WithTooltip, { tooltip, children: /* @__PURE__ */ jsxs25(
      BaseFilterButton,
      __spreadProps(__spreadValues({
        css: { maxWidth: maxWidth + 5 },
        ref,
        filtersSelected,
        variant
      }, rest), {
        children: [
          /* @__PURE__ */ jsxs25(
            Flex,
            {
              gap: "xs",
              css: {
                maxWidth: memoizedMaxWidth,
                padding: "4px 4px 4px 8px",
                overflowX: "hidden",
                alignItems: "center",
                justifyContent: "start"
              },
              children: [
                iconLeft && /* @__PURE__ */ jsx38(Flex, { css: { width: 20, flex: "none" }, children: /* @__PURE__ */ jsx38(Icon_default, { name: iconLeft, size: 20 }) }),
                /* @__PURE__ */ jsx38(Flex, { ref: labelRef, css: { maxWidth: memoizedMaxWidth - 55 }, children: /* @__PURE__ */ jsx38(
                  Text,
                  {
                    size: "2",
                    css: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    },
                    children: renderButtonLabel()
                  }
                ) }),
                selectedFilters && selectedFilters.length > 0 && /* @__PURE__ */ jsxs25(
                  Flex,
                  {
                    css: {
                      fontWeight: "$medium",
                      "+ svg": {
                        flexShrink: 0
                      },
                      flexShrink: 1
                    },
                    gap: "xs",
                    ref: filtersRef,
                    children: [
                      visibleFilters.map((filter, index) => /* @__PURE__ */ jsxs25(
                        Box_default,
                        {
                          css: {
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: itemMaxWidth
                          },
                          children: [
                            filter.label,
                            index < visibleFilters.length - 1 ? ", " : null
                          ]
                        },
                        filter.value
                      )),
                      selectedFilters && selectedFilters.length > visibleFilters.length && /* @__PURE__ */ jsx38(
                        Tooltip,
                        {
                          content: /* @__PURE__ */ jsx38(Flex, { flow: "column", children: selectedFilters.slice(visibleFilters.length).map((filter) => /* @__PURE__ */ jsx38(
                            Box_default,
                            {
                              css: {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                              },
                              children: filter.label
                            },
                            filter.value
                          )) }),
                          children: /* @__PURE__ */ jsxs25(Counter, { variant: "strong", children: [
                            " ",
                            visibleFilters.length > 0 ? "+" : null,
                            selectedFilters.length - visibleFilters.length
                          ] })
                        }
                      )
                    ]
                  }
                ),
                /* @__PURE__ */ jsx38(
                  Flex,
                  {
                    "data-purpose": "state-indicator",
                    css: { width: 20, flex: "none" },
                    children: /* @__PURE__ */ jsx38(Icon_default, { name: icon, size: 20 })
                  }
                )
              ]
            }
          ),
          showRemove && /* @__PURE__ */ jsx38(StyledRemoveFilter, { onPointerDown: onRemove, children: /* @__PURE__ */ jsx38(Icon_default, { name: "fa-xmark-small", size: 20 }) })
        ]
      })
    ) });
  }
);

// src/FilterType/BaseFilterType/components/BoolFilterRadioGroup.tsx
import * as React35 from "react";

// src/Radio/Radio.tsx
import React33 from "react";

// src/Radio/BaseRadio.tsx
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
var RadioContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "7px"
});
var StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: "unset",
  boxSizing: "border-box",
  userSelect: "none",
  "&::before": {
    boxSizing: "border-box"
  },
  "&::after": {
    boxSizing: "border-box"
  },
  width: "14px",
  height: "14px",
  alignItems: "center",
  appearance: "none",
  display: "inline-flex",
  justifyContent: "center",
  lineHeight: "1",
  margin: "0",
  outline: "none",
  padding: "0",
  textDecoration: "none",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  border: "1px solid $neutral50",
  borderRadius: "$rounded-full",
  overflow: "hidden",
  background: "$neutral0",
  "&:hover": {
    background: "linear-gradient(0deg, rgba(31, 37, 44, 0.08), rgba(31, 37, 44, 0.08)), $neutral0"
  },
  "&:focus": {
    outline: "none",
    borderColor: "$blue50",
    boxShadow: "0px 0px 0px 2px $colors$blue30"
  },
  '&[data-state="checked"]': {
    background: "$blue50",
    borderColor: "$blue50",
    "&:hover": {
      background: "$blue60",
      borderColor: "$blue60"
    },
    "&:disabled": {
      background: "$neutral30",
      borderColor: "$neutral30",
      "&:hover": {
        background: "$neutral30",
        borderColor: "$neutral30"
      }
    }
  },
  "&:disabled": {
    borderColor: "$neutral30",
    backgroundColor: "$neutral10",
    "&:hover": {
      background: "$neutral10"
    }
  }
});

// src/Radio/BaseRadioIndicator.tsx
import * as RadioGroupPrimitive2 from "@radix-ui/react-radio-group";
var StyledIndicator2 = styled(RadioGroupPrimitive2.Indicator, {
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  width: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: "5px",
    height: "5px",
    borderRadius: "$rounded-full",
    backgroundColor: "$neutral0"
  },
  "&[data-disabled]::after": {
    backgroundColor: "$neutral50"
  }
});

// src/Radio/Radio.tsx
import { jsx as jsx39, jsxs as jsxs26 } from "react/jsx-runtime";
var Radio = React33.forwardRef((props, forwardedRef) => {
  var _a;
  const uid = React33.useId();
  const elementId = (_a = props.id) != null ? _a : uid;
  return /* @__PURE__ */ jsxs26(RadioContainer, { children: [
    /* @__PURE__ */ jsx39(StyledRadio, __spreadProps(__spreadValues({}, props), { id: elementId, ref: forwardedRef, children: /* @__PURE__ */ jsx39(StyledIndicator2, {}) })),
    props.label && /* @__PURE__ */ jsx39(
      Label,
      {
        htmlFor: elementId,
        optional: props.optional,
        disabled: props.disabled,
        children: props.label
      }
    )
  ] });
});

// src/Radio/RadioGroup.tsx
import * as React34 from "react";

// src/Radio/BaseRadioGroup.tsx
import * as RadioGroupPrimitive3 from "@radix-ui/react-radio-group";
var BaseRadioGroup = styled(RadioGroupPrimitive3.Root, {
  display: "flex",
  gap: "7px",
  variants: {
    orientation: {
      horizontal: {
        flexDirection: "row"
      },
      vertical: {
        flexDirection: "column"
      }
    }
  },
  defaultVariants: {
    orientation: "horizontal"
  }
});

// src/Radio/RadioGroup.tsx
import { jsx as jsx40 } from "react/jsx-runtime";
var RadioGroup = React34.forwardRef((props, forwardedRef) => /* @__PURE__ */ jsx40(BaseRadioGroup, __spreadProps(__spreadValues({}, props), { ref: forwardedRef, children: props.children })));

// src/FilterType/BaseFilterType/StyledFilterType.tsx
var filterTypeButtonTokens = {
  $$filterButtonHeight: "28px"
};
var StyledFilterButton = styled(
  FilterButton,
  {
    letterSpacing: "$0",
    height: "$$filterButtonHeight",
    "~ button": {
      height: "$$filterButtonHeight"
    },
    "& strong": {
      fontWeight: "$medium"
    }
  },
  filterTypeButtonTokens
);
var StyledBoolRadioGroup = styled(RadioGroup, {
  width: 320,
  [`& ${RadioContainer}`]: {
    py: "$1",
    px: "$0"
  },
  [`& ${DropdownMenuDivider}`]: {
    margin: "0 -$3"
  }
});
var StyledSingleNumberValueInput = styled(TextField, {
  width: "100%"
});
var noDropdownItemHighlightStyles = {
  "& [role=menuitem]": {
    "&[data-highlighted]": {
      backgroundColor: "transparent"
    },
    "&:active": {
      backgroundColor: "unset"
    }
  }
};
var publicFilterTypeBaseComponents = {
  Styled: {
    FilterButton: StyledFilterButton,
    BoolRadioGroup: StyledBoolRadioGroup,
    SingleNumberValueInput: StyledSingleNumberValueInput
  }
};

// src/FilterType/BaseFilterType/components/BoolFilterRadioGroup.tsx
import { jsx as jsx41, jsxs as jsxs27 } from "react/jsx-runtime";
var BoolFilterRadioGroup = React35.forwardRef(({ value, onChange, options, error, hint }, forwardedRef) => {
  const hintState = !!error && typeof error === "string" ? "error" : "default";
  const hintLabel = hintState === "error" ? error : hint == null ? void 0 : hint.label;
  const hasHint = !!hintLabel;
  return /* @__PURE__ */ jsxs27(Flex, { flow: "column", gap: "xs", children: [
    /* @__PURE__ */ jsx41(
      StyledBoolRadioGroup,
      {
        ref: forwardedRef,
        value: String(value),
        onValueChange: onChange,
        orientation: "vertical",
        children: options.map(
          (opt, i) => opt.type === "divider" ? /* @__PURE__ */ jsx41(DropdownMenuDivider, {}, `divider-${i}`) : /* @__PURE__ */ jsx41(
            Radio,
            {
              value: String(opt.value),
              label: opt.label
            },
            String(opt.value)
          )
        )
      }
    ),
    hasHint && /* @__PURE__ */ jsx41(Hint, { label: hintLabel, variant: hintState })
  ] });
});
BoolFilterRadioGroup.displayName = "BoolFilterRadioGroup";

// src/FilterType/BaseFilterType/components/FilterSearchButton.tsx
import { t as t9 } from "@kandji-inc/nectar-i18n";
import * as React37 from "react";

// src/FilterType/BaseFilterType/components/FilterSearchMenu.tsx
import { t as t8 } from "@kandji-inc/nectar-i18n";
import * as React36 from "react";
import { jsx as jsx42, jsxs as jsxs28 } from "react/jsx-runtime";
var FilterSearchMenu = React36.forwardRef((menuProps, forwardedRef) => {
  const [searchInputValue, setSearchInputValue] = React36.useState("");
  const scopeProps = useSelectContext();
  const { selectProps, searchProps, refs, tooltipProps } = scopeProps;
  const { getOptionProps, searchFilteredOptions, isShowNoOptionsMessage } = selectProps;
  const {
    getSearchInputProps,
    searchInputId,
    setSearchValue: setSelectSearchValue
  } = searchProps;
  const _a = (getSearchInputProps == null ? void 0 : getSearchInputProps()) || {}, { onChange: onSelectSearchInputChange } = _a, selectSearchInputProps = __objRest(_a, ["onChange"]);
  const { optionsMenuRef } = refs;
  React36.useEffect(
    () => () => {
      setSelectSearchValue("");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on unmount
    []
  );
  const _b = menuProps, { searchFieldProps, css: css3 } = _b, restMenuProps = __objRest(_b, ["searchFieldProps", "css"]);
  return /* @__PURE__ */ jsx42(
    publicSelectBaseComponents.Styled.Menu,
    __spreadProps(__spreadValues({
      ref: optionsMenuRef,
      css: __spreadProps(__spreadValues({}, css3 || {}), {
        overflow: "hidden"
      }),
      size: "sm"
    }, restMenuProps), {
      children: /* @__PURE__ */ jsxs28(TooltipProvider, { children: [
        /* @__PURE__ */ jsx42(
          TextField,
          __spreadValues(__spreadProps(__spreadValues({}, selectSearchInputProps), {
            py2: true,
            px3: true,
            autoFocus: true,
            id: searchInputId,
            value: searchInputValue,
            icon: "magnifying-glass",
            placeholder: t8("Search filters"),
            maxLength: publicSelectUtils.constants.DEFAULT_SEARCH_INPUT_MAX_LENGTH,
            onChange: (e) => {
              onSelectSearchInputChange == null ? void 0 : onSelectSearchInputChange(e);
              setSearchInputValue(e.target.value);
            },
            css: {
              position: "sticky",
              top: 0,
              zIndex: 1,
              marginBottom: "$1"
            }
          }), searchFieldProps)
        ),
        /* @__PURE__ */ jsx42(
          ScrollArea,
          {
            scrollbarPosition: "right",
            css: {
              height: "256px"
            },
            children: isShowNoOptionsMessage ? /* @__PURE__ */ jsx42(publicSelectBaseComponents.Styled.NoOptions, { children: publicSelectUtils.constants.DEFAULT_NO_OPTIONS_FOUND_TEXT }) : searchFilteredOptions.map((option, index) => {
              const { key, option: opt } = getOptionProps({
                option,
                index
              });
              const isSection = opt.section && typeof opt.section === "string" && Array.isArray(opt.options);
              if (isSection) {
                const isLastSection = index === searchFilteredOptions.length - 1;
                const hasSectionLabel = opt.showSectionLabel === true;
                return /* @__PURE__ */ jsxs28(
                  publicSelectBaseComponents.Styled.MenuSection,
                  {
                    ref: forwardedRef,
                    noBottomDivider: hasSectionLabel || isLastSection || isShowNoOptionsMessage,
                    "aria-label": !hasSectionLabel ? opt.section : publicSelectUtils.constants.NO_DATA_ATTR_KEY,
                    "data-section": opt.section,
                    children: [
                      hasSectionLabel && /* @__PURE__ */ jsx42(
                        publicSelectBaseComponents.Styled.MenuSectionLabel,
                        {
                          "data-section-label": opt.section,
                          children: opt.section
                        }
                      ),
                      opt.options.map(
                        (sectionOption, sectionIndex) => {
                          const { key: sectionKey, option: sectionOpt } = getOptionProps({
                            option: sectionOption,
                            index: sectionIndex
                          });
                          return /* @__PURE__ */ jsx42(
                            publicSelectBaseComponents.OptionItem,
                            {
                              value: sectionOpt.value,
                              disabled: sectionOpt.disabled,
                              icon: sectionOpt.icon,
                              iconSize: sectionOpt.iconSize,
                              richLabel: sectionOpt.richLabel,
                              tooltip: mergeTooltipProps(
                                sectionOpt.tooltip,
                                tooltipProps
                              ),
                              children: sectionOpt.label
                            },
                            sectionKey
                          );
                        }
                      )
                    ]
                  },
                  key
                );
              }
              return /* @__PURE__ */ jsx42(
                publicSelectBaseComponents.OptionItem,
                {
                  value: opt.value,
                  disabled: opt.disabled,
                  icon: opt.icon,
                  iconSize: opt.iconSize,
                  richLabel: opt.richLabel,
                  tooltip: mergeTooltipProps(
                    opt.tooltip,
                    tooltipProps
                  ),
                  css: {
                    pl: "$3"
                  },
                  children: opt.label
                },
                key
              );
            })
          }
        )
      ] })
    })
  );
});
FilterSearchMenu.displayName = "FilterSearchMenu";

// src/FilterType/BaseFilterType/components/FilterSearchButton.tsx
import { jsx as jsx43 } from "react/jsx-runtime";
var FilterSearchButton = React37.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      onFilterSelect,
      options,
      value = "",
      text = t9("Add filter"),
      icon = "fa-plus-minus-small",
      asButton = "filter-button",
      tooltipProps = {},
      buttonProps = {},
      menuProps = {},
      testId
    } = _b, rest = __objRest(_b, [
      "onFilterSelect",
      "options",
      "value",
      "text",
      "icon",
      "asButton",
      "tooltipProps",
      "buttonProps",
      "menuProps",
      "testId"
    ]);
    const _a2 = rest, { components = null, componentCss = null } = _a2, restProps = __objRest(_a2, ["components", "componentCss"]);
    const consumer = React37.useMemo(() => {
      const _a3 = componentCss || {
        root: {}
      }, { root: rootCss = {} } = _a3, restCss = __objRest(_a3, ["root"]);
      return {
        components: components || {},
        componentCss: restCss,
        rootCss
      };
    }, [components, componentCss]);
    const restContext = React37.useMemo(
      () => ({
        tooltipProps
      }),
      [tooltipProps]
    );
    return /* @__PURE__ */ jsx43(
      Select,
      __spreadProps(__spreadValues({
        ref: forwardedRef,
        value,
        options,
        onChange: (val, optionsMeta) => {
          onFilterSelect(val, optionsMeta);
        },
        components: __spreadValues({
          Menu: (props) => /* @__PURE__ */ jsx43(FilterSearchMenu, __spreadValues(__spreadValues({}, props), menuProps))
        }, consumer.components),
        componentCss: __spreadValues({
          root: __spreadValues(__spreadProps(__spreadValues({}, filterTypeButtonTokens), {
            "[data-as-element]": {
              height: "$$filterButtonHeight"
            },
            '[data-as-element="button"]': {
              width: text ? "auto" : "$$filterButtonHeight",
              padding: "$1"
            }
          }), consumer.rootCss)
        }, consumer.componentCss),
        searchable: true,
        testId,
        restContext
      }, restProps), {
        children: ({ selectProps, refs, handlers }) => {
          const { isUnselectable } = selectProps;
          const { composedTriggerRefs } = refs;
          const { onSelectTriggerPointerDown } = handlers;
          return asButton === "button" ? /* @__PURE__ */ jsx43(
            Button,
            __spreadProps(__spreadValues({
              ref: composedTriggerRefs,
              variant: "default",
              icon: { name: icon, position: "right" },
              disabled: isUnselectable,
              "aria-haspopup": "listbox",
              "data-select-type": "select",
              "data-as-element": "button"
            }, buttonProps), {
              children: text
            })
          ) : /* @__PURE__ */ jsx43(
            Button,
            {
              ref: composedTriggerRefs,
              variant: "default",
              disabled: isUnselectable,
              onPointerDown: onSelectTriggerPointerDown,
              icon: {
                name: icon,
                position: "right"
              },
              css: {
                p: "4px 4px 4px 8px"
              },
              "aria-haspopup": "listbox",
              "data-select-type": "select",
              "data-as-element": "filter-button",
              children: text
            }
          );
        }
      })
    );
  }
);
FilterSearchButton.displayName = "FilterSearchButton";

// src/FilterType/BaseFilterType/components/OperatorFilterSelect.tsx
import * as React38 from "react";
import { t as t11 } from "@kandji-inc/nectar-i18n";

// src/FilterType/filterTypeUtils.ts
import { t as t10 } from "@kandji-inc/nectar-i18n";
import { z } from "zod";
var BOOLEAN_TRUE_STRING = "true";
var BOOLEAN_FALSE_STRING = "false";
var REMOVE_APPLIED_FILTER_ACTION = "remove_applied_filter";
var ADD_APPLIED_FILTER_ACTION = "add_applied_filter";
var boolFilterSchema = z.object({
  key: z.string().min(1),
  label: z.string().min(1),
  value: z.string().or(z.boolean()).or(z.undefined())
}).superRefine((data, ctx) => {
  if (typeof (data == null ? void 0 : data.value) === "undefined") {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["value"],
      params: {
        key: "value",
        data: data == null ? void 0 : data.value,
        error: "empty_value"
      }
    });
  }
});
function validateBoolFilterSchema(filter) {
  const res = boolFilterSchema.safeParse(filter);
  if (!res.success) {
    const errorMap = Object.fromEntries(
      res.error.issues.filter((issue) => issue.code === "custom").map((issue) => [
        issue.params.key,
        {
          error: issue.params.error,
          message: issue.message,
          data: issue.params.data
        }
      ])
    );
    return [null, errorMap];
  }
  return [filter, null];
}
var boolToStringFilterOptionSchema = z.object({
  value: z.string(),
  label: z.string()
}).nullable().catch(() => null);
var stringToBoolFilterOptionSchema = z.object({
  value: z.literal(BOOLEAN_TRUE_STRING).transform(() => true).or(z.literal(BOOLEAN_FALSE_STRING).transform(() => false)),
  label: z.string()
}).nullable().catch(() => null);
var isNullOperator = (operator) => operator === "is_null";
var isNotNullOperator = (operator) => operator === "is_not_null";
var isNullOrNotNullOperator = (operator) => isNullOperator(operator) || isNotNullOperator(operator);
var isInBetweenOperator = (operator) => operator === "ib";
var getNullValue = ({ is_null }) => is_null && is_null === "true" ? "is_null" : "is_not_null";
function validateStringFilterSchema(filter) {
  const res = stringFilterSchema.safeParse(filter);
  if (!res.success) {
    const errorMap = Object.fromEntries(
      res.error.issues.filter((issue) => issue.code === "custom").map((issue) => [
        issue.params.key,
        {
          error: issue.params.error,
          message: issue.message,
          data: issue.params.data
        }
      ])
    );
    return [null, errorMap];
  }
  return [filter, null];
}
var stringFilterSchema = z.object({
  key: z.string().min(1),
  label: z.string().min(1),
  operator: z.string().min(1),
  value: z.array(z.string().min(1)).or(z.string().min(1))
}).superRefine((data, ctx) => {
  var _a;
  if (!data.operator) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["operator"],
      params: {
        key: "operator",
        data: data.operator,
        error: "empty_operator"
      }
    });
  }
  if (!((_a = data == null ? void 0 : data.value) == null ? void 0 : _a.length) && !isNullOrNotNullOperator(data == null ? void 0 : data.operator)) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["value"],
      params: {
        key: "value",
        data: data == null ? void 0 : data.value,
        error: "empty_value"
      }
    });
  }
});
var baseNumFilterSchema = z.object({
  key: z.string().min(1),
  label: z.string().min(1),
  operator: z.string().min(1)
});
var numFilterSingleSchema = baseNumFilterSchema.extend({
  value: z.any()
}).superRefine((data, ctx) => {
  if (!data.operator) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["operator"],
      params: {
        key: "operator",
        data: data.operator,
        error: "empty_operator"
      }
    });
  }
  if (!isNullOrNotNullOperator(data == null ? void 0 : data.operator)) {
    const res = z.coerce.number().safeParse(data.value);
    z.coerce.string().safeParse(data.value);
    if (!res.success || data.value === "") {
      ctx.addIssue({
        code: "custom",
        message: t10("Required"),
        path: ["value"],
        params: {
          key: "value",
          data: data.value,
          error: "empty_value"
        }
      });
    }
  }
});
var numFilterMultiSchema = baseNumFilterSchema.extend({
  value: z.array(z.coerce.number())
}).superRefine((data, ctx) => {
  var _a;
  if (!data.operator) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["operator"],
      params: {
        key: "operator",
        data: data.operator,
        error: "empty_operator"
      }
    });
  }
  if (!((_a = data == null ? void 0 : data.value) == null ? void 0 : _a.length) && !isNullOrNotNullOperator(data == null ? void 0 : data.operator)) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["value"],
      params: {
        key: "value",
        data: data == null ? void 0 : data.value,
        error: "empty_value"
      }
    });
  }
});
function validateNumFilterSchema(filter) {
  const res = Array.isArray(filter.value) ? numFilterMultiSchema.safeParse(filter) : numFilterSingleSchema.safeParse(filter);
  if (!res.success) {
    const errorMap = Object.fromEntries(
      res.error.issues.filter((issue) => issue.code === "custom").map((issue) => [
        issue.params.key,
        {
          error: issue.params.error,
          message: issue.message,
          data: issue.params.data
        }
      ])
    );
    return [null, errorMap];
  }
  return [filter, null];
}
var issueCustom = (path, error, message, data) => ({
  code: "custom",
  message,
  path: [path],
  params: {
    key: path,
    data,
    error
  }
});
var versionCompare = (a, b) => {
  const left = a.split(".").map(Number);
  const right = b.split(".").map(Number);
  let result = 0;
  left.forEach((item, idx) => {
    if (left[idx] > right[idx]) {
      result = 1;
      return false;
    } else if (left[idx] < right[idx]) {
      result = -1;
      return false;
    }
  });
  return result;
};
var versionFilterPattern = /\d+(\.\d+)+|\d+/;
var versionFilterSchema = baseNumFilterSchema.extend({
  value: z.array(z.any()).or(z.string())
}).superRefine((data, ctx) => {
  var _a;
  if (!data.operator) {
    ctx.addIssue(
      issueCustom("operator", "empty_operator", t10("Required"), data.operator)
    );
  }
  if (data.operator === "ib") {
    let hasIssue = false;
    if (!z.string().min(1).safeParse(data.value[0]).success) {
      ctx.addIssue(
        issueCustom(
          "value_from",
          "empty_value",
          t10("Required"),
          data.value[0]
        )
      );
      hasIssue = true;
    } else {
      const match = data.value[0].match(versionFilterPattern);
      if (!match || match[0] !== data.value[0]) {
        ctx.addIssue(
          issueCustom(
            "value_from",
            "invalid_value",
            t10("Invalid version format"),
            data.value[0]
          )
        );
        hasIssue = true;
      }
    }
    if (!z.string().min(1).safeParse(data.value[1]).success) {
      ctx.addIssue(
        issueCustom("value_to", "empty_value", t10("Required"), data.value[1])
      );
      hasIssue = true;
    } else {
      const match = data.value[1].match(versionFilterPattern);
      if (!match || match[0] !== data.value[1]) {
        ctx.addIssue(
          issueCustom(
            "value_to",
            "invalid_value",
            t10("Invalid version format"),
            data.value[1]
          )
        );
        hasIssue = true;
      }
    }
    if (hasIssue === false && versionCompare(data.value[0], data.value[1]) === 1) {
      ctx.addIssue(
        issueCustom(
          "value_from",
          "invalid_value",
          t10("Enter minimum value"),
          data.value[0]
        )
      );
      ctx.addIssue(
        issueCustom(
          "value_to",
          "invalid_value",
          t10("Enter maximum value"),
          data.value[1]
        )
      );
    }
  } else if (!((_a = data == null ? void 0 : data.value) == null ? void 0 : _a.length) && !isNullOrNotNullOperator(data == null ? void 0 : data.operator)) {
    ctx.addIssue(
      issueCustom("value", "empty_value", t10("Required"), data.value)
    );
  } else if (["lt", "lte", "gt", "gte"].includes(data == null ? void 0 : data.operator)) {
    const match = data.value[0].match(versionFilterPattern);
    if (!match || match[0] !== data.value[0]) {
      ctx.addIssue(
        issueCustom(
          "value",
          "invalid_value",
          t10("Invalid version format"),
          data.value
        )
      );
    }
  }
});
function validateVersionFilterSchema(filter) {
  const res = versionFilterSchema.safeParse(filter);
  if (!res.success) {
    const errorMap = Object.fromEntries(
      res.error.issues.filter((issue) => issue.code === "custom").map((issue) => [
        issue.params.key,
        {
          error: issue.params.error,
          message: issue.message,
          data: issue.params.data
        }
      ])
    );
    return [null, errorMap];
  }
  return [filter, null];
}
var baseEnumFilterSchema = z.object({
  key: z.string().min(1),
  label: z.string().min(1),
  operator: z.string().min(1),
  value: z.union([z.string(), z.array(z.string().min(1)).min(1)])
});
var enumFilterSingleSchema = baseEnumFilterSchema.extend({
  value: z.string()
}).superRefine((data, ctx) => {
  if (!data.operator) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["operator"],
      params: {
        key: "operator",
        data: data.operator,
        error: "empty_operator"
      }
    });
  }
  if (!(data == null ? void 0 : data.value) && !isNullOrNotNullOperator(data == null ? void 0 : data.operator)) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["value"],
      params: {
        key: "value",
        data: data == null ? void 0 : data.value,
        error: "empty_value"
      }
    });
  }
});
var enumFilterMultiSchema = baseEnumFilterSchema.extend({
  value: z.array(z.string().min(1))
}).superRefine((data, ctx) => {
  var _a;
  if (!data.operator) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["operator"],
      params: {
        key: "operator",
        data: data.operator,
        error: "empty_operator"
      }
    });
  }
  if (!((_a = data == null ? void 0 : data.value) == null ? void 0 : _a.length) && !isNullOrNotNullOperator(data == null ? void 0 : data.operator)) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["value"],
      params: {
        key: "value",
        data: data == null ? void 0 : data.value,
        error: "empty_value"
      }
    });
  }
});
var enumFilterSchemas = enumFilterSingleSchema.or(enumFilterMultiSchema);
function validateEnumFilterSchema(filter) {
  const res = enumFilterSchemas.safeParse(filter);
  if (!res.success) {
    const errorMap = Object.fromEntries(
      res.error.issues.filter((issue) => issue.code === "custom").map((issue) => [
        issue.params.key,
        {
          error: issue.params.error,
          message: issue.message,
          data: issue.params.data
        }
      ])
    );
    return [null, errorMap];
  }
  return [res.data, null];
}
var dateFilterSchema = z.object({
  key: z.string().min(1),
  label: z.string(),
  operator: z.string().optional(),
  value: z.string().min(1),
  dateRange: z.object({
    from: z.union([z.date(), z.string(), z.null()]),
    to: z.union([z.date(), z.string(), z.null()])
  }),
  selectedDate: z.union([z.date(), z.string(), z.null()])
}).superRefine((data, ctx) => {
  const constants = constantsFactory();
  const isCustomDateRange = data.value === constants.DATE_ENUM.custom_date_range.value;
  const isCustomRelativeDateRange = data.value === constants.DATE_ENUM.custom_relative_date_range.value;
  const isInBetweenOperator2 = data.operator === constants.DATE_OPERATOR.ib.value;
  const hasValue = !!data.value;
  const hasOperator = !!data.operator;
  const hasSelectedDate = !!data.selectedDate;
  const hasDateRangeFrom = !!data.dateRange.from;
  const hasDateRangeTo = !!data.dateRange.to;
  const hasSelectedPeriod = typeof data.selectedDate === "string" && data.selectedDate.split(" ")[0] !== "";
  const hasSelectedUnit = typeof data.selectedDate === "string" && data.selectedDate.split(" ")[1] !== "";
  const hasRelativeDateRangeFrom = typeof data.dateRange.from === "string" && data.dateRange.from.split(" ")[0] !== "" && data.dateRange.from.split(" ")[1] !== "";
  const hasRelativeDateRangeTo = typeof data.dateRange.to === "string" && data.dateRange.to.split(" ")[0] !== "" && data.dateRange.to.split(" ")[1] !== "";
  if (!hasValue && !hasOperator) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["value"],
      params: {
        key: "value",
        data,
        error: "empty_value"
      }
    });
  }
  if ((isCustomDateRange || isCustomRelativeDateRange) && !hasOperator) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["operator"],
      params: {
        key: "operator",
        data,
        error: "empty_operator"
      }
    });
  }
  if (isCustomDateRange && hasOperator && !isInBetweenOperator2 && !hasSelectedDate) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["selectedDate"],
      params: {
        key: "selectedDate",
        data,
        error: "empty_selected_date"
      }
    });
  }
  if (isCustomDateRange && hasOperator && isInBetweenOperator2 && !hasDateRangeFrom) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["dateRange", "from"],
      params: {
        key: "dateRange.from",
        data,
        error: "empty_date_range"
      }
    });
  }
  if (isCustomDateRange && hasOperator && isInBetweenOperator2 && !hasDateRangeTo) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["dateRange", "to"],
      params: {
        key: "dateRange.to",
        data,
        error: "empty_date_range"
      }
    });
  }
  if (isCustomRelativeDateRange && hasOperator && !isInBetweenOperator2 && !hasSelectedPeriod) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["selectedDate", "period"],
      params: {
        key: "selectedDate",
        data,
        error: "empty_selected_date"
      }
    });
  }
  if (isCustomRelativeDateRange && hasOperator && !isInBetweenOperator2 && !hasSelectedUnit) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["selectedDate", "unit"],
      params: {
        key: "selectedDate",
        data,
        error: "empty_selected_date"
      }
    });
  }
  if (isCustomRelativeDateRange && isInBetweenOperator2 && !hasRelativeDateRangeFrom) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["dateRange", "from"],
      params: {
        key: "dateRange.from",
        data,
        error: "empty_date_range"
      }
    });
  }
  if (isCustomRelativeDateRange && isInBetweenOperator2 && !hasRelativeDateRangeTo) {
    ctx.addIssue({
      code: "custom",
      message: t10("Required"),
      path: ["dateRange", "to"],
      params: {
        key: "dateRange.to",
        data,
        error: "empty_date_range"
      }
    });
  }
});
function validateDateFilterSchema(filter) {
  const res = dateFilterSchema.safeParse(filter);
  if (!res.success) {
    const errorMap = Object.fromEntries(
      res.error.issues.filter((issue) => issue.code === "custom").map((issue) => [
        issue.params.key,
        {
          error: issue.params.error,
          message: issue.message,
          data: issue.params.data
        }
      ])
    );
    return [null, errorMap];
  }
  return [res.data, null];
}
function isDateQuickFilterValue(value) {
  return constantsFactory().DATE_ENUM_QUICK_FILTERS_OPTIONS.some(
    (quickOpt) => quickOpt.value === value
  );
}
var constantsFactory = () => {
  const BOOLEAN_OPERATOR_OPTIONS = [
    {
      label: t10("true"),
      value: "eq"
    },
    {
      label: t10("false"),
      value: "dne"
    }
  ];
  const BOOLEAN_OPERATOR_LABEL_MAP = {
    eq: t10("true"),
    dne: t10("false")
  };
  const NULLABLE_OPERATOR_OPTIONS = [
    {
      label: t10("is blank"),
      value: "is_null",
      meta: {
        multi: false
      }
    },
    {
      label: t10("is not blank"),
      value: "is_not_null",
      meta: {
        multi: false
      }
    }
  ];
  const STRING_OPERATOR_EQUALS_OPTIONS = [
    {
      label: t10("equals"),
      value: "in",
      meta: {
        multi: true
      }
    },
    {
      label: t10("does not equal"),
      value: "not_in",
      meta: {
        multi: true
      }
    }
  ];
  const STRING_OPERATOR_CONTAINS_OPTIONS = [
    {
      label: t10("contains"),
      value: "like",
      meta: {
        multi: true
      }
    },
    {
      label: t10("does not contain"),
      value: "not_like",
      meta: {
        multi: true
      }
    }
  ];
  const STRING_OPERATOR_OPTIONS = [
    {
      section: "equals",
      options: [...STRING_OPERATOR_EQUALS_OPTIONS]
    },
    {
      section: "contains",
      options: [...STRING_OPERATOR_CONTAINS_OPTIONS]
    },
    ...NULLABLE_OPERATOR_OPTIONS
  ];
  const STRING_OPERATOR_OPTIONS_NON_NULLABLE = [
    {
      section: "equals",
      options: [...STRING_OPERATOR_EQUALS_OPTIONS]
    },
    {
      section: "contains",
      options: [...STRING_OPERATOR_CONTAINS_OPTIONS]
    }
  ];
  const STRING_OPERATOR_LABEL_MAP = {
    in: t10("equals"),
    not_in: t10("does not equal"),
    like: t10("contains"),
    not_like: t10("does not contain"),
    is_null: t10("is blank"),
    is_not_null: t10("is not blank")
  };
  const NUM_OPERATOR_EQUALS_OPTIONS = [
    {
      label: t10("equals"),
      value: "in",
      meta: {
        multi: true
      }
    },
    {
      label: t10("does not equal"),
      value: "not_in",
      meta: {
        multi: true
      }
    }
  ];
  const NUM_OPERATOR_NOT_EQUALS_OPTIONS = [
    {
      label: t10("greater than"),
      value: "gt",
      meta: {
        multi: false
      }
    },
    {
      label: t10("less than"),
      value: "lt",
      meta: {
        multi: false
      }
    },
    {
      label: t10("greater than or equal to"),
      value: "gte",
      meta: {
        multi: false
      }
    },
    {
      label: t10("less than or equal to"),
      value: "lte",
      meta: {
        multi: false
      }
    }
  ];
  const NUM_OPERATOR_OPTIONS = [
    {
      section: "equals",
      options: [...NUM_OPERATOR_EQUALS_OPTIONS]
    },
    {
      section: "not_equals",
      options: [...NUM_OPERATOR_NOT_EQUALS_OPTIONS]
    },
    ...NULLABLE_OPERATOR_OPTIONS
  ];
  const NUM_OPERATOR_LABEL_MAP = {
    in: t10("equals"),
    not_in: t10("does not equal"),
    gt: t10("greater than"),
    lt: t10("less than"),
    gte: t10("greater than or equal to"),
    lte: t10("less than or equal to"),
    is_null: t10("is blank"),
    is_not_null: t10("is not blank")
  };
  const VERSION_OPERATOR_IN_BETWEEN_OPTIONS = [
    {
      label: t10("is between"),
      value: "ib",
      meta: {
        multi: false
      }
    }
  ];
  const VERSION_OPERATOR_OPTIONS = [
    {
      section: "equals",
      options: [...NUM_OPERATOR_EQUALS_OPTIONS]
    },
    {
      section: "contains",
      options: [...STRING_OPERATOR_CONTAINS_OPTIONS]
    },
    {
      section: "not_equals",
      options: [...NUM_OPERATOR_NOT_EQUALS_OPTIONS]
    },
    {
      section: "between",
      options: [...VERSION_OPERATOR_IN_BETWEEN_OPTIONS]
    },
    ...NULLABLE_OPERATOR_OPTIONS
  ];
  const VERSION_OPERATOR_LABEL_MAP = {
    like: t10("contains"),
    not_like: t10("does not contain"),
    eq: t10("equals"),
    dne: t10("does not equal"),
    gt: t10("greater than"),
    lt: t10("less than"),
    gte: t10("greater than or equal to"),
    lte: t10("less than or equal to"),
    ib: t10("is between"),
    is_null: t10("is blank"),
    is_not_null: t10("is not blank")
  };
  const ENUM_SINGLE_IN_OPTIONS = [
    {
      label: t10("is one of"),
      value: "in"
    },
    {
      label: t10("is not one of"),
      value: "not_in"
    }
  ];
  const ENUM_SINGLE_OPERATOR_OPTIONS = [
    {
      section: "in",
      options: [...ENUM_SINGLE_IN_OPTIONS]
    },
    ...NULLABLE_OPERATOR_OPTIONS
  ];
  const ENUM_OPERATOR_LABEL_MAP = {
    in: t10("is one of"),
    not_in: t10("is not one of")
  };
  const DATE_OPERATOR = {
    eq: {
      label: t10("is"),
      value: "eq"
    },
    dne: {
      label: t10("is not"),
      value: "dne"
    },
    gt: {
      label: t10("after"),
      value: "gt"
    },
    lt: {
      label: t10("before"),
      value: "lt"
    },
    gte: {
      label: t10("on or after"),
      value: "gte"
    },
    lte: {
      label: t10("on or before"),
      value: "lte"
    },
    ib: {
      label: t10("is between"),
      value: "ib"
    }
  };
  const DATE_OPERATOR_OPTIONS = [
    {
      label: DATE_OPERATOR.eq.label,
      value: DATE_OPERATOR.eq.value
    },
    {
      label: DATE_OPERATOR.dne.label,
      value: DATE_OPERATOR.dne.value
    },
    {
      label: DATE_OPERATOR.gt.label,
      value: DATE_OPERATOR.gt.value
    },
    {
      label: DATE_OPERATOR.lt.label,
      value: DATE_OPERATOR.lt.value
    },
    {
      label: DATE_OPERATOR.gte.label,
      value: DATE_OPERATOR.gte.value
    },
    {
      label: DATE_OPERATOR.lte.label,
      value: DATE_OPERATOR.lte.value
    },
    {
      label: DATE_OPERATOR.ib.label,
      value: DATE_OPERATOR.ib.value
    }
  ];
  const DATE_OPERATOR_LABEL_MAP = {
    [DATE_OPERATOR.eq.value]: DATE_OPERATOR.eq.label,
    [DATE_OPERATOR.dne.value]: DATE_OPERATOR.dne.label,
    [DATE_OPERATOR.gt.value]: DATE_OPERATOR.gt.label,
    [DATE_OPERATOR.lt.value]: DATE_OPERATOR.lt.label,
    [DATE_OPERATOR.gte.value]: DATE_OPERATOR.gte.label,
    [DATE_OPERATOR.lte.value]: DATE_OPERATOR.lte.label,
    [DATE_OPERATOR.ib.value]: DATE_OPERATOR.ib.label
  };
  const DATE_QUICK_FILTER_DEFAULT_OPTION = {
    value: "in_the",
    label: t10("in the")
  };
  const DATE_ENUM = {
    last_24_hours: {
      label: t10("Last 24 hours"),
      value: "last_24_hours"
    },
    last_48_hours: {
      label: t10("Last 48 hours"),
      value: "last_48_hours"
    },
    last_7_days: {
      label: t10("Last 7 days"),
      value: "last_7_days"
    },
    last_30_days: {
      label: t10("Last 30 days"),
      value: "last_30_days"
    },
    custom_relative_date_range: {
      label: t10("Custom relative date"),
      value: "custom_relative_date_range"
    },
    custom_date_range: {
      label: t10("Custom date range"),
      value: "custom_date_range"
    }
  };
  const DATE_ENUM_QUICK_FILTERS_OPTIONS = [
    {
      label: DATE_ENUM.last_24_hours.label,
      value: DATE_ENUM.last_24_hours.value
    },
    {
      label: DATE_ENUM.last_48_hours.label,
      value: DATE_ENUM.last_48_hours.value
    },
    {
      label: DATE_ENUM.last_7_days.label,
      value: DATE_ENUM.last_7_days.value
    },
    {
      label: DATE_ENUM.last_30_days.label,
      value: DATE_ENUM.last_30_days.value
    }
  ];
  const DATE_ENUM_RELATIVE_CUSTOM_OPTIONS = [
    {
      label: DATE_ENUM.custom_relative_date_range.label,
      value: DATE_ENUM.custom_relative_date_range.value
    }
  ];
  const DATE_ENUM_CUSTOM_OPTIONS = [
    {
      label: DATE_ENUM.custom_date_range.label,
      value: DATE_ENUM.custom_date_range.value
    }
  ];
  const DATE_ENUM_OPTIONS = [
    {
      section: "comparison",
      options: [...DATE_ENUM_QUICK_FILTERS_OPTIONS]
    },
    {
      section: "relative_custom",
      options: [...DATE_ENUM_RELATIVE_CUSTOM_OPTIONS]
    },
    {
      section: "custom",
      options: [...DATE_ENUM_CUSTOM_OPTIONS]
    },
    ...NULLABLE_OPERATOR_OPTIONS
  ];
  const DATE_ENUM_LABEL_MAP = {
    [DATE_ENUM.last_24_hours.value]: DATE_ENUM.last_24_hours.label,
    [DATE_ENUM.last_48_hours.value]: DATE_ENUM.last_48_hours.label,
    [DATE_ENUM.last_7_days.value]: DATE_ENUM.last_7_days.label,
    [DATE_ENUM.last_30_days.value]: DATE_ENUM.last_30_days.label,
    [DATE_ENUM.custom_date_range.value]: DATE_ENUM.custom_date_range.label
  };
  const OPERATOR_OPTIONS_BY_TYPE = {
    string_non_null: STRING_OPERATOR_OPTIONS_NON_NULLABLE,
    string: STRING_OPERATOR_OPTIONS,
    num: NUM_OPERATOR_OPTIONS,
    enum_single: ENUM_SINGLE_OPERATOR_OPTIONS,
    enum_multi: [
      // TODO: for multi enum/dict filter type
      {
        label: "",
        value: ""
      }
    ],
    date: DATE_OPERATOR_OPTIONS,
    version: VERSION_OPERATOR_OPTIONS
  };
  return {
    ADD_APPLIED_FILTER_ACTION,
    BOOLEAN_FALSE_STRING,
    BOOLEAN_OPERATOR_LABEL_MAP,
    BOOLEAN_OPERATOR_OPTIONS,
    BOOLEAN_TRUE_STRING,
    DATE_ENUM,
    DATE_ENUM_LABEL_MAP,
    DATE_ENUM_CUSTOM_OPTIONS,
    DATE_ENUM_QUICK_FILTERS_OPTIONS,
    DATE_ENUM_OPTIONS,
    DATE_OPERATOR,
    DATE_OPERATOR_LABEL_MAP,
    DATE_OPERATOR_OPTIONS,
    DATE_QUICK_FILTER_DEFAULT_OPTION,
    ENUM_OPERATOR_LABEL_MAP,
    ENUM_SINGLE_IN_OPTIONS,
    ENUM_SINGLE_OPERATOR_OPTIONS,
    NUM_OPERATOR_LABEL_MAP,
    NUM_OPERATOR_EQUALS_OPTIONS,
    NUM_OPERATOR_NOT_EQUALS_OPTIONS,
    NUM_OPERATOR_OPTIONS,
    OPERATOR_OPTIONS_BY_TYPE,
    REMOVE_APPLIED_FILTER_ACTION,
    STRING_OPERATOR_LABEL_MAP,
    STRING_OPERATOR_CONTAINS_OPTIONS,
    STRING_OPERATOR_EQUALS_OPTIONS,
    STRING_OPERATOR_OPTIONS,
    NULLABLE_OPERATOR_OPTIONS,
    VERSION_OPERATOR_OPTIONS,
    VERSION_OPERATOR_IN_BETWEEN_OPTIONS,
    VERSION_OPERATOR_LABEL_MAP
  };
};

// src/FilterType/BaseFilterType/components/OperatorFilterSelect.tsx
import { jsx as jsx44 } from "react/jsx-runtime";
var OperatorFilterSelect = React38.forwardRef(
  ({
    operatorType,
    operatorValue,
    operatorOption,
    setOperatorValue,
    setOperatorOption,
    options,
    onChange,
    disabled = false,
    placeholder = t11("Select operator"),
    hint,
    error,
    readOnly,
    testId,
    hideNullOperators = false
  }, forwardedRef) => {
    const defaultOperatorOptions = operatorType === "string" && hideNullOperators ? constantsFactory().OPERATOR_OPTIONS_BY_TYPE.string_non_null : constantsFactory().OPERATOR_OPTIONS_BY_TYPE[operatorType];
    const operatorOptions = Array.isArray(options) ? options : defaultOperatorOptions;
    return /* @__PURE__ */ jsx44(
      Select,
      {
        ref: forwardedRef,
        value: operatorValue,
        valueText: operatorOption.label,
        onChange: (val, optionsMeta) => {
          onChange == null ? void 0 : onChange(val, optionsMeta);
          setOperatorValue(val);
          setOperatorOption(
            optionsMeta.option
          );
        },
        placeholder,
        options: operatorOptions,
        disabled,
        hint,
        error,
        readOnly,
        "data-testid": testId
      }
    );
  }
);
OperatorFilterSelect.displayName = "OperatorFilterSelect";

// src/FilterType/BaseFilterType/components/CreatableStringFilterMultiSelect.tsx
import { t as t12 } from "@kandji-inc/nectar-i18n";
import * as React39 from "react";
import { jsx as jsx45 } from "react/jsx-runtime";
var DEFAULT_PLACEHOLDER = () => t12("Enter text");
var DEFAULT_HINT_LABEL = () => t12("Hit enter to add more values");
var CreatableStringFilterMultiSelect = React39.forwardRef(
  ({
    stringFilterSearch,
    setStringFilterSearch,
    stringFilterValues,
    setStringFilterValues,
    setStringFilterOptions,
    handleTextInputChange,
    placeholder = DEFAULT_PLACEHOLDER(),
    searchPattern = void 0,
    maxValuesCount,
    disabled = false,
    readOnly = false,
    hint: controlledHint,
    error,
    testId,
    triggerCss
  }, forwardedRef) => {
    const [internalHintLabel, setInternalHintLabel] = React39.useState(
      stringFilterSearch ? DEFAULT_HINT_LABEL() : ""
    );
    const { hasDupeValue, isAtMaxValuesCount, hint } = React39.useMemo(() => {
      var _a;
      const counter = typeof maxValuesCount === "number" ? {
        count: stringFilterValues.length,
        max: maxValuesCount
      } : null;
      const isAtMax = !!counter && stringFilterValues.length >= counter.max;
      const hasSearchValue = !!(stringFilterSearch == null ? void 0 : stringFilterSearch.length);
      const hasDupe = hasSearchValue && stringFilterValues.includes(stringFilterSearch);
      const hasValidControlledHint = (controlledHint == null ? void 0 : controlledHint.label) && typeof controlledHint.label === "string" || typeof ((_a = controlledHint == null ? void 0 : controlledHint.charCounter) == null ? void 0 : _a.count) === "number" && typeof controlledHint.charCounter.max === "number";
      if (hasValidControlledHint) {
        return {
          hasDupeValue: false,
          isAtMaxValuesCount: isAtMax,
          hint: controlledHint
        };
      }
      if (!hasSearchValue) {
        return {
          hasDupeValue: false,
          isAtMaxValuesCount: isAtMax,
          hint: {
            label: "",
            charCounter: counter
          }
        };
      }
      return {
        hasDupeValue: hasDupe,
        isAtMaxValuesCount: isAtMax,
        hint: {
          label: internalHintLabel,
          charCounter: counter
        }
      };
    }, [
      controlledHint,
      internalHintLabel,
      maxValuesCount,
      stringFilterSearch,
      stringFilterValues
    ]);
    return /* @__PURE__ */ jsx45(
      MultiSelect,
      {
        ref: forwardedRef,
        value: stringFilterValues,
        valueText: stringFilterSearch,
        onChange: (val, { selectedMultiOptions, action }) => {
          const preventOnChange = action === "select-option" && (isAtMaxValuesCount || hasDupeValue);
          if (preventOnChange) {
            return void 0;
          }
          setStringFilterValues(val);
          return setStringFilterOptions(
            selectedMultiOptions
          );
        },
        onEventAction: (eventMeta, { internalOnEventAction }) => {
          internalOnEventAction(eventMeta);
          if (isAtMaxValuesCount) {
            return void 0;
          }
          if (eventMeta.action === "search-input-change") {
            setStringFilterSearch(eventMeta.data);
            return setInternalHintLabel(
              eventMeta.data ? DEFAULT_HINT_LABEL() : ""
            );
          }
          return handleTextInputChange == null ? void 0 : handleTextInputChange(eventMeta.data);
        },
        creatable: {
          active: !isAtMaxValuesCount,
          onCreate: () => {
            if (hasDupeValue) {
              const dupeLabel = t12(
                "{stringFilterSearch} has already been added",
                { stringFilterSearch: `"${stringFilterSearch}"` }
              );
              setInternalHintLabel(dupeLabel);
              return false;
            }
            setStringFilterSearch("");
            return true;
          }
        },
        components: {
          OpenIcon: null
        },
        componentCss: {
          trigger: triggerCss,
          searchInput: {
            '&[data-search-value=""]': {
              width: "100%"
            }
          }
        },
        searchable: !isAtMaxValuesCount,
        open: false,
        disabled,
        readOnly,
        placeholder,
        showHiddenCountValue: false,
        searchPattern,
        hint,
        error,
        testId
      }
    );
  }
);
CreatableStringFilterMultiSelect.displayName = "CreatableStringFilterMultiSelect";

// src/FilterType/BaseFilterType/components/VersionFilterRangeInputs.tsx
import { t as t13 } from "@kandji-inc/nectar-i18n";
import * as React40 from "react";
import { jsx as jsx46, jsxs as jsxs29 } from "react/jsx-runtime";
var VersionFilterRangeInputs = React40.forwardRef(({ handleFromInputChange, handleToInputChange, values, errors }, ref) => {
  return /* @__PURE__ */ jsxs29(
    Flex,
    {
      alignItems: "start",
      flow: "row",
      css: { maxWidth: 320, gap: 6 },
      ref,
      children: [
        /* @__PURE__ */ jsx46(
          TextField,
          {
            placeholder: t13("Enter min value"),
            value: values[0],
            onChange: handleFromInputChange,
            hint: errors.value_from ? { label: errors.value_from, variant: "error" } : void 0,
            state: errors.value_from ? "error" : "default"
          }
        ),
        /* @__PURE__ */ jsx46(Text, { css: { marginTop: 8 }, children: t13("and") }),
        /* @__PURE__ */ jsx46(
          TextField,
          {
            placeholder: t13("Enter max value"),
            value: values[1],
            onChange: handleToInputChange,
            hint: errors.value_to ? { label: errors.value_to, variant: "error" } : void 0,
            state: errors.value_to ? "error" : "default"
          }
        )
      ]
    }
  );
});
VersionFilterRangeInputs.displayName = "VersionFilterRangeInputs";

// src/FilterType/BoolFilterType.tsx
import * as React41 from "react";
import { Fragment as Fragment9, jsx as jsx47, jsxs as jsxs30 } from "react/jsx-runtime";
var getBoolFilterLabelDelimiter = (selectedFilterOptions = []) => {
  const [selectedBoolFilterOption] = selectedFilterOptions;
  return [
    constantsFactory().BOOLEAN_TRUE_STRING,
    constantsFactory().BOOLEAN_FALSE_STRING
  ].includes(selectedBoolFilterOption == null ? void 0 : selectedBoolFilterOption.value) ? ":" : "";
};
var BoolFilterType = React41.forwardRef(
  ({
    children,
    filter,
    filtersActive,
    boolOptions,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    testId,
    dropdownProps = {},
    buttonProps = {}
  }, forwardedRef) => {
    const [appliedFilterTuple, setAppliedFilterTuple] = React41.useState(() => {
      const [initialFilter] = validateBoolFilterSchema(filter);
      const boolFilterTypeOption = boolOptions.find(
        (option) => option.value === (initialFilter == null ? void 0 : initialFilter.operator)
      );
      return [
        initialFilter,
        boolFilterTypeOption ? {
          boolFilterTypeOption
        } : null
      ];
    });
    const [boolValue, setBoolValue] = React41.useState(() => {
      const [initialValidFilter] = appliedFilterTuple;
      if (initialValidFilter) {
        return initialValidFilter.value;
      }
      return void 0;
    });
    const [selectedFilterOptions, setSelectedFilterOptions] = React41.useState(
      () => {
        const [, boolFilterMeta] = appliedFilterTuple;
        try {
          const parsedOption = boolToStringFilterOptionSchema.parse(
            boolFilterMeta == null ? void 0 : boolFilterMeta.boolFilterTypeOption
          );
          return [parsedOption].filter(
            (option) => Boolean((option == null ? void 0 : option.value) && option.label)
          );
        } catch (e) {
          return [];
        }
      }
    );
    const [errors, setErrors] = React41.useState({
      value: ""
    });
    const handleBoolChange = React41.useCallback((value) => {
      setBoolValue(value);
      setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), {
        value: ""
      }));
    }, []);
    React41.useEffect(() => {
      setSelectedFilterOptions(
        boolOptions.map(
          (option) => boolValue === option.value ? boolToStringFilterOptionSchema.parse(option) : null
        ).filter(
          (option) => Boolean((option == null ? void 0 : option.value) && option.label)
        )
      );
    }, [boolOptions, boolValue]);
    const boolFilterRadioGroupProps = React41.useMemo(
      () => ({
        testId: "bool-filter-type-radio-group",
        value: boolValue,
        onChange: handleBoolChange,
        options: boolOptions,
        error: errors.value,
        hint: {
          label: errors.value
        }
      }),
      [boolOptions, boolValue, handleBoolChange, errors]
    );
    const boolFilterRenderOptions = React41.useMemo(
      () => [
        __spreadValues({
          render: BoolFilterRadioGroup
        }, boolFilterRadioGroupProps)
      ],
      [boolFilterRadioGroupProps]
    );
    const _a = dropdownProps, { css: dropdownCss = {} } = _a, restDropdownProps = __objRest(_a, ["css"]);
    const resetAllValues = React41.useCallback(
      ({ resetToPrevApplied } = {
        resetToPrevApplied: true
      }) => {
        if (!resetToPrevApplied) {
          setBoolValue(void 0);
          setSelectedFilterOptions([]);
          return setAppliedFilterTuple([null, null]);
        }
        const [appliedFilter] = appliedFilterTuple;
        if (appliedFilter !== null && appliedFilter.value !== boolValue) {
          setBoolValue(appliedFilter == null ? void 0 : appliedFilter.value);
        }
        return void 0;
      },
      [
        setBoolValue,
        setSelectedFilterOptions,
        setAppliedFilterTuple,
        appliedFilterTuple,
        boolValue
      ]
    );
    return /* @__PURE__ */ jsx47(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const [appliedFilter] = appliedFilterTuple;
          const isFilterEmpty = !appliedFilter;
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            filterType: "boolean",
            filterKey: filter.key,
            filterLabel: filter.label,
            filterValue: boolValue,
            selectedFilterOptions,
            isFilterEmpty,
            setIsOpen,
            resetAllValues
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            resetAllValues({
              resetToPrevApplied: !isFilterEmpty
            });
          }
          return true;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            var _a2;
            const toApply = {
              key: filter.key,
              label: filter.label,
              value: boolValue
            };
            const [onlySelectedOption] = selectedFilterOptions;
            const boolFilterTypeOption = stringToBoolFilterOptionSchema.parse(onlySelectedOption);
            const [validatedFilter, errorMap] = validateBoolFilterSchema(toApply);
            if (!validatedFilter) {
              setErrors({
                value: ((_a2 = errorMap == null ? void 0 : errorMap.value) == null ? void 0 : _a2.message) || ""
              });
              return false;
            }
            const continueToReset = handleApply == null ? void 0 : handleApply({
              filterType: "boolean",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: boolValue,
              selectedFilterOptions,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return resetAllValues();
            }
            const toApplyTuple = [
              validatedFilter,
              boolFilterTypeOption ? { boolFilterTypeOption } : null
            ];
            return setAppliedFilterTuple(toApplyTuple);
          },
          handleClear: (event, { setIsOpen }) => {
            const continueToReset = handleClear == null ? void 0 : handleClear({
              filterType: "boolean",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: boolValue,
              selectedFilterOptions,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              resetAllValues({ resetToPrevApplied: false });
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const [appliedFilter] = appliedFilterTuple;
            const isFilterEmpty = !appliedFilter;
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              filterType: "boolean",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: boolValue,
              selectedFilterOptions,
              isFilterEmpty,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnCancel) {
              resetAllValues({
                resetToPrevApplied: !isFilterEmpty
              });
            }
          },
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "bool-filter-type-button-footer"
        },
        options: boolFilterRenderOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx47(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            ref: forwardedRef,
            selectedFilters: selectedFilterOptions,
            filtersActive,
            showRemove,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                filterType: "boolean",
                filterKey: filter.key,
                filterLabel: filter.label,
                filterValue: boolValue,
                selectedFilterOptions,
                event,
                resetAllValues
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(filterButtonProps) : /* @__PURE__ */ jsxs30(Fragment9, { children: [
              /* @__PURE__ */ jsx47("strong", { children: filter.label }),
              getBoolFilterLabelDelimiter(selectedFilterOptions)
            ] })
          })
        )
      })
    );
  }
);
BoolFilterType.displayName = "BoolFilterType";

// src/FilterType/EnumFilterType.tsx
import { t as t14 } from "@kandji-inc/nectar-i18n";
import * as React42 from "react";
import { Fragment as Fragment10, jsx as jsx48, jsxs as jsxs31 } from "react/jsx-runtime";
var EnumFilterType = React42.forwardRef(
  ({
    children,
    filter,
    filtersActive,
    operatorOptions,
    enumOptions,
    multi = false,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showApply,
    showClear,
    showCancel,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    testId,
    dropdownProps = {},
    buttonProps = {}
  }, forwardedRef) => {
    const enumOperatorOptions = React42.useMemo(
      () => Array.isArray(operatorOptions) ? operatorOptions : constantsFactory().OPERATOR_OPTIONS_BY_TYPE.enum_single,
      [operatorOptions]
    );
    const [appliedFilterTuple, setAppliedFilterTuple] = React42.useState(() => {
      const [initialFilter] = validateEnumFilterSchema(filter);
      const enumFilterTypeOperatorOption = enumOperatorOptions.flatMap((option) => "options" in option ? option.options : option).find((option) => option.value === (initialFilter == null ? void 0 : initialFilter.operator));
      const enumFilterMeta = enumFilterTypeOperatorOption ? {
        enumFilterTypeOperatorOption
      } : null;
      return [initialFilter, enumFilterMeta];
    });
    const [operatorValue, setOperatorValue] = React42.useState(() => {
      const [initialValidFilter] = appliedFilterTuple;
      return initialValidFilter ? initialValidFilter.operator : "";
    });
    const [operatorOption, setOperatorOption] = React42.useState(() => {
      const [, enumFilterMeta] = appliedFilterTuple;
      return (enumFilterMeta == null ? void 0 : enumFilterMeta.enumFilterTypeOperatorOption) || {
        label: "",
        value: ""
      };
    });
    const [enumSelectValue, setEnumSelectValue] = React42.useState(() => {
      const [initialValidFilter] = appliedFilterTuple;
      if (!initialValidFilter) {
        return multi ? [] : "";
      }
      return initialValidFilter.value;
    });
    const [selectedFilterOptions, setSelectedFilterOptions] = React42.useState(() => {
      const [initialValidFilter] = appliedFilterTuple;
      if (!(initialValidFilter == null ? void 0 : initialValidFilter.value)) {
        return [];
      }
      return Array.isArray(initialValidFilter.value) ? enumOptions.filter(
        (option) => initialValidFilter.value.includes(option.value)
      ) : [
        enumOptions.find(
          (option) => option.value === initialValidFilter.value
        )
      ].filter((opt) => Boolean(opt));
    });
    const [errors, setErrors] = React42.useState({
      value: "",
      operator: ""
    });
    const handleOperatorChange = React42.useCallback((value) => {
      setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { operator: "" }));
      if (isNullOrNotNullOperator(value)) {
        setEnumSelectValue(multi ? [] : "");
        setSelectedFilterOptions([]);
      }
    }, []);
    const handleEnumFilterChangeSingle = React42.useCallback(
      (value, optionsMeta) => {
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
        setEnumSelectValue(value);
        setSelectedFilterOptions([optionsMeta.option]);
      },
      []
    );
    const handleEnumFilterChangeMulti = React42.useCallback(
      (value, optionsMeta) => {
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
        setEnumSelectValue(value);
        setSelectedFilterOptions(optionsMeta.selectedMultiOptions);
      },
      []
    );
    const enumFilterSelectProps = React42.useMemo(
      () => {
        var _a2;
        return {
          testId: "enum-filter-type-select",
          value: enumSelectValue,
          valueText: multi ? void 0 : (_a2 = selectedFilterOptions == null ? void 0 : selectedFilterOptions[0]) == null ? void 0 : _a2.label,
          options: enumOptions,
          placeholder: t14("Select from available options"),
          onChange: multi ? handleEnumFilterChangeMulti : handleEnumFilterChangeSingle,
          error: errors.value
        };
      },
      [
        enumOptions,
        enumSelectValue,
        errors.value,
        handleEnumFilterChangeMulti,
        handleEnumFilterChangeSingle,
        multi,
        selectedFilterOptions
      ]
    );
    const operatorFilterSelectProps = React42.useMemo(
      () => ({
        testId: "enum-filter-type-operator-select",
        operatorType: multi ? "enum_multi" : "enum_single",
        operatorValue,
        operatorOption,
        options: enumOperatorOptions,
        setOperatorValue,
        setOperatorOption,
        onChange: handleOperatorChange,
        error: errors.operator
      }),
      [
        multi,
        operatorValue,
        operatorOption,
        enumOperatorOptions,
        handleOperatorChange,
        errors.operator
      ]
    );
    const enumFilterRenderOptions = React42.useMemo(
      () => [
        __spreadValues({
          render: OperatorFilterSelect
        }, operatorFilterSelectProps),
        __spreadValues({
          render: multi ? MultiSelect : Select
        }, enumFilterSelectProps)
      ],
      [operatorFilterSelectProps, multi, enumFilterSelectProps]
    );
    const _a = dropdownProps, { css: dropdownCss = {} } = _a, restDropdownProps = __objRest(_a, ["css"]);
    const resetAllValues = ({ resetToPrevApplied } = {
      resetToPrevApplied: true
    }) => {
      var _a2;
      if (!resetToPrevApplied) {
        setEnumSelectValue(multi ? [] : "");
        setSelectedFilterOptions([]);
        setOperatorValue("");
        setOperatorOption({
          label: "",
          value: ""
        });
        return setAppliedFilterTuple([null, null]);
      }
      const [appliedFilter] = appliedFilterTuple;
      if (!appliedFilter) {
        return void 0;
      }
      const appliedOperatorLabel = (_a2 = enumOperatorOptions.flatMap((option) => "options" in option ? option.options : option).find((option) => option.value === (appliedFilter == null ? void 0 : appliedFilter.operator))) == null ? void 0 : _a2.label;
      if (appliedOperatorLabel) {
        setOperatorValue(appliedFilter.operator);
        setOperatorOption({
          label: appliedOperatorLabel,
          value: appliedFilter.operator
        });
      }
      setEnumSelectValue(appliedFilter.value);
      return setSelectedFilterOptions(
        Array.isArray(appliedFilter.value) ? appliedFilter.value.map((value) => ({ value, label: value })) : [
          {
            value: appliedFilter.value,
            label: appliedFilter.value
          }
        ]
      );
    };
    return /* @__PURE__ */ jsx48(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const [appliedFilter] = appliedFilterTuple;
          const isFilterEmpty = !appliedFilter;
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            filterType: "enum",
            filterKey: filter.key,
            filterLabel: filter.label,
            filterValue: enumSelectValue,
            multi,
            operatorValue,
            selectedFilterOptions,
            isFilterEmpty,
            setIsOpen,
            resetAllValues
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            resetAllValues({
              resetToPrevApplied: !isFilterEmpty
            });
          }
          return true;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            var _a2, _b;
            const toApply = Array.isArray(enumSelectValue) ? {
              key: filter.key,
              label: filter.label,
              value: enumSelectValue,
              operator: operatorValue,
              multi: true
            } : {
              key: filter.key,
              label: filter.label,
              value: enumSelectValue,
              operator: operatorValue,
              multi: false
            };
            const [validatedFilter, errorMap] = validateEnumFilterSchema(toApply);
            if (!validatedFilter) {
              setErrors({
                value: ((_a2 = errorMap == null ? void 0 : errorMap.value) == null ? void 0 : _a2.message) || "",
                operator: ((_b = errorMap == null ? void 0 : errorMap.operator) == null ? void 0 : _b.message) || ""
              });
              return false;
            }
            const continueToReset = handleApply({
              filterType: "enum",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: enumSelectValue,
              multi,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return resetAllValues();
            }
            const toApplyTuple = [
              validatedFilter,
              operatorOption ? {
                enumFilterTypeOperatorOption: operatorOption
              } : null
            ];
            return setAppliedFilterTuple(toApplyTuple);
          },
          handleClear: (event, { setIsOpen }) => {
            const continueToReset = handleClear == null ? void 0 : handleClear({
              filterType: "enum",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: enumSelectValue,
              multi,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              resetAllValues({
                resetToPrevApplied: false
              });
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const [appliedFilter] = appliedFilterTuple;
            const isFilterEmpty = !appliedFilter;
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              filterType: "enum",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: enumSelectValue,
              multi,
              operatorValue,
              selectedFilterOptions,
              isFilterEmpty,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnCancel) {
              resetAllValues({
                resetToPrevApplied: !isFilterEmpty
              });
            }
          },
          showApply,
          showClear,
          showCancel,
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "enum-filter-type-button-footer"
        },
        options: isNullOrNotNullOperator(operatorValue) ? [
          enumFilterRenderOptions[0]
        ] : enumFilterRenderOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx48(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            ref: forwardedRef,
            selectedFilters: isNullOrNotNullOperator(operatorValue) ? (
              // a hack to get the nullish operator to render properly
              [{ label: operatorOption.label, value: "" }]
            ) : selectedFilterOptions,
            filtersActive,
            showRemove,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                filterType: "enum",
                filterKey: filter.key,
                filterLabel: filter.label,
                filterValue: enumSelectValue,
                multi,
                operatorValue,
                selectedFilterOptions,
                resetAllValues,
                event
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(filterButtonProps) : /* @__PURE__ */ jsxs31(Fragment10, { children: [
              /* @__PURE__ */ jsx48("strong", { children: filter.label }),
              " ",
              // a hack to get the nullish operator to render properly
              isNullOrNotNullOperator(operatorValue) ? "" : operatorOption.label
            ] })
          })
        )
      })
    );
  }
);
EnumFilterType.displayName = "EnumFilterType";

// src/FilterType/NumFilterType.tsx
import { t as t15 } from "@kandji-inc/nectar-i18n";
import * as React43 from "react";
import { Fragment as Fragment11, jsx as jsx49, jsxs as jsxs32 } from "react/jsx-runtime";
var defaultFloatRegex = /^(\d+\.?\d*|\.\d+)$/;
var NumFilterType = React43.forwardRef(
  ({
    children,
    filter,
    filtersActive,
    operatorOptions,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showApply,
    showClear,
    showCancel,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    searchPattern = defaultFloatRegex,
    maxValuesCount,
    testId,
    dropdownProps = {},
    buttonProps = {}
  }, forwardedRef) => {
    var _a;
    const [appliedFilter, setAppliedFilter] = React43.useState(() => {
      const [initialFilter] = validateNumFilterSchema(filter);
      return initialFilter;
    });
    const [operatorValue, setOperatorValue] = React43.useState(
      filter.operator || ""
    );
    const [operatorOption, setOperatorOption] = React43.useState(() => {
      const foundOperatorOption = filter.operator ? [
        ...constantsFactory().NUM_OPERATOR_EQUALS_OPTIONS,
        ...constantsFactory().NUM_OPERATOR_NOT_EQUALS_OPTIONS,
        ...constantsFactory().NULLABLE_OPERATOR_OPTIONS
      ].find((option) => option.value === filter.operator) : null;
      if (foundOperatorOption) {
        return {
          value: foundOperatorOption.value,
          label: foundOperatorOption.label,
          meta: {
            multi: foundOperatorOption.meta.multi
          }
        };
      }
      return {
        value: "",
        label: "",
        meta: {
          multi: true
        }
      };
    });
    const isSingleNumberValueInput = ((_a = operatorOption == null ? void 0 : operatorOption.meta) == null ? void 0 : _a.multi) === false;
    const [stringFilterSearch, setStringFilterSearch] = React43.useState(
      (appliedFilter == null ? void 0 : appliedFilter.value) == null || !isSingleNumberValueInput ? "" : appliedFilter.value.toString()
    );
    const [stringFilterValues, setStringFilterValues] = React43.useState(
      filter.value || []
    );
    const [selectedFilterOptions, setSelectedFilterOptions] = React43.useState(
      () => Array.isArray(filter.value) ? filter.value.map((val) => ({
        value: val,
        label: val
      })) : [filter.value].map((val) => ({
        value: val,
        label: val
      }))
    );
    const [errors, setErrors] = React43.useState({
      value: "",
      operator: ""
    });
    React43.useEffect(() => {
      if (isNullOrNotNullOperator(filter.operator)) {
        const nullValue = getNullValue({ is_null: filter.value.toString() });
        setOperatorValue(nullValue);
      }
    }, [filter.operator, filter.value]);
    const handleTextInputChange = React43.useCallback(() => {
      setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
    }, []);
    const resetInputValues = React43.useCallback(() => {
      setStringFilterSearch("");
      setStringFilterValues([]);
      setSelectedFilterOptions([]);
    }, []);
    const numOperators = constantsFactory().NUM_OPERATOR_LABEL_MAP;
    const isKeyOfNumOpMap = (op) => op in numOperators;
    const resetAllValues = React43.useCallback(
      ({ resetToPrevApplied } = {
        resetToPrevApplied: true
      }) => {
        var _a2;
        if (!resetToPrevApplied) {
          setStringFilterSearch("");
          setStringFilterValues([]);
          setSelectedFilterOptions([]);
          setOperatorValue("");
          setOperatorOption({
            label: "",
            value: "",
            meta: {
              multi: true
            }
          });
          return setAppliedFilter(null);
        }
        if (!(appliedFilter == null ? void 0 : appliedFilter.operator)) {
          return void 0;
        }
        const appliedOperatorLabel = isKeyOfNumOpMap(appliedFilter.operator) ? constantsFactory().NUM_OPERATOR_LABEL_MAP[appliedFilter.operator] : (_a2 = operatorOptions == null ? void 0 : operatorOptions.find(
          (option) => option.value === (appliedFilter == null ? void 0 : appliedFilter.operator)
        )) == null ? void 0 : _a2.label;
        if (appliedOperatorLabel) {
          setOperatorValue(appliedFilter.operator);
          setOperatorOption({
            label: appliedOperatorLabel,
            value: appliedFilter.operator,
            meta: {
              multi: !isSingleNumberValueInput
            }
          });
          if (Array.isArray(appliedFilter.value)) {
            setStringFilterSearch("");
            setStringFilterValues(appliedFilter.value);
            return setSelectedFilterOptions(
              appliedFilter.value.map((value) => ({ value, label: value })) || []
            );
          }
          setStringFilterSearch(appliedFilter.value.toString() || "");
          return setSelectedFilterOptions(
            appliedFilter.value !== "" && appliedFilter.value !== null ? [
              {
                value: appliedFilter.value,
                label: appliedFilter.value
              }
            ] : []
          );
        }
        return void 0;
      },
      [appliedFilter, isSingleNumberValueInput, operatorOptions]
    );
    const handleOperatorChange = React43.useCallback(
      (value, optionsMeta) => {
        var _a2, _b2;
        const isNullOperator2 = isNullOrNotNullOperator(value);
        const isChangeNumberInputType = isSingleNumberValueInput ? !!((_a2 = optionsMeta.option.meta) == null ? void 0 : _a2.multi) : ((_b2 = optionsMeta.option.meta) == null ? void 0 : _b2.multi) === false;
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { operator: "" }));
        if (isChangeNumberInputType || isNullOperator2) {
          resetInputValues();
        }
      },
      [isSingleNumberValueInput, resetInputValues]
    );
    const handleSingleNumberInputChange = React43.useCallback(
      (e) => {
        const isValidNumberValue = publicSelectUtils.checkSearchPattern(
          searchPattern,
          e.currentTarget.value
        );
        if (isValidNumberValue) {
          setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
          setStringFilterSearch(e.currentTarget.value);
          setSelectedFilterOptions([
            {
              value: e.currentTarget.value,
              label: e.currentTarget.value
            }
          ]);
        }
      },
      [searchPattern]
    );
    const numberInputProps = React43.useMemo(
      () => isSingleNumberValueInput ? {
        testId: "num-filter-type-single-text-input",
        value: stringFilterSearch,
        onChange: handleSingleNumberInputChange,
        placeholder: t15("Enter value"),
        hint: {
          label: errors.value
        },
        state: errors.value ? "error" : "default",
        wFull: true
      } : {
        testId: "num-filter-type-multi-select",
        stringFilterSearch,
        setStringFilterSearch,
        stringFilterValues,
        setStringFilterValues,
        setStringFilterOptions: setSelectedFilterOptions,
        handleTextInputChange,
        placeholder: t15("Enter value"),
        searchPattern,
        maxValuesCount,
        error: errors.value
      },
      [
        isSingleNumberValueInput,
        stringFilterSearch,
        handleSingleNumberInputChange,
        errors.value,
        stringFilterValues,
        handleTextInputChange,
        searchPattern,
        maxValuesCount
      ]
    );
    const operatorFilterSelectProps = React43.useMemo(
      () => ({
        testId: "num-filter-type-operator-select",
        operatorType: "num",
        operatorValue,
        operatorOption,
        onChange: handleOperatorChange,
        options: operatorOptions,
        setOperatorValue,
        setOperatorOption,
        error: errors.operator
      }),
      [
        errors.operator,
        handleOperatorChange,
        operatorOption,
        operatorOptions,
        operatorValue
      ]
    );
    const creatableNumFilterRenderOptions = React43.useMemo(
      () => [
        __spreadValues({
          render: OperatorFilterSelect
        }, operatorFilterSelectProps),
        __spreadValues({
          render: isSingleNumberValueInput ? StyledSingleNumberValueInput : CreatableStringFilterMultiSelect
        }, numberInputProps)
      ],
      [operatorFilterSelectProps, isSingleNumberValueInput, numberInputProps]
    );
    const _b = dropdownProps, { css: dropdownCss = {} } = _b, restDropdownProps = __objRest(_b, ["css"]);
    return /* @__PURE__ */ jsx49(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const isFilterEmpty = !appliedFilter;
          const filterValue = isSingleNumberValueInput ? stringFilterSearch : stringFilterValues;
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            filterType: "number",
            filterKey: filter.key,
            filterLabel: filter.label,
            filterValue,
            operatorValue,
            selectedFilterOptions,
            isFilterEmpty,
            setIsOpen,
            resetInputValues,
            resetAllValues
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            resetAllValues({
              resetToPrevApplied: !isFilterEmpty
            });
          }
          return void 0;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true,
          disableTypeaheadSearch: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            var _a2, _b2;
            const trimmedSearch = stringFilterSearch.trim();
            const searchAsFilterValues = trimmedSearch ? [stringFilterSearch] : [];
            const applyValue = isSingleNumberValueInput ? stringFilterSearch : [...stringFilterValues, ...searchAsFilterValues];
            const toApply = {
              key: filter.key,
              label: filter.label,
              value: applyValue,
              operator: operatorValue
            };
            const [validatedFilter, errorMap] = validateNumFilterSchema(toApply);
            if (!validatedFilter) {
              setErrors({
                value: ((_a2 = errorMap == null ? void 0 : errorMap.value) == null ? void 0 : _a2.message) || "",
                operator: ((_b2 = errorMap == null ? void 0 : errorMap.operator) == null ? void 0 : _b2.message) || ""
              });
              return false;
            }
            const continueToReset = handleApply == null ? void 0 : handleApply({
              filterType: "number",
              filterKey: validatedFilter.key,
              filterLabel: validatedFilter.label,
              filterValue: applyValue,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetInputValues,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return resetAllValues();
            }
            if (Array.isArray(validatedFilter.value)) {
              setStringFilterValues(validatedFilter.value);
              setSelectedFilterOptions(
                validatedFilter.value.map((value) => ({ value, label: value }))
              );
              setStringFilterSearch("");
            }
            return setAppliedFilter(toApply);
          },
          handleClear: (event, { setIsOpen }) => {
            const filterValue = isSingleNumberValueInput ? stringFilterSearch : stringFilterValues;
            const continueToReset = handleClear == null ? void 0 : handleClear({
              filterType: "number",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetInputValues,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              resetAllValues({
                resetToPrevApplied: false
              });
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const isFilterEmpty = !appliedFilter;
            const filterValue = isSingleNumberValueInput ? stringFilterSearch : stringFilterValues;
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              filterType: "number",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue,
              operatorValue,
              selectedFilterOptions,
              isFilterEmpty,
              event,
              setIsOpen,
              resetInputValues,
              resetAllValues
            });
            if (continueToReset !== false && resetOnCancel) {
              resetAllValues({
                resetToPrevApplied: !isFilterEmpty
              });
            }
          },
          showApply,
          showClear,
          showCancel,
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "num-filter-type-button-footer"
        },
        options: isNullOrNotNullOperator(operatorValue) ? [creatableNumFilterRenderOptions[0]] : creatableNumFilterRenderOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx49(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            ref: forwardedRef,
            selectedFilters: isNullOrNotNullOperator(operatorValue) ? (
              // a hack to get the nullish operator to render properly
              [{ label: operatorOption.label, value: "" }]
            ) : selectedFilterOptions,
            filtersActive,
            showRemove,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                filterType: "number",
                filterKey: filter.key,
                filterLabel: filter.label,
                filterValue: stringFilterValues,
                operatorValue,
                selectedFilterOptions,
                resetInputValues,
                resetAllValues,
                event
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(filterButtonProps) : /* @__PURE__ */ jsxs32(Fragment11, { children: [
              /* @__PURE__ */ jsx49("strong", { children: filter.label }),
              " ",
              // a hack to get the nullish operator to render properly
              isNullOrNotNullOperator(operatorValue) ? "" : operatorOption.label
            ] })
          })
        )
      })
    );
  }
);
NumFilterType.displayName = "NumFilterType";

// src/FilterType/StringFilterType.tsx
import * as React44 from "react";
import { Fragment as Fragment12, jsx as jsx50, jsxs as jsxs33 } from "react/jsx-runtime";
var StringFilterType = React44.forwardRef(
  ({
    children,
    filter,
    filtersActive,
    operatorOptions,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showApply,
    showClear,
    showCancel,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    maxValuesCount,
    testId,
    dropdownProps = {},
    buttonProps = {},
    hideNullOperators = false
  }, forwardedRef) => {
    const [appliedFilter, setAppliedFilter] = React44.useState(() => {
      const [initialFilter] = validateStringFilterSchema(filter);
      return initialFilter;
    });
    const [operatorValue, setOperatorValue] = React44.useState(
      filter.operator || ""
    );
    const [operatorOption, setOperatorOption] = React44.useState(() => {
      const foundOperatorOption = filter.operator ? [
        ...constantsFactory().STRING_OPERATOR_CONTAINS_OPTIONS,
        ...constantsFactory().STRING_OPERATOR_EQUALS_OPTIONS,
        ...constantsFactory().NULLABLE_OPERATOR_OPTIONS
      ].find((option) => option.value === filter.operator) : null;
      if (foundOperatorOption) {
        return {
          value: foundOperatorOption.value,
          label: foundOperatorOption.label,
          meta: foundOperatorOption.meta
        };
      }
      return {
        label: "",
        value: "",
        meta: {
          multi: false
        }
      };
    });
    const [stringFilterSearch, setStringFilterSearch] = React44.useState("");
    const [stringFilterValues, setStringFilterValues] = React44.useState(
      filter.value || []
    );
    const [selectedFilterOptions, setSelectedFilterOptions] = React44.useState(() => {
      if (Array.isArray(filter.value)) {
        return filter.value.map((value) => ({ value, label: value }));
      }
      return [];
    });
    React44.useEffect(() => {
      if (isNullOrNotNullOperator(filter.operator)) {
        const nullValue = getNullValue({ is_null: filter.value.toString() });
        setOperatorValue(nullValue);
      }
    }, []);
    const [errors, setErrors] = React44.useState({
      value: "",
      operator: ""
    });
    const resetInputValues = React44.useCallback(() => {
      setStringFilterSearch("");
      setStringFilterValues([]);
      setSelectedFilterOptions([]);
    }, []);
    const handleTextInputChange = React44.useCallback(() => {
      setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
    }, []);
    const isSingleNumberValueInput = React44.useMemo(
      () => {
        var _a2;
        return ((_a2 = operatorOption == null ? void 0 : operatorOption.meta) == null ? void 0 : _a2.multi) === false;
      },
      [operatorOption]
    );
    const handleOperatorChange = React44.useCallback(
      (value, optionsMeta) => {
        var _a2, _b;
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { operator: "" }));
        const isChangeNumberInputType = isSingleNumberValueInput ? !!((_a2 = optionsMeta.option.meta) == null ? void 0 : _a2.multi) : ((_b = optionsMeta.option.meta) == null ? void 0 : _b.multi) === false;
        if (isNullOrNotNullOperator(value) || isChangeNumberInputType) {
          resetInputValues();
        }
      },
      [isSingleNumberValueInput]
    );
    const operatorFilterSelectProps = React44.useMemo(
      () => ({
        testId: "string-filter-type-operator-select",
        operatorType: "string",
        operatorValue,
        operatorOption,
        onChange: handleOperatorChange,
        options: operatorOptions,
        setOperatorValue,
        setOperatorOption,
        error: errors.operator,
        hideNullOperators
      }),
      [
        errors.operator,
        handleOperatorChange,
        operatorOption,
        operatorOptions,
        operatorValue,
        hideNullOperators
      ]
    );
    const creatableStringFilterMultiSelectProps = React44.useMemo(
      () => ({
        testId: "string-filter-type-multi-select",
        stringFilterSearch,
        stringFilterValues,
        setStringFilterSearch,
        setStringFilterValues,
        setStringFilterOptions: setSelectedFilterOptions,
        handleTextInputChange,
        maxValuesCount,
        error: errors.value
      }),
      [
        stringFilterSearch,
        stringFilterValues,
        handleTextInputChange,
        maxValuesCount,
        errors.value
      ]
    );
    const creatableStringFilterRenderOptions = React44.useMemo(
      () => [
        __spreadValues({
          render: OperatorFilterSelect
        }, operatorFilterSelectProps),
        __spreadValues({
          render: CreatableStringFilterMultiSelect
        }, creatableStringFilterMultiSelectProps)
      ],
      [operatorFilterSelectProps, creatableStringFilterMultiSelectProps]
    );
    const _a = dropdownProps, { css: dropdownCss = {} } = _a, restDropdownProps = __objRest(_a, ["css"]);
    const strOperators = constantsFactory().STRING_OPERATOR_LABEL_MAP;
    const isKeyOfStringOpMap = (op) => op in strOperators;
    const resetAllValues = ({ resetToPrevApplied } = {
      resetToPrevApplied: true
    }) => {
      var _a2, _b;
      if (!resetToPrevApplied) {
        setStringFilterSearch("");
        setStringFilterValues([]);
        setSelectedFilterOptions([]);
        setOperatorValue("");
        setOperatorOption({
          label: "",
          value: "",
          meta: {
            multi: false
          }
        });
        return setAppliedFilter(null);
      }
      if (!(appliedFilter == null ? void 0 : appliedFilter.operator)) {
        return void 0;
      }
      const appliedOperatorLabel = isKeyOfStringOpMap(appliedFilter.operator) ? constantsFactory().STRING_OPERATOR_LABEL_MAP[appliedFilter.operator] : (_a2 = operatorOptions == null ? void 0 : operatorOptions.find(
        (option) => option.value === appliedFilter.operator
      )) == null ? void 0 : _a2.label;
      if (appliedOperatorLabel) {
        setOperatorValue(appliedFilter.operator);
        setOperatorOption({
          label: appliedOperatorLabel,
          value: appliedFilter.operator,
          meta: {
            multi: false
          }
        });
        setStringFilterSearch("");
        if (Array.isArray(appliedFilter.value)) {
          setStringFilterValues(appliedFilter.value);
          return setSelectedFilterOptions(
            ((_b = appliedFilter.value) == null ? void 0 : _b.map((value) => ({
              value,
              label: value
            }))) || []
          );
        }
      }
      return void 0;
    };
    return /* @__PURE__ */ jsx50(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const isFilterEmpty = !appliedFilter;
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            filterType: "string",
            filterKey: filter.key,
            filterLabel: filter.label,
            filterValue: stringFilterValues,
            operatorValue,
            selectedFilterOptions,
            isFilterEmpty,
            setIsOpen,
            resetAllValues
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            resetAllValues({
              resetToPrevApplied: !isFilterEmpty
            });
          }
          return true;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true,
          disableTypeaheadSearch: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            var _a2, _b;
            const trimmedSearch = stringFilterSearch.trim();
            const searchAsFilterValues = trimmedSearch ? [stringFilterSearch] : [];
            const applyValue = [...stringFilterValues, ...searchAsFilterValues];
            const toApply = {
              key: filter.key,
              label: filter.label,
              value: applyValue,
              operator: operatorValue
            };
            const [validatedFilter, errorMap] = validateStringFilterSchema(toApply);
            if (!validatedFilter) {
              setErrors({
                value: ((_a2 = errorMap == null ? void 0 : errorMap.value) == null ? void 0 : _a2.message) || "",
                operator: ((_b = errorMap == null ? void 0 : errorMap.operator) == null ? void 0 : _b.message) || ""
              });
              return false;
            }
            const continueToReset = handleApply == null ? void 0 : handleApply({
              filterType: "string",
              filterKey: validatedFilter.key,
              filterLabel: validatedFilter.label,
              filterValue: validatedFilter.value,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return resetAllValues();
            }
            setStringFilterValues(validatedFilter.value);
            setSelectedFilterOptions(
              applyValue.map((value) => ({ value, label: value }))
            );
            setStringFilterSearch("");
            return setAppliedFilter(toApply);
          },
          handleClear: (event, { setIsOpen }) => {
            const continueToReset = handleClear == null ? void 0 : handleClear({
              filterType: "string",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: stringFilterValues,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              resetAllValues({
                resetToPrevApplied: false
              });
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const isFilterEmpty = !appliedFilter;
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              filterType: "string",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: stringFilterValues,
              operatorValue,
              selectedFilterOptions,
              isFilterEmpty,
              event,
              setIsOpen,
              resetAllValues
            });
            if (continueToReset !== false && resetOnCancel) {
              resetAllValues({
                resetToPrevApplied: !isFilterEmpty
              });
            }
          },
          showApply,
          showClear,
          showCancel,
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "string-filter-type-button-footer"
        },
        options: isNullOrNotNullOperator(operatorValue) ? [creatableStringFilterRenderOptions[0]] : creatableStringFilterRenderOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx50(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            ref: forwardedRef,
            selectedFilters: isNullOrNotNullOperator(operatorValue) ? (
              // a hack to get the nullish operator to render properly
              [{ label: operatorOption.label, value: "" }]
            ) : selectedFilterOptions,
            filtersActive,
            showRemove,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                filterType: "string",
                filterKey: filter.key,
                filterLabel: filter.label,
                filterValue: stringFilterValues,
                operatorValue,
                selectedFilterOptions,
                resetAllValues,
                event
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(filterButtonProps) : /* @__PURE__ */ jsxs33(Fragment12, { children: [
              /* @__PURE__ */ jsx50("strong", { children: filter.label }),
              " ",
              // a hack to get the nullish operator to render properly
              isNullOrNotNullOperator(operatorValue) ? "" : operatorOption.label
            ] })
          })
        )
      })
    );
  }
);
StringFilterType.displayName = "StringFilterType";

// src/FilterType/VersionFilterType.tsx
import { t as t16 } from "@kandji-inc/nectar-i18n";
import * as React45 from "react";
import { Fragment as Fragment13, jsx as jsx51, jsxs as jsxs34 } from "react/jsx-runtime";
var VersionFilterType = React45.forwardRef(
  ({
    children,
    filter,
    filtersActive,
    operatorOptions,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showApply,
    showClear,
    showCancel,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    maxValuesCount,
    testId,
    dropdownProps = {},
    buttonProps = {}
  }, forwardedRef) => {
    var _a;
    const [appliedFilter, setAppliedFilter] = React45.useState(() => {
      const [initialFilter] = validateVersionFilterSchema(filter);
      return initialFilter;
    });
    const [operatorValue, setOperatorValue] = React45.useState(
      filter.operator || ""
    );
    const [operatorOption, setOperatorOption] = React45.useState(() => {
      var _a2;
      const foundOperatorOption = filter.operator ? [
        ...constantsFactory().STRING_OPERATOR_CONTAINS_OPTIONS,
        ...constantsFactory().NUM_OPERATOR_EQUALS_OPTIONS,
        ...constantsFactory().NUM_OPERATOR_NOT_EQUALS_OPTIONS,
        ...constantsFactory().NULLABLE_OPERATOR_OPTIONS,
        ...constantsFactory().VERSION_OPERATOR_IN_BETWEEN_OPTIONS
      ].find((option) => option.value === filter.operator) : null;
      if (foundOperatorOption) {
        return {
          value: foundOperatorOption.value,
          label: foundOperatorOption.label,
          meta: {
            multi: (_a2 = foundOperatorOption.meta) == null ? void 0 : _a2.multi
          }
        };
      }
      return {
        label: "",
        value: "",
        meta: {
          multi: false
        }
      };
    });
    const inputType = React45.useMemo(() => {
      var _a2;
      if (((_a2 = operatorOption.meta) == null ? void 0 : _a2.multi) === false) {
        return isInBetweenOperator(operatorOption.value) ? "range" : "single";
      }
      return "multi";
    }, [operatorOption]);
    const isSingleNumberValueInput = ((_a = operatorOption == null ? void 0 : operatorOption.meta) == null ? void 0 : _a.multi) === false;
    const [stringFilterSearch, setStringFilterSearch] = React45.useState(
      typeof (appliedFilter == null ? void 0 : appliedFilter.value) === "string" ? appliedFilter.value : ""
    );
    const [stringFilterValues, setStringFilterValues] = React45.useState(
      filter.value || []
    );
    const [selectedFilterOptions, setSelectedFilterOptions] = React45.useState(
      () => Array.isArray(filter.value) ? filter.value.map((val) => ({
        value: val,
        label: val
      })) : [filter.value].filter(Boolean).map((val) => ({
        value: val,
        label: val
      }))
    );
    const [errors, setErrors] = React45.useState({
      value: "",
      value_from: "",
      value_to: "",
      operator: ""
    });
    React45.useEffect(() => {
      if (isNullOrNotNullOperator(filter.operator)) {
        const nullValue = getNullValue({ is_null: filter.value.toString() });
        setOperatorValue(nullValue);
      }
    }, [filter.operator, filter.value]);
    const resetInputValues = React45.useCallback(() => {
      setStringFilterSearch("");
      setStringFilterValues([]);
      setSelectedFilterOptions([]);
    }, []);
    const handleTextInputChange = React45.useCallback(() => {
      setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
    }, []);
    const handleOperatorChange = React45.useCallback(
      (value) => {
        var _a2;
        if (operatorOption.value === value) {
          return;
        }
        const sectionChanged = (
          // @ts-ignore
          ((_a2 = operatorOption.originalSection) == null ? void 0 : _a2.options.find(
            // @ts-ignore
            (option) => option.value === value
          )) == null
        );
        if (isNullOrNotNullOperator(value) || sectionChanged) {
          resetInputValues();
        }
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { operator: "" }));
      },
      [operatorOption, resetInputValues]
    );
    const handleFromInputChange = React45.useCallback(
      (e) => {
        const from = e.currentTarget.value;
        setStringFilterValues((prevValues) => [from, prevValues[1] || ""]);
        setSelectedFilterOptions((prevOptions) => {
          var _a2, _b2;
          return [
            {
              value: from,
              label: from
            },
            {
              value: ((_a2 = prevOptions[1]) == null ? void 0 : _a2.value) || "",
              label: ((_b2 = prevOptions[1]) == null ? void 0 : _b2.value) || ""
            }
          ];
        });
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value_from: "" }));
      },
      []
    );
    const handleToInputChange = React45.useCallback(
      (e) => {
        const to = e.currentTarget.value;
        setStringFilterValues((prevValues) => [prevValues[0] || "", to]);
        setSelectedFilterOptions((prevOptions) => {
          var _a2, _b2;
          return [
            {
              value: ((_a2 = prevOptions[0]) == null ? void 0 : _a2.value) || "",
              label: ((_b2 = prevOptions[0]) == null ? void 0 : _b2.value) || ""
            },
            {
              value: to,
              label: to
            }
          ];
        });
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value_to: "" }));
      },
      []
    );
    const handleSingleNumberInputChange = React45.useCallback(
      (e) => {
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
        setStringFilterSearch(e.currentTarget.value);
        setSelectedFilterOptions([
          {
            value: e.currentTarget.value,
            label: e.currentTarget.value
          }
        ]);
      },
      []
    );
    const operatorFilterSelectProps = React45.useMemo(
      () => ({
        testId: "version-filter-type-operator-select",
        operatorType: "version",
        operatorValue,
        operatorOption,
        onChange: handleOperatorChange,
        options: operatorOptions,
        setOperatorValue,
        setOperatorOption,
        error: errors.operator
      }),
      [
        errors.operator,
        handleOperatorChange,
        operatorOption,
        operatorOptions,
        operatorValue
      ]
    );
    const versionRangesProps = React45.useMemo(
      () => ({
        testId: "version-filter-type-range-inputs",
        handleFromInputChange,
        handleToInputChange,
        errors,
        values: stringFilterValues
      }),
      [handleFromInputChange, handleToInputChange, stringFilterValues, errors]
    );
    const singleNumberInputProps = React45.useMemo(
      () => ({
        testId: "version-filter-type-single-text-input",
        value: stringFilterSearch,
        onChange: handleSingleNumberInputChange,
        placeholder: t16("Enter value"),
        hint: {
          label: errors.value
        },
        state: errors.value ? "error" : "default",
        wFull: true
      }),
      [stringFilterSearch, handleSingleNumberInputChange, errors.value]
    );
    const multiNumberInputProps = React45.useMemo(
      () => ({
        testId: "version-filter-type-multi-select",
        stringFilterSearch,
        setStringFilterSearch,
        stringFilterValues,
        setStringFilterValues,
        setStringFilterOptions: setSelectedFilterOptions,
        handleTextInputChange,
        placeholder: t16("Enter value and hit enter to add"),
        maxValuesCount,
        error: errors.value
      }),
      [
        stringFilterValues,
        stringFilterSearch,
        handleTextInputChange,
        maxValuesCount,
        errors.value
      ]
    );
    const creatableVersionFilterRenderOptions = React45.useMemo(() => {
      const renderOptions = [
        __spreadValues({
          render: OperatorFilterSelect
        }, operatorFilterSelectProps)
      ];
      if (isNullOrNotNullOperator(operatorValue)) {
        return renderOptions;
      }
      if (inputType === "multi") {
        renderOptions.push(__spreadValues({
          // @ts-ignore
          render: CreatableStringFilterMultiSelect
        }, multiNumberInputProps));
      } else if (inputType === "single") {
        renderOptions.push(__spreadValues({
          // @ts-ignore
          render: StyledSingleNumberValueInput
        }, singleNumberInputProps));
      } else {
        renderOptions.push(__spreadValues({
          // @ts-ignore
          render: VersionFilterRangeInputs
        }, versionRangesProps));
      }
      return renderOptions;
    }, [
      operatorFilterSelectProps,
      operatorValue,
      singleNumberInputProps,
      multiNumberInputProps,
      versionRangesProps,
      inputType
    ]);
    const _b = dropdownProps, { css: dropdownCss = {} } = _b, restDropdownProps = __objRest(_b, ["css"]);
    const versionOperators = constantsFactory().VERSION_OPERATOR_LABEL_MAP;
    const isKeyOfStringOpMap = (op) => op in versionOperators;
    const resetAllValues = ({ resetToPrevApplied } = {
      resetToPrevApplied: true
    }) => {
      var _a2, _b2;
      if (!resetToPrevApplied) {
        setStringFilterSearch("");
        setStringFilterValues([]);
        setSelectedFilterOptions([]);
        setOperatorValue("");
        setOperatorOption({
          label: "",
          value: "",
          meta: { multi: false }
        });
        return setAppliedFilter(null);
      }
      if (!(appliedFilter == null ? void 0 : appliedFilter.operator)) {
        return void 0;
      }
      const appliedOperatorLabel = isKeyOfStringOpMap(appliedFilter.operator) ? constantsFactory().VERSION_OPERATOR_LABEL_MAP[appliedFilter.operator] : (_a2 = operatorOptions == null ? void 0 : operatorOptions.find(
        (option) => option.value === appliedFilter.operator
      )) == null ? void 0 : _a2.label;
      if (appliedOperatorLabel) {
        setOperatorValue(appliedFilter.operator);
        setOperatorOption({
          label: appliedOperatorLabel,
          value: appliedFilter.operator,
          meta: { multi: !isSingleNumberValueInput }
        });
        if (Array.isArray(appliedFilter.value)) {
          setStringFilterValues(appliedFilter.value);
          setSelectedFilterOptions(
            ((_b2 = appliedFilter.value) == null ? void 0 : _b2.map((value) => ({
              value,
              label: value
            }))) || []
          );
        } else {
          setSelectedFilterOptions([
            {
              value: appliedFilter.value,
              label: appliedFilter.value
            }
          ]);
          setStringFilterSearch(appliedFilter.value);
        }
      }
      return void 0;
    };
    let selectedFilters = selectedFilterOptions;
    if (isInBetweenOperator(operatorOption.value)) {
      selectedFilters = [
        {
          label: selectedFilterOptions.map((o) => o.value).join(" - "),
          value: ""
        }
      ];
    } else if (isNullOrNotNullOperator(operatorOption.value)) {
      selectedFilters = [
        {
          label: operatorOption.label,
          value: ""
        }
      ];
    }
    return /* @__PURE__ */ jsx51(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const isFilterEmpty = !appliedFilter;
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            filterType: "version",
            filterKey: filter.key,
            filterLabel: filter.label,
            filterValue: stringFilterValues,
            operatorValue,
            selectedFilterOptions,
            isFilterEmpty,
            setIsOpen,
            resetInputValues,
            resetAllValues
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            resetAllValues({
              resetToPrevApplied: !isFilterEmpty
            });
          }
          return true;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true,
          disableTypeaheadSearch: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            var _a2, _b2, _c, _d;
            const trimmedSearch = stringFilterSearch.trim();
            const searchAsFilterValues = trimmedSearch ? [trimmedSearch] : [];
            const applyValue = inputType === "range" || inputType === "multi" ? [...stringFilterValues, ...searchAsFilterValues] : trimmedSearch;
            const toApply = {
              key: filter.key,
              label: filter.label,
              value: applyValue,
              operator: operatorValue
            };
            const [validatedFilter, errorMap] = validateVersionFilterSchema(toApply);
            if (!validatedFilter) {
              setErrors({
                value: ((_a2 = errorMap == null ? void 0 : errorMap.value) == null ? void 0 : _a2.message) || "",
                operator: ((_b2 = errorMap == null ? void 0 : errorMap.operator) == null ? void 0 : _b2.message) || "",
                value_from: ((_c = errorMap == null ? void 0 : errorMap.value_from) == null ? void 0 : _c.message) || "",
                value_to: ((_d = errorMap == null ? void 0 : errorMap.value_to) == null ? void 0 : _d.message) || ""
              });
              return false;
            }
            setErrors({
              value: "",
              operator: "",
              value_from: "",
              value_to: ""
            });
            const continueToReset = handleApply == null ? void 0 : handleApply({
              filterType: "version",
              filterKey: validatedFilter.key,
              filterLabel: validatedFilter.label,
              filterValue: validatedFilter.value,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetInputValues,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return resetAllValues();
            }
            if (inputType === "multi") {
              setStringFilterValues(validatedFilter.value);
              setSelectedFilterOptions(
                applyValue.map((value) => ({
                  value,
                  label: value
                }))
              );
              setStringFilterSearch("");
            }
            return setAppliedFilter(toApply);
          },
          handleClear: (event, { setIsOpen }) => {
            const continueToReset = handleClear == null ? void 0 : handleClear({
              filterType: "version",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: stringFilterValues,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetInputValues,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              resetAllValues({
                resetToPrevApplied: false
              });
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const isFilterEmpty = !appliedFilter;
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              filterType: "version",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: stringFilterValues,
              operatorValue,
              selectedFilterOptions,
              isFilterEmpty,
              event,
              setIsOpen,
              resetInputValues,
              resetAllValues
            });
            if (continueToReset !== false && resetOnCancel) {
              resetAllValues({
                resetToPrevApplied: !isFilterEmpty
              });
            }
          },
          showApply,
          showClear,
          showCancel,
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "version-filter-type-button-footer"
        },
        options: isNullOrNotNullOperator(operatorValue) ? [creatableVersionFilterRenderOptions[0]] : creatableVersionFilterRenderOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx51(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            ref: forwardedRef,
            selectedFilters,
            filtersActive,
            showRemove,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                filterType: "version",
                filterKey: filter.key,
                filterLabel: filter.label,
                filterValue: stringFilterValues,
                operatorValue,
                selectedFilterOptions,
                resetInputValues,
                resetAllValues,
                event
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(filterButtonProps) : /* @__PURE__ */ jsxs34(Fragment13, { children: [
              /* @__PURE__ */ jsx51("strong", { children: filter.label }),
              " ",
              // a hack to get the nullish operator to render properly
              isNullOrNotNullOperator(operatorValue) ? "" : operatorOption.label
            ] })
          })
        )
      })
    );
  }
);
VersionFilterType.displayName = "VersionFilterType";

// src/FilterType/TimeFilterType.tsx
import { t as t17 } from "@kandji-inc/nectar-i18n";
import * as React47 from "react";

// src/FilterType/BaseFilterType/components/TimeFilterValueSelect.tsx
import * as React46 from "react";
import { jsx as jsx52, jsxs as jsxs35 } from "react/jsx-runtime";
var TimeFilterValueSelect = React46.forwardRef((_a, ref) => {
  var _b = _a, { singular, units, onUnitsChange } = _b, props = __objRest(_b, ["singular", "units", "onUnitsChange"]);
  return /* @__PURE__ */ jsxs35(Flex, { flow: "row", gap: "xs", ref, children: [
    singular ? /* @__PURE__ */ jsx52(StyledSingleNumberValueInput, __spreadProps(__spreadValues({}, props), { css: { width: 200 } })) : /* @__PURE__ */ jsx52(
      CreatableStringFilterMultiSelect,
      __spreadProps(__spreadValues({}, props), {
        triggerCss: { width: 200 }
      })
    ),
    /* @__PURE__ */ jsx52(
      Select,
      {
        componentCss: {
          trigger: { width: 116 }
        },
        value: units,
        options: [
          {
            value: "seconds",
            label: "seconds"
          },
          {
            value: "minutes",
            label: "minutes"
          },
          {
            value: "hours",
            label: "hours"
          }
        ],
        onChange: onUnitsChange
      }
    )
  ] });
});
TimeFilterValueSelect.displayName = "TimeFilterValueSelect";

// src/FilterType/TimeFilterType.tsx
import { Fragment as Fragment14, jsx as jsx53, jsxs as jsxs36 } from "react/jsx-runtime";
var defaultIntRegex = /^\d+$/;
var toUnits = (label, units) => label + " " + units;
var TimeFilterType = React47.forwardRef(
  ({
    children,
    filter,
    filtersActive,
    operatorOptions,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showApply,
    showClear,
    showCancel,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    searchPattern = defaultIntRegex,
    maxValuesCount,
    testId,
    dropdownProps = {},
    buttonProps = {}
  }, forwardedRef) => {
    var _a;
    const [appliedFilter, setAppliedFilter] = React47.useState(() => {
      const [initialFilter] = validateNumFilterSchema(filter);
      return initialFilter;
    });
    const [operatorValue, setOperatorValue] = React47.useState(
      filter.operator || ""
    );
    const [operatorOption, setOperatorOption] = React47.useState(() => {
      const foundOperatorOption = filter.operator ? [
        ...constantsFactory().NUM_OPERATOR_EQUALS_OPTIONS,
        ...constantsFactory().NUM_OPERATOR_NOT_EQUALS_OPTIONS,
        ...constantsFactory().NULLABLE_OPERATOR_OPTIONS
      ].find((option) => option.value === filter.operator) : null;
      if (foundOperatorOption) {
        return {
          value: foundOperatorOption.value,
          label: foundOperatorOption.label,
          meta: {
            multi: foundOperatorOption.meta.multi
          }
        };
      }
      return {
        value: "",
        label: "",
        meta: {
          multi: true
        }
      };
    });
    const [stringFilterSearch, setStringFilterSearch] = React47.useState("");
    const [stringFilterValues, setStringFilterValues] = React47.useState(
      filter.value || []
    );
    const [units, setUnits] = React47.useState(filter.units || "minutes");
    const [selectedFilterOptions, setSelectedFilterOptions] = React47.useState(
      () => Array.isArray(filter.value) ? filter.value.map((val) => ({
        value: val,
        label: toUnits(val, units)
      })) : [filter.value].filter(Boolean).map((val) => ({
        value: val,
        label: toUnits(val, units)
      }))
    );
    const [errors, setErrors] = React47.useState({
      value: "",
      operator: ""
    });
    React47.useEffect(() => {
      if (isNullOrNotNullOperator(filter.operator)) {
        const nullValue = getNullValue({ is_null: filter.value.toString() });
        setOperatorValue(nullValue);
      }
    }, []);
    const isSingleNumberValueInput = ((_a = operatorOption == null ? void 0 : operatorOption.meta) == null ? void 0 : _a.multi) === false;
    const handleTextInputChange = React47.useCallback(() => {
      setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
    }, []);
    const resetInputValues = React47.useCallback(() => {
      setStringFilterSearch("");
      setStringFilterValues([]);
      setSelectedFilterOptions([]);
    }, []);
    const numOperators = constantsFactory().NUM_OPERATOR_LABEL_MAP;
    const isKeyOfNumOpMap = (op) => op in numOperators;
    const resetAllValues = React47.useCallback(
      ({ resetToPrevApplied } = {
        resetToPrevApplied: true
      }) => {
        var _a2;
        if (!resetToPrevApplied) {
          setStringFilterSearch("");
          setStringFilterValues([]);
          setSelectedFilterOptions([]);
          setOperatorValue("");
          setOperatorOption({
            label: "",
            value: "",
            meta: {
              multi: true
            }
          });
          return setAppliedFilter(null);
        }
        if (!(appliedFilter == null ? void 0 : appliedFilter.operator)) {
          return void 0;
        }
        const appliedOperatorLabel = isKeyOfNumOpMap(appliedFilter.operator) ? constantsFactory().NUM_OPERATOR_LABEL_MAP[appliedFilter.operator] : (_a2 = operatorOptions == null ? void 0 : operatorOptions.find(
          (option) => option.value === (appliedFilter == null ? void 0 : appliedFilter.operator)
        )) == null ? void 0 : _a2.label;
        if (appliedOperatorLabel) {
          setOperatorValue(appliedFilter.operator);
          setOperatorOption({
            label: appliedOperatorLabel,
            value: appliedFilter.operator,
            meta: {
              multi: !isSingleNumberValueInput
            }
          });
          setStringFilterSearch("");
          if (Array.isArray(appliedFilter.value)) {
            setStringFilterValues(appliedFilter.value);
            setStringFilterSearch("");
            return setSelectedFilterOptions(
              appliedFilter.value.map((value) => ({
                value,
                label: toUnits(value, units)
              })) || []
            );
          }
          setStringFilterSearch(appliedFilter.value || "");
          return setSelectedFilterOptions(
            appliedFilter.value ? [
              {
                value: appliedFilter.value,
                label: toUnits(appliedFilter.value, units)
              }
            ] : []
          );
        }
        return void 0;
      },
      [appliedFilter, isSingleNumberValueInput, operatorOptions, units]
    );
    const handleOperatorChange = React47.useCallback(
      (value, optionsMeta) => {
        var _a2, _b2;
        const isNullOperator2 = isNullOrNotNullOperator(value);
        const isChangeNumberInputType = isSingleNumberValueInput ? !!((_a2 = optionsMeta.option.meta) == null ? void 0 : _a2.multi) : ((_b2 = optionsMeta.option.meta) == null ? void 0 : _b2.multi) === false;
        setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { operator: "" }));
        if (isChangeNumberInputType || isNullOperator2) {
          resetInputValues();
        }
      },
      [isSingleNumberValueInput, resetInputValues]
    );
    const handleSingleNumberInputChange = React47.useCallback(
      (e) => {
        const isValidNumberValue = publicSelectUtils.checkSearchPattern(
          searchPattern,
          e.currentTarget.value
        );
        if (isValidNumberValue) {
          setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { value: "" }));
          setStringFilterSearch(e.currentTarget.value);
          setSelectedFilterOptions([
            {
              value: e.currentTarget.value,
              label: toUnits(e.currentTarget.value, units)
            }
          ]);
        }
      },
      [searchPattern, units]
    );
    const handleUnitsChange = React47.useCallback(
      (units2) => setUnits(units2),
      []
    );
    React47.useEffect(() => {
      setSelectedFilterOptions(
        selectedFilterOptions.map((option) => ({
          value: option.value,
          label: toUnits(option.value, units)
        }))
      );
    }, [units]);
    const timeSelectorProps = React47.useMemo(
      () => isSingleNumberValueInput ? {
        testId: "time-filter-type-single-text-input",
        value: stringFilterSearch,
        onChange: handleSingleNumberInputChange,
        onUnitsChange: handleUnitsChange,
        placeholder: t17("Enter value"),
        hint: {
          label: errors.value
        },
        state: errors.value ? "error" : "default",
        wFull: true,
        singular: true,
        units
      } : {
        testId: "time-filter-type-multi-select",
        stringFilterSearch,
        setStringFilterSearch,
        stringFilterValues,
        setStringFilterValues,
        setStringFilterOptions: (options) => {
          const displayOptions = options.map(({ value, label }) => ({
            value,
            label: toUnits(label, units)
          }));
          setSelectedFilterOptions(displayOptions);
        },
        handleTextInputChange,
        onUnitsChange: handleUnitsChange,
        placeholder: t17("Enter value"),
        searchPattern,
        maxValuesCount,
        error: errors.value,
        singular: false,
        units
      },
      [
        isSingleNumberValueInput,
        stringFilterSearch,
        handleSingleNumberInputChange,
        errors.value,
        stringFilterValues,
        handleTextInputChange,
        searchPattern,
        maxValuesCount,
        units
      ]
    );
    const operatorFilterSelectProps = React47.useMemo(
      () => ({
        testId: "time-filter-type-operator-select",
        operatorType: "num",
        operatorValue,
        operatorOption,
        onChange: handleOperatorChange,
        options: operatorOptions,
        setOperatorValue,
        setOperatorOption,
        error: errors.operator
      }),
      [
        errors.operator,
        handleOperatorChange,
        operatorOption,
        operatorOptions,
        operatorValue
      ]
    );
    const creatableNumFilterRenderOptions = React47.useMemo(
      () => [
        __spreadValues({
          render: OperatorFilterSelect
        }, operatorFilterSelectProps),
        __spreadValues({
          render: TimeFilterValueSelect
        }, timeSelectorProps)
      ],
      [operatorFilterSelectProps, isSingleNumberValueInput, timeSelectorProps]
    );
    const _b = dropdownProps, { css: dropdownCss = {} } = _b, restDropdownProps = __objRest(_b, ["css"]);
    return /* @__PURE__ */ jsx53(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const isFilterEmpty = !appliedFilter;
          const filterValue = isSingleNumberValueInput ? stringFilterSearch : stringFilterValues;
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            filterType: "time",
            filterKey: filter.key,
            filterLabel: filter.label,
            filterValue,
            filterUnits: units,
            operatorValue,
            selectedFilterOptions,
            isFilterEmpty,
            setIsOpen,
            resetInputValues,
            resetAllValues
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            resetAllValues({
              resetToPrevApplied: !isFilterEmpty
            });
          }
          return void 0;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true,
          disableTypeaheadSearch: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            var _a2, _b2;
            const trimmedSearch = stringFilterSearch.trim();
            const searchAsFilterValues = trimmedSearch ? [stringFilterSearch] : [];
            const applyValue = isSingleNumberValueInput ? stringFilterSearch : [...stringFilterValues, ...searchAsFilterValues];
            const toApply = {
              key: filter.key,
              label: filter.label,
              value: applyValue,
              operator: operatorValue,
              units
            };
            const [validatedFilter, errorMap] = validateNumFilterSchema(toApply);
            if (!validatedFilter) {
              setErrors({
                value: ((_a2 = errorMap == null ? void 0 : errorMap.value) == null ? void 0 : _a2.message) || "",
                operator: ((_b2 = errorMap == null ? void 0 : errorMap.operator) == null ? void 0 : _b2.message) || ""
              });
              return false;
            }
            const continueToReset = handleApply == null ? void 0 : handleApply({
              filterType: "time",
              filterKey: validatedFilter.key,
              filterLabel: validatedFilter.label,
              filterValue: applyValue,
              filterUnits: units,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetInputValues,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return resetAllValues();
            }
            if (Array.isArray(validatedFilter.value)) {
              setStringFilterValues(validatedFilter.value);
              setSelectedFilterOptions(
                validatedFilter.value.map((value) => ({
                  value,
                  label: toUnits(value, units)
                }))
              );
              setStringFilterSearch("");
            }
            return setAppliedFilter(toApply);
          },
          handleClear: (event, { setIsOpen }) => {
            const filterValue = isSingleNumberValueInput ? stringFilterSearch : stringFilterValues;
            const continueToReset = handleClear == null ? void 0 : handleClear({
              filterType: "time",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue,
              filterUnits: units,
              operatorValue,
              selectedFilterOptions,
              setIsOpen,
              resetInputValues,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              resetAllValues({
                resetToPrevApplied: false
              });
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const isFilterEmpty = !appliedFilter;
            const filterValue = isSingleNumberValueInput ? stringFilterSearch : stringFilterValues;
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              filterType: "time",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue,
              filterUnits: units,
              operatorValue,
              selectedFilterOptions,
              isFilterEmpty,
              event,
              setIsOpen,
              resetInputValues,
              resetAllValues
            });
            if (continueToReset !== false && resetOnCancel) {
              resetAllValues({
                resetToPrevApplied: !isFilterEmpty
              });
            }
          },
          showApply,
          showClear,
          showCancel,
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "time-filter-type-button-footer"
        },
        options: isNullOrNotNullOperator(operatorValue) ? [creatableNumFilterRenderOptions[0]] : creatableNumFilterRenderOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx53(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            ref: forwardedRef,
            selectedFilters: isNullOrNotNullOperator(operatorValue) ? (
              // a hack to get the nullish operator to render properly
              [{ label: operatorOption.label, value: "" }]
            ) : selectedFilterOptions,
            filtersActive,
            showRemove,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                filterType: "time",
                filterKey: filter.key,
                filterLabel: filter.label,
                filterValue: stringFilterValues,
                filterUnits: units,
                operatorValue,
                selectedFilterOptions,
                resetInputValues,
                resetAllValues,
                event
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(filterButtonProps) : /* @__PURE__ */ jsxs36(Fragment14, { children: [
              /* @__PURE__ */ jsx53("strong", { children: filter.label }),
              " ",
              // a hack to get the nullish operator to render properly
              isNullOrNotNullOperator(operatorValue) ? "" : operatorOption.label
            ] })
          })
        )
      })
    );
  }
);
TimeFilterType.displayName = "TimeFilterType";

// src/FilterType/DateFilterType.tsx
import * as React49 from "react";

// src/FilterType/hooks/useDateFilter.ts
import * as React48 from "react";
import { getJSLocale as getJSLocale2, t as t18 } from "@kandji-inc/nectar-i18n";
function useDateFilter({
  filter,
  operatorOptions,
  enumOptions,
  errors,
  setErrors
}) {
  var _a, _b, _c;
  const [enumSelectOption, setEnumSelectOption] = React48.useState(() => {
    var _a2;
    if (filter.value) {
      return (_a2 = enumOptions.flatMap(
        (option) => "options" in option ? option.options : option
      ).find((opt) => opt.value === filter.value)) != null ? _a2 : null;
    }
    return null;
  });
  const [selectedFilterOptions, setSelectedFilterOptions] = React48.useState(() => {
    const initialSelectedFilterOption = enumOptions.flatMap((option) => "options" in option ? option.options : option).find((option) => option.value === filter.value);
    return initialSelectedFilterOption ? [initialSelectedFilterOption] : [];
  });
  const [operatorValue, setOperatorValue] = React48.useState(
    filter.operator || ""
  );
  const dateOperators = constantsFactory().DATE_OPERATOR_LABEL_MAP;
  const isKeyOfDateOpMap = (op) => op in dateOperators;
  const [operatorOption, setOperatorOption] = React48.useState(
    () => isKeyOfDateOpMap(filter.operator) ? {
      value: filter.operator,
      label: constantsFactory().DATE_OPERATOR_LABEL_MAP[filter.operator]
    } : {
      value: "",
      label: ""
    }
  );
  const [singleSelectedDates, setSingleSelectedDates] = React48.useState(filter.selectedDate ? [filter.selectedDate] : []);
  const [fromDates, setFromDates] = React48.useState(
    ((_a = filter.dateRange) == null ? void 0 : _a.from) ? [filter.dateRange.from] : []
  );
  const [toDates, setToDates] = React48.useState(
    ((_b = filter.dateRange) == null ? void 0 : _b.to) ? [filter.dateRange.to] : []
  );
  const clearDates = React48.useCallback(() => {
    setSingleSelectedDates([]);
  }, []);
  const onDatePickerChange = React48.useCallback(
    (val) => {
      setSingleSelectedDates(val);
      setErrors((prev) => __spreadProps(__spreadValues({}, prev), {
        selectedDate: ""
      }));
    },
    [setErrors]
  );
  const datePickerProps = React48.useMemo(
    () => ({
      testId: "date-filter-type-date-picker",
      value: singleSelectedDates,
      onChange: onDatePickerChange,
      onClear: clearDates,
      datePickerCss: {
        top: "142px"
      },
      showCalendarIcon: false,
      showDropdownIcon: true,
      showClearButton: false,
      placeholder: t18("Select date"),
      hint: errors.selectedDate ? { label: errors.selectedDate, variant: "error" } : null
    }),
    [clearDates, errors.selectedDate, onDatePickerChange, singleSelectedDates]
  );
  const onRelativeDatePickerChange = React48.useCallback(
    (relativeDate) => {
      setSingleSelectedDates([relativeDate]);
      setErrors((prev) => __spreadProps(__spreadValues({}, prev), {
        selectedDate: ""
      }));
    },
    [setErrors]
  );
  const relativeDatePickerProps = React48.useMemo(
    () => ({
      testId: "date-filter-type-relative-date-picker",
      value: (singleSelectedDates == null ? void 0 : singleSelectedDates[0]) || "",
      hint: errors.selectedDate,
      onChange: onRelativeDatePickerChange,
      onClear: clearDates
    }),
    [
      clearDates,
      onRelativeDatePickerChange,
      singleSelectedDates,
      errors.selectedDate
    ]
  );
  const onDateRangePickerFromChange = React48.useCallback(
    (val) => {
      setFromDates(val);
      setErrors((prev) => __spreadProps(__spreadValues({}, prev), {
        "dateRange.from": ""
      }));
    },
    [setErrors]
  );
  const onDateRangePickerToChange = React48.useCallback(
    (val) => {
      setToDates(val);
      setErrors((prev) => __spreadProps(__spreadValues({}, prev), {
        "dateRange.to": ""
      }));
    },
    [setErrors]
  );
  const dateRangePickerProps = React48.useMemo(
    () => ({
      testId: "date-filter-type-date-range-picker",
      from: {
        value: fromDates,
        onChange: onDateRangePickerFromChange,
        onClear: () => setFromDates([]),
        hint: errors["dateRange.from"] ? {
          label: errors["dateRange.from"],
          variant: "error"
        } : null
      },
      to: {
        value: toDates,
        onChange: onDateRangePickerToChange,
        onClear: () => setToDates([]),
        hint: errors["dateRange.to"] ? {
          label: errors["dateRange.to"],
          variant: "error"
        } : null
      },
      datePickerCss: {
        top: "142px",
        right: "24px"
      },
      containerCss: {
        justifyContent: "space-between",
        width: "100%"
      }
    }),
    [
      errors,
      fromDates,
      onDateRangePickerFromChange,
      onDateRangePickerToChange,
      toDates
    ]
  );
  const relativeDateRangePickerProps = React48.useMemo(
    () => ({
      testId: "date-filter-type-relative-date-range-picker",
      from: {
        value: fromDates,
        onChange: (from) => onDateRangePickerFromChange([from]),
        hint: errors["dateRange.from"]
      },
      to: {
        value: toDates,
        onChange: (to) => onDateRangePickerToChange([to]),
        hint: errors["dateRange.to"]
      }
    }),
    [
      errors,
      fromDates,
      onDateRangePickerFromChange,
      onDateRangePickerToChange,
      toDates
    ]
  );
  const [enumSelectValue, setEnumSelectValue] = React48.useState(filter.value);
  const enumSelectSection = React48.useRef(
    (_c = enumSelectOption == null ? void 0 : enumSelectOption.section) != null ? _c : null
  );
  const onEnumSelectChange = React48.useCallback(
    (value, { option }) => {
      if (isNullOrNotNullOperator(value)) {
        setOperatorValue(value);
        setOperatorOption({ value, label: "" });
      } else {
        setOperatorValue("");
      }
      setEnumSelectOption(option);
      setEnumSelectValue(value);
      if ((option == null ? void 0 : option.section) !== void 0 && option.section !== enumSelectSection.current) {
        const isQuickDate = isDateQuickFilterValue(option.value);
        setFromDates([]);
        setToDates([]);
        setSingleSelectedDates([]);
        setOperatorValue(isQuickDate ? "eq" : "");
        setOperatorOption({
          label: isQuickDate ? "is" : "",
          value: isQuickDate ? "eq" : ""
        });
        enumSelectSection.current = option.section;
      }
      return setErrors((prev) => __spreadProps(__spreadValues({}, prev), {
        value: ""
      }));
    },
    [setErrors]
  );
  const initialSelectRenderProps = React48.useMemo(
    () => ({
      testId: "date-filter-type-select",
      value: enumSelectValue,
      onChange: onEnumSelectChange,
      options: enumOptions,
      error: errors.value
    }),
    [enumSelectValue, onEnumSelectChange, enumOptions, errors.value]
  );
  const initialSelectRenderOptions = React48.useMemo(
    () => [
      __spreadValues({
        render: Select
      }, initialSelectRenderProps)
    ],
    [initialSelectRenderProps]
  );
  const onDatePickerOperatorChange = React48.useCallback(() => {
    setErrors((prev) => __spreadProps(__spreadValues({}, prev), {
      operator: ""
    }));
  }, [setErrors]);
  const datePickerOperatorProps = React48.useMemo(
    () => ({
      testId: "date-filter-type-operator-select",
      operatorType: "date",
      operatorValue,
      operatorOption,
      options: operatorOptions,
      setOperatorValue,
      setOperatorOption,
      onChange: onDatePickerOperatorChange,
      error: errors.operator
    }),
    [
      errors.operator,
      onDatePickerOperatorChange,
      operatorOption,
      operatorOptions,
      operatorValue
    ]
  );
  const datePickerOperatorRenderOptions = React48.useMemo(
    () => [
      __spreadValues({
        render: Select
      }, initialSelectRenderProps),
      __spreadValues({
        render: OperatorFilterSelect
      }, datePickerOperatorProps)
    ],
    [datePickerOperatorProps, initialSelectRenderProps]
  );
  const datePickerRenderOptions = React48.useMemo(
    () => [
      __spreadValues({
        render: Select
      }, initialSelectRenderProps),
      __spreadValues({
        render: OperatorFilterSelect
      }, datePickerOperatorProps),
      __spreadValues({
        render: DatePicker
      }, datePickerProps)
    ],
    [datePickerOperatorProps, datePickerProps, initialSelectRenderProps]
  );
  const relativeDatePickerRenderOptions = React48.useMemo(() => {
    const selectProps = __spreadProps(__spreadValues({}, datePickerOperatorProps), {
      key: "relative-date-picker-select",
      // relative dates don't support dne operator
      options: datePickerOperatorProps.options.filter(
        (op) => op.value !== "dne"
      )
    });
    return [
      __spreadValues({
        render: Select
      }, initialSelectRenderProps),
      __spreadValues({
        render: OperatorFilterSelect
      }, selectProps),
      __spreadValues({
        render: RelativeDatePicker
      }, relativeDatePickerProps)
    ];
  }, [
    relativeDatePickerProps,
    datePickerOperatorProps,
    initialSelectRenderProps
  ]);
  const dateRangePickerRenderOptions = React48.useMemo(
    () => [
      __spreadValues({
        render: Select
      }, initialSelectRenderProps),
      __spreadValues({
        render: OperatorFilterSelect
      }, datePickerOperatorProps),
      __spreadValues({
        render: DateRangePicker
      }, dateRangePickerProps)
    ],
    [initialSelectRenderProps, datePickerOperatorProps, dateRangePickerProps]
  );
  const relativeDateRangePickerRenderOptions = React48.useMemo(
    () => [
      __spreadValues({
        render: Select
      }, initialSelectRenderProps),
      __spreadValues({
        render: OperatorFilterSelect
      }, datePickerOperatorProps),
      __spreadValues({
        render: RelativeDateRangePicker
      }, relativeDateRangePickerProps)
    ],
    [
      relativeDateRangePickerProps,
      datePickerOperatorProps,
      initialSelectRenderProps
    ]
  );
  const [dateFilterRenderOptions, setDateFilterRenderOptions] = React48.useState(initialSelectRenderOptions);
  React48.useEffect(() => {
    const { DATE_ENUM } = constantsFactory();
    if (enumSelectValue === DATE_ENUM.custom_date_range.value || enumSelectValue === DATE_ENUM.custom_relative_date_range.value) {
      setDateFilterRenderOptions(datePickerOperatorRenderOptions);
    } else {
      setDateFilterRenderOptions(initialSelectRenderOptions);
    }
  }, [
    datePickerOperatorRenderOptions,
    enumSelectValue,
    initialSelectRenderOptions
  ]);
  React48.useEffect(() => {
    const { DATE_ENUM, DATE_OPERATOR } = constantsFactory();
    if (operatorValue !== DATE_OPERATOR.ib.value && !isNullOrNotNullOperator(operatorValue)) {
      if (enumSelectValue === DATE_ENUM.custom_date_range.value) {
        setDateFilterRenderOptions(datePickerRenderOptions);
      }
      if (enumSelectValue === DATE_ENUM.custom_relative_date_range.value) {
        setDateFilterRenderOptions(relativeDatePickerRenderOptions);
      }
    }
  }, [
    datePickerRenderOptions,
    enumSelectValue,
    operatorValue,
    relativeDatePickerRenderOptions
  ]);
  React48.useEffect(() => {
    const { DATE_ENUM, DATE_OPERATOR } = constantsFactory();
    if (enumSelectValue === DATE_ENUM.custom_date_range.value && operatorValue === DATE_OPERATOR.ib.value) {
      setDateFilterRenderOptions(dateRangePickerRenderOptions);
    }
    if (enumSelectValue === DATE_ENUM.custom_relative_date_range.value && operatorValue === DATE_OPERATOR.ib.value) {
      setDateFilterRenderOptions(relativeDateRangePickerRenderOptions);
    }
  }, [
    dateRangePickerRenderOptions,
    relativeDateRangePickerRenderOptions,
    enumSelectValue,
    operatorValue
  ]);
  React48.useEffect(() => {
    if ((enumSelectOption == null ? void 0 : enumSelectOption.value) === enumSelectValue) {
      setSelectedFilterOptions([enumSelectOption]);
    }
    if (enumSelectValue === constantsFactory().DATE_ENUM.custom_date_range.value) {
      if (operatorValue === constantsFactory().DATE_OPERATOR.ib.value) {
        const from = (fromDates == null ? void 0 : fromDates[0]) instanceof Date ? fromDates[0].toLocaleDateString(getJSLocale2()) : "";
        const to = (toDates == null ? void 0 : toDates[0]) instanceof Date ? toDates[0].toLocaleDateString(getJSLocale2()) : "";
        const label = `${from} - ${to}`;
        setSelectedFilterOptions([
          {
            label: from && to ? label : "",
            value: constantsFactory().DATE_ENUM.custom_date_range.value
          }
        ]);
      } else {
        const label = (singleSelectedDates == null ? void 0 : singleSelectedDates[0]) instanceof Date ? singleSelectedDates[0].toLocaleDateString(getJSLocale2()) : "";
        setSelectedFilterOptions([
          {
            label,
            value: constantsFactory().DATE_ENUM.custom_date_range.value
          }
        ]);
      }
    }
  }, [
    enumSelectValue,
    enumSelectOption,
    fromDates,
    operatorValue,
    singleSelectedDates,
    toDates
  ]);
  React48.useEffect(() => {
    var _a2, _b2, _c2;
    if (enumSelectValue === constantsFactory().DATE_ENUM.custom_relative_date_range.value) {
      if (operatorValue === constantsFactory().DATE_OPERATOR.ib.value) {
        const from = (_a2 = fromDates == null ? void 0 : fromDates[0]) == null ? void 0 : _a2.toString();
        const to = (_b2 = toDates == null ? void 0 : toDates[0]) == null ? void 0 : _b2.toString();
        const label = t18("{from} - {to} ago", { from, to });
        setSelectedFilterOptions([
          {
            label: from && to ? label : "",
            value: constantsFactory().DATE_ENUM.custom_date_range.value
          }
        ]);
      } else {
        const label = t18("{date} ago", {
          date: (_c2 = singleSelectedDates == null ? void 0 : singleSelectedDates[0]) == null ? void 0 : _c2.toString()
        });
        setSelectedFilterOptions([
          {
            label: label || "",
            value: constantsFactory().DATE_ENUM.custom_relative_date_range.value
          }
        ]);
      }
    }
  }, [enumSelectValue, fromDates, operatorValue, singleSelectedDates, toDates]);
  React48.useEffect(() => {
    var _a2;
    if (isNullOrNotNullOperator(filter.operator)) {
      const nullValue = getNullValue({ is_null: filter.value.toString() });
      setOperatorValue(nullValue);
      setEnumSelectValue(nullValue);
      const option = constantsFactory().NULLABLE_OPERATOR_OPTIONS.find(
        (option2) => option2.value === nullValue
      );
      setEnumSelectOption({
        label: (_a2 = option == null ? void 0 : option.label) != null ? _a2 : "",
        value: nullValue
      });
    }
  }, [filter]);
  function resetAllValues({
    appliedFilterTuple = [null, null],
    resetToPrevApplied = true
  } = {}) {
    var _a2, _b2, _c2, _d, _e, _f;
    const [appliedFilter, appliedDateFilterMeta] = appliedFilterTuple;
    if (!appliedFilter || !resetToPrevApplied) {
      setFromDates([]);
      setToDates([]);
      setEnumSelectValue("");
      setOperatorValue("");
      setOperatorOption({
        label: "",
        value: ""
      });
      return clearDates();
    }
    setFromDates(
      ((_a2 = appliedFilter.dateRange) == null ? void 0 : _a2.from) ? [(_b2 = appliedFilter.dateRange) == null ? void 0 : _b2.from] : []
    );
    setToDates(
      ((_c2 = appliedFilter.dateRange) == null ? void 0 : _c2.to) ? [(_d = appliedFilter.dateRange) == null ? void 0 : _d.to] : []
    );
    setEnumSelectValue(appliedFilter.value);
    setEnumSelectOption((_e = appliedDateFilterMeta == null ? void 0 : appliedDateFilterMeta.dateFilterTypeOption) != null ? _e : null);
    setOperatorValue(appliedFilter.operator);
    return setOperatorOption(
      (_f = appliedDateFilterMeta == null ? void 0 : appliedDateFilterMeta.dateOperatorOption) != null ? _f : {
        label: "",
        value: ""
      }
    );
  }
  return {
    dateFilterRenderOptions,
    operatorValue,
    operatorOption,
    selectedDate: (singleSelectedDates == null ? void 0 : singleSelectedDates[0]) || null,
    enumSelectValue,
    enumSelectOption,
    selectedFilterOptions,
    dateRange: {
      from: fromDates[0] || null,
      to: toDates[0] || null
    },
    resetAllValues
  };
}

// src/FilterType/DateFilterType.tsx
import { Fragment as Fragment15, jsx as jsx54, jsxs as jsxs37 } from "react/jsx-runtime";
var DateFilterType = React49.forwardRef(
  ({
    children,
    filter,
    filtersActive,
    operatorOptions,
    enumOptions,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showApply,
    showClear,
    showCancel,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    testId,
    dropdownProps = {},
    buttonProps = {},
    buttonVariant = null
  }, forwardedRef) => {
    const { dateEnumOptions, dateOperatorOptions } = React49.useMemo(() => {
      return {
        dateEnumOptions: Array.isArray(enumOptions) ? enumOptions : constantsFactory().DATE_ENUM_OPTIONS,
        dateOperatorOptions: Array.isArray(operatorOptions) ? operatorOptions : constantsFactory().DATE_OPERATOR_OPTIONS
      };
    }, [enumOptions, operatorOptions]);
    const [errors, setErrors] = React49.useState({
      value: "",
      operator: "",
      selectedDate: "",
      "dateRange.from": "",
      "dateRange.to": ""
    });
    const {
      dateFilterRenderOptions,
      operatorValue,
      operatorOption,
      selectedDate,
      dateRange,
      enumSelectValue,
      enumSelectOption,
      selectedFilterOptions,
      resetAllValues
    } = useDateFilter({
      filter,
      operatorOptions: dateOperatorOptions,
      enumOptions: dateEnumOptions,
      errors,
      setErrors
    });
    const [appliedFilterTuple, setAppliedFilterTuple] = React49.useState([null, null]);
    React49.useEffect(() => {
      const [initialFilter] = validateDateFilterSchema(filter);
      const dateFilterTypeOption = dateEnumOptions.flatMap((option) => "options" in option ? option.options : option).find((option) => option.value === filter.value);
      const dateOperatorOption = dateOperatorOptions == null ? void 0 : dateOperatorOptions.find(
        (option) => option.value === filter.operator
      );
      const dateFilterMeta = dateOperatorOption || dateFilterTypeOption ? {
        dateOperatorOption,
        dateFilterTypeOption
      } : null;
      const newFilterTuple = [initialFilter, dateFilterMeta];
      setAppliedFilterTuple(newFilterTuple);
      resetAllValues({
        appliedFilterTuple: newFilterTuple,
        resetToPrevApplied: true
      });
    }, [filter]);
    const [validFilter, errorMap] = React49.useMemo(() => {
      const toApply = {
        key: filter.key,
        label: filter.label,
        value: enumSelectValue,
        operator: operatorValue,
        dateRange,
        selectedDate
      };
      return validateDateFilterSchema(toApply);
    }, [
      dateRange,
      enumSelectValue,
      filter.key,
      filter.label,
      operatorValue,
      selectedDate
    ]);
    const _a = dropdownProps, { css: dropdownCss = {} } = _a, restDropdownProps = __objRest(_a, ["css"]);
    return /* @__PURE__ */ jsx54(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const [appliedFilter] = appliedFilterTuple;
          const isFilterEmpty = !appliedFilter;
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            filterType: "date",
            filterKey: filter.key,
            filterLabel: filter.label,
            filterValue: enumSelectValue,
            operatorValue,
            dateRange,
            selectedDate,
            selectedFilterOptions,
            isFilterEmpty,
            setIsOpen,
            resetAllValues
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            resetAllValues({
              appliedFilterTuple,
              resetToPrevApplied: !isFilterEmpty
            });
          }
          return true;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            var _a2, _b, _c, _d, _e;
            if (!validFilter || !enumSelectOption) {
              setAppliedFilterTuple([null, null]);
              setErrors({
                value: ((_a2 = errorMap == null ? void 0 : errorMap.value) == null ? void 0 : _a2.message) || "",
                operator: ((_b = errorMap == null ? void 0 : errorMap.operator) == null ? void 0 : _b.message) || "",
                selectedDate: ((_c = errorMap == null ? void 0 : errorMap.selectedDate) == null ? void 0 : _c.message) || "",
                "dateRange.from": ((_d = errorMap == null ? void 0 : errorMap["dateRange.from"]) == null ? void 0 : _d.message) || "",
                "dateRange.to": ((_e = errorMap == null ? void 0 : errorMap["dateRange.to"]) == null ? void 0 : _e.message) || ""
              });
              return false;
            }
            const continueToReset = handleApply == null ? void 0 : handleApply({
              filterType: "date",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: enumSelectValue,
              operatorValue,
              dateRange,
              selectedDate,
              selectedFilterOptions,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return resetAllValues();
            }
            const toApplyTuple = [
              validFilter,
              {
                dateFilterTypeOption: enumSelectOption,
                dateOperatorOption: operatorOption
              }
            ];
            return setAppliedFilterTuple(toApplyTuple);
          },
          handleClear: (event, { setIsOpen }) => {
            const continueToReset = handleClear == null ? void 0 : handleClear({
              filterType: "date",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: enumSelectValue,
              operatorValue,
              dateRange,
              selectedDate,
              selectedFilterOptions,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              setAppliedFilterTuple([null, null]);
              resetAllValues({
                resetToPrevApplied: false
              });
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const [appliedFilter] = appliedFilterTuple;
            const isFilterEmpty = !appliedFilter;
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              filterType: "date",
              filterKey: filter.key,
              filterLabel: filter.label,
              filterValue: enumSelectValue,
              operatorValue,
              dateRange,
              selectedDate,
              selectedFilterOptions,
              isFilterEmpty,
              setIsOpen,
              resetAllValues,
              event
            });
            if (continueToReset !== false && resetOnCancel) {
              resetAllValues({
                appliedFilterTuple,
                resetToPrevApplied: !isFilterEmpty
              });
            }
          },
          showApply,
          showClear,
          showCancel,
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "date-filter-type-button-footer"
        },
        options: dateFilterRenderOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx54(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            ref: forwardedRef,
            itemMaxWidth: 220,
            maxWidth: 440,
            selectedFilters: validFilter ? selectedFilterOptions : void 0,
            filtersActive,
            showRemove,
            variant: buttonVariant,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                filterType: "date",
                filterKey: filter.key,
                filterLabel: filter.label,
                filterValue: enumSelectValue,
                operatorValue,
                dateRange,
                selectedDate,
                selectedFilterOptions,
                resetAllValues,
                event
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(__spreadProps(__spreadValues({}, filterButtonProps), { operator: operatorOption })) : /* @__PURE__ */ jsxs37(Fragment15, { children: [
              /* @__PURE__ */ jsx54("strong", { children: filter.label }),
              " ",
              operatorOption.label
            ] })
          })
        )
      })
    );
  }
);
DateFilterType.displayName = "DateFilterType";

// src/FilterType/CheckboxFilterType.tsx
import * as React50 from "react";
import { Fragment as Fragment16, jsx as jsx55, jsxs as jsxs38 } from "react/jsx-runtime";
var OptionSearch = (props) => /* @__PURE__ */ jsx55(TextField, __spreadValues({ wFull: true, iconLeft: true, icon: "magnifying-glass" }, props));
var CheckboxFilterType = React50.forwardRef(
  ({
    options,
    children,
    filter,
    filtersActive,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    testId,
    dropdownProps = {},
    buttonProps = {},
    searchProps = { enabled: false }
  }, forwardedRef) => {
    const [selected, setSelected] = React50.useState(
      options.map((s) => {
        var _a2;
        return __spreadProps(__spreadValues({}, s), {
          checked: ((_a2 = filter == null ? void 0 : filter.values) == null ? void 0 : _a2.includes(s.value)) || false
        });
      })
    );
    const [appliedSelected, setAppliedSelected] = React50.useState(
      options.map((s) => {
        var _a2;
        return __spreadProps(__spreadValues({}, s), {
          checked: ((_a2 = filter == null ? void 0 : filter.values) == null ? void 0 : _a2.includes(s.value)) || false
        });
      })
    );
    const [searchTerm, setSearchTerm] = React50.useState("");
    const reset = (toPrevious = false) => {
      if (toPrevious) {
        setSelected([...structuredClone(appliedSelected)]);
        return;
      }
      setSelected((prev) => [
        ...prev.map((s) => __spreadProps(__spreadValues({}, s), {
          checked: false
        }))
      ]);
      setAppliedSelected((prev) => [
        ...prev.map((s) => __spreadProps(__spreadValues({}, s), {
          checked: false
        }))
      ]);
    };
    const onCheckItem = (key) => setSelected((prev) => {
      const pSelectIdx = prev.findIndex(({ key: pKey }) => pKey === key);
      prev[pSelectIdx].checked = !prev[pSelectIdx].checked;
      return [...prev];
    });
    const _a = searchProps, { enabled: isOptionSearchEnabled } = _a, optionSearchProps = __objRest(_a, ["enabled"]);
    const optionSearchConfig = __spreadProps(__spreadValues({
      render: OptionSearch
    }, optionSearchProps), {
      onChange: (e) => (
        // @ts-expect-error -- TODO: fix
        setSearchTerm(e.target.value)
      ),
      value: searchTerm
    });
    const checkboxOptions = React50.useMemo(() => {
      const dropdownOpts = [
        ...options.filter(
          ({ label }) => label.toLowerCase().includes(searchTerm.toLowerCase())
        ).map(({ key, label, value }) => ({
          render: (_) => {
            var _a2;
            return /* @__PURE__ */ jsx55(Flex, { onClick: () => onCheckItem(key), children: /* @__PURE__ */ jsx55(
              Checkbox,
              {
                label,
                checked: (_a2 = selected.find(({ key: sKey }) => sKey === key)) == null ? void 0 : _a2.checked,
                value
              }
            ) });
          }
        }))
      ];
      if (isOptionSearchEnabled) {
        dropdownOpts.unshift(optionSearchConfig);
      }
      return dropdownOpts;
    }, [options, selected, searchTerm]);
    const _b = dropdownProps, { css: dropdownCss = {} } = _b, restDropdownProps = __objRest(_b, ["css"]);
    React50.useEffect(() => {
      if (filter && "values" in filter) {
        const setOpts = () => options.map((s) => __spreadProps(__spreadValues({}, s), {
          checked: filter.values.includes(s.value) || false
        }));
        setSelected(setOpts());
        setAppliedSelected(setOpts());
      }
    }, [filter]);
    return /* @__PURE__ */ jsx55(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            value: selected,
            resetAllValues: reset
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            reset(true);
          }
          setSearchTerm("");
          return true;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true,
          disableTypeaheadSearch: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            const continueToReset = handleApply == null ? void 0 : handleApply({
              selected,
              setIsOpen,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return reset();
            }
            setAppliedSelected([...structuredClone(selected)]);
          },
          handleClear: (event, { setIsOpen }) => {
            const continueToReset = handleClear == null ? void 0 : handleClear({
              selected,
              setIsOpen,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              reset();
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              selected,
              setIsOpen,
              event
            });
            if (continueToReset !== false && resetOnCancel) {
              reset(true);
            }
          },
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "checkbox-filter-type-button-footer"
        },
        options: checkboxOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx55(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            variant: "subtle",
            ref: forwardedRef,
            selectedFilters: options.filter((_, idx) => selected[idx].checked),
            filtersActive,
            showRemove,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                value: selected,
                resetAllValues: reset
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(filterButtonProps) : /* @__PURE__ */ jsxs38(Fragment16, { children: [
              /* @__PURE__ */ jsx55("strong", { children: filter.label }),
              options.filter((_, idx) => selected[idx].checked).length > 0 && ":"
            ] })
          })
        )
      })
    );
  }
);
CheckboxFilterType.displayName = "CheckboxFilterType";

// src/FilterType/RadioFilterType.tsx
import * as React51 from "react";
import { Fragment as Fragment17, jsx as jsx56, jsxs as jsxs39 } from "react/jsx-runtime";
var OptionSearch2 = (props) => /* @__PURE__ */ jsx56(TextField, __spreadValues({ wFull: true, iconLeft: true, icon: "magnifying-glass" }, props));
var RadioFilterType = React51.forwardRef(
  ({
    options,
    children,
    filter,
    filtersActive,
    handleApply,
    handleClear,
    handleCancel,
    handleOpenChange,
    handleRemove,
    showRemove,
    applyDisabled,
    clearDisabled,
    cancelDisabled,
    closeOnApply,
    closeOnClear,
    closeOnCancel,
    resetOnApply = false,
    resetOnClear = true,
    resetOnCancel = true,
    resetOnClose = true,
    applyLabel,
    clearLabel,
    cancelLabel,
    testId,
    dropdownProps = {},
    buttonProps = {},
    searchProps = { enabled: false }
  }, forwardedRef) => {
    const [selected, setSelected] = React51.useState(
      options.map((s) => __spreadProps(__spreadValues({}, s), {
        checked: (filter == null ? void 0 : filter.value) === s.value || false
      }))
    );
    const [appliedSelected, setAppliedSelected] = React51.useState(
      options.map((s) => __spreadProps(__spreadValues({}, s), {
        checked: (filter == null ? void 0 : filter.value) === s.value || false
      }))
    );
    const [searchTerm, setSearchTerm] = React51.useState("");
    const reset = (toPrevious = false) => {
      if (toPrevious) {
        setSelected([...structuredClone(appliedSelected)]);
        return;
      }
      setSelected((prev) => [
        ...prev.map((s) => __spreadProps(__spreadValues({}, s), {
          checked: false
        }))
      ]);
      setAppliedSelected((prev) => [
        ...prev.map((s) => __spreadProps(__spreadValues({}, s), {
          checked: false
        }))
      ]);
    };
    const onCheckItem = (key) => setSelected(
      (prev) => prev.map((_a2) => {
        var _b2 = _a2, { key: pKey } = _b2, rest = __objRest(_b2, ["key"]);
        return __spreadProps(__spreadValues({}, rest), {
          key: pKey,
          checked: key === pKey
        });
      })
    );
    const _a = searchProps, { enabled: isOptionSearchEnabled } = _a, optionSearchProps = __objRest(_a, ["enabled"]);
    const optionSearchConfig = __spreadProps(__spreadValues({
      render: OptionSearch2
    }, optionSearchProps), {
      onChange: (e) => (
        // @ts-expect-error -- TODO: fix
        setSearchTerm(e.target.value)
      ),
      value: searchTerm
    });
    const radioOptions = React51.useMemo(() => {
      const dropdownOpts = [
        {
          render: (_) => {
            var _a2;
            return /* @__PURE__ */ jsx56(
              RadioGroup,
              {
                value: (_a2 = selected.find(({ checked }) => checked)) == null ? void 0 : _a2.value,
                orientation: "vertical",
                children: options.filter(
                  ({ label }) => label.toLowerCase().includes(searchTerm.toLowerCase())
                ).map(({ key, label, value }) => /* @__PURE__ */ jsx56(Flex, { onClick: () => onCheckItem(key), children: /* @__PURE__ */ jsx56(Radio, { label, value }) }, key))
              }
            );
          }
        }
      ];
      if (isOptionSearchEnabled) {
        dropdownOpts.unshift(optionSearchConfig);
      }
      return dropdownOpts;
    }, [options, selected, searchTerm]);
    const _b = dropdownProps, { css: dropdownCss = {} } = _b, restDropdownProps = __objRest(_b, ["css"]);
    React51.useEffect(() => {
      if (filter && "value" in filter) {
        const setOpts = () => options.map((s) => __spreadProps(__spreadValues({}, s), {
          checked: filter.value === s.value || false
        }));
        setSelected(setOpts());
        setAppliedSelected(setOpts());
      }
    }, [filter]);
    return /* @__PURE__ */ jsx56(
      DropdownMenu,
      __spreadProps(__spreadValues({
        withArrow: false,
        contentProps: __spreadValues({
          align: "start"
        }, restDropdownProps.contentProps || {}),
        handleOpenChange: (open, { setIsOpen }) => {
          const continueToReset = handleOpenChange == null ? void 0 : handleOpenChange(open, {
            value: selected.find(({ checked }) => checked),
            resetAllValues: reset
          });
          if (continueToReset === false) {
            return false;
          }
          if (!open && resetOnClose) {
            reset(true);
          }
          setSearchTerm("");
          return true;
        },
        itemProps: {
          preventMenuCloseOnSelect: true,
          preventWithinItemFocus: true,
          preventLeaveItemBlur: true,
          disableTypeaheadSearch: true
        },
        footer: {
          type: "filter",
          handleApply: (event, { setIsOpen }) => {
            const continueToReset = handleApply == null ? void 0 : handleApply({
              selected: selected.find(({ checked }) => checked),
              setIsOpen,
              event
            });
            if (continueToReset !== false && resetOnApply) {
              return reset();
            }
            setAppliedSelected([...structuredClone(selected)]);
          },
          handleClear: (event, { setIsOpen }) => {
            const continueToReset = handleClear == null ? void 0 : handleClear({
              selected: selected.find(({ checked }) => checked),
              setIsOpen,
              event
            });
            if (continueToReset !== false && resetOnClear) {
              reset();
            }
          },
          handleCancel: (event, { setIsOpen }) => {
            const continueToReset = handleCancel == null ? void 0 : handleCancel({
              selected: selected.find(({ checked }) => checked),
              setIsOpen,
              event
            });
            if (continueToReset !== false && resetOnCancel) {
              reset(true);
            }
          },
          applyDisabled,
          clearDisabled,
          cancelDisabled,
          closeOnApply,
          closeOnClear: typeof closeOnClear === "boolean" ? closeOnClear : true,
          closeOnCancel,
          applyLabel,
          clearLabel,
          cancelLabel,
          testId: "radio-filter-type-button-footer"
        },
        options: radioOptions,
        css: __spreadValues(__spreadValues({}, noDropdownItemHighlightStyles), dropdownCss)
      }, restDropdownProps), {
        children: /* @__PURE__ */ jsx56(
          StyledFilterButton,
          __spreadProps(__spreadValues({
            variant: "subtle",
            ref: forwardedRef,
            selectedFilters: options.filter((_, idx) => selected[idx].checked),
            filtersActive,
            showRemove,
            handleRemove: handleRemove ? (event) => {
              event.preventDefault();
              handleRemove({
                value: selected.find(({ checked }) => checked),
                resetAllValues: reset
              });
            } : void 0,
            "data-testid": testId
          }, buttonProps), {
            children: (filterButtonProps) => typeof children === "function" ? children(filterButtonProps) : /* @__PURE__ */ jsxs39(Fragment17, { children: [
              /* @__PURE__ */ jsx56("strong", { children: filter.label }),
              options.filter((_, idx) => selected[idx].checked).length > 0 && ":"
            ] })
          })
        )
      })
    );
  }
);
RadioFilterType.displayName = "RadioFilterType";

// src/Grid/Grid.tsx
import * as React52 from "react";

// src/Grid/BaseGrid.tsx
var BaseGrid = styled(Box_default, {
  display: "grid",
  variants: {
    align: {
      start: {
        alignItems: "start"
      },
      center: {
        alignItems: "center"
      },
      end: {
        alignItems: "end"
      },
      stretch: {
        alignItems: "stretch"
      },
      baseline: {
        alignItems: "baseline"
      }
    },
    justify: {
      start: {
        justifyContent: "start"
      },
      center: {
        justifyContent: "center"
      },
      end: {
        justifyContent: "end"
      },
      between: {
        justifyContent: "space-between"
      }
    },
    placeItems: {
      center: {
        placeItems: "center"
      },
      start: {
        placeItems: "start"
      },
      end: {
        placeItems: "end"
      },
      between: {
        placeItems: "space-between"
      },
      baseline: {
        placeItems: "baseline"
      }
    },
    flow: {
      row: {
        gridAutoFlow: "row"
      },
      column: {
        gridAutoFlow: "column"
      },
      dense: {
        gridAutoFlow: "dense"
      },
      rowDense: {
        gridAutoFlow: "row dense"
      },
      columnDense: {
        gridAutoFlow: "column dense"
      }
    },
    columns: {
      1: {
        gridTemplateColumns: "repeat(1, 1fr)"
      },
      2: {
        gridTemplateColumns: "repeat(2, 1fr)"
      },
      3: {
        gridTemplateColumns: "repeat(3, 1fr)"
      },
      4: {
        gridTemplateColumns: "repeat(4, 1fr)"
      },
      5: {
        gridTemplateColumns: "repeat(5, 1fr)"
      },
      6: {
        gridTemplateColumns: "repeat(6, 1fr)"
      },
      7: {
        gridTemplateColumns: "repeat(7, 1fr)"
      },
      8: {
        gridTemplateColumns: "repeat(8, 1fr)"
      },
      9: {
        gridTemplateColumns: "repeat(9, 1fr)"
      },
      10: {
        gridTemplateColumns: "repeat(10, 1fr)"
      },
      11: {
        gridTemplateColumns: "repeat(11, 1fr)"
      },
      12: {
        gridTemplateColumns: "repeat(12, 1fr)"
      }
    },
    rows: {
      1: {
        gridTemplateRows: "repeat(1, 1fr)"
      },
      2: {
        gridTemplateRows: "repeat(2, 1fr)"
      },
      3: {
        gridTemplateRows: "repeat(3, 1fr)"
      },
      4: {
        gridTemplateRows: "repeat(4, 1fr)"
      },
      5: {
        gridTemplateRows: "repeat(5, 1fr)"
      },
      6: {
        gridTemplateRows: "repeat(6, 1fr)"
      },
      7: {
        gridTemplateRows: "repeat(7, 1fr)"
      },
      8: {
        gridTemplateRows: "repeat(8, 1fr)"
      },
      9: {
        gridTemplateRows: "repeat(9, 1fr)"
      },
      10: {
        gridTemplateRows: "repeat(10, 1fr)"
      },
      11: {
        gridTemplateRows: "repeat(11, 1fr)"
      },
      12: {
        gridTemplateRows: "repeat(12, 1fr)"
      }
    },
    gap: {
      1: {
        gap: "$1"
      },
      2: {
        gap: "$2"
      },
      3: {
        gap: "$3"
      },
      4: {
        gap: "$4"
      },
      5: {
        gap: "$5"
      },
      6: {
        gap: "$6"
      },
      7: {
        gap: "$7"
      },
      8: {
        gap: "$8"
      },
      9: {
        gap: "$9"
      }
    },
    gapX: {
      1: {
        columnGap: "$1"
      },
      2: {
        columnGap: "$2"
      },
      3: {
        columnGap: "$3"
      },
      4: {
        columnGap: "$4"
      },
      5: {
        columnGap: "$5"
      },
      6: {
        columnGap: "$6"
      },
      7: {
        columnGap: "$7"
      },
      8: {
        columnGap: "$8"
      },
      9: {
        columnGap: "$9"
      }
    },
    gapY: {
      1: {
        rowGap: "$1"
      },
      2: {
        rowGap: "$2"
      },
      3: {
        rowGap: "$3"
      },
      4: {
        rowGap: "$4"
      },
      5: {
        rowGap: "$5"
      },
      6: {
        rowGap: "$6"
      },
      7: {
        rowGap: "$7"
      },
      8: {
        rowGap: "$8"
      },
      9: {
        rowGap: "$9"
      }
    }
  }
});

// src/Grid/Grid.tsx
import { jsx as jsx57 } from "react/jsx-runtime";
var Grid = React52.forwardRef(
  (props, forwardedRef) => {
    const _a = props, { children, css: css3 } = _a, rest = __objRest(_a, ["children", "css"]);
    return /* @__PURE__ */ jsx57(BaseGrid, __spreadProps(__spreadValues({ ref: forwardedRef, css: css3 }, rest), { children }));
  }
);

// src/Loader/BaseLoader.tsx
import { keyframes as keyframes2, styled as styled4 } from "@stitches/react";
var LoaderContainer = styled4("div", {
  display: "inline-flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "$1"
});
var rotateAnimation = keyframes2({
  "0%": {
    transform: "rotate(0deg)"
  },
  "100%": {
    transform: "rotate(360deg)"
  }
});
var AnimationContainer = styled4("div", {
  willChange: "transform",
  transform: "rotate(0deg)",
  transformOrigin: "center center",
  animation: `${rotateAnimation} 1s linear infinite`
});

// src/Loader/components/LoaderIconLg.tsx
import { jsx as jsx58, jsxs as jsxs40 } from "react/jsx-runtime";
var IconLg = () => /* @__PURE__ */ jsxs40(
  "svg",
  {
    width: "32",
    height: "32",
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx58(
        "circle",
        {
          cx: "16",
          cy: "16",
          r: "12",
          stroke: "#CAD5E5",
          strokeOpacity: "0.24",
          strokeWidth: "2"
        }
      ),
      /* @__PURE__ */ jsx58(
        "path",
        {
          d: "M16 4C9.37258 4 4 9.37258 4 16",
          stroke: "#106AF2",
          strokeWidth: "2",
          strokeLinecap: "round"
        }
      )
    ]
  }
);
var LoaderIconLg_default = IconLg;

// src/Loader/components/LoaderIconMd.tsx
import { jsx as jsx59, jsxs as jsxs41 } from "react/jsx-runtime";
var IconMd = () => /* @__PURE__ */ jsxs41(
  "svg",
  {
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx59(
        "circle",
        {
          cx: "10",
          cy: "10",
          r: "7.5",
          stroke: "#CAD5E5",
          strokeOpacity: "0.24",
          strokeWidth: "2"
        }
      ),
      /* @__PURE__ */ jsx59(
        "path",
        {
          d: "M10 2.5C5.85786 2.5 2.5 5.85786 2.5 10",
          stroke: "#106AF2",
          strokeWidth: "2",
          strokeLinecap: "round"
        }
      )
    ]
  }
);
var LoaderIconMd_default = IconMd;

// src/Loader/components/LoaderIconSm.tsx
import { jsx as jsx60, jsxs as jsxs42 } from "react/jsx-runtime";
var LoaderIconSm = () => /* @__PURE__ */ jsxs42(
  "svg",
  {
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx60(
        "circle",
        {
          cx: "8",
          cy: "8",
          r: "6",
          stroke: "#CAD5E5",
          strokeOpacity: "0.24",
          strokeWidth: "2"
        }
      ),
      /* @__PURE__ */ jsx60(
        "path",
        {
          d: "M8 2C4.68629 2 2 4.68629 2 8",
          stroke: "#106AF2",
          strokeWidth: "2",
          strokeLinecap: "round"
        }
      )
    ]
  }
);
var LoaderIconSm_default = LoaderIconSm;

// src/Loader/components/LoaderIconXl.tsx
import { jsx as jsx61, jsxs as jsxs43 } from "react/jsx-runtime";
var LoaderIconXl = () => /* @__PURE__ */ jsxs43(
  "svg",
  {
    width: "128",
    height: "128",
    viewBox: "0 0 128 128",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx61(
        "circle",
        {
          cx: "64",
          cy: "64",
          r: "48",
          stroke: "#CAD5E5",
          strokeOpacity: "0.24",
          strokeWidth: "6"
        }
      ),
      /* @__PURE__ */ jsx61(
        "path",
        {
          d: "M64 16C37.4903 16 16 37.4903 16 64",
          stroke: "#106AF2",
          strokeWidth: "6",
          strokeLinecap: "round"
        }
      )
    ]
  }
);
var LoaderIconXl_default = LoaderIconXl;

// src/Loader/Loader.tsx
import { jsx as jsx62, jsxs as jsxs44 } from "react/jsx-runtime";
var sizeMap = {
  sm: "16",
  md: "20",
  lg: "32",
  xl: "128"
};
var sizeComponentMap = {
  sm: LoaderIconSm_default,
  md: LoaderIconMd_default,
  lg: LoaderIconLg_default,
  xl: LoaderIconXl_default
};
var Loader = (props) => {
  const _a = props, { size = "sm", label, isShown = true } = _a, restProps = __objRest(_a, ["size", "label", "isShown"]);
  if (!isShown) {
    return null;
  }
  const sizeVal = sizeMap[size];
  const IconComponent = sizeComponentMap[size];
  return /* @__PURE__ */ jsxs44(LoaderContainer, __spreadProps(__spreadValues({}, restProps), { children: [
    /* @__PURE__ */ jsx62(
      AnimationContainer,
      {
        css: { width: `${sizeVal}px`, height: `${sizeVal}px` },
        children: /* @__PURE__ */ jsx62(IconComponent, {})
      }
    ),
    label && /* @__PURE__ */ jsx62(Text, { size: 1, variant: "disabled", children: label })
  ] }));
};

// src/NectarThemeProvider/NectarThemeProvider.tsx
import { TooltipProvider as TooltipProvider2 } from "@radix-ui/react-tooltip";
import * as React53 from "react";
import { jsx as jsx63 } from "react/jsx-runtime";
var NectarThemeContext = React53.createContext({ theme, css });
var NectarThemeProvider = (props) => {
  const { children } = props;
  return /* @__PURE__ */ jsx63(NectarThemeContext.Provider, { value: { theme, css }, children: /* @__PURE__ */ jsx63(TooltipProvider2, { children }) });
};
var useTheme = () => React53.useContext(NectarThemeContext);

// src/Pagination/Pagination.tsx
import { formatNumber, t as t19 } from "@kandji-inc/nectar-i18n";
import * as React54 from "react";

// src/SelectV2/components/Roots/DefaultSelect.tsx
import { forwardRef as forwardRef37 } from "react";

// src/SelectV2/SelectV2Components.ts
import * as Select2 from "radix-ui-react-select-2.0";
var StyledSelectV2Trigger = styled(Select2.Trigger, BaseButton, {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "$primary",
  fontVariantNumeric: "unset",
  "&[data-state=open]": {
    "[data-purpose=state-indicator]": {
      transform: "rotate(180deg)"
    }
  }
});
var StyledSelectV2Content = styled(Select2.Content, {
  height: "min-content",
  padding: "$1 0px",
  background: "$neutral0",
  filter: "drop-shadow($elevation2)",
  borderRadius: "$rounded"
});
var StyledSelectV2Placeholder = styled("span", {
  color: "$input_secondary_text_enabled"
});
var StyledSelectV2Icon = styled(Select2.Icon, {
  display: "flex"
});
var StyledSelectV2Item = styled(Select2.Item, {
  all: "unset",
  padding: "6px $3",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  fontFamily: "$primary",
  gap: "$3",
  cursor: "pointer",
  // These are common across all theme variants
  backgroundColor: "$neutral0",
  "&[data-highlighted]": {
    backgroundColor: "#505E7129"
  },
  "&:active": {
    backgroundColor: "#505E713D"
  },
  "&[data-state=checked]": {
    background: "$blue10"
  },
  "&[data-disabled]": {
    color: "$input_default_text_disabled",
    cursor: "not-allowed"
  },
  variants: {
    theme: {
      default: {
        color: "$neutral90",
        "&[data-disabled]": {
          color: "#35414E7A"
        }
      },
      danger: {
        color: "$red50",
        "&[data-disabled]": {
          color: "#DB30257A"
        }
      },
      action: {
        color: "$blue50",
        "&[data-disabled]": {
          color: "#106AF27A"
        }
      }
    },
    noHighlight: {
      true: {
        "&[data-highlighted]": {
          backgroundColor: "transparent"
        }
      }
    }
  },
  defaultVariants: {
    theme: "default"
  }
});
var StyledSelectV2Group = styled(Select2.Group, {});
var StyledSelectV2GroupLabel = styled(Select2.Label, {
  padding: "$1 $3",
  fontSize: "$1",
  fontWeight: "$medium",
  color: "$neutral60"
});
var StyledSelectV2Separator = styled(Select2.Separator, {
  height: "1px",
  backgroundColor: "$neutral10",
  margin: "$1 0"
});
var StyledSelectV2 = {
  Trigger: StyledSelectV2Trigger,
  Content: StyledSelectV2Content,
  Item: StyledSelectV2Item,
  Root: Select2.Root,
  Portal: Select2.Portal,
  Viewport: Select2.Viewport,
  Value: Select2.Value,
  Placeholder: StyledSelectV2Placeholder,
  Icon: StyledSelectV2Icon,
  ItemText: Select2.ItemText,
  Group: StyledSelectV2Group,
  GroupLabel: StyledSelectV2GroupLabel,
  Separator: StyledSelectV2Separator
};

// src/SelectV2/utils/getSelectedOption.tsx
var DEFAULT_EMPTY_OPTION = {
  value: "DEFAULT_OPTION",
  label: "Select"
};
function getSelectedOption(value, options, nullOption) {
  const selectedOption = options.find((o) => o.value === value);
  const emptySelection = nullOption;
  return selectedOption != null ? selectedOption : emptySelection;
}

// src/SelectV2/SelectV2.styles.tsx
var TriggerIcon = styled(Icon_default, {
  width: 20,
  height: 20
});
var OptionIcon = styled(Icon_default, {
  marginRight: "$1",
  width: "20px",
  height: "20px",
  minWidth: "20px",
  minHeight: "20px"
});
var StyledContent2 = styled(StyledSelectV2.Content, {
  width: "var(--radix-select-trigger-width)",
  margin: "4px 0",
  maxHeight: 320,
  zIndex: 9999
});
var SelectValueContainer = styled(Flex, {
  display: "flex",
  gap: "$1",
  alignItems: "center",
  width: "100%",
  overflow: "hidden"
});
var SelectValueIconPadding = {
  maxWidth: "calc(100% - 18px)"
};
var SelectValueLabelContainer = styled("span", {
  maxWidth: "100%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden"
});
var SelectItemContainer = styled(Flex, {
  gap: 4
});
var SelectItemTextContainer = styled("div", {
  width: "calc(var(--radix-select-trigger-width) - 20%)",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
});

// src/SelectV2/components/Contents/DefaultContent.tsx
import { jsx as jsx64 } from "react/jsx-runtime";
function DefaultContent({ children, css: css3 }) {
  return /* @__PURE__ */ jsx64(StyledSelectV2.Portal, { children: /* @__PURE__ */ jsx64(StyledContent2, { css: css3, position: "popper", children: /* @__PURE__ */ jsx64(StyledSelectV2.Viewport, { children }) }) });
}

// src/SelectV2/components/Contents/SearchContent.tsx
import { forwardRef as forwardRef34 } from "react";
import { jsx as jsx65, jsxs as jsxs45 } from "react/jsx-runtime";
var SearchContainer = styled("div", {
  padding: "6px 12px"
});
var SelectItemsContainer = styled("div", {
  maxHeight: 320,
  overflow: "auto"
});
var StyledScrollAreaRoot2 = styled(ScrollAreaPrimitive3.Root, {
  width: "100%",
  height: "100%"
});
var StyledScrollAreaViewport2 = styled(
  ScrollAreaPrimitive3.Viewport,
  StyledScrollAreaRoot2
);
var StyledScrollAreaScrollbar2 = styled(ScrollAreaPrimitive3.Scrollbar, {
  width: 4,
  padding: "5px 2px"
});
var StyledScrollAreaThumb2 = styled(ScrollAreaPrimitive3.Thumb, {
  background: "rgba(0, 0, 0, 0.3)",
  borderRadius: 3
});
var ScrollArea2 = {
  Root: StyledScrollAreaRoot2,
  Viewport: StyledScrollAreaViewport2,
  Scrollbar: StyledScrollAreaScrollbar2,
  Thumb: StyledScrollAreaThumb2
};
function SearchContentComponent({
  children,
  search,
  onSearchChange,
  placeholder,
  align,
  css: css3
}, ref) {
  return /* @__PURE__ */ jsx65(StyledSelectV2.Portal, { children: /* @__PURE__ */ jsxs45(StyledContent2, { position: "popper", css: css3, align, children: [
    /* @__PURE__ */ jsx65(SearchContainer, { children: /* @__PURE__ */ jsx65(
      TextField,
      {
        ref,
        autoComplete: "off",
        icon: "magnifying-glass",
        placeholder,
        compact: true,
        value: search,
        onChange: (e) => {
          onSearchChange(e.target.value);
        }
      }
    ) }),
    /* @__PURE__ */ jsx65(SelectItemsContainer, { children: /* @__PURE__ */ jsxs45(ScrollArea2.Root, { type: "auto", children: [
      /* @__PURE__ */ jsx65(StyledSelectV2.Viewport, { asChild: true, style: { position: "unset" }, children: /* @__PURE__ */ jsx65(ScrollArea2.Viewport, { css: { paddingBottom: 12 }, children }) }),
      /* @__PURE__ */ jsx65(ScrollArea2.Scrollbar, { orientation: "vertical", children: /* @__PURE__ */ jsx65(ScrollArea2.Thumb, {}) })
    ] }) })
  ] }) });
}
var SearchContent = forwardRef34(SearchContentComponent);

// src/SelectV2/components/Contents/index.tsx
var SelectContents = {
  Default: DefaultContent,
  Searchable: SearchContent
};

// src/SelectV2/utils/optionUtils.ts
function getGroupedOptions(options) {
  var _a;
  const optionGroups = /* @__PURE__ */ new Map();
  const ungroupedOptions = [];
  for (const option of options) {
    if (option.group == null) {
      ungroupedOptions.push(option);
    } else {
      const groupOptions = (_a = optionGroups.get(option.group)) != null ? _a : [];
      optionGroups.set(option.group, [...groupOptions, option]);
    }
  }
  const groupedOptions = Array.from(optionGroups);
  return { groupedOptions, ungroupedOptions };
}

// src/SelectV2/components/ListItems/DefaultListItem.tsx
import { jsx as jsx66, jsxs as jsxs46 } from "react/jsx-runtime";
function DefaultListItem({
  value,
  disabled,
  icon,
  hint,
  label,
  iconElement: IconElement,
  css: css3
}) {
  return /* @__PURE__ */ jsx66(StyledSelectV2.Item, { value, disabled, children: /* @__PURE__ */ jsxs46(SelectItemContainer, { children: [
    IconElement && /* @__PURE__ */ jsx66(IconElement, {}),
    icon && /* @__PURE__ */ jsx66(OptionIcon, { name: icon }),
    /* @__PURE__ */ jsxs46(SelectItemTextContainer, { css: css3, children: [
      label,
      hint && /* @__PURE__ */ jsx66(Text, { size: "1", variant: "description", children: hint })
    ] })
  ] }) }, value);
}

// src/SelectV2/components/ListItems/index.ts
var SelectListItems = {
  Default: DefaultListItem
};

// src/SelectV2/components/SelectItemList.tsx
import { jsx as jsx67 } from "react/jsx-runtime";
function SelectItemList({ options }) {
  return options.map((option) => /* @__PURE__ */ jsx67(SelectListItems.Default, __spreadValues({}, option), option.value));
}

// src/SelectV2/components/SelectGroupedItems.tsx
import { Fragment as Fragment18, jsx as jsx68, jsxs as jsxs47 } from "react/jsx-runtime";
function SelectGroupedItems({ options }) {
  const { groupedOptions, ungroupedOptions } = getGroupedOptions(options);
  return /* @__PURE__ */ jsxs47(Fragment18, { children: [
    groupedOptions.map(([group, options2]) => /* @__PURE__ */ jsxs47(StyledSelectV2.Group, { children: [
      /* @__PURE__ */ jsx68(StyledSelectV2.GroupLabel, { children: group }),
      /* @__PURE__ */ jsx68(SelectItemList, { options: options2 })
    ] }, group)),
    groupedOptions.length > 0 && ungroupedOptions.length > 0 && /* @__PURE__ */ jsx68(StyledSelectV2.Separator, {}),
    /* @__PURE__ */ jsx68(SelectItemList, { options: ungroupedOptions })
  ] });
}

// src/SelectV2/components/Triggers/DefaultTrigger.tsx
import { forwardRef as forwardRef35 } from "react";

// src/SearchableText/SearchableText.tsx
import { jsx as jsx69 } from "react/jsx-runtime";
var escapeSpecialCharacters = (text) => text == null ? void 0 : text.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
function highlightText(text, term, color = "var(--colors-yellow30)", boldOptions) {
  if (term) {
    const parts = text == null ? void 0 : text.split(
      new RegExp(`(${escapeSpecialCharacters(term)})`, "gi")
    );
    let matchCount = 0;
    return /* @__PURE__ */ jsx69("span", { children: parts == null ? void 0 : parts.map((part, i) => {
      const isMatchingSearchText = part.toLowerCase() === (term == null ? void 0 : term.toLowerCase());
      const isBold = isMatchingSearchText && (boldOptions == null ? void 0 : boldOptions.matchIndex) === matchCount;
      const highlightColor = isBold ? boldOptions.color : color;
      if (isMatchingSearchText) {
        matchCount += 1;
      }
      return /* @__PURE__ */ jsx69(
        "span",
        {
          style: {
            background: isMatchingSearchText ? highlightColor : ""
          },
          children: part
        },
        i
      );
    }) });
  }
  return /* @__PURE__ */ jsx69("span", { children: /* @__PURE__ */ jsx69("span", { children: text }) });
}
function SearchableText({ children, search }) {
  return highlightText(children, search);
}

// src/SelectV2/components/Triggers/DefaultTrigger.tsx
import { jsx as jsx70, jsxs as jsxs48 } from "react/jsx-runtime";
function DefaultTriggerComponent(_a, ref) {
  var _b = _a, {
    value,
    placeholder,
    selectedOption,
    iconName = "fa-angle-down-small",
    variant = "subtle",
    searchText
  } = _b, props = __objRest(_b, [
    "value",
    "placeholder",
    "selectedOption",
    "iconName",
    "variant",
    "searchText"
  ]);
  const { icon, iconElement: IconElement } = selectedOption;
  return /* @__PURE__ */ jsxs48(StyledSelectV2.Trigger, __spreadProps(__spreadValues({ ref, variant }, props), { children: [
    /* @__PURE__ */ jsx70(
      StyledSelectV2.Value,
      {
        placeholder: /* @__PURE__ */ jsx70(StyledSelectV2.Placeholder, { children: placeholder }),
        asChild: true,
        children: /* @__PURE__ */ jsxs48(SelectValueContainer, { children: [
          IconElement && /* @__PURE__ */ jsx70(IconElement, {}),
          icon && /* @__PURE__ */ jsx70(OptionIcon, { name: icon }),
          /* @__PURE__ */ jsx70(
            SelectValueLabelContainer,
            {
              style: icon != null || IconElement != null ? SelectValueIconPadding : {},
              children: searchText ? /* @__PURE__ */ jsx70(SearchableText, { search: searchText, children: value }) : value
            }
          )
        ] })
      }
    ),
    /* @__PURE__ */ jsx70(StyledSelectV2.Icon, { className: "selectIcon", "data-purpose": "state-indicator", children: /* @__PURE__ */ jsx70(TriggerIcon, { name: iconName }) })
  ] }));
}
var DefaultTrigger = forwardRef35(DefaultTriggerComponent);

// src/SelectV2/components/Triggers/FilterTrigger.tsx
import { forwardRef as forwardRef36 } from "react";
import { jsx as jsx71, jsxs as jsxs49 } from "react/jsx-runtime";
var StyledStatus = styled("span", {
  fontWeight: "$regular"
});
var TriggerContainer = styled("span", {
  textTransform: "capitalize"
});
function FilterTriggerComponent(_a, ref) {
  var _b = _a, { selectedOption, filterLabel } = _b, props = __objRest(_b, ["selectedOption", "filterLabel"]);
  return /* @__PURE__ */ jsxs49(
    StyledSelectV2.Trigger,
    __spreadProps(__spreadValues({
      ref,
      compact: true,
      variant: selectedOption == null ? "filter" : "filterApplied"
    }, props), {
      children: [
        /* @__PURE__ */ jsx71(TriggerContainer, { children: selectedOption == null ? filterLabel : /* @__PURE__ */ jsxs49("div", { children: [
          /* @__PURE__ */ jsxs49(StyledStatus, { children: [
            filterLabel,
            ":\xA0"
          ] }),
          selectedOption.label
        ] }) }),
        /* @__PURE__ */ jsx71(StyledSelectV2.Icon, { style: { marginLeft: "auto" }, children: /* @__PURE__ */ jsx71(Icon_default, { name: "fa-angle-down-small", style: { width: 20, height: 20 } }) })
      ]
    })
  );
}
var FilterTrigger = forwardRef36(FilterTriggerComponent);

// src/SelectV2/components/Triggers/index.tsx
var SelectTriggers = {
  Default: DefaultTrigger,
  Filter: FilterTrigger
};

// src/SelectV2/components/Roots/DefaultSelect.tsx
import { Fragment as Fragment19, jsx as jsx72, jsxs as jsxs50 } from "react/jsx-runtime";
function DefaultSelectComponent({
  options,
  defaultOptions = [],
  value,
  onValueChange,
  onOpenChange,
  triggerProps = {},
  trigger,
  content,
  disabled = false
}, ref) {
  var _a, _b;
  const selectedOption = getSelectedOption(
    value,
    options,
    (_a = defaultOptions[0]) != null ? _a : DEFAULT_EMPTY_OPTION
  );
  const triggerValue = (selectedOption == null ? void 0 : selectedOption.label) || options[0].label;
  return /* @__PURE__ */ jsxs50(
    StyledSelectV2.Root,
    {
      value,
      onValueChange,
      onOpenChange,
      disabled,
      children: [
        trigger != null && trigger(ref, selectedOption),
        trigger == null && /* @__PURE__ */ jsx72(
          SelectTriggers.Default,
          __spreadProps(__spreadValues({}, triggerProps), {
            ref,
            selectedOption,
            value: (_b = triggerProps.value) != null ? _b : triggerValue
          })
        ),
        content != null && content(),
        content == null && /* @__PURE__ */ jsx72(SelectContents.Default, { children: /* @__PURE__ */ jsxs50(Fragment19, { children: [
          defaultOptions.length > 0 && /* @__PURE__ */ jsx72(SelectItemList, { options: defaultOptions }),
          /* @__PURE__ */ jsx72(SelectGroupedItems, { options })
        ] }) })
      ]
    }
  );
}
var DefaultSelect = forwardRef37(DefaultSelectComponent);

// src/SelectV2/components/Roots/AsyncSelect.tsx
import {
  useDebounce,
  useFocusInput,
  useInfiniteScroll
} from "@kandji-inc/nectar-utils";
import { forwardRef as forwardRef38, useState as useState26 } from "react";

// src/SelectV2/utils/getItemFromValue.tsx
function getItemFromValue(value, items, defaultOption = DEFAULT_EMPTY_OPTION) {
  var _a;
  return (_a = items.find((item) => item.value === value)) != null ? _a : defaultOption;
}

// src/SelectV2/components/Roots/AsyncSelect.tsx
import { Fragment as Fragment20, jsx as jsx73, jsxs as jsxs51 } from "react/jsx-runtime";
function AsyncSelectComponent({
  selectedOption,
  onValueChange,
  onOpenChange,
  transformData,
  useGetData,
  defaultOptions = [],
  triggerProps,
  trigger,
  listItems
}, ref) {
  const [isOpen, setIsOpen] = useState26(false);
  const [search, setSearch] = useState26("");
  const debouncedSearch = useDebounce(search, 750);
  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = useGetData(debouncedSearch);
  const options = transformData(data);
  const inputRef = useFocusInput([options, isOpen]);
  const lastElementRef = useInfiniteScroll({
    isLoading,
    hasNextPage,
    isFetching,
    fetchNextPage
  });
  function handleOnOpen(open) {
    setSearch("");
    setIsOpen(open);
    onOpenChange == null ? void 0 : onOpenChange(open);
  }
  function handleValueChange(value) {
    const option = getItemFromValue(value, options, defaultOptions[0]);
    onValueChange(option);
    setIsOpen(false);
  }
  return /* @__PURE__ */ jsxs51(
    StyledSelectV2.Root,
    {
      value: selectedOption.value,
      onValueChange: handleValueChange,
      onOpenChange: handleOnOpen,
      children: [
        trigger != null && trigger(options),
        trigger == null && /* @__PURE__ */ jsx73(
          SelectTriggers.Default,
          __spreadProps(__spreadValues({}, triggerProps), {
            ref,
            value: selectedOption.label,
            selectedOption
          })
        ),
        /* @__PURE__ */ jsxs51(
          SelectContents.Searchable,
          {
            ref: inputRef,
            search,
            onSearchChange: setSearch,
            children: [
              listItems != null && listItems(options),
              listItems == null && /* @__PURE__ */ jsxs51(Fragment20, { children: [
                defaultOptions.length > 0 && search.length < 1 && /* @__PURE__ */ jsx73(SelectItemList, { options: defaultOptions }),
                /* @__PURE__ */ jsx73(SelectItemList, { options })
              ] }),
              /* @__PURE__ */ jsx73("div", { ref: lastElementRef })
            ]
          }
        )
      ]
    }
  );
}
var AsyncSelect = forwardRef38(AsyncSelectComponent);

// src/SelectV2/SelectV2.tsx
var SelectV2 = {
  Default: DefaultSelect,
  Async: AsyncSelect
};

// src/SelectV2/MultiSelectV2.tsx
import {
  Button as Button2,
  ListBox,
  ListBoxItem,
  Popover,
  Select as Select3,
  SelectValue
} from "react-aria-components";
import { jsx as jsx74, jsxs as jsxs52 } from "react/jsx-runtime";
var StyledButton = styled(Button2, StyledSelectV2.Trigger);
var StyledListBox = styled(ListBox, StyledSelectV2.Content, {
  "&[data-focused]": {
    outline: "none"
  }
});
var StyledListBoxItem = styled(ListBoxItem, StyledSelectV2.Item, {
  "&[data-focused]": {
    backgroundColor: "#505E7129"
  }
});
var StyledValue = styled(SelectValue, {
  display: "inline-flex",
  fontWeight: "$medium",
  gap: 4
});
var StyledPopover = styled(Popover, {});
var TriggerIcon2 = styled(Icon_default, {
  width: 20,
  height: 20
});
var StyledMultiSelectV2 = {
  Root: Select3,
  Button: StyledButton,
  TriggerIcon: TriggerIcon2,
  Popover: StyledPopover,
  ListBox: StyledListBox,
  ListBoxItem: StyledListBoxItem,
  Value: StyledValue
};
function MultiSelectCounter({ value }) {
  return /* @__PURE__ */ jsxs52(Counter, { variant: "strong", children: [
    "+\xA0",
    value
  ] });
}
var MultiSelectFilterValueTitle = styled("span", {
  fontWeight: "$regular"
});
function MultiSelectFilterValue({
  title,
  filters
}) {
  const filtersApplied = filters.length > 0;
  return /* @__PURE__ */ jsxs52(StyledValue, { children: [
    filtersApplied ? /* @__PURE__ */ jsxs52("div", { children: [
      /* @__PURE__ */ jsxs52(MultiSelectFilterValueTitle, { children: [
        title,
        ":\xA0"
      ] }),
      filters[0]
    ] }) : title,
    filters.length > 1 && /* @__PURE__ */ jsx74(MultiSelectCounter, { value: filters.length - 1 })
  ] });
}

// src/Pagination/BasePagination.ts
var BasePagination = styled(Box_default, {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$1",
  width: "100%"
});

// src/Pagination/Pagination.tsx
import { jsx as jsx75, jsxs as jsxs53 } from "react/jsx-runtime";
var PaginationIconButton = styled(IconButton, {
  width: "36px",
  height: "36px",
  padding: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  borderRadius: "$rounded",
  "& > svg": {
    height: "20px",
    width: "20px"
  },
  backgroundColor: "rgba(255, 255, 255, 1e-05)",
  color: "$button_subtle_icon",
  "&:disabled": {
    backgroundColor: "rgba(255, 255, 255, 1e-05)",
    color: "$neutral40",
    pointerEvents: "none"
  },
  "&:hover": {
    background: "rgba(31, 37, 44, 0.08)"
  },
  "&:active": {
    background: "rgba(31, 37, 44, 0.16)"
  },
  "&:focus": {
    boxShadow: "0px 0px 0px 2px $colors$blue30",
    background: "rgba(255, 255, 255, 1e-05)"
  },
  cursor: "pointer"
});
function getPageString(currentPage, totalPages) {
  const translatedCurrentPage = formatNumber(currentPage);
  const translatedTotalPages = formatNumber(totalPages);
  return t19("{currentPage} of {totalPages}", {
    currentPage: translatedCurrentPage,
    totalPages: translatedTotalPages
  });
}
function PaginationSummary({
  currentPage,
  totalItems,
  itemsPerPage
}) {
  if (itemsPerPage === null || !itemsPerPage)
    return null;
  if (totalItems === null || !totalItems)
    return /* @__PURE__ */ jsx75(Paragraph, { variant: "description", children: t19("0 results") });
  const subject = t19("results");
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems);
  const translatedRangeStart = formatNumber(rangeStart);
  const translatedRangeEnd = formatNumber(rangeEnd);
  const translatedTotalItems = formatNumber(totalItems);
  return /* @__PURE__ */ jsx75(Paragraph, { variant: "description", children: t19("Showing {rangeStart} - {rangeEnd} of {totalItems} {subject}", {
    rangeStart: translatedRangeStart,
    rangeEnd: translatedRangeEnd,
    totalItems: translatedTotalItems,
    subject
  }) });
}
var Pagination = React54.forwardRef(
  (_a, ref) => {
    var _b = _a, {
      currentPage,
      totalPages,
      onPageChange,
      totalItems,
      itemsPerPage,
      menuAbove,
      bulkActions
    } = _b, rest = __objRest(_b, [
      "currentPage",
      "totalPages",
      "onPageChange",
      "totalItems",
      "itemsPerPage",
      "menuAbove",
      "bulkActions"
    ]);
    const [valueText, setValueText] = React54.useState(
      getPageString(currentPage, totalPages)
    );
    const isLastPage = currentPage === totalPages;
    const isFirstPage = currentPage === 1;
    const handlePageChange = (page) => {
      setValueText(getPageString(page, totalPages));
      onPageChange(page);
    };
    const getTriggerWidth = React54.useMemo(() => {
      let triggerWidth = 96;
      if (totalPages > 99) {
        triggerWidth = 112;
      }
      if (totalPages > 999) {
        triggerWidth = 128;
      }
      return triggerWidth;
    }, [totalPages]);
    React54.useEffect(() => {
      setValueText(getPageString(currentPage, totalPages));
    }, [currentPage, totalPages]);
    return /* @__PURE__ */ jsxs53(BasePagination, __spreadProps(__spreadValues({}, rest), { ref, children: [
      /* @__PURE__ */ jsx75(Flex, { children: bulkActions || /* @__PURE__ */ jsx75(
        PaginationSummary,
        {
          currentPage,
          totalItems,
          itemsPerPage
        }
      ) }),
      /* @__PURE__ */ jsxs53(Flex, { gap: "xs", children: [
        /* @__PURE__ */ jsx75(
          PaginationIconButton,
          {
            type: "button",
            onClick: () => handlePageChange(1),
            disabled: isFirstPage,
            icon: "arrow-left-to-line"
          }
        ),
        /* @__PURE__ */ jsx75(
          PaginationIconButton,
          {
            type: "button",
            onClick: () => handlePageChange(currentPage - 1),
            disabled: isFirstPage,
            icon: "fa-arrow-left-to-line-control"
          }
        ),
        /* @__PURE__ */ jsx75(
          Select,
          {
            options: Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (page) => ({
                label: page.toString(),
                value: page.toString()
              })
            ),
            value: currentPage.toString(),
            valueText,
            onChange: (e) => handlePageChange(parseInt(e, 10)),
            openIconProps: { name: "angle-down" },
            label: valueText,
            disabled: totalPages === 1,
            componentCss: {
              trigger: {
                width: `${getTriggerWidth}px`,
                height: "36px"
              },
              label: {
                display: "none"
              },
              menu: {
                width: "100%",
                bottom: menuAbove ? 44 : void 0
              },
              searchInput: {
                color: "$neutral90"
              }
            }
          }
        ),
        /* @__PURE__ */ jsx75(
          PaginationIconButton,
          {
            type: "button",
            onClick: () => handlePageChange(currentPage + 1),
            disabled: isLastPage,
            icon: "fa-arrow-right-to-line-control"
          }
        ),
        /* @__PURE__ */ jsx75(
          PaginationIconButton,
          {
            type: "button",
            onClick: () => handlePageChange(totalPages),
            disabled: isLastPage,
            icon: "arrow-right-to-line"
          }
        )
      ] })
    ] }));
  }
);
var PageSizer = ({
  totalItems,
  itemsPerPage,
  onItemsPerPageChange,
  itemsPerPageOptions = [50, 100, 300]
}) => {
  const options = itemsPerPageOptions.map((option) => ({
    value: option,
    label: formatNumber(option)
  }));
  return /* @__PURE__ */ jsxs53(Flex, { alignItems: "center", css: { gap: "3px" }, children: [
    /* @__PURE__ */ jsx75(
      SelectV2.Default,
      {
        value: itemsPerPage.toString(),
        onValueChange: (e) => onItemsPerPageChange(parseInt(e, 10)),
        triggerProps: {
          value: formatNumber(itemsPerPage),
          disabled: totalItems <= options[0].value
        },
        options: options.map((option) => ({
          value: option.value.toString(),
          label: option.label
        }))
      }
    ),
    /* @__PURE__ */ jsx75(
      Text,
      {
        css: {
          color: "$neutral60",
          fontSize: "$2",
          fontWeight: "$regular",
          lineHeight: "$2"
        },
        children: t19("results per page")
      }
    )
  ] });
};
var PaginationPrimitives = {
  Summary: PaginationSummary,
  IconButton: PaginationIconButton,
  getPageString,
  PageSizer
};

// src/Popover/Popover.tsx
import * as PopoverPrimitive from "@radix-ui/react-popover";
import React55 from "react";

// src/Panel/Panel.tsx
var panelStyles = css({
  backgroundColor: "$panel",
  borderRadius: "$rounded-xl",
  boxShadow: "$colors$shadowLight 0px 10px 38px -10px, $colors$shadowDark 0px 10px 20px -15px"
});
var Panel = styled("div", panelStyles);

// src/Popover/Popover.tsx
import { jsx as jsx76, jsxs as jsxs54 } from "react/jsx-runtime";
var Popover2 = PopoverPrimitive.Root;
var PopoverTrigger = PopoverPrimitive.Trigger;
var StyledContent3 = styled(PopoverPrimitive.Content, panelStyles, {
  minWidth: 200,
  minHeight: "$6",
  maxWidth: 265,
  "&:focus": {
    outline: "none"
  }
});
var PopoverContent = React55.forwardRef((_a, fowardedRef) => {
  var _b = _a, { children, hideArrow, arrowStyle } = _b, props = __objRest(_b, ["children", "hideArrow", "arrowStyle"]);
  return /* @__PURE__ */ jsx76(PopoverPrimitive.Portal, { children: /* @__PURE__ */ jsxs54(StyledContent3, __spreadProps(__spreadValues({ sideOffset: 0 }, props), { ref: fowardedRef, children: [
    children,
    !hideArrow && /* @__PURE__ */ jsx76(Box_default, { css: { color: "$panel" }, children: /* @__PURE__ */ jsx76(
      PopoverPrimitive.Arrow,
      {
        asChild: true,
        width: 36,
        height: 8,
        style: __spreadValues({
          transform: "rotate(180deg)"
        }, arrowStyle),
        children: /* @__PURE__ */ jsx76(
          "svg",
          {
            xmlns: "http://www.w3.org/2000/svg",
            width: "37",
            height: "8",
            viewBox: "0 0 37 8",
            fill: "none",
            children: /* @__PURE__ */ jsx76(
              "path",
              {
                d: "M18.4873 0L24.4873 8L12.4873 8L18.4873 0Z",
                fill: "white"
              }
            )
          }
        )
      }
    ) })
  ] })) });
});
var PopoverClose = PopoverPrimitive.Close;

// src/ProgressChips/ProgressChips.tsx
import { forwardRef as forwardRef40 } from "react";

// src/ProgressChips/BaseProgressChip.tsx
var BaseProgressChip = styled("span", {
  minWidth: "22px",
  height: "6px",
  borderRadius: "2px"
});

// src/ProgressChips/ProgressChips.tsx
import { jsx as jsx77 } from "react/jsx-runtime";
var ProgressChips = forwardRef40(
  (_a, ref) => {
    var _b = _a, { length, filledLength, filledColor = "$red60" } = _b, rest = __objRest(_b, ["length", "filledLength", "filledColor"]);
    const safeFilledLength = Math.min(filledLength, length);
    return /* @__PURE__ */ jsx77(
      Flex,
      __spreadProps(__spreadValues({
        alignItems: "center",
        css: { gap: "3px", paddingRight: "6px" },
        ref
      }, rest), {
        children: Array.from({ length }, (_, i) => /* @__PURE__ */ jsx77(
          BaseProgressChip,
          __spreadValues({
            css: {
              backgroundColor: i < safeFilledLength ? filledColor : "$badge_surface_neutral"
            }
          }, rest),
          i
        ))
      })
    );
  }
);

// src/ScrollContainer/ScrollContainer.tsx
import * as React57 from "react";
import { jsx as jsx78 } from "react/jsx-runtime";
var ScrollContainer = React57.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      children,
      width = "100%",
      minWidth = "auto",
      maxWidth = "auto",
      height = "100%",
      minHeight = "auto",
      maxHeight = "auto",
      overflow = "auto",
      scrollBehavior = "smooth",
      overscrollBehavior = "contain",
      scrollShadowLayer = 2,
      scrollDebounceMs = 200,
      showScrollShadowBottom = false,
      showScrollShadowRight = false,
      css: cssProp = {}
    } = _b, rest = __objRest(_b, [
      "children",
      "width",
      "minWidth",
      "maxWidth",
      "height",
      "minHeight",
      "maxHeight",
      "overflow",
      "scrollBehavior",
      "overscrollBehavior",
      "scrollShadowLayer",
      "scrollDebounceMs",
      "showScrollShadowBottom",
      "showScrollShadowRight",
      "css"
    ]);
    const [scrollContainerWidth, setScrollContainerWidth] = React57.useState();
    const [scrollContainerHeight, setScrollContainerHeight] = React57.useState();
    const [isScrolling, setIsScrolling] = React57.useState(false);
    const [isScrollLock, setIsScrollLock] = React57.useState(false);
    const [scrollYStatus, setScrollYStatus] = React57.useState("containedY");
    const [scrollXStatus, setScrollXStatus] = React57.useState("containedX");
    const scrollContainerRootRef = React57.useRef(null);
    const scrollContainerRef = React57.useRef(null);
    const timeoutId = React57.useRef(null);
    const isScrollYContained = scrollYStatus === "containedY";
    const isScrollTopStart = scrollYStatus === "topStart";
    const isScrollBottomEnd = scrollYStatus === "bottomEnd";
    const isScrollMidY = scrollYStatus === "midScrollY";
    const isScrollXContained = scrollXStatus === "containedX";
    const isScrollLeftStart = scrollXStatus === "leftStart";
    const isScrollRightEnd = scrollXStatus === "rightEnd";
    const isScrollMidX = scrollXStatus === "midScrollX";
    const dataStateAttr = React57.useMemo(() => {
      if (isScrollLock) {
        return "scroll-lock";
      }
      if (isScrolling) {
        return "scrolling";
      }
      return "idle";
    }, [isScrollLock, isScrolling]);
    React57.useLayoutEffect(() => {
      const scrollContainerEl = scrollContainerRef.current;
      const handleScrollLock = () => {
        setIsScrollLock(true);
      };
      const handleScrollUnlock = () => {
        setIsScrollLock(false);
      };
      if (scrollContainerEl) {
        scrollContainerEl.addEventListener(
          "ScrollContainer:lockscroll",
          handleScrollLock
        );
        scrollContainerEl.addEventListener(
          "ScrollContainer:unlockscroll",
          handleScrollUnlock
        );
      }
      return () => {
        if (scrollContainerEl) {
          scrollContainerEl.removeEventListener(
            "ScrollContainer:lockscroll",
            handleScrollLock
          );
          scrollContainerEl.removeEventListener(
            "ScrollContainer:unlockscroll",
            handleScrollUnlock
          );
        }
      };
    }, []);
    React57.useLayoutEffect(() => {
      const scrollContainerEl = scrollContainerRef.current;
      if (scrollContainerEl) {
        const {
          scrollTop,
          scrollLeft,
          scrollHeight,
          scrollWidth,
          clientHeight,
          clientWidth
        } = scrollContainerEl;
        const hasOverflowY = scrollHeight > clientHeight;
        const hasOverflowX = scrollWidth > clientWidth;
        if (hasOverflowY) {
          const isScrollYStart = scrollTop === 0;
          const isScrollYEnd = scrollTop + clientHeight >= scrollHeight;
          const isMidScrollY = !isScrollYStart && !isScrollYEnd;
          const scrollStartEndStatus = isScrollYStart ? "topStart" : "bottomEnd";
          const scrollStatus = isMidScrollY ? "midScrollY" : scrollStartEndStatus;
          setScrollYStatus(scrollStatus);
        } else {
          setScrollYStatus("containedY");
        }
        if (hasOverflowX) {
          const isScrollXStart = scrollLeft === 0;
          const isScrollXEnd = scrollLeft + clientWidth >= scrollWidth;
          const isMidScrollX = !isScrollXStart && !isScrollXEnd;
          const scrollStartEndStatus = isScrollXStart ? "leftStart" : "rightEnd";
          const scrollStatus = isMidScrollX ? "midScrollX" : scrollStartEndStatus;
          setScrollXStatus(scrollStatus);
        } else {
          setScrollXStatus("containedX");
        }
        setScrollContainerWidth(clientWidth);
        setScrollContainerHeight(clientHeight);
      }
    }, [children]);
    const onTrackScrollInfo = React57.useCallback(
      (e) => {
        if (timeoutId.current !== null) {
          clearTimeout(timeoutId.current);
        }
        setIsScrolling(true);
        const {
          scrollTop,
          scrollLeft,
          scrollHeight,
          scrollWidth,
          clientHeight,
          clientWidth
        } = e.currentTarget;
        const hasOverflowY = scrollHeight > clientHeight;
        const hasOverflowX = scrollWidth > clientWidth;
        const isScrollYStart = scrollTop === 0;
        const isScrollYEnd = scrollTop + clientHeight >= scrollHeight;
        const isMidScrollY = !isScrollYStart && !isScrollYEnd;
        if (isMidScrollY) {
          setScrollYStatus("midScrollY");
        }
        const isScrollXStart = scrollLeft === 0;
        const isScrollXEnd = scrollLeft + clientWidth >= scrollWidth;
        const isMidScrollX = !isScrollXStart && !isScrollXEnd;
        if (isMidScrollX) {
          setScrollXStatus("midScrollX");
        }
        if (!hasOverflowY) {
          setScrollYStatus("containedY");
        } else if (isScrollYStart) {
          setScrollYStatus("topStart");
          setIsScrolling(false);
        } else if (isScrollYEnd) {
          setScrollYStatus("bottomEnd");
          setIsScrolling(false);
        }
        if (!hasOverflowX) {
          setScrollXStatus("containedX");
        } else if (isScrollXEnd) {
          setScrollXStatus("rightEnd");
          setIsScrolling(false);
        } else if (isScrollXStart) {
          setScrollXStatus("leftStart");
          setIsScrolling(false);
        }
        timeoutId.current = setTimeout(() => {
          setIsScrolling(false);
        }, scrollDebounceMs);
      },
      [scrollDebounceMs]
    );
    const onForwardRef = (ref) => {
      if (scrollContainerRootRef) {
        scrollContainerRootRef.current = ref;
      }
      const assignRef = forwardedRef;
      const refCollection = {
        scrollContainerRootRef,
        scrollContainerRef
      };
      if (typeof assignRef === "function") {
        assignRef(refCollection);
      } else if (assignRef) {
        assignRef.current = refCollection;
      }
    };
    const scrollYShadowOpacity = () => {
      if (!showScrollShadowBottom || isScrollYContained) {
        return 0;
      }
      return isScrollBottomEnd ? 0 : 1;
    };
    const scrollXShadowOpacity = () => {
      if (!showScrollShadowRight || isScrollXContained) {
        return 0;
      }
      return isScrollRightEnd ? 0 : 1;
    };
    return /* @__PURE__ */ jsx78(
      Box_default,
      __spreadProps(__spreadValues({
        ref: onForwardRef,
        css: cssProp,
        className: scrollContainerRootClassName({
          scrollYShadow: showScrollShadowBottom ? "bottom" : "none",
          scrollXShadow: showScrollShadowRight ? "right" : "none",
          css: {
            $$scrollLockOpacity: 0.7,
            $$scrollTopStartShadow: "$shadows$scroll_overflow_y_shadow_top $colors$scroll_overflow_shadow_color",
            $$scrollBottomEndShadow: "$shadows$scroll_overflow_y_shadow_bottom $colors$scroll_overflow_shadow_color",
            $$scrollLeftStartShadow: "$shadows$scroll_overflow_x_shadow_right $colors$scroll_overflow_shadow_color",
            $$scrollRightEndShadow: "$shadows$scroll_overflow_x_shadow_left $colors$scroll_overflow_shadow_color",
            $$scrollYShadowLayer: scrollShadowLayer,
            $$scrollXShadowLayer: scrollShadowLayer,
            $$scrollYShadowOpacity: scrollYShadowOpacity(),
            $$scrollXShadowOpacity: scrollXShadowOpacity(),
            $$scrollContainerPointerEvents: isScrollLock ? "none" : "auto",
            opacity: isScrollLock ? "$$scrollLockOpacity" : 1,
            width,
            minWidth,
            maxWidth,
            height,
            minHeight,
            maxHeight
          }
        })
      }, rest), {
        children: /* @__PURE__ */ jsx78(
          Box_default,
          {
            ref: scrollContainerRef,
            role: "region",
            tabIndex: 0,
            onScroll: onTrackScrollInfo,
            className: scrollContainerClassName({
              css: {
                $$isScrollMidY: isScrollMidY ? 1 : 0,
                $$isScrollTopStart: isScrollTopStart ? 1 : 0,
                $$isScrollTopStartMidY: isScrollTopStart || isScrollMidY ? 1 : 0,
                $$isScrollBottomEnd: isScrollBottomEnd ? 1 : 0,
                $$isScrollBottomEndMidY: isScrollBottomEnd || isScrollMidY ? 1 : 0,
                $$isScrollMidX: isScrollMidX ? 1 : 0,
                $$isScrollLeftStart: isScrollLeftStart ? 1 : 0,
                $$isScrollLeftStartMidX: isScrollLeftStart || isScrollMidX ? 1 : 0,
                $$isScrollRightEnd: isScrollRightEnd ? 1 : 0,
                $$isScrollRightEndMidX: isScrollRightEnd || isScrollMidX ? 1 : 0,
                overflow: isScrollLock ? "hidden" : overflow,
                width,
                minWidth,
                maxWidth,
                height,
                minHeight,
                maxHeight,
                scrollBehavior,
                overscrollBehavior
              }
            }),
            "data-state": dataStateAttr,
            "data-scroll-container-width": scrollContainerWidth,
            "data-scroll-container-height": scrollContainerHeight,
            "data-scroll-y-contained": isScrollYContained,
            "data-scroll-top-start": isScrollTopStart,
            "data-scroll-bottom-end": isScrollBottomEnd,
            "data-scroll-mid-y": isScrollMidY,
            "data-scroll-x-contained": isScrollXContained,
            "data-scroll-right-end": isScrollRightEnd,
            "data-scroll-left-start": isScrollLeftStart,
            "data-scroll-mid-x": isScrollMidX,
            children
          }
        )
      })
    );
  }
);
var scrollContainerRootClassName = css({
  variants: {
    "": {
      ScrollContainerRoot: {
        position: "relative",
        transition: "opacity $default",
        pointerEvents: "$$scrollContainerPointerEvents",
        userSelect: "$$scrollContainerPointerEvents"
      }
    },
    scrollYShadow: {
      bottom: {
        "&::before": {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: 10,
          zIndex: "$$scrollYShadowLayer",
          pointerEvents: "none",
          transition: "opacity $default",
          boxShadow: "$$scrollBottomEndShadow",
          opacity: "$$scrollYShadowOpacity",
          content: ""
        }
      },
      none: {}
    },
    scrollXShadow: {
      right: {
        "&::after": {
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          width: 10,
          zIndex: "$$scrollXShadowLayer",
          pointerEvents: "none",
          transition: "opacity $default",
          transform: "rotateY(180deg)",
          boxShadow: "$$scrollRightEndShadow",
          opacity: "$$scrollXShadowOpacity",
          content: ""
        }
      },
      none: {}
    }
  },
  defaultVariants: {
    "": "ScrollContainerRoot",
    scrollYShadow: "none",
    scrollXShadow: "none"
  }
});
var scrollContainerClassName = css({
  variants: {
    "": {
      ScrollContainer: {
        position: "relative"
      }
    }
  },
  defaultVariants: {
    "": "ScrollContainer"
  }
});
var scrollContainerClassNames = {
  scrollContainerRoot: scrollContainerRootClassName,
  scrollContainer: scrollContainerClassName
};

// src/Section/Section.tsx
var SectionHeader = styled("header", {
  "&:not(:last-child)": {
    borderBottom: "1px solid $neutral20"
  },
  "& > *:first-child": {
    marginTop: 0
  },
  "& > *:last-child": {
    marginBottom: 0
  }
});
var SectionBody = styled("article", {
  "&:not(:last-child)": {
    borderBottom: "1px solid $neutral20"
  },
  "& > *:first-child": {
    marginTop: 0
  },
  "& > *:last-child": {
    marginBottom: 0
  }
});
var Section = styled("section", {
  // Reset
  boxSizing: "border-box",
  flexShrink: 0,
  "&::before": {
    boxSizing: "border-box",
    content: '""'
  },
  "&::after": {
    boxSizing: "border-box",
    content: '""'
  },
  // END reset
  border: "1px solid $neutral20",
  borderRadius: "$rounded-lg",
  [`& ${SectionHeader}`]: {
    padding: "$5"
  },
  [`& ${SectionBody}`]: {
    padding: "$5"
  }
});
Section.displayName = "Section";

// src/Separator/Separator.tsx
import * as SeparatorPrimitive from "@radix-ui/react-separator";
var Separator3 = styled(SeparatorPrimitive.Root, {
  border: "none",
  margin: 0,
  flexShrink: 0,
  backgroundColor: "$neutral20",
  cursor: "default",
  variants: {
    size: {
      "1": {
        '&[data-orientation="horizontal"]': {
          height: "1px",
          width: "100%"
        },
        '&[data-orientation="vertical"]': {
          width: "1px",
          height: "100%"
        }
      },
      "2": {
        '&[data-orientation="horizontal"]': {
          height: "2px",
          width: "100%"
        },
        '&[data-orientation="vertical"]': {
          width: "2px",
          height: "100%"
        }
      }
    }
  },
  defaultVariants: {
    size: "1"
  }
});

// src/SortableList/SortableList.tsx
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import * as React59 from "react";

// src/SortableList/SortableItem.tsx
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React58, { useEffect as useEffect18, useRef as useRef9 } from "react";
import { jsx as jsx79, jsxs as jsxs55 } from "react/jsx-runtime";
var StyledGripHandle = styled("span", {
  "& svg": {
    height: "14px",
    width: "14px"
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});
var SortableItem = (props) => {
  const {
    content,
    css: css3,
    id,
    getRowLabel,
    index,
    disabled,
    tooltip,
    gripPosition = "left",
    renderItem
  } = props;
  const {
    attributes,
    listeners: listeners2,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id });
  const prevTransformY = useRef9(null);
  const dragDirection = useRef9(null);
  useEffect18(() => {
    if (isDragging) {
      if (prevTransformY.current !== null && transform && (transform == null ? void 0 : transform.y) !== null) {
        dragDirection.current = (transform == null ? void 0 : transform.y) < prevTransformY.current ? "up" : "down";
      }
      prevTransformY.current = transform && (transform == null ? void 0 : transform.y) !== null ? transform == null ? void 0 : transform.y : null;
    } else {
      prevTransformY.current = null;
      dragDirection.current = null;
    }
  }, [isDragging, transform, transform == null ? void 0 : transform.y]);
  const labelStyle = { width: "$8" };
  const draggableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: !isDragging ? "auto" : 999,
    width: "100%"
  };
  const cardStyle = React58.useMemo(() => {
    return __spreadValues({
      borderRadius: "$rounded",
      background: isDragging ? "#E3E5E8" : "initial",
      "&:hover": {
        background: isDragging ? "#E3E5E8" : "#F1F2F4"
      },
      position: "relative",
      boxShadow: !isDragging ? "none" : "$elevation1",
      "&::before": {
        content: isDragging && dragDirection.current === "up" ? '""' : "none",
        display: "block",
        width: "100%",
        height: "1px",
        background: "$blue50",
        position: "absolute",
        top: "-5px",
        // 1px line height + 4px gap
        left: 0
      },
      "&::after": {
        content: isDragging && dragDirection.current === "down" ? '""' : "none",
        display: "block",
        width: "100%",
        height: "1px",
        background: "$blue50",
        position: "absolute",
        bottom: "-5px",
        // 1px line height + 4px gap
        left: 0
      }
    }, css3);
  }, [isDragging, css3, dragDirection.current]);
  useEffect18(() => {
    if (isDragging) {
      document.body.style.cursor = "grabbing";
    } else {
      document.body.style.cursor = "";
    }
  }, [isDragging]);
  const handleGripMouseEnter = React58.useCallback(() => {
    if (!isDragging) {
      document.body.style.cursor = "grab";
    }
  }, [isDragging]);
  const handleGripMouseLeave = React58.useCallback(() => {
    if (!isDragging) {
      document.body.style.cursor = "";
    }
  }, [isDragging]);
  const itemContent = React58.useMemo(() => {
    if (renderItem) {
      return renderItem({
        isDragging,
        dragDirection: dragDirection.current,
        content,
        attributes,
        listeners: listeners2,
        handleGripMouseEnter,
        handleGripMouseLeave
      });
    }
    return /* @__PURE__ */ jsx79(Flex, { alignItems: "center", gap: "sm", children: /* @__PURE__ */ jsx79(Card, { css: cardStyle, children: /* @__PURE__ */ jsxs55(Flex, { alignItems: "center", gap: "sm", children: [
      !disabled && gripPosition === "left" && /* @__PURE__ */ jsx79(
        StyledGripHandle,
        __spreadProps(__spreadValues(__spreadValues({}, attributes), listeners2), {
          onMouseEnter: handleGripMouseEnter,
          onMouseLeave: handleGripMouseLeave,
          children: /* @__PURE__ */ jsx79(Icon_default, { name: "grip-dots-vertical", size: "sm", color: "gray" })
        })
      ),
      content,
      !disabled && gripPosition === "right" && /* @__PURE__ */ jsx79(
        StyledGripHandle,
        __spreadProps(__spreadValues(__spreadValues({}, attributes), listeners2), {
          onMouseEnter: handleGripMouseEnter,
          onMouseLeave: handleGripMouseLeave,
          children: /* @__PURE__ */ jsx79(Icon_default, { name: "grip-dots", size: "sm", color: "gray" })
        })
      )
    ] }) }) });
  }, [
    renderItem,
    isDragging,
    content,
    attributes,
    listeners2,
    handleGripMouseEnter,
    handleGripMouseLeave,
    disabled,
    gripPosition,
    cardStyle,
    dragDirection.current
  ]);
  return /* @__PURE__ */ jsx79(ConditionalTooltip, __spreadProps(__spreadValues({}, tooltip), { children: /* @__PURE__ */ jsxs55(Flex, { flow: "row", alignItems: "center", children: [
    getRowLabel && /* @__PURE__ */ jsx79(Box_default, { css: labelStyle, children: getRowLabel(index) }),
    /* @__PURE__ */ jsx79("div", { ref: setNodeRef, style: draggableStyle, children: itemContent })
  ] }) }));
};

// src/SortableList/SortableList.tsx
import { jsx as jsx80 } from "react/jsx-runtime";
var SortableList = (props) => {
  const {
    items: sortableItems,
    onChange,
    getRowLabel,
    disabled,
    tooltipProps = {}
  } = props;
  const [items, setItems] = React59.useState(sortableItems);
  React59.useEffect(() => {
    setItems(sortableItems);
  }, [sortableItems]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  const handleDragEnd = (e) => {
    var _a;
    const { active, over } = e;
    const isOverItemDisabled = ((_a = items.find(({ id }) => id === (over == null ? void 0 : over.id))) == null ? void 0 : _a.disabled) === true;
    if (active.id !== (over == null ? void 0 : over.id) && !isOverItemDisabled) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === (over == null ? void 0 : over.id));
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
      if (onChange) {
        onChange(newItems);
      }
    }
  };
  return /* @__PURE__ */ jsx80(
    DndContext,
    {
      collisionDetection: closestCenter,
      modifiers: [restrictToVerticalAxis],
      onDragEnd: handleDragEnd,
      sensors,
      children: /* @__PURE__ */ jsx80(
        SortableContext,
        {
          items,
          strategy: verticalListSortingStrategy,
          disabled,
          children: /* @__PURE__ */ jsx80(TooltipProvider, { children: /* @__PURE__ */ jsx80(Flex, { flow: "column", gap: "xs", children: items.map(
            ({
              id,
              content,
              css: css3,
              icon,
              tooltip,
              disabled: itemDisabled,
              gripPosition,
              renderItem
            }, index) => /* @__PURE__ */ jsx80(
              SortableItem,
              {
                content,
                icon,
                css: css3,
                getRowLabel,
                id,
                index,
                tooltip: mergeTooltipProps(tooltip, tooltipProps),
                disabled: itemDisabled != null ? itemDisabled : disabled,
                gripPosition,
                renderItem
              },
              id
            )
          ) }) })
        }
      )
    }
  );
};

// src/Stepper/Stepper.tsx
import React60 from "react";

// src/Stepper/BaseStepper.tsx
var BaseStepper = styled(Flex, {});
var Steps = styled(Flex, {
  cursor: "default"
});
var Step = styled(Flex, {});
var StepperIcon = styled(Box_default, {
  color: "$neutral0"
});
var StepCount = styled("h4", {
  fontWeight: 600,
  color: "$neutral50",
  userSelect: "none",
  "&[data-active='true']": {
    color: "$neutral0"
  },
  "&[data-prev='true']": {
    color: "$neutral50"
  }
});
var StepperIconCircle = styled(Flex, {
  padding: 0,
  width: "$6",
  height: "$6",
  outline: "1px solid $neutral50",
  background: "inherit",
  borderRadius: "$rounded-full",
  "&[data-active='true']": {
    background: "$blue50",
    outline: "1px solid transparent"
  },
  "&[data-prev='true']": {
    outline: "1px solid transparent",
    background: "$neutral30"
  }
});
var StepLabel = styled(Text, {
  fontWeight: 500,
  fontSize: "$3",
  lineHeight: "$3",
  color: "$neutral50",
  userSelect: "none",
  "&[data-active='true']": {
    color: "$neutral80"
  },
  "&[data-prev='true']": {
    color: "$neutral50"
  }
});
var StepSubLabel = styled(Text, {
  fontWeight: 400,
  fontSize: "$1",
  lineHeight: "$1",
  color: "$neutral50",
  userSelect: "none",
  "&[data-active='true']": {
    color: "$neutral80"
  },
  "&[data-prev='true']": {
    color: "$neutral50"
  }
});
var StepNextIcon = styled(Flex, {
  color: "$neutral50",
  width: "$2",
  height: "$3"
});

// src/Stepper/Stepper.tsx
import { jsx as jsx81, jsxs as jsxs56 } from "react/jsx-runtime";
var Stepper = React60.forwardRef(
  (props, ref) => {
    const { steps, css: css3, onClickStep } = props;
    const activeIdx = steps.findIndex(({ active = false }) => active);
    return /* @__PURE__ */ jsx81(BaseStepper, { css: css3, alignItems: "center", ref, children: /* @__PURE__ */ jsx81(Steps, { gap: "md", children: steps.map((step, idx) => {
      return /* @__PURE__ */ jsxs56(
        Step,
        {
          alignItems: "center",
          gap: "sm",
          onClick: () => Boolean(idx < activeIdx) && onClickStep && onClickStep(step),
          css: {
            cursor: Boolean(idx < activeIdx) && onClickStep ? "pointer" : "default"
          },
          children: [
            /* @__PURE__ */ jsx81(StepperIcon, { children: /* @__PURE__ */ jsx81(
              StepperIconCircle,
              {
                alignItems: "center",
                justifyContent: "center",
                gap: "sm",
                "data-active": step.active,
                "data-prev": Boolean(idx < activeIdx),
                children: /* @__PURE__ */ jsx81(
                  StepCount,
                  {
                    "data-active": step.active,
                    "data-prev": Boolean(idx < activeIdx),
                    children: idx + 1
                  }
                )
              }
            ) }),
            /* @__PURE__ */ jsxs56(Flex, { flow: "column", children: [
              /* @__PURE__ */ jsx81(
                StepLabel,
                {
                  "data-active": step.active,
                  "data-prev": Boolean(idx < activeIdx),
                  children: step.label
                }
              ),
              step.subLabel && /* @__PURE__ */ jsx81(
                StepSubLabel,
                {
                  "data-active": step.active,
                  "data-prev": Boolean(idx < activeIdx),
                  children: step.subLabel
                }
              )
            ] }),
            idx < steps.length - 1 && /* @__PURE__ */ jsx81(StepNextIcon, { alignItems: "center", children: /* @__PURE__ */ jsx81(Icon_default, { name: "chevron-right" }) })
          ]
        },
        step.id
      );
    }) }) });
  }
);

// src/Stepper/use-stepper.ts
import { useState as useState30 } from "react";
var useStepper = (steps) => {
  const [_steps, setSteps] = useState30(steps);
  const activeIdx = _steps.findIndex(({ active = false }) => active);
  const isFinal = activeIdx === _steps.length - 1;
  const updateStep = (id, updates) => {
    setSteps((prev2) => [
      ...prev2.map((toUpdate) => {
        if (toUpdate.id !== id) {
          return toUpdate;
        }
        return __spreadValues(__spreadValues({}, toUpdate), updates);
      })
    ]);
  };
  const changeStep = (start, jump) => {
    setSteps((prev2) => {
      var _a;
      const u = prev2;
      if (activeIdx > -1) {
        (_a = u[activeIdx]) == null ? true : delete _a.active;
      }
      let curr = start;
      while (jump) {
        u[curr++].active = Boolean(jump--);
      }
      return [...u];
    });
  };
  const next = (jump = 1) => {
    if (activeIdx === steps.length - 1) {
      return null;
    }
    changeStep(activeIdx + 1, jump);
  };
  const prev = (jump = 1) => {
    if (!activeIdx) {
      return null;
    }
    changeStep(activeIdx - 1, jump);
  };
  const goToStep = (id) => {
    const idx = _steps.findIndex((step) => step.id === id);
    if (idx === -1) {
      return;
    }
    changeStep(idx, 1);
  };
  return [next, prev, _steps, _steps[activeIdx], isFinal, updateStep, goToStep];
};

// src/Switch/Switch.tsx
import * as React61 from "react";

// src/Switch/BaseSwitch.tsx
import * as SwitchPrimitive from "@radix-ui/react-switch";
var SwitchContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "7px"
});
var StyledThumb = styled(SwitchPrimitive.Thumb, {
  position: "absolute",
  right: 18,
  top: "calc(50% - 8px)",
  width: 16,
  height: 16,
  backgroundColor: "$neutral40",
  borderRadius: "$rounded-full",
  transition: "transform 100ms cubic-bezier(0.22, 1, 0.36, 1)",
  transform: "translateX(1px)",
  willChange: "transform",
  '&[data-state="checked"]': {
    transform: "translateX(16px)",
    backgroundColor: "$neutral0"
  }
});
var StyledSwitch = styled(SwitchPrimitive.Root, {
  all: "unset",
  boxSizing: "border-box",
  userSelect: "none",
  "&::before": {
    boxSizing: "border-box"
  },
  "&::after": {
    boxSizing: "border-box"
  },
  // Reset
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  lineHeight: "1",
  margin: "0",
  outline: "none",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  backgroundColor: "$neutral0",
  border: "1px solid $neutral40",
  borderRadius: "$rounded-full",
  position: "relative",
  "&:focus": {
    boxShadow: "0px 0px 0px 2px #8FB4F3",
    border: "1px solid $blue50"
  },
  "&:disabled": {
    backgroundColor: "$neutral20",
    border: "1px solid $neutral20"
  },
  '&[data-state="checked"]': {
    backgroundColor: "$blue50",
    border: "none",
    "&:disabled": {
      backgroundColor: "$blue20",
      border: "none"
    }
  },
  variants: {
    size: {
      "1": {
        width: "36px",
        height: "20px"
      }
    }
  },
  defaultVariants: {
    size: "1"
  }
});

// src/Switch/Switch.tsx
import { jsx as jsx82, jsxs as jsxs57 } from "react/jsx-runtime";
var Switch = React61.forwardRef((props, forwardedRef) => {
  var _a;
  const uid = React61.useId();
  const elementId = (_a = props.id) != null ? _a : uid;
  return /* @__PURE__ */ jsxs57(SwitchContainer, { children: [
    props.labelLeft && /* @__PURE__ */ jsx82(Label, { htmlFor: elementId, children: props.labelLeft }),
    /* @__PURE__ */ jsx82(StyledSwitch, __spreadProps(__spreadValues({}, props), { id: elementId, ref: forwardedRef, children: /* @__PURE__ */ jsx82(StyledThumb, {}) })),
    props.labelRight && /* @__PURE__ */ jsx82(Label, { htmlFor: elementId, children: props.labelRight })
  ] });
});

// src/Table/Th.tsx
import { useComposedRefs as useComposedRefs3 } from "@radix-ui/react-compose-refs";
import * as React62 from "react";

// src/Table/base-components/BaseCaption.tsx
var BaseCaption = styled("caption");

// src/Table/base-components/BaseTd.tsx
var BaseTd = styled("td", {
  $$tdWidth: "var(---cellWidth, $sizes$12)",
  $$tdMinWidth: "var(---cellMinWidth, auto)",
  $$tdMaxWidth: "var(---cellMaxWidth, auto)",
  $$tdHeight: "var(---cellHeight, 44px)",
  $$tdMinHeight: "var(---cellMinHeight, auto)",
  $$tdMaxHeight: "var(---cellMaxHeight, auto)",
  $$tdActiveBg: "var(---cellActiveBg, $colors$table_cell_background_active_opaque)",
  $$tdPadding: "var(---cellPadding, $space$2 $space$3)",
  $$tdTruncate: "var(---cellTruncate, ellipsis)",
  width: "$$tdWidth",
  minWidth: "$$tdMinWidth",
  maxWidth: "$$tdMaxWidth",
  height: "$$tdHeight",
  minHeight: "$$tdMinHeight",
  maxHeight: "$$tdMaxHeight",
  p: "$$tdPadding",
  fontSize: "$2",
  lineHeight: "$2",
  textAlign: "inherit",
  verticalAlign: "inherit",
  truncate: "$$tdTruncate",
  color: "$neutral90",
  backgroundColor: "$neutral0",
  boxShadow: "$inset_border_bottom_1 $colors$neutral30",
  "&:active": {
    backgroundColor: "$$tdActiveBg"
  }
});

// src/Table/base-components/BaseTh.tsx
var BaseTh = styled("th", {
  $$thWidth: "var(---cellWidth, $sizes$12)",
  $$thMinWidth: "var(---cellMinWidth, auto)",
  $$thMaxWidth: "var(---cellMaxWidth, auto)",
  $$thHeight: "var(---cellHeight, 36px)",
  $$thMinHeight: "var(---cellMinHeight, auto)",
  $$thMaxHeight: "var(---cellMaxHeight, auto)",
  $$thActiveBg: "var(---cellActiveBg, $colors$table_cell_background_active_opaque)",
  $$thPadding: "var(---cellPadding, $space$2 $space$3)",
  $$thTruncate: "var(---cellTruncate, ellipsis)",
  $$thRowHeaderOffsetLeft: "var(---rowHeaderOffsetLeft, 0px)",
  $$thPinnedRowHeaderLayer: "var(---pinnedRowHeaderLayer, $$tableLayerOne)",
  $$thPinnedRowHeaderShadowOpacity: "var(---isScrollRightEndMidX, 0)",
  width: "$$thWidth",
  minWidth: "$$thMinWidth",
  maxWidth: "$$thMaxWidth",
  height: "$$thHeight",
  minHeight: "$$thMinHeight",
  maxHeight: "$$thMaxHeight",
  p: "$$thPadding",
  fontWeight: "$medium",
  fontSize: "$1",
  lineHeight: "$1",
  textAlign: "inherit",
  verticalAlign: "inherit",
  truncate: "$$thTruncate",
  color: "$neutral70",
  backgroundColor: "$neutral0",
  boxShadow: "$inset_border_top_1 $colors$neutral30, $inset_border_bottom_1 $colors$neutral30",
  "&[scope=row]": {
    fontSize: "$2",
    lineHeight: "$2",
    color: "$neutral90",
    boxShadow: "$inset_border_right_1 $colors$neutral30, $inset_border_bottom_1 $colors$neutral30 ",
    "&:active": {
      backgroundColor: "$$thActiveBg"
    }
  },
  "&[scope=col][data-pinned=true]": {
    boxShadow: "$inset_border_top_1 $colors$neutral30, $inset_border_right_1 $colors$neutral30, $inset_border_bottom_1 $colors$neutral30"
  },
  "&[data-pinned=true]": {
    position: "sticky",
    left: 0,
    overflow: "visible",
    zIndex: "$$thPinnedRowHeaderLayer",
    "&::after": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 10,
      boxShadow: "$scroll_overflow_x_shadow_right $colors$scroll_overflow_shadow_color",
      opacity: "$$thPinnedRowHeaderShadowOpacity",
      pointerEvents: "none",
      transform: "translateX(100%)",
      transition: "opacity $default",
      content: ""
    }
  },
  variants: {
    showMenuOnHover: {
      true: {
        "&:hover [data-hover-menu]": {
          opacity: "1"
        }
      }
    }
  }
});

// src/Table/base-components/BaseTable.tsx
var BaseTable = styled("table", {
  $$tableWidth: "100%",
  $$tableBaseLayer: 1,
  $$tableLayerOne: "$$tableBaseLayer",
  $$tableLayerTwo: "calc($$tableLayerOne + 1)",
  $$cellTextAlign: "left",
  $$cellVerticalAlign: "middle",
  reset: "*",
  tableLayout: "fixed",
  // TODO: try 'auto' layout?
  borderCollapse: "collapse",
  borderColor: "$neutral30",
  position: "relative",
  width: "$$tableWidth",
  fontFamily: "$primary",
  textAlign: "$$cellTextAlign",
  verticalAlign: "$$cellVerticalAlign",
  ":where(tbody) :where(tr:last-of-type)": {
    [`& ${BaseTh}[data-pinned=true]`]: {
      boxShadow: "$colors$neutral30 $inset_border_right_1"
    },
    [`& ${BaseTd}`]: {
      boxShadow: "none"
    }
  }
});

// src/Table/base-components/BaseTbody.tsx
var BaseTbody = styled("tbody", {
  textAlign: "inherit",
  verticalAlign: "inherit"
});

// src/Table/base-components/BaseTr.tsx
var BaseTr = styled("tr", {
  $$trRowHoverBg: "var(---rowHoverBg, $colors$table_row_background_hover_opaque)",
  $$thActiveBg: "var(---cellActiveBg, $colors$table_cell_background_active_opaque)",
  $$tdActiveBg: "var(---cellActiveBg, $colors$table_cell_background_active_opaque)",
  $$trAnchorHoverTextDecoration: "var(---anchorHoverTextDecoration, underline)",
  textAlign: "inherit",
  verticalAlign: "inherit",
  variants: {
    hoverBg: {
      row: {
        "&:hover": {
          backgroundColor: "$$trRowHoverBg",
          [`& ${BaseTh}[scope="row"]`]: {
            backgroundColor: "$$trRowHoverBg",
            "&:active": {
              backgroundColor: "$$thActiveBg"
            }
          },
          [`& ${BaseTd}`]: {
            backgroundColor: "$$trRowHoverBg",
            "&:active": {
              backgroundColor: "$$tdActiveBg"
            }
          }
        }
      },
      none: {}
    },
    hoverAnchorUnderline: {
      true: {
        "&:hover :where(th, td) a": {
          textDecoration: "$$trAnchorHoverTextDecoration"
        }
      }
    },
    selected: {
      true: {
        position: "relative",
        [`& ${BaseTd}`]: {
          [`&:before`]: {
            content: "",
            position: "absolute",
            top: "-1px",
            left: 0,
            right: 0,
            height: "1px",
            backgroundColor: "$colors$blue30"
          },
          backgroundColor: "$$trRowHoverBg",
          boxShadow: "$inset_border_bottom_1 $colors$blue30"
        }
      }
    }
  },
  defaultVariants: {
    hoverBg: "row",
    hoverAnchorUnderline: true
  }
});

// src/Table/base-components/BaseThead.tsx
var BaseThead = styled("thead", {
  $$theadPinnedColumnHeaderLayer: "var(---pinnedColumnHeaderLayer, $$tableLayerTwo)",
  textAlign: "inherit",
  verticalAlign: "inherit",
  "&[data-pinned=true]": {
    position: "sticky",
    top: 0,
    zIndex: "$$theadPinnedColumnHeaderLayer",
    [`& ${BaseTr}`]: {
      border: "none"
    }
  }
});

// src/Table/base-components/BaseTfoot.tsx
var BaseTfoot = styled("tfoot", {
  textAlign: "inherit",
  verticalAlign: "inherit"
});

// src/Table/Th.tsx
import { jsx as jsx83, jsxs as jsxs58 } from "react/jsx-runtime";
var Th = React62.forwardRef(
  (props, forwardedRef) => {
    const _a = props, {
      children,
      sort,
      showMenu,
      showMenuOnHover = showMenu,
      showSortOnHover = true,
      menuDisabled = false,
      menuOptions,
      title,
      scope = "col"
    } = _a, rest = __objRest(_a, [
      "children",
      "sort",
      "showMenu",
      "showMenuOnHover",
      "showSortOnHover",
      "menuDisabled",
      "menuOptions",
      "title",
      "scope"
    ]);
    const thRef = React62.useRef(null);
    const composedThRefs = useComposedRefs3(forwardedRef, thRef);
    const [offsetLeft, setOffsetLeft] = React62.useState(0);
    const [menuButtonHovered, setMenuButtonHovered] = React62.useState(false);
    React62.useLayoutEffect(() => {
      if (thRef.current) {
        setOffsetLeft(thRef.current.offsetLeft);
      }
    }, []);
    const {
      state: sortState,
      disabled: sortDisabled,
      onSort
    } = sort != null ? sort : {
      state: void 0,
      disabled: void 0,
      onSort: void 0
    };
    const ariaSort = React62.useMemo(() => {
      if (!sortState) {
        return "none";
      }
      switch (sortState) {
        case "asc":
          return "ascending";
        case "desc":
          return "descending";
        default:
          return "none";
      }
    }, [sortState]);
    const getSortIcon = () => {
      switch (sortState) {
        case "asc":
          return "arrow-up";
        case "desc":
          return "arrow-down";
        case "none":
          return "arrow-up-arrow-down";
        default:
          return null;
      }
    };
    const sortIcon = getSortIcon();
    const hasSortFn = typeof onSort === "function";
    const isSortDisabled = sortDisabled === true;
    const showSortIcon = !!sortIcon;
    const showHoverVariant = hasSortFn && !menuButtonHovered;
    const handleSort = React62.useCallback(
      (e) => {
        if (!hasSortFn || isSortDisabled) {
          return;
        }
        onSort(e);
      },
      [isSortDisabled, hasSortFn, onSort]
    );
    const handleScrollLock = React62.useCallback((open) => {
      const thElement = thRef.current;
      if (thElement) {
        const scrollLockEvent = new CustomEvent(
          open ? "ScrollContainer:lockscroll" : "ScrollContainer:unlockscroll",
          {
            detail: { target: thElement },
            bubbles: true,
            cancelable: true
          }
        );
        thElement.dispatchEvent(scrollLockEvent);
      }
      return void 0;
    }, []);
    const handleMenuPointerDown = React62.useCallback(
      (e) => {
        if (menuDisabled) {
          e.preventDefault();
        }
      },
      [menuDisabled]
    );
    const handleMenuPointerUp = React62.useCallback(
      (e) => {
        if (menuDisabled) {
          e.stopPropagation();
        }
      },
      [menuDisabled]
    );
    return /* @__PURE__ */ jsx83(
      BaseTh,
      __spreadProps(__spreadValues({
        ref: composedThRefs,
        onClick: handleSort,
        showMenuOnHover: !!showMenuOnHover,
        title,
        abbr: title,
        scope,
        className: thRootClassName({
          hover: showHoverVariant,
          css: {
            $$thRowHeaderOffsetLeft: `${offsetLeft}px`
          }
        }),
        "aria-sort": ariaSort
      }, rest), {
        children: /* @__PURE__ */ jsxs58(ThContent, { className: thContentClassName(), children: [
          /* @__PURE__ */ jsxs58(Flex, { gap: "xs", className: thTitleContainerClassName(), children: [
            /* @__PURE__ */ jsx83(
              ThTitle,
              {
                onClick: handleSort,
                hover: showHoverVariant,
                className: thTitleClassName(),
                children
              }
            ),
            showSortIcon && /* @__PURE__ */ jsx83(
              ThButton,
              {
                onClick: handleSort,
                disabled: isSortDisabled,
                className: thButtonClassName({
                  for: "sort",
                  isSorted: sortState === "asc" || sortState === "desc"
                }),
                "data-hover-menu": !showSortOnHover,
                children: /* @__PURE__ */ jsx83(Icon_default, { name: sortIcon, size: "sm" })
              }
            )
          ] }),
          showMenu && menuOptions && /* @__PURE__ */ jsx83(
            DropdownMenu,
            {
              options: menuOptions.map((option) => __spreadProps(__spreadValues({}, option), {
                onClick: (e) => {
                  var _a2;
                  (_a2 = option.onClick) == null ? void 0 : _a2.call(option, e);
                  e.stopPropagation();
                }
              })),
              withArrow: false,
              handleOpenChange: handleScrollLock,
              css: {
                $$thDropdownMenuLayer: "var(---tableLayerTwo, 2)",
                zIndex: "$$thDropdownMenuLayer"
              },
              children: /* @__PURE__ */ jsx83(
                ThButton,
                {
                  onPointerDown: handleMenuPointerDown,
                  onPointerUp: handleMenuPointerUp,
                  onMouseEnter: () => setMenuButtonHovered(true),
                  onMouseLeave: () => setMenuButtonHovered(false),
                  disabled: menuDisabled,
                  className: thButtonClassName({
                    for: "dropdownMenu",
                    css: {
                      opacity: showMenuOnHover ? 0 : 1
                    }
                  }),
                  "data-hover-menu": "true",
                  onClick: (e) => e.stopPropagation(),
                  children: /* @__PURE__ */ jsx83(Icon_default, { name: "ellipsis", size: "sm" })
                }
              )
            }
          )
        ] })
      })
    );
  }
);
var ThContent = styled(Box_default);
var ThButton = styled("button", {
  display: "inline-flex",
  alignItems: "center",
  backgroundColor: "transparent",
  color: "CurrentColor",
  border: "none",
  borderRadius: "$rounded",
  padding: 1,
  cursor: "pointer",
  "&:disabled": {
    cursor: "not-allowed",
    color: "$neutral40"
  }
});
var ThTitle = styled("span", {
  variants: {
    hover: {
      true: {
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline"
        },
        [`&:hover + ${ThButton}`]: {
          opacity: "1"
        }
      }
    }
  }
});
var thRootClassName = css({
  variants: {
    "": {
      ThRoot: {
        $$thRowHeaderOffsetLeft: "0px"
      }
    },
    hover: {
      true: {
        cursor: "pointer",
        "&:hover": {
          [`& ${ThTitle}`]: {
            textDecoration: "underline"
          },
          [`& ${ThButton}`]: {
            "&[data-hover-menu=false]": {
              opacity: 1
            }
          }
        }
      }
    }
  },
  defaultVariants: {
    "": "ThRoot"
  }
});
var thContentClassName = css({
  variants: {
    "": {
      ThContent: {
        position: "relative",
        display: "grid",
        gridTemplateColumns: "minmax(calc(100% - $5), 100%) auto",
        justifyContent: "space-between",
        columnGap: "$1"
      }
    }
  },
  defaultVariants: {
    "": "ThContent"
  }
});
var thTitleContainerClassName = css({
  $$width: "100%",
  variants: {
    "": {
      ThTitleContainer: {
        width: "$$width"
      }
    }
  },
  defaultVariants: {
    "": "ThTitleContainer"
  }
});
var thTitleClassName = css({
  variants: {
    "": {
      ThTitle: {
        display: "inline-block",
        lineHeight: "$1",
        truncate: "$$thTruncate"
      }
    }
  },
  defaultVariants: {
    "": "ThTitle"
  }
});
var thButtonClassName = css({
  variants: {
    "": {
      ThButton: {
        "&[data-hover-menu=false]": {
          opacity: 0
        },
        "&:hover": {
          backgroundColor: "$button_subtle_background_hover"
        },
        svg: {
          width: 14,
          height: 14
        }
      }
    },
    isSorted: {
      true: {
        "&[data-hover-menu=false]": {
          opacity: 1
        },
        "&:hover": {
          backgroundColor: "$button_subtle_background_hover"
        },
        svg: {
          width: 14,
          height: 14
        }
      }
    },
    for: {
      sort: {},
      dropdownMenu: {}
    }
  },
  defaultVariants: {
    "": "ThButton"
  }
});
var thClassNames = {
  thRoot: thRootClassName,
  thContent: thContentClassName,
  thTitleContainer: thTitleContainerClassName,
  thTitle: thTitleClassName,
  thButton: thButtonClassName
};

// src/Table/Caption.tsx
var Caption = BaseCaption;

// src/Table/Table.tsx
import * as React63 from "react";
import { jsx as jsx84 } from "react/jsx-runtime";
var Table = React63.forwardRef((_a, forwardedRef) => {
  var _b = _a, { children, css: css3 } = _b, rest = __objRest(_b, ["children", "css"]);
  return /* @__PURE__ */ jsx84(BaseTable, __spreadProps(__spreadValues({ ref: forwardedRef, css: css3 }, rest), { children }));
});

// src/Table/Tbody.tsx
var Tbody = BaseTbody;

// src/Table/Td.tsx
import * as React64 from "react";
import { jsx as jsx85 } from "react/jsx-runtime";
var Td = React64.forwardRef(
  (props, ref) => {
    const _a = props, { children } = _a, rest = __objRest(_a, ["children"]);
    return /* @__PURE__ */ jsx85(BaseTd, __spreadProps(__spreadValues({}, rest), { ref, children }));
  }
);

// src/Table/Thead.tsx
var Thead = BaseThead;

// src/Table/Tr.tsx
var Tr = BaseTr;

// src/Table/Tfoot.tsx
var Tfoot = BaseTfoot;

// src/Table/index.ts
var TablePrimitives = {
  Caption: BaseCaption,
  Table: BaseTable,
  Tbody: BaseTbody,
  Td: BaseTd,
  Th: BaseTh,
  Thead: BaseThead,
  Tr: BaseTr,
  Tfoot: BaseTfoot
};
var tableClassNames = {
  Th: thClassNames
};

// src/Tabs/BaseTabs.tsx
import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React66 from "react";

// src/Tabs/tabsUtils.ts
import * as React65 from "react";
var tabsContentComponentIdentifier = ".TabsContent";

// src/Tabs/BaseTabs.tsx
import { jsx as jsx86 } from "react/jsx-runtime";
var StyledTabsTrigger = styled(TabsPrimitive.TabsTrigger, {
  boxSizing: "border-box",
  userSelect: "none",
  outline: "none",
  background: "none",
  border: "none",
  display: "inline-flex",
  flexShrink: 0,
  alignItems: "center",
  justifyContent: "center",
  px: 0,
  zIndex: "$1",
  fontWeight: 500,
  color: "$neutral70",
  cursor: "pointer",
  // default regular size
  fontSize: "$3",
  lineHeight: "$3",
  letterSpacing: "$1",
  pt: "$2",
  pb: "$2",
  '&[data-state="active"]': {
    color: "$blue50",
    borderBottom: "1px solid $blue50"
  },
  "&:hover": {
    color: "#317FF4"
    // TODO: use token string when exists
  },
  "&:active": {
    color: "#0D60DE"
    // TODO: use token string when exists
  },
  "&[data-disabled]": {
    color: "rgba(53, 65, 78, 0.48);",
    // TODO: use token string when exists
    cursor: "not-allowed"
  },
  variants: {
    compact: {
      true: {
        fontSize: "$2",
        lineHeight: "$2",
        letterSpacing: "$2",
        pt: 6,
        pb: 5
      }
    }
  },
  defaultVariants: {
    compact: false
  }
});
var StyledTabsSeparator = styled(Separator3, {
  $$tabWidth: "100%",
  position: "absolute",
  bottom: 0,
  width: "$$tabWidth",
  height: 1,
  backgroundColor: "$neutral30",
  variants: {
    variant: {
      content: {
        $$tabWidth: "100%",
        backgroundColor: "$neutral30"
      },
      defaultTab: {
        backgroundColor: "$blue50",
        transform: "translateX($$tabOffsetLeft)",
        transition: "none"
      },
      tab: {
        backgroundColor: "$blue50",
        transform: "translateX($$tabOffsetLeft)",
        transition: "transform 250ms ease-in-out"
      }
    },
    size: {}
  },
  defaultVariants: {
    variant: "content",
    size: ""
    // removes default Separator `size` variant
  }
});
var StyledTabsContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  "&:focus": {
    outline: "none"
  }
});
var StyledTabsRoot = styled(TabsPrimitive.Root, {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  fontFamily: "$primary"
});
var StyledTabsGroup = styled("div", {
  position: "relative"
});
var StyledTabsList = styled(TabsPrimitive.List, {
  boxSizing: "border-box",
  display: "flex",
  flexShrink: 0,
  columnGap: "$4",
  "&:focus": {
    outline: "none"
  },
  [`& ${StyledTabsTrigger}`]: {
    // use `TabsContentSeparator` as active tab border when grouped in
    // `StyledTabsList`
    '&[data-state="active"]': {
      borderBottom: "none"
    }
  }
});
var TabsTrigger2 = React66.forwardRef((props, ref) => {
  const _a = props, { children, tabId, disabled, onClick } = _a, rest = __objRest(_a, ["children", "tabId", "disabled", "onClick"]);
  return /* @__PURE__ */ jsx86(
    StyledTabsTrigger,
    __spreadProps(__spreadValues({
      ref,
      value: tabId,
      disabled,
      onClick,
      "data-nectar-styled-name": "trigger"
    }, rest), {
      children
    }),
    tabId
  );
});
TabsTrigger2.displayName = "TabsTrigger";
var TabsContentSeparator = (props) => {
  const { activeTab } = props;
  const isDefaultActiveTabSet = React66.useRef(false);
  React66.useEffect(() => {
    if (activeTab && !isDefaultActiveTabSet.current) {
      isDefaultActiveTabSet.current = true;
    }
  }, [activeTab]);
  const innerSeparatorVariant = isDefaultActiveTabSet.current ? "tab" : "defaultTab";
  return /* @__PURE__ */ jsx86(
    StyledTabsSeparator,
    {
      "data-nectar-styled-name": "separator",
      "data-nectar-variants-variant": "content",
      children: /* @__PURE__ */ jsx86(
        StyledTabsSeparator,
        {
          variant: innerSeparatorVariant,
          css: {
            $$tabWidth: `${(activeTab == null ? void 0 : activeTab.offsetWidth) || 0}px`,
            $$tabOffsetLeft: `${(activeTab == null ? void 0 : activeTab.offsetLeft) || 0}px`
          },
          "data-nectar-styled-name": "separator",
          "data-nectar-variants-variant": innerSeparatorVariant
        }
      )
    }
  );
};
TabsContentSeparator.displayName = "TabsContentSeparator";
var TabsContent = (_a) => {
  var _b = _a, {
    tabId,
    lazy = false,
    activeTabId
  } = _b, rest = __objRest(_b, [
    "tabId",
    "lazy",
    "activeTabId"
  ]);
  const isLazyRendered = lazy === true;
  if (isLazyRendered) {
    return activeTabId === tabId ? /* @__PURE__ */ jsx86(
      StyledTabsContent,
      __spreadValues({
        value: tabId,
        "data-nectar-styled-name": "content"
      }, rest),
      tabId
    ) : null;
  }
  return /* @__PURE__ */ jsx86(
    StyledTabsContent,
    __spreadValues({
      value: tabId,
      "data-nectar-styled-name": "content"
    }, rest),
    tabId
  );
};
TabsContent.toString = () => tabsContentComponentIdentifier;
TabsContent.displayName = "TabsContent";
var publicTabsStyledComponents = {
  Root: StyledTabsRoot,
  Group: StyledTabsGroup,
  List: StyledTabsList,
  Separator: StyledTabsSeparator,
  Trigger: StyledTabsTrigger,
  Content: StyledTabsContent
};

// src/Tabs/Tabs.tsx
import * as React67 from "react";
import { jsx as jsx87, jsxs as jsxs59 } from "react/jsx-runtime";
var TabsContainer = React67.forwardRef(({ children, tabs, tabId, defaultTabId, compact = false, css: css3 = {} }, ref) => {
  const [activeTab, setActiveTab] = React67.useState(
    null
  );
  const onActiveTabRef = (tabRef) => {
    var _a;
    const isActiveTab = ((_a = tabRef == null ? void 0 : tabRef.getAttribute) == null ? void 0 : _a.call(tabRef, "data-state")) === "active";
    if (isActiveTab) {
      setActiveTab(tabRef);
    }
  };
  return /* @__PURE__ */ jsxs59(
    StyledTabsRoot,
    {
      ref,
      defaultValue: defaultTabId,
      value: tabId,
      css: css3,
      "data-nectar-styled-name": "root",
      children: [
        /* @__PURE__ */ jsxs59(StyledTabsGroup, { "data-nectar-styled-name": "group", children: [
          /* @__PURE__ */ jsx87(StyledTabsList, { "data-nectar-styled-name": "list", children: tabs.map((tab) => /* @__PURE__ */ jsx87(
            TabsTrigger2,
            {
              ref: onActiveTabRef,
              tabId: tab.tabId,
              onClick: tab.onClick,
              disabled: tab.disabled,
              compact,
              "data-tabid": tab.tabId,
              css: tab.css,
              children: tab.label
            },
            tab.tabId
          )) }),
          /* @__PURE__ */ jsx87(TabsContentSeparator, { activeTab })
        ] }),
        React67.Children.map(children, (child) => {
          var _a;
          if (React67.isValidElement(child)) {
            return React67.cloneElement(child, __spreadProps(__spreadValues({}, child.props), {
              activeTabId: (_a = activeTab == null ? void 0 : activeTab.getAttribute("data-tabid")) != null ? _a : "",
              "data-contentid": child.props.tabId
            }));
          }
          return child;
        })
      ]
    }
  );
});
TabsContainer.displayName = "TabsContainer";
var Tabs = {
  Container: TabsContainer,
  Content: TabsContent,
  Tab: TabsTrigger2
};

// src/Tabs/index.ts
var Tabs2 = __spreadValues({
  Styled: publicTabsStyledComponents
}, Tabs);

// src/TextArea/TextArea.tsx
import * as React68 from "react";

// src/TextArea/BaseTextArea.tsx
var baseTextAreaCss = css({
  overflow: "auto",
  height: "auto",
  minHeight: "auto",
  maxHeight: "auto",
  pointerEvents: "auto",
  fontSize: "$2",
  lineHeight: "$2",
  letterSpacing: "$2",
  "&:read-only": {
    resize: "none",
    cursor: "not-allowed"
  },
  "&:disabled": {
    resize: "none",
    cursor: "not-allowed"
  }
});

// src/TextArea/TextArea.tsx
import { jsx as jsx88 } from "react/jsx-runtime";
var TextFieldAsTextArea = TextField;
var TEXTAREA_LINE_HEIGHT_PX = parseInt(theme.lineHeights["2"].value, 10);
var TEXTAREA_TOP_AND_BOTTOM_PADDING_PX = 2 * parseInt(theme.space["2"].value, 10);
var TEXTAREA_TOP_AND_BOTTOM_BORDER_WIDTH_PX = 2;
var DEFAULT_MIN_HEIGHT_PX = 5 * TEXTAREA_LINE_HEIGHT_PX + TEXTAREA_TOP_AND_BOTTOM_PADDING_PX + TEXTAREA_TOP_AND_BOTTOM_BORDER_WIDTH_PX;
var TextArea = React68.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      value,
      onChange,
      readOnly = false,
      autoFocus = false,
      minHeight = DEFAULT_MIN_HEIGHT_PX,
      maxHeight = "auto",
      resize = false
    } = _b, rest = __objRest(_b, [
      "value",
      "onChange",
      "readOnly",
      "autoFocus",
      "minHeight",
      "maxHeight",
      "resize"
    ]);
    const internalRef = React68.useRef(null);
    const onMountAutoFocusOnce = React68.useRef(autoFocus);
    React68.useImperativeHandle(
      forwardedRef,
      () => internalRef.current
    );
    React68.useEffect(() => {
      var _a2;
      if (onMountAutoFocusOnce.current) {
        onMountAutoFocusOnce.current = false;
        (_a2 = internalRef.current) == null ? void 0 : _a2.focus();
      }
    }, []);
    const adjustTextAreaHeight = React68.useCallback(() => {
      var _a2, _b2;
      if (!internalRef.current || resize) {
        return false;
      }
      if (value === "") {
        internalRef.current.style.removeProperty("height");
      } else {
        internalRef.current.style.height = `${internalRef.current.scrollHeight + // accounts for border-top and border-bottom width taking vertical
        // space in textarea box with `box-sizing: border-box` so that
        // height expands/shrinks exactly to content to prevent scrollbar
        // from appearing
        TEXTAREA_TOP_AND_BOTTOM_BORDER_WIDTH_PX}px`;
      }
      (_b2 = (_a2 = internalRef.current) == null ? void 0 : _a2.scrollTo) == null ? void 0 : _b2.call(_a2, {
        top: internalRef.current.scrollHeight + TEXTAREA_TOP_AND_BOTTOM_BORDER_WIDTH_PX,
        behavior: "smooth"
      });
    }, [resize, value]);
    return /* @__PURE__ */ jsx88(
      TextFieldAsTextArea,
      __spreadProps(__spreadValues({
        onInput: adjustTextAreaHeight,
        ref: internalRef,
        as: "textarea",
        value,
        onChange,
        readOnly,
        className: baseTextAreaCss,
        style: {
          minHeight: typeof minHeight === "number" ? `${minHeight}px` : minHeight,
          maxHeight: typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight,
          resize: resize ? "vertical" : "none"
        }
      }, rest), {
        loading: false,
        showClearButton: false,
        onClear: void 0,
        icon: void 0
      })
    );
  }
);
TextArea.displayName = "TextArea";

// src/Toast/BaseToast.tsx
import * as ToastPrimitive from "@radix-ui/react-toast";
var DismissIcon2 = styled(Icon_default, {
  "&:hover": {
    color: "$neutral60"
  },
  "&:active": {
    color: "$neutral80"
  },
  color: "$neutral70",
  cursor: "pointer"
});
var StyledToastRoot = styled(ToastPrimitive.Root, {
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  boxShadow: "$elevation2",
  borderRadius: "$rounded",
  background: "$neutral0",
  width: 280,
  py: "$3",
  pl: "$4",
  pr: "$3",
  fontFamily: "$primary",
  "&[data-state=open]": {
    animation: `${animations.slideInFromLeft.cubicCurve}`
  },
  "&[data-state=closed]": {
    animation: animations.hide.default
  },
  variants: {
    variant: {
      default: {
        borderTop: "none"
      },
      success: {
        borderTop: "2px solid $green40"
      },
      error: {
        borderTop: "2px solid $red40"
      },
      warning: {
        borderTop: "2px solid $yellow40"
      },
      progress: {
        // TODO: animate border-top progress bar
      },
      "progress-indeterminate": {
        borderTop: "none"
      }
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
var StyledToastTitle = styled(ToastPrimitive.Title, {
  display: "flex",
  alignItems: "center",
  gap: "6px",
  fontSize: "$1",
  fontWeight: "$medium",
  lineHeight: "$1",
  overflowX: "hidden",
  textOverflow: "ellipsis"
});
var StyledTitleContainer = styled(Flex);
var StyledToastDescription = styled(ToastPrimitive.Description, {
  fontSize: "$1",
  lineHeight: "$1",
  overflowX: "hidden",
  textOverflow: "ellipsis"
});
var StyledToastAction = styled(ToastPrimitive.Action, {
  fontSize: "$1",
  lineHeight: "$1",
  color: "$blue50",
  background: "none",
  border: "none",
  cursor: "pointer",
  textAlign: "left",
  fontWeight: "$medium",
  padding: 0
});
var StyledContentContainer = styled(Flex);
var StyledToastClose = styled(ToastPrimitive.Close, {
  background: "none",
  border: "none"
});
var defaultViewportStyles = css({
  position: "fixed",
  bottom: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  padding: 20,
  width: 390,
  maxWidth: "100vw",
  margin: 0,
  listStyle: "none",
  zIndex: "$max",
  outline: "none"
});
var StyledToastViewport = styled(
  ToastPrimitive.Viewport,
  defaultViewportStyles
);
var StyledToastProgressIcon = styled(Box_default, {
  transform: "translate(-50%, -50%)",
  width: 12,
  height: 12,
  borderRadius: "$rounded-full",
  border: "2.5px solid $input_default_icon_active",
  borderTopColor: "transparent",
  animation: animations.rotate.infinite
});
var publicToastBaseComponents = {
  Styled: {
    Root: StyledToastRoot,
    Title: StyledToastTitle,
    TitleContainer: StyledTitleContainer,
    Description: StyledToastDescription,
    Action: StyledToastAction,
    ContentContainer: StyledContentContainer,
    Close: StyledToastClose,
    Viewport: StyledToastViewport,
    ProgressIcon: StyledToastProgressIcon
  }
};

// src/Toast/Toast.tsx
import { hasOwn as hasOwn2 } from "@kandji-inc/nectar-utils";
import * as ToastPrimitive2 from "@radix-ui/react-toast";
import * as React69 from "react";

// src/Toast/toastUtils.tsx
import { jsx as jsx89 } from "react/jsx-runtime";
function getToastVariantIcon(variant) {
  switch (variant) {
    case "default":
      return null;
    case "success":
      return /* @__PURE__ */ jsx89(Box_default, { children: /* @__PURE__ */ jsx89(
        Icon_default,
        {
          name: "circle-check",
          size: "sm",
          color: theme.colors.green40.value
        }
      ) });
    case "error":
      return /* @__PURE__ */ jsx89(Box_default, { children: /* @__PURE__ */ jsx89(
        Icon_default,
        {
          name: "hexagon-exclamation",
          size: "sm",
          color: theme.colors.red40.value
        }
      ) });
    case "warning":
      return /* @__PURE__ */ jsx89(Box_default, { children: /* @__PURE__ */ jsx89(
        Icon_default,
        {
          name: "diamond-exclamation",
          size: "sm",
          color: theme.colors.yellow40.value
        }
      ) });
    case "progress":
      return /* @__PURE__ */ jsx89(StyledToastProgressIcon, {});
    case "progress-indeterminate":
      return /* @__PURE__ */ jsx89(StyledToastProgressIcon, {});
    default:
      return null;
  }
}

// src/Toast/Toast.tsx
import { jsx as jsx90, jsxs as jsxs60 } from "react/jsx-runtime";
var DEFAULT_TOAST_ACTION_ALT_TEXT = "Toast action";
var DEFAULT_REPLACE_TOAST_CLOSE_TIMEOUT_MS = 500;
var DEFAULT_TOAST_DURATION_MS = 5e3;
var Toast = React69.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      title: consumerTitle,
      content: consumerContent,
      children: consumerAction,
      variant: consumerVariant,
      duration: consumerDuration = DEFAULT_TOAST_DURATION_MS,
      altText: consumerAltText = DEFAULT_TOAST_ACTION_ALT_TEXT,
      defaultOpen = false,
      managerRef,
      testId,
      viewportCss = {}
    } = _b, restProps = __objRest(_b, [
      "title",
      "content",
      "children",
      "variant",
      "duration",
      "altText",
      "defaultOpen",
      "managerRef",
      "testId",
      "viewportCss"
    ]);
    const [toastState, setToastState] = React69.useState(() => ({
      open: defaultOpen,
      title: consumerTitle,
      content: consumerContent,
      action: consumerAction,
      variant: consumerVariant,
      altText: consumerAltText,
      duration: consumerDuration
    }));
    const { open, title, content, action, variant, altText, duration } = toastState;
    const mutManagerRef = React69.useMemo(
      () => managerRef && hasOwn2(managerRef, "current") ? managerRef : false,
      // eslint-disable-next-line react-hooks/exhaustive-deps -- manager ref must be stable throughout the component lifecycle
      []
    );
    const setOpen = React69.useCallback(
      (isOpen) => setToastState((prev) => __spreadProps(__spreadValues({}, prev), { open: isOpen })),
      []
    );
    const updateToast = React69.useCallback(
      (newState) => {
        if (newState.open && toastState.open) {
          const mergedState = __spreadValues(__spreadValues({}, toastState), newState);
          setOpen(false);
          setTimeout(() => {
            setToastState(mergedState);
          }, DEFAULT_REPLACE_TOAST_CLOSE_TIMEOUT_MS);
        } else {
          setToastState((prev) => __spreadValues(__spreadValues({}, prev), newState));
        }
      },
      [setOpen, toastState]
    );
    React69.useEffect(() => {
      if (mutManagerRef) {
        mutManagerRef.current = {
          setToastState,
          updateToast
        };
      }
    }, [mutManagerRef, updateToast]);
    React69.useEffect(() => {
      if (consumerDuration > 0) {
        setToastState((prev) => __spreadProps(__spreadValues({}, prev), { duration: consumerDuration }));
      } else {
        setToastState((prev) => __spreadProps(__spreadValues({}, prev), {
          duration: DEFAULT_TOAST_DURATION_MS
        }));
      }
    }, [consumerDuration]);
    return /* @__PURE__ */ jsxs60(ToastPrimitive2.Provider, { duration, children: [
      /* @__PURE__ */ jsxs60(
        StyledToastRoot,
        __spreadProps(__spreadValues({
          ref: forwardedRef,
          open,
          onOpenChange: setOpen,
          defaultOpen,
          variant,
          "data-testid": testId
        }, restProps), {
          children: [
            /* @__PURE__ */ jsxs60(StyledTitleContainer, { justifyContent: "space-between", children: [
              /* @__PURE__ */ jsxs60(StyledToastTitle, { children: [
                getToastVariantIcon(variant),
                title
              ] }),
              /* @__PURE__ */ jsx90(StyledToastClose, { "aria-label": "Close", onClick: () => setOpen(false), children: /* @__PURE__ */ jsx90(DismissIcon2, { "aria-hidden": true, name: "fa-xmark-small", size: "sm" }) })
            ] }),
            /* @__PURE__ */ jsxs60(StyledContentContainer, { flow: "column", gap: "xs", children: [
              /* @__PURE__ */ jsx90(StyledToastDescription, { children: content }),
              action && /* @__PURE__ */ jsx90(
                StyledToastAction,
                {
                  altText: altText || DEFAULT_TOAST_ACTION_ALT_TEXT,
                  asChild: true,
                  children: action
                }
              )
            ] })
          ]
        })
      ),
      /* @__PURE__ */ jsx90(
        StyledToastViewport,
        {
          css: __spreadValues(__spreadValues({}, defaultViewportStyles), viewportCss)
        }
      )
    ] });
  }
);

// src/Toast/ToastV2.tsx
import * as ToastPrimitives from "@radix-ui/react-toast";
import * as React70 from "react";
import { jsx as jsx91 } from "react/jsx-runtime";
var ToastProvider = ToastPrimitives.Provider;
var ToastViewport = React70.forwardRef((props, ref) => /* @__PURE__ */ jsx91(StyledToastViewport, __spreadValues({ ref }, props)));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
var Toast2 = React70.forwardRef((_a, ref) => {
  var _b = _a, { variant } = _b, props = __objRest(_b, ["variant"]);
  return /* @__PURE__ */ jsx91(StyledToastRoot, __spreadValues({ ref, variant }, props));
});
Toast2.displayName = ToastPrimitives.Root.displayName;
var ToastAction = React70.forwardRef((props, ref) => /* @__PURE__ */ jsx91(StyledToastAction, __spreadValues({ ref }, props)));
ToastAction.displayName = ToastPrimitives.Action.displayName;
var ToastClose = React70.forwardRef((props, ref) => /* @__PURE__ */ jsx91(StyledToastClose, __spreadProps(__spreadValues({ ref, "toast-close": "" }, props), { children: /* @__PURE__ */ jsx91(DismissIcon2, { "aria-hidden": true, name: "fa-xmark-small", size: "sm" }) })));
ToastClose.displayName = ToastPrimitives.Close.displayName;
var ToastTitle = React70.forwardRef((props, ref) => /* @__PURE__ */ jsx91(StyledToastTitle, __spreadValues({ ref }, props)));
ToastTitle.displayName = ToastPrimitives.Title.displayName;
var ToastDescription = React70.forwardRef((props, ref) => /* @__PURE__ */ jsx91(StyledToastDescription, __spreadValues({ ref }, props)));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

// src/Toast/use-toast.ts
import * as React71 from "react";
var TOAST_LIMIT = 1;
var TOAST_REMOVE_DELAY = 1e6;
var count = 0;
function genId() {
  count = (count + 1) % Number.MAX_VALUE;
  return count.toString();
}
var toastTimeouts = /* @__PURE__ */ new Map();
var addToRemoveQueue = (toastId) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }
  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: "REMOVE_TOAST",
      toastId
    });
  }, TOAST_REMOVE_DELAY);
  toastTimeouts.set(toastId, timeout);
};
var reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return __spreadProps(__spreadValues({}, state), {
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT)
      });
    case "UPDATE_TOAST":
      return __spreadProps(__spreadValues({}, state), {
        toasts: state.toasts.map(
          (t20) => t20.id === action.toast.id ? __spreadValues(__spreadValues({}, t20), action.toast) : t20
        )
      });
    case "DISMISS_TOAST": {
      const { toastId } = action;
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach((toastItem) => {
          addToRemoveQueue(toastItem.id);
        });
      }
      return __spreadProps(__spreadValues({}, state), {
        toasts: state.toasts.map(
          (t20) => t20.id === toastId || toastId === void 0 ? __spreadProps(__spreadValues({}, t20), {
            open: false
          }) : t20
        )
      });
    }
    case "REMOVE_TOAST":
      if (action.toastId === void 0) {
        return __spreadProps(__spreadValues({}, state), {
          toasts: []
        });
      }
      return __spreadProps(__spreadValues({}, state), {
        toasts: state.toasts.filter((t20) => t20.id !== action.toastId)
      });
  }
};
var listeners = [];
var memoryState = { toasts: [] };
function dispatch(action) {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
}
function toast(_a) {
  var props = __objRest(_a, []);
  const id = genId();
  const update = (updateProps) => dispatch({
    type: "UPDATE_TOAST",
    toast: __spreadProps(__spreadValues({}, updateProps), { id })
  });
  const dismiss = () => dispatch({ type: "DISMISS_TOAST", toastId: id });
  dispatch({
    type: "ADD_TOAST",
    toast: __spreadProps(__spreadValues({}, props), {
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) {
          dismiss();
        }
      }
    })
  });
  return {
    id,
    dismiss,
    update
  };
}
function useToast() {
  const [state, setState] = React71.useState(memoryState);
  React71.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);
  return __spreadProps(__spreadValues({}, state), {
    toast,
    dismiss: (toastId) => dispatch({ type: "DISMISS_TOAST", toastId })
  });
}

// src/Toast/Toaster.tsx
import { jsx as jsx92, jsxs as jsxs61 } from "react/jsx-runtime";
function Toaster() {
  const { toasts } = useToast();
  return /* @__PURE__ */ jsxs61(ToastProvider, { children: [
    toasts.map(
      (_a) => {
        var _b = _a, {
          id,
          title,
          content,
          action,
          variant,
          testid,
          hideCloseButton = false,
          duration = 5e3
        } = _b, props = __objRest(_b, [
          "id",
          "title",
          "content",
          "action",
          "variant",
          "testid",
          "hideCloseButton",
          "duration"
        ]);
        return /* @__PURE__ */ jsxs61(
          Toast2,
          __spreadProps(__spreadValues({
            testid,
            variant,
            duration
          }, props), {
            children: [
              /* @__PURE__ */ jsxs61(StyledTitleContainer, { justifyContent: "space-between", children: [
                /* @__PURE__ */ jsxs61(ToastTitle, { children: [
                  getToastVariantIcon(variant),
                  title
                ] }),
                !hideCloseButton && /* @__PURE__ */ jsx92(ToastClose, {})
              ] }),
              /* @__PURE__ */ jsxs61(StyledContentContainer, { flow: "column", gap: "xs", children: [
                content && /* @__PURE__ */ jsx92(ToastDescription, { children: content }),
                action && /* @__PURE__ */ jsx92(ToastAction, { altText: "toast action", asChild: true, children: action })
              ] })
            ]
          }),
          id
        );
      }
    ),
    /* @__PURE__ */ jsx92(ToastViewport, {})
  ] });
}

// src/Clickable/Clickable.tsx
import {
  forwardRef as forwardRef51
} from "react";
import { Button as Button3 } from "react-aria-components";
import { jsx as jsx93 } from "react/jsx-runtime";
var ClickableButton = styled(Button3, {
  background: "none",
  color: "inherit",
  border: "none",
  padding: 0,
  font: "inherit",
  cursor: "pointer",
  outline: "inherit",
  "&[data-disabled=true]": {
    cursor: "unset"
  }
});
function ClickableComponent(_a) {
  var _b = _a, {
    children,
    onClick,
    disabled = false
  } = _b, props = __objRest(_b, [
    "children",
    "onClick",
    "disabled"
  ]);
  return /* @__PURE__ */ jsx93(ClickableButton, __spreadProps(__spreadValues({}, props), { onPress: onClick, isDisabled: disabled, children }));
}
var Clickable = forwardRef51(ClickableComponent);

// src/Combobox/ComboboxComponents.tsx
import { Input, ListBox as ListBox2, ListBoxItem as ListBoxItem2, Popover as Popover3 } from "react-aria-components";
var StyledComboboxInput = styled(Input, BaseTextField);
var StyledComboboxPopover = styled(Popover3, {
  width: "var(--trigger-width)"
});
var StyledListBox2 = styled(ListBox2, StyledSelectV2.Content, {
  marginTop: -2,
  "&[data-focused]": {
    outline: "none"
  }
});
var StyledListBoxItem2 = styled(ListBoxItem2, StyledSelectV2.Item, {
  "&[data-focused]": {
    backgroundColor: "#505E7129"
  }
});
var StyledCombobox = {
  Input: StyledComboboxInput,
  Popover: StyledComboboxPopover,
  ListBox: StyledListBox2,
  ListBoxItem: StyledListBoxItem2
};

// src/Combobox/Combobox.tsx
import * as React72 from "react";
import { Button as Button4, ComboBox } from "react-aria-components";
import { jsx as jsx94, jsxs as jsxs62 } from "react/jsx-runtime";
var ComboboxComponent = ({
  options,
  value,
  placeholder,
  disabled = false,
  isCompact = false,
  iconName = "fa-angle-down-small",
  onInputChange,
  onOpenChange,
  onSelectionChange,
  onBlur
}, forwardRef53) => {
  return /* @__PURE__ */ jsxs62(
    ComboBox,
    {
      menuTrigger: "focus",
      onOpenChange,
      onInputChange,
      inputValue: value,
      onSelectionChange,
      onBlur,
      children: [
        /* @__PURE__ */ jsxs62(Flex, { alignItems: "center", children: [
          /* @__PURE__ */ jsx94(
            StyledCombobox.Input,
            {
              disabled,
              compact: isCompact,
              placeholder,
              onChange: (e) => onInputChange(e.target.value),
              ref: forwardRef53
            }
          ),
          /* @__PURE__ */ jsx94(
            Button4,
            {
              style: {
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                padding: 0,
                background: "transparent",
                border: "none",
                marginLeft: isCompact ? "-24px" : "-28px",
                width: 20,
                height: 20
              },
              children: /* @__PURE__ */ jsx94(Icon_default, { name: iconName, style: { width: 20, height: 20 } })
            }
          )
        ] }),
        /* @__PURE__ */ jsx94(StyledCombobox.Popover, { children: /* @__PURE__ */ jsx94(StyledCombobox.ListBox, { children: options.map((option) => /* @__PURE__ */ jsx94(StyledCombobox.ListBoxItem, { children: option.label }, option.value)) }) })
      ]
    }
  );
};
var Combobox = React72.forwardRef(ComboboxComponent);
export {
  ADD_APPLIED_FILTER_ACTION,
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AsyncSelect,
  Badge,
  Banner,
  BaseFilterButton,
  BaseGrid,
  BaseIconButton,
  BasePagination,
  Body,
  BoolFilterRadioGroup,
  BoolFilterType,
  Box_default as Box,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  ButtonGroup,
  ButtonSelect,
  Callout,
  Caption,
  Card,
  Checkbox,
  CheckboxFilterType,
  Chip,
  Clickable,
  Code,
  Combobox,
  StyledCombobox as ComboboxPrimitives,
  ConditionalTooltip,
  Counter,
  CreatableStringFilterMultiSelect,
  DateFilterType,
  DatePicker,
  DateRangePicker,
  DefaultSelect,
  Dialog,
  DialogPrimitives,
  DialogV2,
  DropdownMenu,
  DropdownMenuFilterFooter,
  DropdownMenuPrimitives,
  EnumFilterType,
  FilterButton,
  FilterSearchButton,
  FilterSearchMenu,
  publicFilterTypeBaseComponents as FilterTypeBase,
  Flex,
  Grid,
  Heading,
  Hint,
  Icon_default as Icon,
  IconButton,
  Label,
  Link,
  Loader,
  MultiSelect,
  MultiSelectFilterValue,
  NectarThemeContext,
  NectarThemeProvider,
  NumFilterType,
  OperatorFilterSelect,
  Pagination,
  PaginationPrimitives,
  Paragraph,
  Popover2 as Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  ProgressChips,
  REMOVE_APPLIED_FILTER_ACTION,
  Radio,
  RadioFilterType,
  RadioGroup,
  RelativeDatePicker,
  RelativeDateRangePicker,
  ScrollArea,
  ScrollAreaPrimitive3 as ScrollAreaPrimitive,
  ScrollBar,
  ScrollContainer,
  Section,
  SectionBody,
  SectionHeader,
  Select,
  publicSelectBaseComponents as SelectBase,
  SelectContents,
  SelectContext,
  SelectGroupedItems,
  SelectListItems,
  SelectPrimitive4 as SelectPrimitive,
  SelectTriggers,
  SelectV2,
  StyledSelectV2 as SelectV2Primitives,
  Separator3 as Separator,
  SeparatorPrimitive,
  SortableList,
  Stepper,
  StringFilterType,
  StyledMultiSelectV2,
  StyledRemoveFilter,
  Switch,
  Table,
  TablePrimitives,
  Tabs2 as Tabs,
  Tbody,
  Td,
  Text,
  TextArea,
  TextField,
  Tfoot,
  Th,
  Thead,
  TimeFilterType,
  Toast,
  publicToastBaseComponents as ToastBase,
  Toaster as Toaster_UNSTABLE,
  Tooltip,
  TooltipProvider,
  Tr,
  VersionFilterType,
  animations,
  boolToStringFilterOptionSchema,
  cn,
  config,
  constantsFactory,
  css,
  cssUtils,
  dropdownSizeTokens,
  extractCssUtilsFromProps,
  fadeIn,
  fadeOut,
  flexGapVariants,
  getGroupedOptions,
  getNullValue,
  hide,
  isCssUtilKeyGuard,
  isDateQuickFilterValue,
  isInBetweenOperator,
  isNotNullOperator,
  isNullOperator,
  isNullOrNotNullOperator,
  keyframes,
  marginUtilVariants,
  mergeTooltipProps,
  paddingUtilVariants,
  rotate,
  scrollContainerClassNames,
  publicSelectUtils as selectUtils,
  sizeUtilVariants,
  slideInFromLeft,
  slideInFromRight,
  stackingUtilVariants,
  stringToBoolFilterOptionSchema,
  styled,
  swipeOut,
  tableClassNames,
  thClassNames,
  theme,
  timings,
  useDialog,
  useSelect,
  useSelectContext,
  useStepper,
  useTheme,
  useToast as useToast_UNSTABLE,
  validateBoolFilterSchema,
  validateDateFilterSchema,
  validateEnumFilterSchema,
  validateNumFilterSchema,
  validateStringFilterSchema,
  validateVersionFilterSchema
};
